export interface IReportMemberDetails {
    Data: IReportMemberInfoData;
}

export interface IReportMemberInfoData {
    Birthday: string | null;
    IsMale: string | null; //
    Remark: string;
    MemberName: string;
    MemberNickName: string;
    RegisterDate: string;
    UserName: string;
    PartnerId: number;
    AgentUserCode: string;
    AgentNickName: string;
    DomainName: string;
    RegisterDomain: string;
}

export enum ContactTypeEnum {
    VChat = 1,
    QQ = 2,
    Mobile = 3,
    WeChat = 4,
    Live360 = 5,
    NineOneNine = 6,
    EightEightSix = 7,
}

export enum ContactTypeGroupEnum {
    PersonalContact = 1,
    LiveAccount = 2,
}

export interface IMemberDetailsRepoprtSummary {
    ReportMonth: number;
    IsActive: boolean;
    BetRate: number;
}

export interface IMemberDetailsContacts {
    ContactTypeGroupId: ContactTypeGroupEnum;
    ContactTypeId: ContactTypeEnum;
    ContactContent: string;
    AllowEdit?: boolean;
    IsUnique?: boolean;
}

export interface IReportMemberStatisticInfoData {
    AmountSummary: number;
    LatestUpdated: string;
    LatestAmount: number;
    Remark?: string;
    TransactionNo?: string;
}

export interface IReportMemberStatisticsData {
    FirstRecharge: IReportMemberStatisticInfoData;
    Recharge: IReportMemberStatisticInfoData;
    Deposit: IReportMemberStatisticInfoData;
    WinLose: IReportMemberStatisticInfoData;
    BetAmount: IReportMemberStatisticInfoData;
    Withdraw: IReportMemberStatisticInfoData;
    Rebate: IReportMemberStatisticInfoData;
    Bonus: IReportMemberStatisticInfoData;
}

export interface IMemberDetailsData {
    Contacts: IMemberDetailsContacts[];
    MemberInfo: IReportMemberInfoData;
    MemberStatistic: IReportMemberStatisticsData;
    RelatedMemberCount: number;
    RelatedReportSummary: IMemberDetailsRepoprtSummary[];
    SelfReportSummary: IMemberDetailsRepoprtSummary[];
}

export type IMemberDetailStatisticsData =
    | IMemberDetailStatisticRechargeData
    | IMemberDetailStatisticDepositData
    | IMemberDetailStatisticWinLoseData
    | IMemberDetailStatisticWithdrawData;

export interface IMemberDetailStatisticRechargeData {
    TotalRecord: number;
    TotalAmount: number;
    Summary: Array<{
        Remark: string;
        Amount: number;
        TotalRecord: number;
    }>;
    Data: Array<{
        RechargeTime: string;
        Amount: number;
        TransactionNo: string;
        Remark: string;
    }>;
}

export interface IMemberDetailStatisticDepositData {
    TotalRecord: number;
    TotalAmount: number;
    Summary: Array<{
        TotalRecord: number;
        DepositAmount: number;
        SubType: string;
    }>;
    Data: Array<{
        CreateTime: string;
        Status: string;
        Amount: number;
        SubType: string;
    }>;
}

export interface IMemberDetailStatisticWithdrawData {
    Summary: {
        TotalRecords: number;
        Amount: number;
        CommFee: number;
    };
    Data: Array<{
        CreateTime: string;
        Status: string;
        Amount: number;
    }>;
}

export interface IMemberDetailStatisticWinLoseData {
    TotalRecord: number;
    TotalWinLose: number;
    TotalBetAmount: number;
    MemberGameGroupStatistics: Array<{
        GameGroupName: string;
        TotalWin: number;
        BetAmount: number;
    }>;
    MemberGameProductStatistics: Array<{
        ProductName: string;
        TotalWin: number;
        BetAmount: number;
    }>;
}

export interface IMemberContactsRequest {
    Contacts: IMemberDetailsContacts[];
    AgentUserCode: string;
    PartnerId: number;
    UserName: string;
    MemberName: string;
    MemberNickName: string;
    BirthDay?: string | null;
    IsMale?: string | null;
    Remark: string;
}
