import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { RelationMemberData, RelationMemberItem } from '@/models/page/report/IRelationMember';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function relationMember(input: RelationMemberData, pageNo?: number, pageSize?: number): ITableLayoutData {
    const outputConfig: TableConfig<RelationMemberItem> = {
        column: [
            { name: 'serialId', text: 'Lbl_serial_number', itemKeys: ['SerialId'], type: TableColumnStyle.Text },
            { name: 'groupName', text: 'Th_GroupName', itemKeys: ['GroupName'], type: TableColumnStyle.Text },
            { name: 'groupId', text: '', itemKeys: ['GroupId'], type: TableColumnStyle.Numeric, invisible: true },
            { name: 'relationAccountCount', text: 'Th_RelationAccountCount', itemKeys: ['RelatedMember'], type: TableColumnStyle.Numeric },
            { name: 'month', text: 'Th_month', itemKeys: ['ReportMonth'], type: TableColumnStyle.DateMonth },
            { name: 'currentValidCount', text: 'Th_CurrentValidCount', itemKeys: ['EffectiveRelatedMember'], type: TableColumnStyle.Numeric },
            { name: 'depositAmount', text: 'Lbl_RechargeSumForRelation', itemKeys: ['RechargeAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'rechargeAmount', text: 'Lbl_UpAmount', itemKeys: ['UpAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'withdrawAmount', text: 'Th_withdrawAmount', itemKeys: ['WithdrawAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'betAmountAmount', text: 'Th_betAmount', itemKeys: ['BetAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'winLossAmount', text: 'Th_totalWin', itemKeys: ['TotalWin'], type: TableColumnStyle.CommaNumericWithSymbol },
            { name: 'rebateAmount', text: 'Lbl_Bonus', itemKeys: ['BonusAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'returnAmount', text: 'Lbl_Rebate', itemKeys: ['RebateAmount'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'bettingRate',
                text: 'Th_BettingRate',
                itemKeys: ['BetRate'],
                type: TableColumnStyle.VIPRank,
            },
            {
                name: 'status',
                text: 'Th_status',
                itemKeys: ['ReportStatus'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    return `Lbl_SettlementStatus_${data.ReportStatus}`;
                },
            },
        ],
        footer: ['depositAmount', 'rechargeAmount', 'withdrawAmount', 'betAmountAmount', 'winLossAmount', 'rebateAmount', 'returnAmount'],
    };

    const addSerialIdToData = input.Data.map((item, index) => ({
        ...item,
        SerialId: index + 1 + (pageNo ? (pageNo - 1) * (pageSize || 1) : 0),
    }));

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(addSerialIdToData, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.TotalRecords, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
    // return { tableHeader, tableBody, tableFooter, totalRecords: 0, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
