
export class CommManager {
  constructor(private _callback: CommCallback) {}

  public dispatcher(event: CommEvent) {
      this._callback(event);
  }
}

export type CommCallback = (event: CommEvent) => void;

export type CommEvent = {
    action: 'info',
    params: {
        info: string,
    }
} | {
    action: 'contact',
    params: {
        userId: number,
    }
} | {
    action: 'messageCount',
    params: {
        count: number,
    }
} | {
    action: 'session',
    params: {
        type: 'group',
        id: number,
    } | {
        type: 'session',
        id: string,
    }
}
