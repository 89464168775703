import { numberComma } from '@/service/utils';
import { ICardBoardProps, ICardBoardTabProps, ICardItem, ICardTableBoardProps, IInfoItem } from '@/models/page/dashboard/IDashboard';
import { ButtonsGroup } from './buttonsGroup';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { NumberHighlight } from '@/components/numberHighlight/numberHighlight';
import Hint from '@/components/hint';
import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import { useTranslation } from 'react-i18next';
// for tailwind
const cssClass = 'grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5';

export const CardInfoBoard = (props: ICardBoardProps) => {
    return (
        <div>
            <div className=" flex justify-between m-1 pl-2">
                <h1 className="font-black text-lg">
                    <span>{props.title}</span> <Hint title={props.helper?.title} content={props.helper?.content}></Hint>
                </h1>
            </div>
            <div className={`grid grid-cols-${props.cols} grid-flow-row-dense gap-1`}>
                {props.infos ? <InfoItem items={props.infos}></InfoItem> : ''}
                {props.items.map((item) => (
                    <CardItem key={`ci_${item.title}`} {...item}></CardItem>
                ))}
            </div>
        </div>
    );
};

export const CardTabBoard = (props: ICardBoardTabProps) => {
    return (
        <div className=''>
            <div className=" flex justify-between m-1 pl-2">
                <h1 className="font-black text-lg">
                    <span className="mr-1">{props.title}</span>
                    <Hint title={props.helper?.title} content={props.helper?.content}></Hint>
                </h1>
                <ButtonsGroup buttons={props.buttons} onClick={props.onClick}></ButtonsGroup>
            </div>
            <div className={`grid grid-cols-${props.cols} grid-flow-row-dense gap-1`}>
                {props.infos ? <InfoItem items={props.infos}></InfoItem> : ''}
                {props.items.map((item, index) => (
                    <CardItem key={`ci_${item.title}_${index}`} {...item} titleStyle={props.titleStyle}></CardItem>
                ))}
            </div>
        </div>
    );
};

export const CardBoard = (props: ICardBoardProps) => {
    return (
        <>
            <h1 className="font-black text-lg">{props.title}</h1>
            <div className={`grid grid-cols-${props.cols} grid-flow-row-dense gap-1`}>
                {props.infos ? <InfoItem items={props.infos}></InfoItem> : ''}
                {props.items.map((item) => (
                    <CardItem key={`ci_${item.title}`} {...item}></CardItem>
                ))}
            </div>
        </>
    );
};

export const InfoItem = (props: { items: IInfoItem<string>[] }) => {
    return (
        <>
            <div className="w-48 m-2 border-solid border-l-2 border-indigo-600 flex flex-col justify-between">
                {props.items.map((item, idx) => (
                    <div key={`it_${item.key}`}>
                        <span className="ml-1 text-gray-800 font-bold">{item.text}</span>
                        <span className="ml-2 text-blue-600 font-semibold">{item.displayValue ? item.displayValue : item.value}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export const CardItem = (props: ICardItem) => {
    const style = props.titleStyle || {
        width: 'fit-content',
        top: '-11px',
        left: '50%',
        transform: 'translateX(-50%)',
    };

    return (
        <>
            <div key={props.title} className={'border-solid border-2 border-gray-200 m-2 p-1 relative rounded-lg flex flex-col h-24'}>
                <span className="absolute w-fit font-bold text-lg pt-2" style={style}>
                    {props.title}
                </span>
                <div className="flex flex-col mt-8">
                    <div className="flex justify-between ">
                        <span>{`${props.cureentTitle}`}:</span>
                        {props.isHighlight ? (
                            <NumberHighlight value={props.cureent} cssClass="text-lg font-bold"></NumberHighlight>
                        ) : (
                            <span>{numberComma(FloatHelper.floatFixed(props.cureent, RoundType.Floor, 0))}</span>
                        )}
                    </div>
                    <div className="flex justify-between text-sm mt-1 text-gray-500">
                        <span>{`${props.lastTitle}`}:</span>
                        {props.isHighlight ? (
                            <NumberHighlight value={props.last}></NumberHighlight>
                        ) : (
                            <span>{numberComma(FloatHelper.floatFixed(props.last, RoundType.Floor, 0))}</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export const CardTableBoard = (props: ICardTableBoardProps) => {
    const { t } = useTranslation();
    return (
        <div className='pl-2'>
            <div className=" flex justify-between m-1 ">
                <h1 className="font-black text-lg">{props.title}</h1>
                <ButtonsGroup buttons={props.buttons} onClick={props.onClick} defaultSelectedKey={props.defaultSelectedKey}></ButtonsGroup>
            </div>
            <div className="flex m-2">
                <div className=" flex flex-col whitespace-nowrap font-bold mr-2">
                    {props.rowNames.map((row, idx) => (
                        <span key={`ctbrow_${row}`} className={`${idx !== props.rowNames.length - 1 ? 'border-b-2 border-gray-100' : ''}`}>
                            {row}
                        </span>
                    ))}
                </div>
                <div className="flex overflow-x-auto">
                    {props.items.map((item, i) => (
                        <div key={`ctbr_${item.title}_${i}`} className={'flex flex-col flex-1 '}>
                            <span className=" text-right whitespace-nowrap border-b-2 border-gray-200 pl-3">{item.title}</span>
                            <span className=" text-right whitespace-nowrap border-b-2 border-gray-200 pl-3">
                                {t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[item.partner].text}`)}
                            </span>
                            <span className=" text-right pl-3">{numberComma(FloatHelper.floatFixed(item.value, RoundType.Floor, 0))}</span>
                            <NumberHighlight value={item.total} cssClass={'bg-slate-200 pl-3'}></NumberHighlight>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
