import { IMemberAdminPageProps } from '@/pages/memberAdmin/admin/pageConfig';

export enum StatusEnum {
    All = 0,
    Effective = 1,
    InEffective = 2,
}

export enum VipLevelEnum {
    All = -1,
    Lv0 = 0,
    Lv1 = 1,
    Lv2 = 2,
    Lv3 = 3,
    Lv4 = 4,
    Lv5 = 5,
    Lv6 = 6,
    Lv7 = 7,
    Lv8 = 8,
    Lv9 = 9,
}

export type IRelationAccountQueryProps = {
    DateFrom: string;
    DateTo: string;
    PartnerId: number;
    EffectiveStatus: StatusEnum;
} & IMemberAdminPageProps;

export type IRelationAccountItemModel = {
    PartnerId: number;
    AgentNickName: string;
    AgentUserCode: string;
    UserName: string; // Member Account
    MemberNickname: string;
    RegisterDate: string;
    VipLevel: VipLevelEnum;
    EffectiveStatus: StatusEnum;
    UnBetDays: number;
    FirstRechargeAmount: number;
    RechargeAmount: number;
    UpAmount: number;
    WithdrawAmount: number;
    BetAmount: number;
    TotalWin: number;
    BonusAmount: number;
    RebateAmount: number;
    IsReadOnly: boolean;
};

export type IRelationAccountResponseModel = {
    GroupName: string;
    GroupId: number;
    Details: IRelationAccountItemModel[];
};

export type IRelationAccountSummaryModel = {
    FirstChargeAmount: number;
    RechargeAmount: number;
    UpAmount: number;
    WithdrawAmount: number;
    BetAmount: number;
    TotalWin: number;
    BonusAmount: number;
    RebateAmount: number;
};

export type IMemberPartner = {
    AgentUserCode: string;
    PartnerId: number;
    UserName: string;
};

export type IRelationUpdateMemberGroupModel = {
    IsUpdateDetail: boolean;
    GroupId: number;
    GroupName: string;
    MemberPartners: IMemberPartner[];
};
