import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import LoadingMask from '@/components/loadingMask/loadingMask';
import MemberModel from '@/components/memberModel';
import useMemberInfo from '@/hooks/useMemberInfo';
import { api } from '@/service/api';
import { Avatar, Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { summonChat } from '@/store/slices/chat.slice';
import cn from 'mxcn';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';
import { useCommContext } from '@/hooks/useComm';
import { IChatFriendModel, IMemberInfo, useFriendList } from './useFriendList';

const tableCol = [
    { label: 'Lbl_serialId', name: 'Id' },
    { label: 'Lbl_MyFriends', name: 'Name' },
    { label: 'Th_PartnerAndMember', name: 'Members' },
    { label: 'Lbl_optional', name: 'optional' },
];

export const MyFriendListComp = () => {
    const [ref, onModelTrigger] = useMemberInfo();
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const dispatch = useDispatch();
    const commAnnounce = useCommContext()

    const { friends, isLoading } = useFriendList()

    const onClickBrandMember = (it: IMemberInfo) => {
        onModelTrigger(api.memberDetails, { userName: it.MemberUserName, partnerId: it.PartnerId });
    };

    const onClickChat = (item: IChatFriendModel) => {
        dispatch(summonChat());
        commAnnounce?.({
            action: 'session',
            params: {
                type: 'session',
                id: item.VChatId,
            },
        });
    };

    return (
        <>
            <LoadingMask visible={isLoading} />
            <div>
                <TableContainer>
                    <Table variant="striped" colorScheme="blackAlpha">
                        <Thead>
                            <Tr>
                                {tableCol.map((item) => (
                                    <Th key={`aff-th-${item.label}`}>
                                        <span className="font-bold">{t(item.label)}</span>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {!!friends &&
                                friends.length > 0 &&
                                friends.map((item, idx) => (
                                    <Tr key={`tr-key-${item.NickName}`}>
                                        <Td>{idx + 1}</Td>
                                        <Td>
                                            <div className="flex">
                                                <div className="flex-1 flex items-stretch">
                                                    <Avatar name={item.NickName} src={item.HeadImg} />
                                                    <div className="ml-2 self-center font-bold">{item.NickName}</div>
                                                </div>
                                                {item.MsgCount > 0 ? (
                                                    <div className="rounded-full bg-red-600 w-7 h-7 self-center flex justify-center text-gray-300">
                                                        <div className="self-center">{item.MsgCount}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Td>
                                        <Td>
                                            {item.PartnerUsers.map((it, idx) => (
                                                <div
                                                    key={`tbl-td-f-${it.PartnerId}-${it.MemberUserName}-${item.NickName}`}
                                                    className={cn({ 'mb-1': idx === 0 })}>
                                                    {permissionList.includes(PermissionsId.MemberStatistics) ? (
                                                        <Button variant="link" className="mr-2" colorScheme="blue" onClick={() => onClickBrandMember(it)}>
                                                            {it.MemberUserName} ({t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[it.PartnerId].text}`)})
                                                        </Button>
                                                    ) : (
                                                        <span>
                                                            {it.MemberUserName} ({t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[it.PartnerId].text}`)})
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                        </Td>
                                        <Td>
                                            <span
                                                className="icon icon-chat-list bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 "
                                                onClick={() => onClickChat(item)}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            {(!friends || friends.length === 0) && (
                                <Tr>
                                    <Td colSpan={tableCol.length} textAlign="center">
                                        {t('Lbl_NoRecord')}
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
            <MemberModel ref={ref} />
        </>
    );
};
