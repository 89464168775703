import { PopupModel } from '@/components/popupModal/popupModal';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, UseDisclosureProps } from '@chakra-ui/react';

export type IBasePopupProps<T> = {
    title?: string;
    data: T[];
    cols: IColInfo<T>[];
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
} & UseDisclosureProps;

export const ICOL_INFO_ENUM = {
    Text: 'Text',
    Component: 'Component',
} as const;

export type ICOL_INFO_ENUM_TYPE = keyof typeof ICOL_INFO_ENUM;

export type IColInfo<T> = {
    label: string;
    name: keyof T;
    type: ICOL_INFO_ENUM_TYPE;
    displayComponent?: (item: T) => JSX.Element;
};

export const BaseTablePopup = <T,>(params: IBasePopupProps<T>) => {
    const { t } = useTranslation();

    const getComp = (col: IColInfo<T>, item: T) => {
        switch (col.type) {
            case ICOL_INFO_ENUM.Text: {
                return <>{item[col.name]}</>;
            }
            case ICOL_INFO_ENUM.Component: {
                return <>{col.displayComponent ? col.displayComponent(item) : <></>}</>;
            }
            default: {
                return <>{item[col.name]}</>;
            }
        }
    };

    return (
        <>
            <PopupModel {...params}>
                <div className=" mb-5">
                    <TableContainer>
                        <Table variant="striped" colorScheme="blackAlpha">
                            <Thead>
                                <Tr>
                                    <Th key={'aff-th-serial-id'}>
                                        <span className="font-bold">{t('Lbl_serialId')}</span>
                                    </Th>
                                    {params.cols.map((item) => {
                                        return (
                                            <Th key={`aff-th-${item.label}`}>
                                                <span className="font-bold">{t(item.label)}</span>
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {!!params.data && params.data.length > 0 ? (
                                    params.data.map((item, idx) => (
                                        <Tr key={`tr-key-${JSON.stringify(item)}`}>
                                            <Td>{idx + 1}</Td>
                                            {params.cols.map((col) => (
                                                <Td key={`base-td-key-${col.label}-${idx}`}>{getComp(col, item)}</Td>
                                            ))}
                                        </Tr>
                                    ))
                                ) : (
                                    <></>
                                )}

                                {!params.data || params.data.length === 0 ? (
                                    <Tr>
                                        <Td colSpan={params.cols.length} className="text-center" textAlign="center">
                                            {t('Lbl_NoRecord')}
                                        </Td>
                                    </Tr>
                                ) : (
                                    <></>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </div>
            </PopupModel>
        </>
    );
};
