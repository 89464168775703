import { getEnableConfig } from '@/assets/config/common/partner.config';
import MultiSelect from '@/components/multiSelect';
import { useTranslation } from 'react-i18next';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { RootState } from '@/store/store.config';
import { Select } from '@chakra-ui/react';
import { useObservable, useSubscription } from 'observable-hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Subject } from 'rxjs';

export type ITabBoardProp = {
    // title: string;
    tabs: ITabSetting[];
    onChange: (item: { partnerId: number; agentIdList: string[] }) => void;
    defaultTabId: number;
};

export type ITabSetting = {
    id: number;
    baseClass?: string;
    activeClass?: string;
    text: string;
};

export const TabSelectOptionBoard = React.memo((props: ITabBoardProp) => {
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState(props.defaultTabId);
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) => state.userStore.agentPartners);
    const defaultSelectedUserList = useMemo(() => {
        return agentUserList
            .filter((item) => item.IsEnabled)
            .filter((item) => activeKey === 0 || activeKey === ~~item.PartnerId)
            .map((config) => {
                return config.AgentUserId;
            });
    }, [activeKey]);

    const agentListKey = useMemo(() => {
        return agentUserList
            .filter((item) => activeKey === 0 || activeKey === ~~item.PartnerId)
            .map((c) => {
                const p = `Lbl_Partner_${getEnableConfig().find((list) => ~~list.id === ~~c.PartnerId)!.text}`;
                return {
                    id: c.AgentUserId,
                    text: `${c.NickName}(${t(p)})`,
                };
            });
    }, [activeKey, t]);

    const onChange$ = useObservable(
        () =>
            new Subject<{
                partnerId: number;
                agentIdList: string[];
            }>(),
    );

    const multiSelectOnChangeHandler = useCallback(
        (newValue: string[]) => {
            onChange$.next({ partnerId: activeKey, agentIdList: newValue });
        },
        [activeKey],
    );

    useSubscription(onChange$, (item) => {
        props.onChange(item);
    });

    const style = {};

    return (
        <div className="flex items-center divide-x-2 divide-gray-200 text-lg">
            <div className={' ml-2 font-bold text-lg'} style={style}>
                {t('Th_partnerId') + '-' + t('Th_agentUserList')}
            </div>
            <div className="flex">
                <div className="">
                    <Select
                        width={'28'}
                        variant={'outline'}
                        className={'ml-2'}
                        color="GrayText"
                        borderColor="orange"
                        defaultValue={activeKey}
                        onChange={(e) => {
                            const value = Number(e.target.value);
                            setActiveKey(value);
                        }}>
                        {props.tabs.map((tab) => (
                            <option key={`sel_${tab.id}_${tab.text}`} value={tab.id}>
                                {tab.text}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="ml-6">-</div>
                <div className="ml-4">
                    <MultiSelect
                        className="w-[150px] h-[40px]"
                        label="全部"
                        options={agentListKey || []}
                        defaultSelectOptions={defaultSelectedUserList}
                        onChange={multiSelectOnChangeHandler}></MultiSelect>
                </div>
            </div>
        </div>
    );
});
