import { useCallback } from 'react';
import { TableColumnStyle } from '@/models/layout/tableLayout';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { numberComma, parseCommissionType } from '@/service/utils';
import { useTranslation } from 'react-i18next';

export default function useTransferTableValue() {
    const { t } = useTranslation();
    const transferTableValue = useCallback(
        (text: string | number, style: TableColumnStyle): string => {
            const temp = parseCommissionType(text, style);
            switch (style) {
                case TableColumnStyle.CommaNumericWithIcon: {
                    const [key, icon] = temp.toString().split('_with_');
                    const num = numberComma(FloatHelper.floatFixed(parseFloat(key), RoundType.Floor, 0));
                    return num.toString();
                }
                case TableColumnStyle.CommaNumericWithSymbol:
                case TableColumnStyle.CommaNumericWithNegativeSymbol:
                    return temp.toString();
                case TableColumnStyle.Partner:
                    return t(temp as string).toString();
                case TableColumnStyle.TextI18N:
                    return t(temp as string).toString();
                case TableColumnStyle.TextI18NWithColor: {
                    const [key, color] = temp.toString().split('_with_');
                    return t(key as string).toString();
                }
                case TableColumnStyle.TextWithColor: {
                    const [value, color] = text.toString().split('/color:');
                    return value;
                }
                case TableColumnStyle.Icon: {
                    return text.toString();
                }
                case TableColumnStyle.VIPRank: {
                    return text.toString();
                }

                default:
                    return temp.toString();
            }
        },
        [t],
    );

    return transferTableValue;
}
