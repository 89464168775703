import React from 'react';
import dayjs from 'dayjs';
import { numberComma } from '@/service/utils';
import cn from 'mxcn';
import { MemberStatisticType } from '@/pages/memberAdmin/admin/statistics/useStatisticsData';
import {
    IMemberDetailStatisticDepositData,
    IMemberDetailStatisticRechargeData,
    IMemberDetailStatisticsData,
    IMemberDetailStatisticWinLoseData,
    IMemberDetailStatisticWithdrawData,
} from '@/models/page/report/IReportMemberDetails';
import { TFunction } from 'i18next';
import { FloatHelper } from '@/service/floatHelper';

export interface IMemberItineraryTableLayoutConfig<T = IMemberDetailStatisticsData, U = any> {
    layoutName: string;
    collectionGetter: (data: T) => U[];
    headers: string[];
    contentBuilder: (entry: U, data: T) => (React.ReactNode | ((t: TFunction) => React.ReactNode))[];
    footerBuilder?: (data: T) => (React.ReactNode | ((t: TFunction) => React.ReactNode))[];
}

const getMemberItineraryBetAmountTableLayoutConfig = (type: 'BetAmount' | 'WinLose') =>
    ({
        layoutName: type === 'WinLose' ? 'Lbl_GameWinLose' : 'Th_betAmount',
        headers: ['Th_game', 'Th_betAmount', 'Th_totalWin'],
        collectionGetter: (data) => ('MemberGameProductStatistics' in data ? data.MemberGameProductStatistics : []),
        contentBuilder: (entry: IMemberDetailStatisticWinLoseData['MemberGameProductStatistics'][0]) => [
            entry.ProductName,
            '$' + numberComma(FloatHelper.floatFixed(entry.BetAmount, 'Floor', 0)),
            <span
                key={entry.ProductName}
                className={cn({
                    'text-red-600': entry.TotalWin > 0,
                    'text-green-600': entry.TotalWin < 0,
                })}>
                {'$' + numberComma(FloatHelper.floatFixed(entry.TotalWin, 'Floor', 0))}
            </span>,
        ],
        footerBuilder: (data) => [
            (t) => t('Lbl_total-summary'),
            '$' + numberComma(FloatHelper.floatFixed((data as IMemberDetailStatisticWinLoseData)['TotalBetAmount'], 'Floor', 0)),
            <span
                key="total-win-lose"
                className={cn({
                    'text-red-600': (data as IMemberDetailStatisticWinLoseData)['TotalWinLose'] > 0,
                    'text-green-600': (data as IMemberDetailStatisticWinLoseData)['TotalWinLose'] < 0,
                })}>
                {'$' + numberComma(FloatHelper.floatFixed((data as IMemberDetailStatisticWinLoseData)['TotalWinLose'], 'Floor', 0))}
            </span>,
        ],
    }) as IMemberItineraryTableLayoutConfig;

export const memberItineraryTableLayoutConfigs: Record<MemberStatisticType, IMemberItineraryTableLayoutConfig> = {
    BetAmount: getMemberItineraryBetAmountTableLayoutConfig('BetAmount'),
    WinLose: getMemberItineraryBetAmountTableLayoutConfig('WinLose'),
    Recharge: {
        layoutName: 'Lbl_RechargeRecord',
        collectionGetter: (d) => ('Data' in d ? d.Data : ([] as IMemberDetailStatisticRechargeData[])),
        headers: ['Lbl_RechargeTime', 'Th_balance', 'Th_tradeNo', 'Th_note'],
        contentBuilder: (entry: IMemberDetailStatisticRechargeData['Data'][0]) => [
            dayjs(entry.RechargeTime).format('YYYY-MM-DD hh:mm:ss'),
            '$' + numberComma(FloatHelper.floatFixed(entry.Amount, 'Floor', 0)),
            entry.TransactionNo,
            <span key={entry.TransactionNo}>{entry.Remark}</span>,
        ],
        footerBuilder: (data) => [
            (t) => t('Lbl_total-summary'),
            '$' + numberComma(FloatHelper.floatFixed((data as IMemberDetailStatisticRechargeData).TotalAmount, 'Floor', 0)),
        ],
    },
    Withdraw: {
        layoutName: 'Lbl_WithdrawRecord',
        collectionGetter: (d) => ('Data' in d ? d.Data : ([] as IMemberDetailStatisticWithdrawData[])),
        headers: ['Lbl_WithdrawTime', 'Th_balance', 'Th_status'],
        contentBuilder: (entry: IMemberDetailStatisticWithdrawData['Data'][0]) => [
            dayjs(entry.CreateTime).format('YYYY-MM-DD hh:mm:ss'),
            '$' + numberComma(FloatHelper.floatFixed(entry.Amount, 'Floor', 0)),
            (t) =>
                entry.Status ? (
                    <span key={entry.CreateTime} className="text-red-600">
                        {t('Txt_success')}
                    </span>
                ) : (
                    <span key={entry.CreateTime} className="text-green-600">
                        {t('Txt_fail')}
                    </span>
                ),
        ],
        footerBuilder: (data) => [
            (t) => t('Lbl_total-summary'),
            '$' + numberComma(FloatHelper.floatFixed((data as IMemberDetailStatisticWithdrawData).Summary.Amount, 'Floor', 0)),
        ],
    },
    Deposit: {
        layoutName: 'Lbl_DepositRecord',
        collectionGetter: (d) => ('Data' in d ? d.Data : ([] as IMemberDetailStatisticDepositData[])),
        headers: ['Lbl_DepositTime', 'Th_balance', 'Th_status'],
        contentBuilder: (entry: IMemberDetailStatisticDepositData['Data'][0]) => [
            dayjs(entry.CreateTime).format('YYYY-MM-DD hh:mm:ss'),
            '$' + numberComma(FloatHelper.floatFixed(entry.Amount, 'Floor', 0)),
            (t) =>
                entry.Status ? (
                    <span key={entry.CreateTime} className="text-red-600">
                        {t('Txt_success')}
                    </span>
                ) : (
                    <span key={entry.CreateTime} className="text-green-600">
                        {t('Txt_fail')}
                    </span>
                ),
        ],
        footerBuilder: (data) => [
            (t) => t('Lbl_total-summary'),
            '$' + numberComma(FloatHelper.floatFixed((data as IMemberDetailStatisticDepositData).TotalAmount, 'Floor', 0)),
        ],
    },
};
