import { HintEnum } from '../hint/hint';

export const hintMapping: Record<HintEnum, { title: string; content: string[] }> = {
    [HintEnum.MemberCommission]: {
        title: '本月佣金帮助',
        content: [
            '总盈利：本账号时间段内的投注输赢金额；',
            '净盈利：公司输赢-成本（紅利+平台费+ 存提手续费+累加上月）；',
            '预计会员佣金：当前账号下级会员投注亏损产生的佣金；当会员佣金为负或为0时，佣金比例显示为100%；',
            '预计代理佣金：当前账号的二级、三级代理产生会员佣金时，本账号将获得部份佣金奖励；',
            '预计代充返利：代理为会员代充后将获得的返利金额；',
            '每月1日-5日进行上月的总佣金结算，结算后于每月5日-10日开放代理佣金提款；',
            '红色正数代表公司盈利，绿色负数代表公司成本。',
        ],
    },
    [HintEnum.ProxyCommission]: {
        title: '代理佣金帮助',
        content: [
            '总盈利：直属会员时间段内的投注输赢金额；',
            '预计代理佣金：当前账号的二级、三级代理产生会员佣金时，本账号将获得部份佣金奖励；',
            '每月1日-5日进行上月的总佣金结算，结算后于每月5日-10日开放代理佣金提款；',
            '红色正数代表公司盈利，绿色负数代表公司成本；',
        ],
    },
    [HintEnum.OperateSummary]: {
        title: '运营数据帮助',
        content: [
            '总盈利：本账号时间段内的投注输赢金额；',
            '下注会员数：时间段内有投注行为的会员数；',
            '下注金额：会员真实下注包含已取消、和局、无效、有效订单数量；',
            '首存会员数：第一次充值的会员数，包括充值和代充；',
            '首存金额：时间段内会员第一次充值的总金额，包括充值和代充；',
            '充值会员数：只统计会员自己充值的人数；',
            '充值金额：只统计会员自己充值的金额；',
            '代充会员数：只统计会员通过代理代充渠道的人数；',
            '代充金额：只统计会员通过代理代充渠道的充值金额；',
        ],
    },
    [HintEnum.AgentRebate]: {
        title: '代充返利帮助',
        content: [
            '预计代充返利：代理为会员代充后将获得的返利金额；',
            '每月1日-5日进行上月的总佣金结算，结算后于每月5日-10日开放代理佣金提款；',
            '红色正数代表公司盈利，绿色负数代表公司成本；',
        ],
    },
};
