import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { IMemberChatHistoryModalRef, MemberChatHistoryModal } from '@/components/memberChatHistoryModal/memberChatHistoryModal';
import { api } from '@/service/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';
import { summonChat } from '@/store/slices/chat.slice';
import { useFriendList } from '@/components/myFriendListComp/useFriendList';
import { useAddVChatFriend } from '@/components/chatView/useAddVChatFriend';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { useCommContext } from '@/hooks/useComm';

export interface ChatViewProps {
    chatId: string | null;
    agentUserCode: string;
    partnerId: number;
    userName: string;
}

export const ChatView = ({ chatId, partnerId, agentUserCode, userName }: ChatViewProps) => {
    const { t } = useTranslation();
    const modalRef = useRef<IMemberChatHistoryModalRef>(null);
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const dispatch = useDispatch();
    const commAnnounce = useCommContext()
    const { friends, isLoading: isFriendListLoading, reload: reloadFriendlist } = useFriendList();
    const [addFriend, isAddFriendLoading, isRequested] = useAddVChatFriend({
        onResponded: (rep) => {
            reloadFriendlist({});
        },
    });

    const isFriend = useMemo(() => friends.some((it) => it.VChatId === chatId), [friends, chatId]);

    const isLoading = isAddFriendLoading || isFriendListLoading;

    function getChatHistory() {
        modalRef.current?.onModelDataTrigger(api.memberChatHistory, {
            PartnerId: partnerId,
            MemberUserName: userName,
        });
    }

    function handleClickChat () {
        if (chatId) {
            dispatch(summonChat());
            commAnnounce?.({
                action: 'session',
                params: {
                    type: 'session',
                    id: chatId,
                },
            });
        }
    }

    return (
        <div className="flex mb-3 gap-x-6">
            {chatId &&
                (isFriend ? (
                    <Button type="button" onClick={handleClickChat}>
                        {t('Th_Start_Chat_With', [chatId])}
                    </Button>
                ) : (
                    <Button type="button" isLoading={isAddFriendLoading} onClick={() => addFriend({ vChatId: chatId })}>
                        {t('Lbl_Chat_Add_Friend')}
                    </Button>
                ))}

            {isFriend && permissionList.includes(PermissionsId.ViewChatHistory) && (
                <>
                    <Button type="button" onClick={getChatHistory}>
                        {t('Th_Chat_History')}
                    </Button>
                    <MemberChatHistoryModal ref={modalRef} userName={userName} partnerId={partnerId} agentUserCode={agentUserCode} />
                </>
            )}

            <LoadingMask visible={isLoading} />
        </div>
    );
};
