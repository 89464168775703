import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import { IRegisterMemberPara } from '@/models/apiRequest';
import {
    ICardTablePagingBoardProps,
    IPagingDataType,
    IRealtimeHandler,
    IRealtimeTableConstValue, IRegisterMember,
    IRegisterMemberSummary,
} from '@/models/page/dashboard/IDashboard';
import { api } from '@/service/api';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { DATETIME_FORMAT } from '@/constants/datetime';

export function useRegisterMember({
                                      agentIdList,
                                      buttonSettings,
                                      onPageChange,
                                      onUpdateRealtimeData,
                                      partnerId,
                                      onUpdateRealtimeType,
                                      userId
                                  }: IRealtimeHandler) {
    const { t } = useTranslation();

    const transformData = useCallback(
        (data: IRegisterMemberSummary): ICardTablePagingBoardProps<IRegisterMember> => {
            const records = data.Data;
            return {
                totalRecords: data.TotalRecords,
                title: t('Lbl_RealtimeReport'),
                buttons: buttonSettings,
                titleNames: [t('Th_partnerId'), t('Lbl_MemberAccount'), t('Lbl_RegistDomain'), t('Lbl_RegistTime')],
                keys: ['PartnerId', 'UserName', 'DomainName', 'RegisterDate'],
                items: records,
                types: [IPagingDataType.Partner, IPagingDataType.Text, IPagingDataType.Text, IPagingDataType.DateMonth],
                onClick: (key) => {
                    onUpdateRealtimeType(key as IRealtimeTableConstValue);
                },
                onPagingChange: (page) => {
                    onPageChange(page);
                    handleRegisterMembers({ PageNo: page, PartnerId: partnerId, UserIdList: agentIdList });
                },
            };
        },
        [partnerId, agentIdList, t],
    );

    const [registerMembersData, setRegisterMembersData] = useState<IRegisterMemberSummary>();

    const handleRegisterMembers = useFetchApi<Partial<IRegisterMemberPara>, IRegisterMemberSummary>(
        api.registerMembers,
        ({ value }) => {
            const base = {
                DateFrom: dayjs().subtract(5, 'd').startOf('d').format(DATETIME_FORMAT.YMDHMSS),
                DateTo: dayjs().endOf('d').format(DATETIME_FORMAT.YMDHMSS),
                PartnerId: partnerId,
                UserId: userId,
                PageNo: 1,
                PageSize: 10,
            } as IRegisterMemberPara;

            return { ...base, ...value };
        },
        ({ value: { data } }) => {
            if (data.IsSuccess) {
                setRegisterMembersData(data.Data);
                onUpdateRealtimeData(transformData(data.Data));
            } else {
            }
        },
    );

    return [registerMembersData, handleRegisterMembers] as const;
}
