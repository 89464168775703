import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { UserRoleStatusEnum } from '@/models/page/systemSetting';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function accountManagement(input: any, pageNo?: number, pageSize?: number): ITableLayoutData {
    const outputConfig: TableConfig<any> = {
        column: [
            { name: 'serialId', text: 'Lbl_serial_number', itemKeys: ['SerialId'], type: TableColumnStyle.Text },
            { name: 'userName', text: 'Th_userName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'name', text: 'Th_Name', itemKeys: ['Name'], type: TableColumnStyle.Text },
            { name: 'role', text: 'Th_Role', itemKeys: ['Role'], type: TableColumnStyle.Text },
            { name: 'registerDate', text: 'Th_registerDate', itemKeys: ['RegisterDate'], type: TableColumnStyle.Date },
            {
                name: 'status',
                text: 'Th_status',
                itemKeys: ['Status'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    return data.Status === UserRoleStatusEnum.Enable ? 'Lbl_enable' : 'Lbl_disable';
                },
            },
            { name: 'agent_M6', text: 'Agent_M6', itemKeys: ['Agent_M6'], type: TableColumnStyle.Numeric },
            { name: 'agent_BB', text: 'Agent_BB', itemKeys: ['Agent_BB'], type: TableColumnStyle.Numeric },
            { name: 'agent_N8', text: 'Agent_N8', itemKeys: ['Agent_N8'], type: TableColumnStyle.Numeric },
        ],
        footer: [],
    };

    const addSerialIdToData = input.Data.map((item: any, index: number) => ({
        ...item,
        SerialId: index + 1 + (pageNo ? (pageNo - 1) * (pageSize || 1) : 0),
    }));

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(addSerialIdToData, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.TotalRecords, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
