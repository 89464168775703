import { ITableLayoutData, TableColumnStyle, TableHeaderTextList, TableHeaderType, TableSearch } from '@/models/layout/tableLayout';
import { commissionHistoryData } from '@/service/modelTransfer/history/commissionHistory';
import { memberCommissionHistoryData } from '@/service/modelTransfer/history/memberCommissionHistory';

export enum HistoryTableLayoutEnum {
    NONE,
    COMMISSION_HISTORY,
    MEMBER_COMMISSION_HISTORY,
}

export type HistoryTableLayoutConfig = {
    layoutId: HistoryTableLayoutEnum;
    pageName: string;
    headerType: TableHeaderType;
    search?: TableSearch[] | TableSearch[][];
    headerText?: TableHeaderTextList[];
    parseFetchDataFun: (input: any) => ITableLayoutData;
};

export const historyTableLayoutConfig: HistoryTableLayoutConfig[] = [
    {
        pageName: 'commissionHistory',
        layoutId: HistoryTableLayoutEnum.COMMISSION_HISTORY,
        headerType: TableHeaderType.TEXT,
        search: [],
        headerText: [
            { title: '代理', key: 'agentNickName', type: TableColumnStyle.Text },
            { title: '品牌', key: 'partnerId', type: TableColumnStyle.Partner },
            { title: '日期', key: 'date', type: TableColumnStyle.DateMonth },
        ],
        parseFetchDataFun: commissionHistoryData,
    },
    {
        pageName: 'memberCommissionHistory',
        layoutId: HistoryTableLayoutEnum.MEMBER_COMMISSION_HISTORY,
        headerType: TableHeaderType.TEXT,
        headerText: [
            // { title: '代理', key: 'agentNickName', type: TableColumnStyle.Text },
            { title: '品牌', key: 'partnerId', type: TableColumnStyle.Partner },
            { title: '日期', key: 'date', type: TableColumnStyle.DateMonth },
        ],
        parseFetchDataFun: memberCommissionHistoryData,
    },
];
