import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Comlink from 'comlink';
import { CommEvent, CommManager } from './CommManager';

export type CommAnnouncer = (event: CommEvent) => void;

export function useComm(ref?: React.RefObject<HTMLIFrameElement>) {
  const [isReady, setReady] = useState(false);
  const [messages, setMessages] = useState<CommEvent[]>([]);
  const commRef = useRef<Comlink.Remote<CommManager> | null>(null);

  const dispatcher = useCallback<CommAnnouncer>((event: CommEvent) => {
    setMessages((s) => [...s, event]);
  }, []);

  const announce = useCallback<CommAnnouncer>((event: CommEvent) => {
    commRef.current?.dispatcher(event);
  }, [])

  useEffect(() => {
    if (commRef.current) return;

    const contentWindow = ref?.current?.contentWindow ?? self.parent;
    const remote = Comlink.wrap<CommManager>(Comlink.windowEndpoint(contentWindow));
    Comlink.expose(new CommManager(Comlink.proxy(dispatcher)), Comlink.windowEndpoint(self));

    commRef.current = remote;
    setReady(true);

    remote
      .dispatcher({
        action: 'info',
        params: {
          info: Date.now().toString(),
        },
      });
  }, [commRef, dispatcher, ref]);

  return {
    isReady,
    messages,
    lastMessage: messages[messages.length - 1] as CommEvent | undefined,
    announce,
    terminate() {
      setMessages([]);
    },
  };
}
