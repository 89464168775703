import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { IDialogSetting, showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IResponse } from '@/models/entities/IResponse';

interface IAddVChatFriend {
    vChatId: string;
}

interface IUseAddVChatFriendParams {
    onResponded?: (data: IResponse<null>) => void;
}

export function useAddVChatFriend({ onResponded }: IUseAddVChatFriendParams = {}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [requested, setRequested] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetch = useFetchApi<IAddVChatFriend, null, null>(
        api.addVChatUserFriend,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            if (value.data.IsSuccess) {
                const message: { [key: string]: string } = { 'ID not exists.': t('Msg_PleaseBindingVChatId') };
                const dialogSetting: IDialogSetting = value.data.Data
                    ? {
                          title: t('Lbl_Button_Info'),
                          content: `${t('Lbl_Already_Added_Friend')}`,
                          type: DialogButtonType.Info,
                      }
                    : {
                          title: t('Lbl_Button_Error'),
                          content: `${message[value.data.Message] || value.data.Message}`,
                          type: DialogButtonType.Info,
                      };

                dispatch(showDialog(dialogSetting));
                setRequested(true);
                onResponded?.(value.data);
            }
            setIsLoading(false);
        },
        [],
        () => {
            setIsLoading(false);
        },
    );

    return [fetch, isLoading, requested] as const;
}
