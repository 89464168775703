import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';

import { IReportGame, IReportGameData } from '@/models/page/report/IReportGame';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';
import { numberComma } from '@/service/utils';

export function gameData(input: IReportGame): ITableLayoutData {
    const outputConfig: TableConfig<IReportGameData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'game', text: 'Th_game', itemKeys: ['Game'], type: TableColumnStyle.Text },
            { name: 'users', text: 'Th_betUser', itemKeys: ['Users'], type: TableColumnStyle.Numeric },
            { name: 'effectAmount', text: 'Th_betAmount', itemKeys: ['EffectAmount'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'amount',
                text: 'Lbl_PLaceBetAmount',
                itemKeys: ['Amount'],
                type: TableColumnStyle.NumericText,
                calculate: (data, keys) => {
                    if (data.PartnerId === 3) {
                        return '-';
                    }
                    return numberComma(FloatHelper.floatFixed(data.Amount, RoundType.Floor, 0));
                },
            },
            { name: 'validAmount', text: 'Th_validAmount', itemKeys: ['ValidAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'prize', text: 'Th_prize', itemKeys: ['Prize'], type: TableColumnStyle.CommaNumeric },
            { name: 'totalWin', text: 'Th_totalWin', itemKeys: ['TotalWin'], type: TableColumnStyle.CommaNumericWithSymbol },
            { name: 'betWay', text: '', itemKeys: ['BetWay'], type: TableColumnStyle.CommaNumericWithSymbol, invisible: true },
            { name: 'gamePlatFormId', text: '', itemKeys: ['GamePlatFormId'], type: TableColumnStyle.CommaNumericWithSymbol, invisible: true },
        ],
        footer: ['users', 'effectAmount', 'amount', 'prize', 'validAmount', 'totalWin'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);
    tableFooter['amount'] = numberComma(FloatHelper.floatFixed(parseFloat(tableFooter['amount'] + ''), RoundType.Floor, 0)); // 寫死，這個值要去千分位

    return { tableHeader, tableBody, tableFooter, totalRecords: 1, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}

// 有少欄位
