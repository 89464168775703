import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import { HintEnum } from '@/models/hint/hint';
import { hintMapping } from '@/models/mapping/hint.mapping';
import {
    ICardTableBoardProps,
    ICardBoardTabProps,
    ICardBoardProps,
    IDashboardParams,
    IDashboard,
    IDashboardFilterOptions,
    IDashboardFilterOptionsValue,
    ICardItem,
    ICardTableItem,
    IButtonSetting,
    IInfoItem,
    ICardInfoTextBoardProps,
    IInfoTextItem,
    ITextBoardProp,
    IOtherInfoTextBoardProp,
} from '@/models/page/dashboard/IDashboard';
import { api } from '@/service/api';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { numberComma } from '@/service/utils';
import { useState } from 'react';

export function useDashboard() {
    const { t } = useTranslation();
    const [gameStaticData, setGameStaticData] = useState<ICardTableBoardProps>();
    const [operationStatisticData, setOperationStatisticData] = useState<ICardBoardTabProps>();
    const [memberCommissionData, setMemberCommissionData] = useState<ICardBoardProps>();
    const [proxyCommission, setProxyCommissionData] = useState<ICardBoardProps>();
    const [rechargeRebateData, setRechargeRebateData] = useState<ICardInfoTextBoardProps>();
    const [textBoardProp, setTextBoardPropData] = useState<ITextBoardProp>();
    const [otherInfoTextBoardProp, setOtherInfoTextBoardProp] = useState<IOtherInfoTextBoardProp>();
    const handleDashboard = useFetchApi<IDashboardParams, IDashboard>(
        api.dashboard,
        ({ value }) => {
            return value;
        },
        ({ value: { data } }) => {
            if (data.IsSuccess) {
                // setDashboardData(data.Data);
                setGameStaticData(genGameStatistic(data.Data, IDashboardFilterOptions.Today)); // default props
                setOperationStatisticData(genOperationStatistic(data.Data, IDashboardFilterOptions.Today));
                setMemberCommissionData(genMemberCommission(data.Data));
                setProxyCommissionData(genProxyCommission(data.Data));
                setRechargeRebateData(genRechargeRebate(data.Data));
                setTextBoardPropData({ LastUpdatedDate: data.Data.OtherInfo.LastUpdatedDate });
                setOtherInfoTextBoardProp({
                    EffectiveUserCount: data.Data.OtherInfo.EffectiveUserCount,
                    HistoryRechargeUsers: data.Data.OtherInfo.HistroyRechargeUsers,
                });
            } else {
            }
        },
    );

    const genOperationStatisticItems = (dashboard: IDashboard, key: IDashboardFilterOptionsValue): ICardItem[] => {
        const orders = [
            'NewUsers',
            'BetUsers',
            'FirstRechargeNum',
            'RechargeNum',
            'UpAmountUser',
            'TotalWin',
            'BetAmount',
            'FirstRechargeSum',
            'RechargeSum',
            'UpAmount',
        ] as const;

        const titleMapping: Record<string, string> = {
            'RechargeSum': 'Lbl_RechargeSumForRelation',
        }

        const currentItem = dashboard.OperationStatistic[key] || {};
        const mapping: { [name: string]: string[] } = {
            [IDashboardFilterOptions.Today]: [IDashboardFilterOptions.Today, IDashboardFilterOptions.Yesterday],
            [IDashboardFilterOptions.ThisMonth]: [IDashboardFilterOptions.ThisMonth, IDashboardFilterOptions.LastMonth],
        };
        const lastKey = mapping[key][1] || key;
        const lastItem = dashboard.OperationStatistic[lastKey] || {};

        return orders.map((name) => {
            return {
                title: t((name in titleMapping) ? titleMapping[name] as string : `Lbl_${name}`),
                cureent: currentItem[name] || 0,
                last: lastItem[name] || 0,
                isHighlight: false,
                cureentTitle: t(`Lbl_${mapping[key][0]}`),
                lastTitle: t(`Lbl_${mapping[key][1]}`),
            } as ICardItem;
        });
        // dashboard.OperationStatistic[key][];
    };

    const genButtons = (keys: string[]) => {
        return keys.map((key) => {
            return {
                key: key,
                text: t(`Lbl_${key}`),
            } as IButtonSetting;
        });
    };

    const genOperationStatistic = (dashboardData: IDashboard, key: IDashboardFilterOptionsValue): ICardBoardTabProps => {
        return {
            cols: 5,
            title: t('Lbl_OperationSummary'),
            items: genOperationStatisticItems(dashboardData, key),
            buttons: genButtons([IDashboardFilterOptions.Today, IDashboardFilterOptions.ThisMonth]),
            defaultSelectedKey: IDashboardFilterOptions.Today,
            onClick: (key: string) => {
                setOperationStatisticData(genOperationStatistic(dashboardData, key as IDashboardFilterOptionsValue));
            },
            helper: hintMapping[HintEnum.OperateSummary],
        };
    };

    const genMemberCommission = (dashboardData: IDashboard): ICardBoardProps => {
        // TotalWin from OperationStatistic
        const orders = ['TotalWin', 'NetWin', 'Bonus', 'Commission', 'PlatformFee', 'DepositWithdrawFee', 'Rebate'] as const;
        const highlightKeys = ['TotalWin', 'NetWin', 'Commission'];

        const currentMap = dashboardData.MemberCommission[IDashboardFilterOptions.ThisMonth];
        const lastMap = dashboardData.MemberCommission[IDashboardFilterOptions.LastMonth];

        const curTotalWin = dashboardData.OperationStatistic[IDashboardFilterOptions.ThisMonth].TotalWin;
        const lastTotalWin = dashboardData.OperationStatistic[IDashboardFilterOptions.LastMonth].TotalWin;

        const curLastAmount = currentMap.LastAmount || 0;
        const lastLastAmount = lastMap.LastAmount || 0;

        const items = orders.map((key) => {
            const item = {
                title: t(`Lbl_${key}`),
                cureent: key === 'TotalWin' ? curTotalWin : currentMap[key],
                last: key === 'TotalWin' ? lastTotalWin : lastMap[key],
                isHighlight: highlightKeys.includes(key),
                cureentTitle: t(`Lbl_${IDashboardFilterOptions.ThisMonth}`),
                lastTitle: t(`Lbl_${IDashboardFilterOptions.LastMonth}`),
            } as ICardItem;
            return item;
        });

        return {
            cols: 4,
            title: t('Lbl_MemberCommission'),
            infos: [
                { key: '佣金比例', text: t('Th_commRate'), value: dashboardData.CommissionRate, displayValue: `${dashboardData.CommissionRate}%` },
                {
                    key: '累计上月',
                    text: t('Th_lastAmount'),
                    value: curLastAmount,
                    displayValue: `${numberComma(FloatHelper.floatFixed(curLastAmount, RoundType.Floor, 0))}`,
                },
                {
                    key: '累计上上月',
                    text: t('Th_lastlastAmount'),
                    value: lastLastAmount,
                    displayValue: `${numberComma(FloatHelper.floatFixed(lastLastAmount, RoundType.Floor, 0))}`,
                },
            ] as IInfoItem<string>[],
            items: items,
            helper: hintMapping[HintEnum.MemberCommission],
        } as ICardBoardProps;
    };

    const genGameStatistic = (dashboardData: IDashboard, key: IDashboardFilterOptionsValue): ICardTableBoardProps => {
        const orderKeys: string[] = [IDashboardFilterOptions.Today, IDashboardFilterOptions.TwoDayAgo, IDashboardFilterOptions.ThisMonth];

        const btkeys = Object.keys(dashboardData.GameStatistic)
            .filter((key) => dashboardData.GameStatistic[key] && dashboardData.GameStatistic[key].length > 0)
            .sort((a, b) => orderKeys.indexOf(a) - orderKeys.indexOf(b));

        return {
            title: t('Lbl_GameDataSummary'),
            rowNames: [t('Lbl_GameName'), t('Th_partnerId'), t('Lbl_EffectBet'), t('Lbl_WinLose')],
            buttons: genButtons(btkeys),
            defaultSelectedKey: IDashboardFilterOptions.Today,
            onClick: (key: string) => {
                setGameStaticData(genGameStatistic(dashboardData, key as IDashboardFilterOptionsValue));
            },
            items: dashboardData.GameStatistic[key as string].map((item) => {
                return {
                    title: item.Game,
                    value: item.ValidAmount,
                    total: item.TotalWin,
                    partner: item.PartnerId,
                } as ICardTableItem;
            }),
        };
    };

    const genProxyCommission = (dashboardData: IDashboard): ICardBoardProps => {
        // TotalWin from OperationStatistic
        const orders = ['ProxyComm', 'L2ProxyComm', 'L3ProxyComm'] as const;
        const highlightKeys = ['ProxyComm', 'L2ProxyComm', 'L3ProxyComm'];
        const titleMap = ['Lbl_DefaultCommission', 'Lbl_SecondaryCommission', 'Lbl_ThirdCommission'];

        const currentMap = dashboardData.ProxyCommission[IDashboardFilterOptions.ThisMonth];
        const lastMap = dashboardData.ProxyCommission[IDashboardFilterOptions.LastMonth];

        const items = orders.map((key, idx) => {
            const item = {
                title: t(titleMap[idx]),
                cureent: currentMap[key],
                last: lastMap[key],
                isHighlight: highlightKeys.includes(key),
                cureentTitle: t(`Lbl_${IDashboardFilterOptions.ThisMonth}`),
                lastTitle: t(`Lbl_${IDashboardFilterOptions.LastMonth}`),
            } as ICardItem;
            return item;
        });

        return {
            cols: 3,
            title: t('Lbl_ThisMonthCommission'),
            items: items,
            helper: hintMapping[HintEnum.ProxyCommission],
        } as ICardBoardProps;
    };

    const genRechargeRebate = (dashboardData: IDashboard): ICardInfoTextBoardProps => {
        // TotalWin from OperationStatistic

        const tab: IInfoTextItem = {
            title: t('Lbl_EstRechargeRebate'),
            items: [IDashboardFilterOptions.ThisMonth, IDashboardFilterOptions.LastMonth].map((key) => {
                return {
                    key: key,
                    value: dashboardData.ProxyCommission[key].UpAmountReturn,
                    text: t(`Lbl_${key}`),
                    displayValue: numberComma(FloatHelper.floatFixed(dashboardData.ProxyCommission[key].UpAmountReturn, RoundType.Floor, 0)) + '',
                };
            }),
        };

        return {
            title: t('Lbl_ThisMonthRechargeRebate'),
            infos: [
                { key: '返利比例', text: t('Lbl_RebateRate'), value: 0.5, displayValue: `${0.5}%` },
                { key: '最低发放额度', text: t('Lbl_MinimumPayoutAmount'), value: 1, displayValue: `${numberComma(1)}` },
                { key: '上月未发放返利', text: t('Lbl_LastMonthNoRebate'), value: 0, displayValue: `${numberComma(0)}` },
            ] as IInfoItem<string>[],
            tab: tab,
            helper: hintMapping[HintEnum.AgentRebate],
        } as ICardInfoTextBoardProps;
    };

    return {
        handleDashboard,
        memberCommissionData,
        proxyCommission,
        operationStatisticData,
        gameStaticData,
        rechargeRebateData,
        textBoardProp,
        otherInfoTextBoardProp,
    };
}
