import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import cn from 'mxcn';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';
import { useMemo } from 'react';

type IRouteTab = {
    router: string;
    text: string;
    permission: PermissionsId;
};

export const MemberAdminTabs = () => {
    const { t } = useTranslation();
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);

    const mapping: IRouteTab[] = useMemo(
        () =>
            [
                {
                    router: '/member',
                    text: 'Nav_member',
                    permission: PermissionsId.PersonalInformation,
                },
                {
                    router: '/cross-system',
                    text: 'Nav_crossSystem',
                    permission: PermissionsId.SecurityCenter,
                },
            ].filter((item) => permissionList.includes(item.permission)),
        [permissionList],
    );

    return (
        <>
            <div className="flex mt-1">
                {mapping.map((item) => (
                    <NavLink to={item.router} className="flex" key={`navlink-${item.router}`}>
                        {({ isActive }) => (
                            <div
                                className={cn(
                                    'min-w-[100px] leading-10 h-10 text-center whitespace-nowrap pr-2 pl-2',
                                    isActive && 'text-gray-800 font-bold bg-orange-300',
                                )}>
                                {t(item.text)}
                            </div>
                        )}
                    </NavLink>
                ))}
            </div>
        </>
    );
};
