import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportFund, IReportFundData } from '@/models/page/report/IReportFund';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function fundData(input: IReportFund): ITableLayoutData {
    const outputConfig: TableConfig<IReportFundData> = {
        column: [
            { name: 'date', text: 'Th_date', itemKeys: ['Date'], type: TableColumnStyle.Date },
            { name: 'newUser', text: 'Th_newUser', itemKeys: ['NewUser'], type: TableColumnStyle.Numeric },
            { name: 'betUser', text: 'Th_betUser', itemKeys: ['BetUser'], type: TableColumnStyle.Numeric },
            { name: 'effectiveAmount', text: 'Th_betAmount', itemKeys: ['EffectiveAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'totalWin', text: 'Th_totalWin', itemKeys: ['TotalWin'], type: TableColumnStyle.CommaNumericWithSymbol },
        ],
        footer: ['newUser', 'betUser', 'effectiveAmount', 'totalWin'],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.TotalRecords, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
