import { useTranslation } from 'react-i18next';
import { Image, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Select, useDisclosure } from '@chakra-ui/react';
import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { APIModel } from '@/models/apiRequest';
import { IMemberChatHistoryRequest, useMemberChatHistoryData } from '@/components/memberChatHistoryModal/useMemberChatHistoryData';
import { api } from '@/service/api';
import LoadingMask from '@/components/loadingMask/loadingMask';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from '@/constants/datetime';
import cn from 'mxcn';
import { ChatMessageBox } from './chatMessageBox';
import { IChatMessage, IChatMessageCard, IChatMessageGroup, MESSAGE_TYPE } from '@/models/chatMessage/IChatMessage';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';

export interface IMemberChatHistoryModalRef {
    onModelDataTrigger(api: APIModel, request: IMemberChatHistoryRequest): void;
}

interface IMemberChatHistoryModalProps {
    agentUserCode: string;
    partnerId: number;
    userName: string;
}

export const MemberChatHistoryModal = forwardRef<IMemberChatHistoryModalRef, IMemberChatHistoryModalProps>(memberChatHistoryModal);

export function memberChatHistoryModal({ userName, partnerId }: IMemberChatHistoryModalProps, ref: ForwardedRef<IMemberChatHistoryModalRef>) {
    const { t } = useTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchTerm, setSearchTerm] = useState('');
    const chatFromUsers = useSelector((state: RootState) => state.userStore.userVChatInfos);
    const [clickedItem, setClickedItem] = useState<IChatMessage>();

    const [fromUserId, setFromUserId] = useState<number>();

    const [chatHistory, setMemberChatHistory, fetchChatHistory, isLoading] = useMemberChatHistoryData();

    const fetch = useCallback(
        (params: Partial<IMemberChatHistoryRequest> = {}) => {
            fetchChatHistory({
                api: api.ChatHistory,
                PartnerId: partnerId,
                MemberUserName: userName,
                UserId: fromUserId,
                ...params,
                Keyword: '',
            });
        },
        [fromUserId],
    );

    const currentMessages = useMemo(() => {
        if (!chatHistory?.messages || chatHistory.messages.length === 0) {
            return [];
        }

        if (!searchTerm || searchTerm.length === 0) {
            return chatHistory?.messages;
        }

        const filterMessages: IChatMessage[] = [];

        const searchReg = RegExp(searchTerm, 'ig');

        for (const item of chatHistory.messages) {
            switch (item.MsgType) {
                case MESSAGE_TYPE.audio:
                case MESSAGE_TYPE.video:
                case MESSAGE_TYPE.pic:
                    {
                        const temp = item.Message.split('/');
                        const filename = temp[temp.length - 1];

                        if (searchReg.test(filename)) {
                            filterMessages.push(item);
                        }
                    }
                    break;
                case MESSAGE_TYPE.file:
                    {
                        const [, name] = item.Message.split('|') || [];
                        if (searchReg.test(name)) {
                            filterMessages.push(item);
                        }
                    }
                    break;
                case MESSAGE_TYPE.invite:
                    {
                        const cardInfo: IChatMessageGroup = JSON.parse(item.Message || '');
                        if (searchReg.test(cardInfo.GroupName)) {
                            filterMessages.push(item);
                        }
                    }
                    break;
                case MESSAGE_TYPE.card:
                    {
                        const cardInfo: IChatMessageCard = JSON.parse(item.Message || '');
                        if (searchReg.test(cardInfo.NickName)) {
                            filterMessages.push(item);
                        }
                    }
                    break;

                case MESSAGE_TYPE.txt:
                    {
                        const emojiRegexp = RegExp(/\[e\d+\]|\n/, 'ig');

                        const hasvalue = !!item.Message.split(emojiRegexp).find((msg) => msg.includes(searchTerm));
                        if (hasvalue) {
                            filterMessages.push(item);
                        }
                    }
                    break;
                default:
                    break;
            }
        }

        return filterMessages;
    }, [chatHistory?.messages, searchTerm]);

    useImperativeHandle(ref, () => ({
        onModelDataTrigger: (api, request) => {
            fetch(request);
            onOpen();
        },
    }));

    useEffect(() => {
        if (!isOpen) return;
        fetch();
    }, [fromUserId]);

    const onFromUserChange = (userId: number) => {
        setFromUserId(userId);
        setSearchTerm('');
        setClickedItem(undefined);
    };

    const handleMessageClick = (item: IChatMessage) => {
        setSearchTerm('');
        setClickedItem(item);
    };

    useEffect(() => {
        if (!clickedItem) return;

        const element = document.querySelector(`#msg_${+new Date(clickedItem.CreateTime)}`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, [currentMessages]);

    const selectedItemCss = 'font-bold bg-gray-300';
    const selectedItemImageCss = 'w-20';

    const onModalClose = () => {
        setFromUserId(undefined);
        setSearchTerm('');
        setMemberChatHistory(null);
        setClickedItem(undefined);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onModalClose} size="6xl" isCentered scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent className="h-[80vh]">
                <ModalHeader className="flex flex-col">
                    <div className="flex items-center rounded-t">
                        <div className="flex-1 text-center">{t('Th_Chat_History')}</div>

                        <button type="button" onClick={onModalClose}>
                            <span className="icon icon-cancel-circle" />
                        </button>
                    </div>

                    <div className="bg-gray-200 inset-x-0 grid grid-cols-2 grid-rows-3 gap-2 items-center p-2 font-normal text-base">
                        <div className="flex items-center">
                            <div className="basis-1/5">{t('Th_userName')}：</div>
                            <div className="font-medium flex-1">{userName}</div>
                        </div>
                        <div className="flex items-center">
                            <div className="basis-1/5">{t('Th_VChatNickName')}：</div>
                            <div className="font-medium flex-1">
                                {!!chatHistory?.toUser.UserNickName
                                    ? `${chatHistory?.toUser.UserNickName || '-'} (${chatHistory?.toUser.UserVChatId || '-'})`
                                    : '-'}
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="basis-1/5">{t('Th_Chat_TargetName')}：</div>
                            <div className="flex-1">
                                <Select background="white" onChange={(e) => onFromUserChange(Number(e.target.value))}>
                                    {chatFromUsers.map((item) => (
                                        <option key={item.UserId} value={item.UserId}>
                                            {item.NickName} ({item.VChatId})
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="flex col-start-1 items-center">
                            <div className="basis-1/5">{t('Th_Content')}：</div>
                            <div className="flex-1">
                                <Input
                                    background="white"
                                    placeholder={t('Lbl_Search')}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.currentTarget.value)}
                                />
                            </div>
                        </div>
                    </div>
                </ModalHeader>

                <ModalBody style={{ paddingTop: 0 }} className="flex flex-col gap-4">
                    <ul className="bg-gray-100 p-4 flex-1">
                        {currentMessages.map((v) => (
                            <li
                                key={+new Date(v.CreateTime)}
                                className={`group flex gap-2 border-b  border-b-stone-200 p-2 cursor-pointer hover:font-bold hover:bg-gray-200 ${
                                    clickedItem?.CreateTime === v.CreateTime ? selectedItemCss : ''
                                }`}
                                id={`msg_${+new Date(v.CreateTime)}`}
                                data-msgtype={v.MsgType}
                                onClick={() => !!searchTerm && handleMessageClick(v)}>
                                <div>
                                    <Image
                                        src={v.UserHeadImg}
                                        className={`w-16 aspect-square group-hover:w-20 ${
                                            clickedItem?.CreateTime === v.CreateTime ? selectedItemImageCss : ''
                                        }`}
                                    />
                                </div>

                                <div className="flex-1 flex flex-col gap-1">
                                    <span className={cn('text-sm text-stone-500', [v.UserId === chatHistory?.toUser.UserId && 'font-bold'])}>
                                        {v.UserNickName}
                                    </span>
                                    <ChatMessageBox searchTerm={searchTerm} {...v} />
                                </div>

                                <div className="text-stone-500">{dayjs(v.CreateTime).format(DATETIME_FORMAT.YMDHMS)}</div>
                            </li>
                        ))}

                        {currentMessages.length === 0 ? (
                            <li className="group flex gap-2  border-b-stone-200 p-2">
                                <div className="my-4 flex justify-center w-full">
                                    <span className="text-5xl text-gray-400 icon icon-notification">
                                        {!!chatHistory?.toUser.UserNickName ? t('Lbl_NoChatMessage') : t('Lbl_Chat_Not_Friend')}
                                    </span>
                                </div>
                            </li>
                        ) : (
                            ''
                        )}
                    </ul>
                </ModalBody>
            </ModalContent>

            <LoadingMask visible={isLoading} />
        </Modal>
    );
}
