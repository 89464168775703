import React, { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, MenuGroup, MenuOptionGroup, MenuItemOption, MenuButtonProps } from '@chakra-ui/react';
import { TableSearchOption } from '@/models/layout/tableLayout';
import { useTranslation } from 'react-i18next';

export type MultiSelectProps = {
    label: string;
    options: TableSearchOption[];
    defaultSelectOptions: number[];
    onChange?: (selectedValues: string[]) => void;
    buttonProps?: MenuButtonProps;
    className?: string;
};
const MultiSelect = (props: MultiSelectProps): JSX.Element => {
    const { label, options, buttonProps, className = '', defaultSelectOptions = [] } = props;
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState<string[]>(() => options.filter((v) => defaultSelectOptions.includes(v.id)).map((v) => v.id + ''));
    const [optionKey, setOptionKey] = useState(0);
    useEffect(() => {
        const result = options.filter((v) => defaultSelectOptions.includes(v.id)).map((v) => v.id + '');
        setSelectedOptions(result);
        props.onChange?.(result);
        setOptionKey(Date.now());
    }, [options, defaultSelectOptions]);
    return (
        <Menu closeOnSelect={false}>
            {({ onClose, forceUpdate }) => (
                <>
                    <MenuButton
                        type="button"
                        backgroundColor={'white'}
                        color={'gray.500'}
                        borderColor={'gray.100'}
                        borderWidth={1}
                        fontSize={'md'}
                        p={2}
                        px={4}
                        borderRadius="5px"
                        _focus={{
                            outline: 'none',
                        }}
                        className={className}
                        {...buttonProps}>
                        {selectedOptions.length === options.length ? label : ` 已选择(${selectedOptions.length})`}
                    </MenuButton>
                    <MenuList maxHeight={'260px'} overflowY={'auto'}>
                        <MenuGroup title={undefined}>
                            <MenuItem
                                onClick={() => {
                                    setSelectedOptions(options.map((v) => v.id + ''));
                                    props.onChange?.(options.map((v) => v.id + ''));
                                    // onClose();
                                    setOptionKey(Date.now());
                                }}>
                                {t('Lbl_selectAll')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setSelectedOptions([]);
                                    props.onChange?.([]);
                                    // onClose();
                                    setOptionKey(Date.now());
                                }}>
                                {t('Lbl_clearAll')}
                            </MenuItem>
                        </MenuGroup>
                        <MenuDivider />
                        <MenuOptionGroup
                            key={optionKey}
                            title={undefined}
                            defaultValue={selectedOptions}
                            type="checkbox"
                            /* eslint-disable @typescript-eslint/ban-ts-comment */
                            // @ts-ignore Arguments type is just wrong upstream.
                            onChange={(values: string[]) => {
                                // Filter out empty strings, because, well, this component seems to add
                                // an empty string out of nowhere.
                                setSelectedOptions(values.filter((_) => _.length));
                                props.onChange?.(values);
                            }}
                            /* eslint-enable @typescript-eslint/ban-ts-comment */
                        >
                            {options.map((option) => {
                                return (
                                    // Use 'type'='button' to make sure it doesn't default to 'type'='submit'.
                                    <MenuItemOption key={`multiselect-menu-${option.id}`} type="checkbox" value={option.id + ''} defaultChecked>
                                        {option.text}
                                    </MenuItemOption>
                                );
                            })}
                        </MenuOptionGroup>
                    </MenuList>
                </>
            )}
        </Menu>
    );
};

MultiSelect.displayName = 'MultiSelectMenu';

export default MultiSelect;
