import ReactPaginate from 'react-paginate';
import './pagination.scss';
type Props = {
    onPageChange?: (selectedItem: { selected: number }) => void;
    pageCount?: number;
    defaultPage?: number;
    maxVisible?: number;
};
const Pagination = ({ defaultPage = 0, pageCount = 0, maxVisible, onPageChange }: Props) => {
    return (
        <ReactPaginate
            className="flex"
            pageClassName=""
            pageLinkClassName="reputation-pagination"
            breakLinkClassName="reputation-pagination"
            breakLabel="..."
            activeLinkClassName="reputation-active"
            nextLabel={
                <span className="block px-3 py-2 text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 aria-disabled:bg-gray-200 aria-disabled:cursor-default">
                    <span className="text-lg icon icon-keyboard_arrow_right" />
                </span>
            }
            forcePage={defaultPage}
            onPageChange={onPageChange}
            pageRangeDisplayed={maxVisible}
            pageCount={pageCount}
            previousLabel={
                <span className="block px-3 py-2 text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 aria-disabled:bg-gray-200 aria-disabled:cursor-default">
                    <span className="text-lg icon icon-keyboard_arrow_left" />
                </span>
            }
            renderOnZeroPageCount={undefined}
        />
    );
};

export default Pagination;
