import { MemberAdminPageEnum } from '@/models/memberAdmin';
import { PermissionsId } from '@/models/permissions';
import { Dispatch, SetStateAction } from 'react';
import MemberAdminChatPage from './chat';
import MemberAdminProfilePage from './profile';
import { RelationAccount } from './relationAccount/relationAccount';
import MemberAdminStatisticsPage from './statistics';

export interface IMemberAdminPageProps {
    AgentUserCode: string;
    MemberPartnerId: number;
    MemberUserName: string;
    Reload?(): void;
    setVChatVisible?: Dispatch<SetStateAction<boolean | undefined>>;
}

export const memberAdminPageConfig = {
    [MemberAdminPageEnum.EDIT_PROFILE]: {
        name: 'Th_MemberAdmin_Profile',
        component: MemberAdminProfilePage,
        permission: PermissionsId.BasicInformation,
        id: MemberAdminPageEnum.EDIT_PROFILE
    },
    [MemberAdminPageEnum.STATISTICS]: {
        name: 'Th_MemberAdmin_Statistics',
        component: MemberAdminStatisticsPage,
        permission: PermissionsId.MemberStatistics,
        id: MemberAdminPageEnum.STATISTICS
    },
    [MemberAdminPageEnum.RELATION_ACCOUNT]: {
        name: 'Th_MemberAdmin_RelationAccount',
        component: RelationAccount,
        permission: PermissionsId.RelatedMembers,
        id: MemberAdminPageEnum.RELATION_ACCOUNT
    },
    [MemberAdminPageEnum.CHAT]: {
        name: 'Th_MemberAdmin_Chat',
        component: MemberAdminChatPage,
        permission: PermissionsId.MemberChat,
        id: MemberAdminPageEnum.CHAT
    }
};
