export type IUserAccountsVChatInfo = {
    NickName: string;
    VChatId: string;
    HeadImg: string;
    UserId: number;
};

export type IChatMessage = {
    UserId: number;
    UserNickName: string;
    UserHeadImg: string;
    CreateTime: string;
    Message: string;
    MsgType: string;
};

export type IChatMessageGroup = {
    GroupID: number;
    GroupImg: string;
    GroupName: string;
    Info?: string;
    JoinType: number;
    MaxUserCount: number;
    UserCount: number;
};

export type IChatMessageCard = {
    HeadImg: string;
    NickName: string;
    UserID: number;
};

export const MESSAGE_TYPE = {
    txt: 'txt',
    call: 'call',
    noticeallban: 'noticeallban',
    emo: 'emo',
    file: 'file',

    pic: 'pic',
    audio: 'audio',
    video: 'video',
    card: 'card',
    notice: 'notice',

    invite: 'invite',
} as const;

export type IVChatUser = {
    HeadImg: string;
    NickName: string;
    Phone: string;
    TelCode: string;
    VChatID: string;
};
