import { ISystemSettingPopupLayoutProps } from '../layout.mapping';
import { useTranslation } from 'react-i18next';
import { Input, Text } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { ISaveSubAccountRequest, SaveType } from '@/models/page/systemSetting';
import { api } from '@/service/api';
import useFetchApi from '@/hooks/useFetchApi';

type Props = {
    account?: string;
    updateStatusFn?: (isSuccess: boolean) => void;
    loadingVisibleFn?: (visible: boolean) => void;
} & ISystemSettingPopupLayoutProps;
const ResetPassword = ({ account, onClose, updateStatusFn, loadingVisibleFn }: Props) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [validateFailedList, setValidateFailedList] = useState<string[]>([]);
    const updatePassword = useFetchApi<ISaveSubAccountRequest>(
        api.saveAndUpdateSubAccount,
        ({ value, deps: [setIsLoading] }) => {
            setIsLoading?.(true);
            return value;
        },
        ({ value, deps: [setIsLoading] }) => {
            onClose();
            setIsLoading?.(false);
            if (value.data.IsSuccess) {
                updateStatusFn?.(true);
            } else {
                updateStatusFn?.(false);
            }
        },
        [loadingVisibleFn],
        (error, [setIsLoading]) => {
            onClose();
            setIsLoading?.(false);
        },
    );

    const onConfirmHandler = useCallback(() => {
        const isPasswordNotNone = password !== '';
        const isSameWithConfirmPassword = password === confirmPassword;
        const validatePass = validateFailedList.length === 0 && isPasswordNotNone && isSameWithConfirmPassword;

        if (validatePass) {
            !!account && updatePassword({ SaveType: SaveType.UPDATE_PASSWORD, Account: account, Password: password });
        } else {
            const _arr = [];
            if (!isPasswordNotNone) {
                _arr.push('password');
            } else if (!isSameWithConfirmPassword) {
                _arr.push('confirmPassword');
            }
            setValidateFailedList(_arr);
        }
    }, [validateFailedList, account, password]);
    return (
        <>
            <div className="flex flex-col text-center">
                <div className="text-left text-xl m-4">
                    <span className="icon icon-info " /> <span>{t('Ttl_SubAccountResetPassword', [ account ])}</span>
                </div>
                <div>
                    <div className="flex items-center w-full p-2">
                        <Text className="mr-4 whitespace-nowrap before:content-['*'] before:text-red-500 after:content-[':']">{t('Lbl_SubAccountNewPassword')}</Text>
                        <Input
                            isInvalid={validateFailedList.includes('password')}
                            type="password"
                            className="flex-1 disabled:bg-gray-300"
                            errorBorderColor="crimson"
                            value={password}
                            placeholder=""
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onBlur={(e) => {
                                setValidateFailedList((list) => {
                                    e.target.value === '' ? list.push('password') : (list = list.filter((v) => v !== 'password'));
                                    return [...list];
                                });
                            }}
                        />
                    </div>
                    <div className="flex items-center w-full p-2">
                        <Text className="mr-4 whitespace-nowrap before:content-['*'] before:text-red-500 after:content-[':']">{t('Lbl_ConfirmSubAccountNewPassword')}</Text>
                        <Input
                            isInvalid={validateFailedList.includes('confirmPassword')}
                            type="password"
                            className="flex-1 disabled:bg-gray-300"
                            errorBorderColor="crimson"
                            value={confirmPassword}
                            placeholder=""
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            onBlur={(e) => {
                                setValidateFailedList((list) => {
                                    e.target.value === '' || e.target.value !== password
                                        ? list.push('confirmPassword')
                                        : (list = list.filter((v) => v !== 'confirmPassword'));
                                    return [...list];
                                });
                            }}
                        />
                    </div>
                </div>
                <div>
                    <button
                        className=" m-2 w-24 h-8 bg-gray-400 text-white rounded hover:bg-gray-500"
                        onClick={() => {
                            onClose();
                        }}>
                        <label className="px-1 cursor-pointer">{t('Lbl_Button_Cancle')}</label>
                    </button>

                    <button
                        className=" m-2 w-24 h-8 bg-affiliate text-white rounded hover:bg-gray-500"
                        onClick={() => {
                            onConfirmHandler();
                        }}>
                        <label className="px-1 cursor-pointer">{t('Btn_confirm')}</label>
                    </button>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
