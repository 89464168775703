import MemberModel, { MemberModelRef } from '@/components/memberModel';
import TableColumnComp from '@/components/tableColumnComp';
import { APIModel } from '@/models/apiRequest';
import { ITableLayoutData, TableColumnStyle } from '@/models/layout/tableLayout';
import { MemberAdminPageEnum, MemberDetailParams } from '@/models/memberAdmin';
import { PermissionsId } from '@/models/permissions';
import { api } from '@/service/api';
import { alignCenterStyles, numericStyles } from '@/service/utils';
import { RootState } from '@/store/store.config';
import dayjs from 'dayjs';
import { Fragment, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type Props = {
    data?: ITableLayoutData;
    redirectPage?: (type: MemberAdminPageEnum, params: MemberDetailParams) => void;
    offsetPageSN?: number;
};
const MemberAdminTable = ({ data, redirectPage, offsetPageSN }: Props) => {
    const { t } = useTranslation();
    const parseHeader = () => {
        const result = data?.tableHeader || [];
        return result;
    };
    const parsebody = () => {
        const result = data?.tableBody || [];
        return result;
    };
    const col = parseHeader();
    const body = parsebody();
    const footer = data?.tableFooter;
    const parseFooterStyle = useCallback((text: string | number, style: TableColumnStyle) => {
        return <TableColumnComp text={text} style={style} />;
    }, []);
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const ref = useRef<MemberModelRef>(null);
    const onModelTrigger = useCallback((api: APIModel, data: { userName?: string; partnerId?: number; dateFrom: string; dateTo: string }) => {
        ref.current?.onMemberDataTrigger &&
            ref.current.onMemberDataTrigger(api, {
                DateFrom: data.dateFrom,
                DateTo: data.dateTo,
                UserName: data.userName!,
                PartnerId: data.partnerId!,
            });
    }, []);
    const handleMemberClick = (memberAccount: { userName: string; partnerId: number; dateFrom: string; dateTo: string }) => {
        onModelTrigger(api.memberDetails, {
            userName: memberAccount.userName,
            partnerId: memberAccount.partnerId,
            dateFrom: memberAccount.dateFrom,
            dateTo: memberAccount.dateTo,
        });
    };

    return (
        <div className="table w-full min-w-[300px] ">
            <div className="table-header-group bg-gray-100 text-xs">
                <div className="table-row ">
                    <div className="table-cell p-6 ">{t('Lbl_serial_number')}</div>
                    {col
                        .filter((item) => !item.invisible)
                        .map((item, i) => {
                            return (
                                <div
                                    key={`table-header-${i}`}
                                    className={`table-cell p-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                        alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                    }`}>
                                    {t(item.text)}
                                </div>
                            );
                        })}
                    <div className="table-cell p-6">{t('Lbl_optional')}</div>
                </div>
            </div>
            <div className="table-row-group">
                {body.map((hash, pi) => {
                    const params = {} as MemberDetailParams;
                    col.forEach((item) => {
                        (params as any)[item.name] = hash[item.name];
                    });

                    return (
                        <Fragment key={`ff-${pi}`}>
                            <div className="table-row odd:bg-gray-200 even:bg-slate-50" key={`table-td-tr-${pi}`}>
                                <div className="table-cell py-4 px-6">{pi + 1 + (offsetPageSN || 0)}</div>
                                {col
                                    .filter((item) => !item.invisible)
                                    .map((item, i) => {
                                        return (
                                            <div
                                                className={`table-cell py-4 px-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                    alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                } ${item.name === 'memberCode' ? 'text-blue-500 cursor-pointer' : ''}`}
                                                key={`tbody-${item.name}`}
                                                onClick={() => {
                                                    if (item.name === 'memberCode') {
                                                        handleMemberClick({
                                                            userName: hash[item.name] as string,
                                                            partnerId: hash['partnerId'] as number,
                                                            dateFrom: dayjs(hash['registerDate']).startOf('day').format('YYYY-MM-DD'),
                                                            dateTo: dayjs().format('YYYY-MM-DD'),
                                                        });
                                                    }
                                                }}>
                                                <TableColumnComp text={hash[item.name]} style={item.type} />
                                            </div>
                                        );
                                    })}
                                <div className="table-cell py-4 px-6">
                                    <div className="flex">
                                        {permissionList.includes(PermissionsId.BasicInformation) && (
                                            <span
                                                className="icon icon-edit-profile bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 "
                                                onClick={() => {
                                                    redirectPage?.(MemberAdminPageEnum.EDIT_PROFILE, params);
                                                }}
                                            />
                                        )}

                                        {permissionList.includes(PermissionsId.MemberStatistics) && (
                                            <span
                                                className="icon icon-statistics bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400"
                                                onClick={() => redirectPage?.(MemberAdminPageEnum.STATISTICS, params)}
                                            />
                                        )}

                                        {permissionList.includes(PermissionsId.RelatedMembers) && (
                                            <span
                                                className="icon icon-relation-account bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400"
                                                onClick={() => redirectPage?.(MemberAdminPageEnum.RELATION_ACCOUNT, params)}
                                            />
                                        )}

                                        {/* <span
                                                className="icon icon-recharge bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400"
                                                onClick={() => redirectPage?.(MemberAdminPageEnum.RECHARGE, params)}></span>
                                                */}
                                        {hash['hasVChatId'] && permissionList.includes(PermissionsId.MemberChat) && (
                                            <span
                                                className="icon icon-chat bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400"
                                                onClick={() => redirectPage?.(MemberAdminPageEnum.CHAT, params)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>

            {body.length > 0 && !!footer && (
                <div className="table-footer-group bg-gray-300 text-xs font-bold">
                    <div className="table-row ">
                        <div className="table-cell py-4 px-6" />
                        {col
                            .filter((item) => !item.invisible)
                            .map((item, index) => {
                                return (
                                    <div
                                        key={`table-footer-${index}`}
                                        className={`table-cell py-4 px-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                            alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                        }`}>
                                        {index === 0
                                            ? t('Lbl_total-summary')
                                            : footer[item.name] !== undefined && footer[item.name] !== ''
                                            ? parseFooterStyle(footer[item.name], item.type)
                                            : ''}
                                    </div>
                                );
                            })}
                        <div className="table-cell py-4 px-6" />
                    </div>
                </div>
            )}
            <MemberModel ref={ref} />
        </div>
    );
};

export default MemberAdminTable;
