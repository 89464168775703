import PartnerAgentListComp from '@/components/partnerAgentListComp';
import useFetchApi from '@/hooks/useFetchApi';
import { PartnerAgentList } from '@/models/page/systemSetting';
import { api } from '@/service/api';
import { Checkbox, Input, Text, Textarea } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { from, groupBy, map, mergeMap, reduce, toArray } from 'rxjs';
import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import { PageMode } from '.';
import { IDialogSetting, showDialog } from '@/store/slices/dialog.slice';
import { useDispatch } from 'react-redux';
import { DialogButtonType } from '@/components/dialog/dialog';
import { NoteInfos } from '@/components/noteInfos/noteInfos';

enum SaveType {
    New = 0,
    Update = 1,
}

interface IFriends {
    IsSelected: boolean;
    Remark: string;
    UserId: number;
    VChatId: string;
    NickName: string;
}

interface IGroup {
    GroupAdmins?: { Name: string; VChatId: string }[];
    GroupCode: number;
    GroupId: number;
    GroupName: string;
    GroupOwner?: { Name: string; VChatId: string };
    IsSelected: boolean;
    IsDeleted: boolean;
}

interface IResp {
    DefaultFriends: IFriends[];
    DefaultGroups: IGroup[];
    DisplayName: string;
    LinkedPartnerAgents: { AgentUserCode: string; PartnerId: number; IsSelected: boolean }[];
    LiveStreamingName: string;
    RegisterCode: string;
    Remark: string;
}

interface IEditRequest {
    RegisterCodeId: number;
    DisplayName: string;
    LiveStreamingName: string;
    Remark: string;
    SaveType: SaveType;
    SelectedFriendUserIds: number[];
    SelectedGroupIds: number[];
    SelectedPartnerAgents: Record<string, string[]>;
}

const CreateAndEditChat = ({
    pageMode,
    setIsLoading,
    registerCodeId,
    goMain,
}: {
    pageMode: PageMode;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    registerCodeId: number;
    goMain(): void;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [linkedPartnerAgents, setLinkedPartnerAgents] = useState<IResp['LinkedPartnerAgents']>([]);
    const [defaultPartnerAgentList, setDefaultPartnerAgentList] = useState<Record<string, PartnerAgentList[]>>();
    // 關聯代理號的參數
    const PartnerAgentListCompProp = useMemo(() => {
        return {
            defaultPartnerAgentList: defaultPartnerAgentList,
            selectedAgentList: linkedPartnerAgents
                .filter((item) => item.IsSelected)
                .map((v) => {
                    return {
                        PartnerId: v.PartnerId,
                        AgentUserCode: v.AgentUserCode,
                        IsAppliedAgent: v.IsSelected,
                    };
                }) as PartnerAgentList[],
            onChange: (currentPartnerId: number, list: (string | number)[]) => {
                const selectedList = list.map((item) => ({ AgentUserCode: item.toString(), PartnerId: currentPartnerId, IsSelected: true }));
                setLinkedPartnerAgents((o) => {
                    const _list = o.filter((v) => v.PartnerId !== currentPartnerId);
                    return [..._list, ...selectedList];
                });
            },
        };
    }, [defaultPartnerAgentList, linkedPartnerAgents]);
    const [partnerAgentListInvalid, setPartnerAgentListInvalid] = useState(false);

    const [registerCode, setRegisterCode] = useState('');

    const [displayName, setDisplayName] = useState('');
    const [displayNameInvalid, setDisplayNameInvalid] = useState(false);
    const [streamingRoom, setStreamingRoom] = useState('');
    const [streamingRoomInvalid, setStreamingRoomInvalid] = useState(false);
    const [remark, setRemark] = useState('');

    const getRegisterCodeDetail = useFetchApi<{ RegisterCodeId: number }, IResp, PageMode>(
        api.getRegisterCodeDetail,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_pageMode] }) => {
            if (value.data.IsSuccess) {
                // PartnerAgentListCompProp.defaultPartnerAgentList = value.data.Data.SelectedPartnerAgents;
                setDefaultFriendsList(value.data.Data.DefaultFriends);
                setDefaultChatGroupList(value.data.Data.DefaultGroups);
                setDisplayName(value.data.Data.DisplayName);
                setStreamingRoom(value.data.Data.LiveStreamingName);
                setRemark(value.data.Data.Remark);
                setLinkedPartnerAgents(value.data.Data.LinkedPartnerAgents);

                from(value.data.Data.LinkedPartnerAgents)
                    .pipe(
                        groupBy((v) => v.PartnerId),
                        mergeMap((v) =>
                            v.pipe(
                                toArray(),
                                map((s) => ({ [PARTNERS_CONFIGS_MAP[v.key].text]: s })),
                            ),
                        ),
                        reduce((acc, cur) => {
                            return { ...acc, ...cur };
                        }),
                    )
                    .subscribe((v: Record<string, PartnerAgentList[]>) => {
                        setDefaultPartnerAgentList(v);
                    });
            }
            setIsLoading(false);
            if (_pageMode !== PageMode.Main) {
                setRegisterCode(_pageMode === PageMode.Create ? '-' : value.data.Data.RegisterCode);
            }
        },
        [pageMode],
        () => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        getRegisterCodeDetail({ RegisterCodeId: registerCodeId });
    }, [pageMode, registerCodeId]);

    const [defaultFriendsList, setDefaultFriendsList] = useState<IFriends[]>([]);
    const [defaultChatGroupList, setDefaultChatGroupList] = useState<IGroup[]>([]);
    const saveOrUpdate = useFetchApi<IEditRequest>(
        api.saveORUpdateRegisterCode,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_t, _goMain] }) => {
            if (value.data.IsSuccess) {
                dispatch(
                    showDialog({
                        title: t('Lbl_Button_Info'),
                        content: t('Txt_updateSuccess'),
                        onClose: () => {
                            _goMain();
                        },
                        type: DialogButtonType.Info,
                    }),
                );
            }
            setIsLoading(false);
        },
        [t, goMain],
        () => {
            setIsLoading(false);
        },
    );

    const saveFn = useCallback(() => {
        if (pageMode !== PageMode.Main) {
            let SelectedPartnerAgents = {} as Record<string, string[]>;
            from(linkedPartnerAgents.filter((item) => item.IsSelected))
                .pipe(
                    groupBy((v) => v.PartnerId),
                    mergeMap((v) =>
                        v.pipe(
                            toArray(),
                            map((s) => ({ [PARTNERS_CONFIGS_MAP[v.key].id]: s.map((n) => n.AgentUserCode) })),
                        ),
                    ),
                    reduce((acc, cur) => {
                        return { ...acc, ...cur };
                    }),
                )
                .subscribe((v) => {
                    SelectedPartnerAgents = v;
                });

            const param = {
                DisplayName: displayName,
                LiveStreamingName: streamingRoom,
                Remark: remark,
                SaveType: pageMode === PageMode.Create ? SaveType.New : SaveType.Update,
                SelectedFriendUserIds: defaultFriendsList.filter((v) => v.IsSelected).map((v) => v.UserId),
                SelectedGroupIds: defaultChatGroupList.filter((v) => v.IsSelected && !v.IsDeleted).map((v) => v.GroupId),
                SelectedPartnerAgents,
                RegisterCodeId: registerCodeId,
            };

            const validateDisplayName = displayName === '' || displayName === null;
            const streamingRoomDisplayName = streamingRoom === '' || streamingRoom === null;
            setPartnerAgentListInvalid(linkedPartnerAgents.length === 0);
            setDisplayNameInvalid(validateDisplayName);
            setStreamingRoomInvalid(streamingRoomDisplayName);
            if (validateDisplayName || linkedPartnerAgents.length === 0) {
                return;
            }
            saveOrUpdate(pageMode === PageMode.Create ? param : { ...param, RegisterCodeId: registerCodeId! });
        }
    }, [displayName, streamingRoom, remark, linkedPartnerAgents, defaultFriendsList, defaultChatGroupList, pageMode, registerCodeId]);

    const handleClickSave = () => {
        const invalid = displayName === undefined || displayName === '' || displayName.length === 0;

        if (invalid) {
            dispatch(
                showDialog({
                    title: t('Lbl_Button_Warring'),
                    content: `${t('Msg_PleaseEnterRequiredInput')}`,
                    type: DialogButtonType.Warning,
                }),
            );
            return;
        }

        const noFriends = defaultFriendsList.filter((v) => v.IsSelected).length === 0;
        const noGroups = defaultChatGroupList.filter((v) => v.IsSelected).length === 0;

        if (noFriends && noGroups) {
            const dialogSetting: IDialogSetting = {
                title: t('Lbl_Button_Warring'),
                content: `${t('Msg_AtLeastChatSelectOne')}`,
                type: DialogButtonType.Warning,
            };
            dispatch(showDialog(dialogSetting));
            return;
        }

        saveFn();
    };

    const tableTdCss = 'border-slate-300 font-bold p-2 border-[1px] text-gray-500';
    const tableCss = 'border-separate border-spacing-0 w-full text-sm border-[1px] border-slate-400';

    const hintContents = ['您设定的关联代理会影响旗下子帐号可否查看到此注册码。'];

    return (
        <>
            <div className="flex w-1/2">
                <div className="flex flex-col items-start flex-1 p-2 gap-y-2">
                    <div className="flex items-center w-full gap-1">
                        <div className="basis-1/4">
                            <Text className="whitespace-nowrap">{t('Th_RegistrationCode')}:</Text>
                        </div>
                        <div className="basis-3/4">
                            <div className="flex w-full text-gray-500">{registerCode}</div>
                        </div>
                    </div>
                    <div className="flex items-center w-full justify-between">
                        <div className="basis-1/4">
                            <Text className="whitespace-nowrap before:content-['*'] before:text-red-500">{t('Lbl_nickName')}:</Text>
                        </div>
                        <div className="basis-3/4">
                            <Input
                                isInvalid={displayNameInvalid}
                                borderColor="gray.400"
                                value={displayName}
                                onChange={(e) => {
                                    setDisplayName(e.target.value);
                                }}
                                onBlur={(e) => {
                                    setDisplayNameInvalid(e.target.value === '');
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex items-center w-full justify-between">
                        <div className="basis-1/4">
                            <Text className="whitespace-nowrap">{t('Lbl_Studio')}:</Text>
                        </div>
                        <div className="basis-3/4">
                            <Input
                                borderColor="gray.400"
                                onChange={(e) => {
                                    setStreamingRoom(e.target.value);
                                }}
                                value={streamingRoom}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-1">
                    <Text className="mr-4 mt-2 whitespace-nowrap w-14">{t('Lbl_note')}:</Text>
                    <Textarea
                        borderColor="gray.400"
                        placeholder={t('Lbl_Multiline_Input')}
                        height="auto"
                        defaultValue={remark}
                        onChange={(e) => setRemark(e.target.value)}
                    />
                </div>
            </div>

            <div data-default-friends className="m-2">
                <div className="flex">
                    <h1 className="text-lg font-semibold  mr-2 whitespace-nowrap">{t('Lbl_RegisterFriend')}:</h1>
                    <p className="text-lg">
                        {defaultFriendsList.filter((v) => v.IsSelected).length}/{defaultFriendsList.length}
                    </p>
                </div>

                <div className="overflow-y-auto max-h-[550px] w-1/2 ">
                    <table className={tableCss}>
                        <thead className="border sticky top-0 z-10 bg-gray-200">
                            <tr className="">
                                <th className={`${tableTdCss} text-left`}>{t('Lbl_serialId')}</th>
                                <th className={tableTdCss}>{t('Th_VChatNicknameAndID')}</th>
                                <th className={tableTdCss}>{t('Th_Remark')}</th>
                                <th className={tableTdCss}>{t('Th_Selected')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {defaultFriendsList.map((item, index) => {
                                return (
                                    <tr className="" key={item.UserId}>
                                        <td className={tableTdCss}>{index + 1}</td>
                                        <td className={tableTdCss}>{`${item.NickName || ''} (${item.VChatId})`}</td>
                                        <td className={tableTdCss}>{item.Remark}</td>
                                        <td className={tableTdCss}>
                                            <Checkbox
                                                defaultChecked={item.IsSelected}
                                                onChange={(e) => {
                                                    setDefaultFriendsList((list) => {
                                                        return list.map((v) => {
                                                            if (v.UserId === item.UserId) {
                                                                v.IsSelected = e.target.checked;
                                                            }
                                                            return v;
                                                        });
                                                    });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div data-default-groups className="m-2 mt-4">
                <div className="flex">
                    <h1 className="text-lg font-semibold  mr-2 whitespace-nowrap">{t('Lbl_RegisterGroupChat')}:</h1>
                    <p className="text-lg">
                        {defaultChatGroupList.filter((v) => v.IsSelected).length}/{defaultChatGroupList.length}
                    </p>
                </div>

                <div className="overflow-y-auto max-h-[200px] w-1/2 ">
                    <table className={tableCss}>
                        <thead className="border sticky top-0 z-10 bg-gray-200">
                            <tr className="">
                                <th className={`${tableTdCss} text-left`}>{t('Lbl_serialId')}</th>
                                <th className={tableTdCss}>{t('Th_VChatGroupId')}</th>
                                <th className={tableTdCss}>{t('Th_VChatGroupName')}</th>
                                <th className={tableTdCss}>{t('Th_VChatGroupOwner')}</th>
                                <th className={tableTdCss}>{t('Th_VChatGroupManager')}</th>
                                <th className={tableTdCss}>{t('Th_Selected')}</th>
                            </tr>
                        </thead>
                        <tbody className=" ">
                            {defaultChatGroupList.map((item, index) => {
                                return (
                                    <tr className="" key={item.GroupId}>
                                        <td className={tableTdCss}>{index + 1}</td>
                                        <td className={tableTdCss}>{item.GroupId}</td>
                                        <td className={tableTdCss}>{item.IsDeleted ? t('Lbl_ChatGroupAlreadyDeleted') : item.GroupName}</td>
                                        <td className={tableTdCss}>{item.GroupOwner?.Name}</td>
                                        <td className={tableTdCss}> {item.GroupAdmins?.map((v) => v.Name).join(',')}</td>
                                        <td className={tableTdCss}>
                                            {item.IsDeleted ? (
                                                <></>
                                            ) : (
                                                <Checkbox
                                                    defaultChecked={item.IsSelected}
                                                    onChange={(e) => {
                                                        setDefaultChatGroupList((list) => {
                                                            return list.map((v) => {
                                                                if (v.GroupId === item.GroupId) {
                                                                    v.IsSelected = e.target.checked;
                                                                }
                                                                return v;
                                                            });
                                                        });
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="m-2">
                <div className="flex">
                    <h1 className="text-lg font-semibold  mr-2 whitespace-nowrap">{t('Lbl_AssociatedAgent')}:</h1>
                    <p className="text-lg">
                        {linkedPartnerAgents.filter((item) => item.IsSelected).length}/{Object.values(defaultPartnerAgentList || {}).flat().length}
                    </p>
                </div>
                <div className={`w-1/2 ${partnerAgentListInvalid && 'border-4 border-red-500'}`}>
                    <PartnerAgentListComp {...PartnerAgentListCompProp} />
                    <NoteInfos notes={hintContents} />
                </div>
            </div>

            <div data-submit className="flex justify-end w-1/2 m-2">
                <button
                    className=" m-2 w-24 h-8 bg-white border border-gray-500  text-gray-500 rounded hover:bg-gray-500 hover:text-white"
                    onClick={() => {
                        goMain();
                    }}>
                    <label className="px-1 cursor-pointer">{t('Lbl_Button_Cancle')}</label>
                </button>
                <button className=" m-2 w-24 h-8 bg-affiliate text-white rounded hover:bg-gray-500" onClick={() => handleClickSave()}>
                    <label className="px-1 cursor-pointer">{t('Btn_confirm')}</label>
                </button>
            </div>
        </>
    );
};

export default CreateAndEditChat;
