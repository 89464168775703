export enum IReportCommissionStatus {
    Settlement = -2,
    PendingFinalSettlement = -1,
    PendingTrial = 0,
    PassFirst = 1,
    FaileFirst = 2,
    PassSecond = 3,
    FailSecond = 4,
    Unknow = 99,
}

export type MemberCommission = {
    PartnerId: number;
    UserCount: number;
    TotalWin: number;
    PlatFee: number;
    Bonus: number;
    Rebeat: number;
    LastAmount: number;
    NetWin: number;
    CommRate: number;
    Amount: number;
    WithdrawFee: number;
    Date: Date;
    AgentUserCode: string;
    AgentUserNickName: string;
    AgentUserId: number;
    Beta: MemberCommission;
};

export interface IReportCommission {
    Data: IReportCommissionData[];
}

export interface IReportCommissionData {
    PartnerId: number;
    Date: Date;
    CommAmount: number;
    CommAmountBeta: number;
    ProxyComm: number;
    Amount: number;
    ReturnAmount: number;
    Status: IReportCommissionStatus;
    MemberCommission: MemberCommission;
    AgentUserCode: string;
    AgentUserId: number;
}

export interface IReportMemberCommissionData {
    PartnerId: number;
    UserName: string;
    BetCount: number;
    BetAmount: number;
}

export interface IReportMemberCommission {
    Summary: {
        BetCount: number;
        BetAmount: number;
        Users: number;
    };

    Data: IReportMemberCommissionData[];
}

export interface IReportProxyMember {
    Data: IReportProxyMemberData;
}

export interface IReportProxyMemberData {
    CommRate: number;
    CommissionAmount: number;
    DownLines: IReportProxyMemberDownLine[];
    ProxyAmount: number;
    ProxyEffectCount: number;
    ProxyLevelName: string;
}

export interface IReportProxyMemberDownLine {
    ChildSize: number;
    CommRate: number;
    CommissionAmount: number;
    EffectChildSize: number;
    Level: number;
    Mode: 2;
    ProxyAmount: number;
    UserName: string;
}
