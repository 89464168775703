import Datepicker from 'react-tailwindcss-datepicker';
import { DateRangeType, DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import React, { useCallback, useEffect, useState } from 'react';
import { getEnumValueByKey } from '@/service/utils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from '@/constants/datetime';
import { noop } from 'rxjs';
import cn from 'mxcn';

import './dataRangePicker.scss';

enum QuickDateType {
    YESTERDAY,
    TODAY,
    THIS_MONTH,
    LAST_MONTH,
}

export interface IValueType extends DateRangeType {
    isQuick?: boolean;
}

export interface IDateRangePickerProps {
    label?: string;
    valueHandler?: (newValue: IValueType) => void;
    shortcuts?: 'monthOnly' | 'noToday' | boolean;
    format?: keyof typeof DATETIME_FORMAT;
    withoutI18n?: boolean;
    defaultDate?: { start: string | undefined; end: string | undefined };
    minDate?: string;
    className?: string;
    labelClassName?: string;
}

const DateRangePicker = ({
    valueHandler = noop,
    label = 'Lbl_date_range',
    className,
    labelClassName,
    defaultDate,
    shortcuts,
    withoutI18n,
    minDate,
    format,
}: IDateRangePickerProps) => {
    const { t } = useTranslation();

    const [value, setValue] = useState<DateValueType>(() => {
        const start = defaultDate ? dayjs(defaultDate.start) : dayjs().subtract(1, 'day');
        const end = dayjs(defaultDate?.end);

        return {
            startDate: start.format(DATETIME_FORMAT.YMD),
            endDate: end.format(DATETIME_FORMAT.YMD),
        };
    });

    useEffect(() => {
        const startDate = defaultDate && dayjs(defaultDate.start).format(DATETIME_FORMAT.YMD);
        const endDate = defaultDate && dayjs(defaultDate.end).format(DATETIME_FORMAT.YMD);

        setValue((date) => {
            let result = date;
            if (!!startDate && !!endDate) {
                result = { startDate, endDate };
            }
            return result;
        });
    }, [defaultDate]);

    const handleValueChange = (newValue: DateValueType) => {
        if (newValue?.startDate && newValue?.endDate) {
            setValue(newValue);
            valueHandler(newValue as IValueType);
        } else {
            setValue((state) => (state ? { ...state } : null));
        }
    };

    const btnCallback = useCallback((e: React.MouseEvent, isQuick?: boolean) => {
        const targetValue = (e.target as any).value as QuickDateType;
        setValue((v) => {
            let result;

            switch (~~targetValue) {
                case QuickDateType.YESTERDAY: {
                    result = {
                        startDate: dayjs().subtract(1, 'day').startOf('day').format(DATETIME_FORMAT.YMD),
                        endDate: dayjs().subtract(1, 'day').endOf('day').format(DATETIME_FORMAT.YMD),
                    };
                    break;
                }
                case QuickDateType.TODAY: {
                    result = {
                        startDate: dayjs().startOf('day').format(DATETIME_FORMAT.YMD),
                        endDate: dayjs().format(DATETIME_FORMAT.YMD),
                    };
                    break;
                }
                case QuickDateType.THIS_MONTH: {
                    result = {
                        startDate: dayjs().startOf('month').format(DATETIME_FORMAT.YMD),
                        endDate: dayjs().format(DATETIME_FORMAT.YMD),
                    };
                    break;
                }
                case QuickDateType.LAST_MONTH: {
                    result = {
                        startDate: dayjs().subtract(1, 'month').startOf('month').format(DATETIME_FORMAT.YMD),
                        endDate: dayjs().subtract(1, 'month').endOf('month').format(DATETIME_FORMAT.YMD),
                    };
                    break;
                }
                default: {
                    result = v;
                }
            }

            valueHandler({ ...result, isQuick } as IValueType);

            return result;
        });
    }, []);

    const quickDateBtn = Object.keys(QuickDateType)
        .filter((key) => isNaN(key as any))
        .filter((key) => {
            switch (shortcuts) {
                case 'monthOnly':
                    return ![QuickDateType.YESTERDAY, QuickDateType.TODAY].includes(+getEnumValueByKey(QuickDateType, key));
                case 'noToday':
                    return +getEnumValueByKey(QuickDateType, key) !== QuickDateType.TODAY;
                default:
                    return shortcuts ?? false;
            }
        })
        .map((key, i) => (
            <button
                key={`${key}-${i}`}
                className="mx-1 btn whitespace-nowrap data-active:bg-orange-300"
                onClick={(e) => btnCallback(e, true)}
                value={getEnumValueByKey(QuickDateType, key)}>
                {t(`Lbl_${key.toLowerCase()}`)}
            </button>
        ));

    return (
        <>
            <div className="flex flex-nowrap justify-between ">
                <div className="flex">
                    <div className={cn('self-center text-center min-w-[99px]', labelClassName)}>
                        <label className="text-base whitespace-nowrap">{!withoutI18n ? t(label) : label}:</label>
                    </div>
                    <div className={cn(className ?? 'w-[370px] min-w-[245px]')}>
                        <Datepicker
                            primaryColor="orange"
                            startFrom={dayjs().subtract(1, 'month').startOf('month').toDate()}
                            displayFormat={format ? DATETIME_FORMAT[format] : undefined}
                            minDate={minDate}
                            maxDate={new Date()}
                            value={value}
                            onChange={handleValueChange}
                            toggleIcon={() => <span className="icon icon-calendar" />}
                            i18n="zh-cn"
                        />
                    </div>
                </div>

                {shortcuts && (
                    <div data-quick-date className="flex items-center">
                        <label className="ml-2 mr-4 whitespace-nowrap">{t('Lbl_quick_date') + ':'}</label>

                        {quickDateBtn}
                    </div>
                )}
            </div>
        </>
    );
};

export default DateRangePicker;
