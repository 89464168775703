import { ITableBodyLayoutData, TableConfig, TableConfigColumn } from '@/models/layout/tableLayout';
export const getTableHeader = <T>(list: TableConfigColumn<T>[]) => {
    return list.map((item) => {
        return { name: item.name, type: item.type, text: item.text, invisible: item.invisible, switch: item.switchId };
    });
};
export const getTableBody = <T>(list: T[], configList: TableConfigColumn<T>[]): ITableBodyLayoutData[] =>
    list.map((item) => {
        const result: ITableBodyLayoutData = {};
        configList.forEach((config) => {
            if (config.calculate) {
                result[config.name] = config.calculate(item, config.itemKeys);
            } else {
                result[config.name] = (item as any)[config.itemKeys[0]];
            }
        });

        return result;
    });

export const getTableFooter = <T, R extends { [key: string]: any } | any[]>(
    config: TableConfig<T>,
    input: R,
    body: ITableBodyLayoutData[],
): ITableBodyLayoutData => {
    if (!Array.isArray(input) && input.Summary) {
        return config.column.reduce<ITableBodyLayoutData>((pre, acc) => {
            return { ...pre, [acc.name]: config.footer.includes(acc.name) ? (input.Summary as any)[acc.itemKeys[0]] : '' };
        }, {});
    } else {
        return config.column.reduce<ITableBodyLayoutData>((pre, acc) => {
            return { ...pre, [acc.name]: config.footer.includes(acc.name) ? body.reduce((_pre, _acc) => _pre + ~~_acc[acc.name], 0) : '' };
        }, {});
    }
};

export const numberFix3 = (data: any, keys: string[]): string | number => {
    const value = parseFloat(data[keys[0]].toString());
    return value.toFixed(3);
};
