import { ValueOf } from '../dashboard/IDashboard';

export const RechagerTaskStatusEnum = {
    Pending: 100,
    Processsing: 200,
    Success: 300,
    Failed: 400,
    IGNORE: 500,
} as const;

export type IRechargeQueryModel = {
    PartnerId: number;
    DownLineList: number[];
    TaskStatus: number[];
    PageNo: number;
    PageSize: number;
};

export type IRechargeItemModel = {
    AgentNickName: string;
    AgentUserId: number;
    Amount: number;
    CategoryId: number;
    DateCreated: string;
    DateUpdated: string;
    ErrorMessage: string;
    Multiple: number;
    PartnerId: number;
    RechargeOrderId: number;
    Remark: string;
    RemarkType: number;
    RetryTimes: number;
    TaskStatus: number;
    TransactionNo: string;
    UserName: string;
    RechargeAuditLogs: IRechageLogItem[];
};

export type IRechargePageModel = {
    RechargeOrders: IRechargeItemModel[];
    TotalRecord: number;
};

export type IRechageLogItem = {
    DateCreated: string;
    TaskStatus: number;
    UserUpdated: string;
    ErrorMessage: string;
    RechargeOrderId: number;
};

export type IAgentBalanceModel = {
    AgentNickName: string;
    AgentUserCode: string;
    AgentUserId: number;
    CreditBalance: number;
    PartnerId: number;
};

export type IAgentDetailResponseModel = {
    USDTRate: number;
    AgentBalance: IAgentBalanceModel[];
};

export type IMemberInfoRequestModel = {
    AgentUserId: number;
    PartnerId: number;
    MemberCodes: string[];
};

export type IMemberInfoResponsModel = {
    InvalidMembers: string[];
    ValidMembers: IMemberInfoModel[];
};

export type IMemberInfoModel = {
    AgentUserCode: string;
    AgentUserId: number;
    CurrencyId: number;
    DomainName: string;
    MemberName: string;
    MemberNickName: string;
    PartnerId: number;
    RegisterDate: string;
    RegisterDomain: string;
    Remark: string;
    UnBetDays: number;
    UserName: string;
};

export const CurrencyTypeEnum = {
    RMB: 1,
    USDT: 2,
    UnKnown: 0,
} as const;

export type ICurrencyTypeEnumValue = ValueOf<typeof CurrencyTypeEnum>;

export type IMemberCurrencyInfo = {
    UserName: string;
    CurrencyType: ICurrencyTypeEnumValue;
};
