import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportMember, IReportMemberData } from '@/models/page/report/IReportMember';
import { SortingStatus } from '@/models/sorting';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function memberData(input: IReportMember): ITableLayoutData {
    const outputConfig: TableConfig<IReportMemberData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'registerDate', text: 'Th_registerDate', itemKeys: ['RegisterDate'], type: TableColumnStyle.DateTime, sortKey: 0 },
            { name: 'userName', text: 'Th_userName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'vipLevel', text: 'Th_vipLevel', itemKeys: ['VipLevel'], type: TableColumnStyle.Numeric, sortKey: 1 },
            {
                name: 'effectiveStatus',
                text: 'Th_effectiveStatus',
                itemKeys: ['EffectiveStatus'],
                type: TableColumnStyle.TextI18NWithColor,
                calculate: (data, keys) => {
                    const colorMap = new Map<number, string>([
                        [1, 'text-red-500'],
                        [2, 'text-green-500'],
                    ]);

                    const i18nMap = new Map<number, string>([
                        [1, 'Txt_ValidMember'],
                        [2, 'Txt_InvalidMember'],
                    ]);

                    return `${i18nMap.get(data.EffectiveStatus)}_with_${colorMap.get(data.EffectiveStatus)}`;
                },
            },
            { name: 'rechargeCount', text: 'Th_rechargeCount', itemKeys: ['RechargeCount'], type: TableColumnStyle.Numeric, sortKey: 2 },
            { name: 'rechargeAmount', text: 'Th_rechargeSum', itemKeys: ['RechargeAmount'], type: TableColumnStyle.CommaNumeric, sortKey: 3 },
            { name: 'withdrawAmount', text: 'Th_withdrawAmount', itemKeys: ['WithdrawAmount'], type: TableColumnStyle.CommaNumeric, sortKey: 4 },
            { name: 'betAmount', text: 'Th_betAmount', itemKeys: ['BetAmount'], type: TableColumnStyle.CommaNumeric, sortKey: 5 },
            { name: 'totalWin', text: 'Th_totalWin', itemKeys: ['TotalWin'], type: TableColumnStyle.CommaNumericWithSymbol, sortKey: 6 },
            { name: 'unBetDays', text: 'Th_unBetDays', itemKeys: ['UnBetDays'], type: TableColumnStyle.Numeric },
            { name: 'upAmountCount', text: 'Th_upAmountCount', itemKeys: ['UpAmountCount'], type: TableColumnStyle.Numeric, sortKey: 7 },
            { name: 'upAmount', text: 'Th_effectAmountSum', itemKeys: ['UpAmount'], type: TableColumnStyle.CommaNumeric, sortKey: 8 },
            { name: 'bonusAmount', text: 'Th_bonus', itemKeys: ['BonusAmount'], type: TableColumnStyle.CommaNumeric, sortKey: 9 },
            { name: 'rebateAmount', text: 'Th_rebeat', itemKeys: ['RebateAmount'], type: TableColumnStyle.CommaNumeric, sortKey: 10 },
        ],
        footer: ['rechargeCount', 'rechargeAmount', 'withdrawAmount', 'betAmount', 'totalWin', 'upAmountCount', 'upAmount', 'bonusAmount', 'rebateAmount'],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);
    const sortableFields = outputConfig.column
        .filter((v) => v.sortKey !== undefined && !isNaN(v.sortKey))
        .map((v) => {
            const status =
                input.Summary.SortColumn === v.sortKey! ? (input.Summary.IsDescendingSort ? SortingStatus.DESC : SortingStatus.ASC) : SortingStatus.NONE;
            return { sortKey: v.sortKey!, name: v.name, status };
        });

    return {
        tableHeader,
        tableBody,
        tableFooter,
        totalRecords: input.Summary.TotalRecords || 0,
        hasFooter: outputConfig.footer && outputConfig.footer.length > 0,
        sortableFields,
    };
}
