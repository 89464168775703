import { MemberModelRef } from '@/components/memberModel';
import { DATETIME_FORMAT } from '@/constants/datetime';
import { APIModel } from '@/models/apiRequest';
import dayjs from 'dayjs';
import { useRef, useCallback, RefObject } from 'react';

export default function useMemberInfo(): [
    RefObject<MemberModelRef>,
    (api: APIModel, data: { userName?: string; partnerId?: number; dateFrom?: string; dateTo?: string }) => void,
] {
    const ref = useRef<MemberModelRef>(null);

    const onModelTrigger = useCallback((api: APIModel, data: { userName?: string; partnerId?: number; dateFrom?: string; dateTo?: string }) => {
        ref.current?.onMemberDataTrigger &&
            ref.current.onMemberDataTrigger(api, {
                DateFrom: data.dateFrom ?? dayjs().subtract(1, 'year').format(DATETIME_FORMAT.YMDHMSS),
                DateTo: data.dateTo ?? dayjs().format(DATETIME_FORMAT.YMDHMSS),
                UserName: data.userName!,
                PartnerId: data.partnerId!,
            });
    }, []);

    return [ref, onModelTrigger];
}
