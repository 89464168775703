import { IButtonsGroupProps, IButtonSetting } from '@/models/page/dashboard/IDashboard';
import { Button } from '@chakra-ui/react';
import { useState } from 'react';

export const ButtonsGroup = ({
    buttons = [],
    defaultSelectedKey,
    onClick
                             }: IButtonsGroupProps) => {
    const [selectedKey, setSelectedKey] = useState(defaultSelectedKey ?? buttons[0]?.key);
    const handleClick = (bt: IButtonSetting) => {
        setSelectedKey(bt.key);
        onClick(bt.key);
    };
    return (
        <div>
            {buttons.map((bt) => (
                <Button
                    key={`bt_${bt.key}`}
                    className=" mr-1"
                    colorScheme="orange"
                    size="sm"
                    variant={selectedKey === bt.key ? 'solid' : 'outline'}
                    onClick={() => handleClick(bt)}>
                    {bt.text}
                </Button>
            ))}
        </div>
    );
};
