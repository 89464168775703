import reportTransferFun from '@/service/modelTransfer/report';
import { ITableLayoutData } from '../layout/tableLayout';
import { ReportPageEnum } from '../transfer/reportTransfer';

export const tableMapping: Record<ReportPageEnum, (input: any, pageNo?: number, pageSize?: number) => ITableLayoutData> = {
    [ReportPageEnum.FUND]: reportTransferFun.fundData,
    [ReportPageEnum.FUND_NEW_ACCOUNT]: reportTransferFun.funNewAccountData,
    [ReportPageEnum.COMMISSION]: reportTransferFun.commissionData,
    [ReportPageEnum.COMMISSION_AGENT_LEVEL]: reportTransferFun.agentLevelData,
    [ReportPageEnum.COMMISSION_MEMBER]: reportTransferFun.commissionMemberData,
    [ReportPageEnum.COMMISSSION_MEMBER_DETAIL]: reportTransferFun.commissionMemberDetailData,
    [ReportPageEnum.COMMISSION_RECHARGE_WITHDRAW_FEE]: reportTransferFun.rechargeWthdrawFeeData,
    [ReportPageEnum.TEAM]: reportTransferFun.teamData,
    [ReportPageEnum.MEMBER]: reportTransferFun.memberData,
    [ReportPageEnum.GAME]: reportTransferFun.gameData,
    [ReportPageEnum.GAME_BRAND]: reportTransferFun.gameDetailsData,
    [ReportPageEnum.FIRST_CHARGE]: reportTransferFun.memberFirstChargeData,
    [ReportPageEnum.REBATE]: reportTransferFun.rebateData,
    [ReportPageEnum.REBATE_BONUS_DETAIL]: reportTransferFun.rebateBonusUserDetailData,
    [ReportPageEnum.REBATE_USER_DETAIL]: reportTransferFun.rebateUserDetailData,
    [ReportPageEnum.CHARGE_AND_REDRAW]: reportTransferFun.chargeAndWithdrawData,
    [ReportPageEnum.WITHDRAW_DEPOSIT_DETAIL]: reportTransferFun.withdrawUserData,
    [ReportPageEnum.RECHARGE_DEPOSIT_DETAIL]: reportTransferFun.depositDetailData,
    [ReportPageEnum.AGENT_RECHARGE]: reportTransferFun.agentRechargeData,
    [ReportPageEnum.RELATION_MEMBER]: reportTransferFun.relationMember,
    [ReportPageEnum.RELATION_MEMBER_DETAIL]: reportTransferFun.relationMemberDetail,
};
