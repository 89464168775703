import { HistoryTableLayoutEnum } from '@/components/historyModel/layout';
import { ITableLayoutData, TableColumnStyle, TableConfig, TableExpandBody } from '@/models/layout/tableLayout';
import { MemberCommission } from '@/models/page/report/IReportCommission';
import { api } from '@/service/api';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';
import { numberComma } from '@/service/utils';

export function commissionMemberData(input: MemberCommission[]): ITableLayoutData {
    const outputConfig: TableConfig<MemberCommission> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'date', text: 'Th_date', itemKeys: ['Date'], type: TableColumnStyle.Date, invisible: true },
            { name: 'agentUserId', text: '', itemKeys: ['AgentUserId'], type: TableColumnStyle.Text, invisible: true },
            { name: 'agentUserCode', text: 'Th_proxyName', itemKeys: ['AgentUserCode'], type: TableColumnStyle.Text },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'userCount', text: 'Th_userCount', itemKeys: ['UserCount'], type: TableColumnStyle.Numeric },
            { name: 'totalWin', text: 'Th_totalWin', itemKeys: ['TotalWin'], type: TableColumnStyle.CommaNumericWithSymbol },
            { name: 'platfee', text: 'Th_platfee', itemKeys: ['PlatFee'], type: TableColumnStyle.CommaNumeric },
            { name: 'bonus', text: 'Th_bonus', itemKeys: ['Bonus'], type: TableColumnStyle.CommaNumeric },
            { name: 'rebeat', text: 'Th_rebeat', itemKeys: ['Rebeat'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'rechargAndWithdrawfee',
                text: 'Th_rechargAndWithdrawfee',
                itemKeys: ['RechargeFee', 'WithdrawFee'],
                type: TableColumnStyle.CommaNumeric,
                calculate: (data, keys) => {
                    const num = keys.reduce((x, y) => {
                        return FloatHelper.plus(parseFloat((data as any)[y]), x);
                    }, 0);

                    return num;
                },
            },
            { name: 'lastAmount', text: 'Th_lastAmount', itemKeys: ['LastAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'netWin', text: 'Th_netWin', itemKeys: ['NetWin'], type: TableColumnStyle.CommaNumericWithSymbol },
            { name: 'commRate', text: 'Th_commRate', itemKeys: ['CommRate'], type: TableColumnStyle.Percentage },
            { name: 'amount', text: 'Th_actureAmount', itemKeys: ['Amount'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'diff',
                text: 'Th_Diff',
                itemKeys: ['TotalWin', 'Bonus', 'Rebeat', 'RechargeFee', 'WithdrawFee', 'NetWin', 'Amount'],
                type: TableColumnStyle.Icon,
                calculate: (data, keys) => {
                    if (!(data as any).Beta) {
                        return '';
                    }
                    const isSome = keys.every((key) => {
                        const a = FloatHelper.floatFixed(parseFloat((data as any)[key] + ''), RoundType.Floor, 0);
                        const b = FloatHelper.floatFixed(parseFloat((data as any).Beta[key] + ''), RoundType.Floor, 0);
                        return a === b;
                    });

                    return isSome ? '' : 'notification';
                },
            },
        ],
        footer: [],
    };
    const betaData = input.map((item) => item.Beta).filter((v) => !!v);
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);
    const calcTableExpandBody = (key: string, ori: number, cur: number, className: string) => {
        const _cur = FloatHelper.floatFixed(parseFloat(cur + ''), RoundType.Floor, 0);
        const _ori = FloatHelper.floatFixed(parseFloat(ori + ''), RoundType.Floor, 0);
        const diff = _ori - _cur;
        return {
            columnKey: key,
            value: `${numberComma(_ori)} /n (${diff > 0 ? '多' : diff === 0 ? '' : '少'}${numberComma(Math.abs(diff))})/color:${
                diff > 0 ? 'text-red-500' : diff === 0 ? '' : 'text-green-500'
            }`,
            type: 'multiLine-text',
            className,
        } as TableExpandBody;
    };
    const tableExpandBody = tableBody.map((item, index) => {
        if (betaData.length === 0) return [];
        const currentBeta = betaData[index];
        const date = { columnKey: 'partnerId', value: '内部数据', type: 'text' } as TableExpandBody;
        const winlose = calcTableExpandBody('totalWin', currentBeta['TotalWin'], +item['totalWin'], 'text-right');
        const rebeat = calcTableExpandBody('rebeat', currentBeta['Rebeat'], +item['rebeat'], 'text-right');
        const bonus = calcTableExpandBody('bonus', currentBeta['Bonus'], +item['bonus'], 'text-right');
        const netWin = calcTableExpandBody('netWin', currentBeta['NetWin'], +item['netWin'], 'text-right');
        const amount = calcTableExpandBody('amount', currentBeta['Amount'], +item['amount'], 'text-right');
        // 存提手續費要另外計算
        const betaRechargAndWithdrawfee = ['RechargeFee', 'WithdrawFee'].reduce((x, y) => {
            return FloatHelper.plus(parseFloat((currentBeta as any)[y]), x);
        }, 0);

        const rechargAndWithdrawfee = calcTableExpandBody('rechargAndWithdrawfee', betaRechargAndWithdrawfee, +item['rechargAndWithdrawfee'], 'text-right');

        const status = {
            columnKey: 'diff',
            value: '抓取纪录',
            type: 'link',
            requestData: {
                src: api.memberCommissionHistory,
                requestKey: ['Date', 'AgentUserId'],
                layoutId: HistoryTableLayoutEnum.MEMBER_COMMISSION_HISTORY,
            },
        } as TableExpandBody;
        return !!item.diff ? [date, winlose, rebeat, bonus, netWin, amount, rechargAndWithdrawfee, status] : [];
    });
    return {
        tableHeader,
        tableBody,
        tableFooter,
        totalRecords: input.length,
        hasFooter: outputConfig.footer && outputConfig.footer.length > 0,
        tableExpandBody: tableExpandBody,
    };
}
