import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportChargeAndWithdrawal, IReportChargeAndWithdrawalData } from '@/models/page/report/IChargeAndWithdrawal';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function chargeAndWithdrawData(input: IReportChargeAndWithdrawal): ITableLayoutData {
    const outputConfig: TableConfig<IReportChargeAndWithdrawalData> = {
        column: [
            { name: 'date', text: 'Th_tradeDate', itemKeys: ['Date'], type: TableColumnStyle.Date },
            { name: 'rechargeUser', text: 'Th_rechargeNum', itemKeys: ['RechargeUser'], type: TableColumnStyle.Numeric },
            { name: 'recharge', text: 'Th_rechargeSum', itemKeys: ['Recharge'], type: TableColumnStyle.CommaNumeric },
            { name: 'withdrawUser', text: 'Th_withdrawNum', itemKeys: ['WithdrawUser'], type: TableColumnStyle.Numeric },
            { name: 'withdraw', text: 'Th_withdrawAmount', itemKeys: ['Withdraw'], type: TableColumnStyle.CommaNumeric },
            { name: 'upAmountUser', text: 'Th_upAmountUser', itemKeys: ['UpAmountUser'], type: TableColumnStyle.Numeric },
            { name: 'upAmount', text: 'Th_effectAmountSum', itemKeys: ['UpAmount'], type: TableColumnStyle.CommaNumeric },
        ],
        footer: ['rechargeUser', 'recharge', 'withdrawUser', 'withdraw', 'upAmountUser', 'upAmount'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return {
        tableHeader,
        tableBody,
        tableFooter,
        totalRecords: input.Summary.TotalRecords,
        hasFooter: outputConfig.footer && outputConfig.footer.length > 0,
    };
}
