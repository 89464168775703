import { COLORS, IPieChartData } from '@/components/pieChart/helpers';
import { Cell, Legend, Pie, ResponsiveContainer, PieChart as PieRechart, Customized } from 'recharts';
import { useTranslation } from 'react-i18next';

const CHILDREN_COLORS = ['#845ec2', '#296073', '#3596b5', '#adc5cf', '#ae5d00'];

export interface IPieOfPieChartProps {
    type: 'deposit' | 'recharge';
    parentData: IPieChartData[];
    childrenData: IPieChartData[];
}

export function PieOfPieChart({ type, parentData, childrenData }: IPieOfPieChartProps) {
    return (
        <ResponsiveContainer width="100%" height={420}>
            <PieRechart width={160} height={420}>
                <Pie
                    data={parentData}
                    dataKey="value"
                    cx={0}
                    cy={160}
                    startAngle={135}
                    endAngle={495}
                    outerRadius={80}
                    fill="#8884d8"
                    labelLine={false}
                    label={renderCustomizedLabel(false)}>
                    {parentData.map((entry, index) => (
                        <Cell key={entry.name} fill={COLORS[index]} />
                    ))}
                </Pie>

                <Customized component={UpperTangentLine} />
                <Customized component={LowerTangentLine} />

                <Pie
                    data={childrenData}
                    dataKey="value"
                    cx="65%"
                    cy="60%"
                    outerRadius={110}
                    startAngle={135}
                    endAngle={495}
                    fill="#8884d8"
                    labelLine={false}
                    label={renderCustomizedLabel(true)}>
                    {childrenData.map((entry, index) => (
                        <Cell key={entry.name} fill={CHILDREN_COLORS[index]} />
                    ))}
                </Pie>

                <Customized component={ParentPieLabel} />
                <Customized component={ChildrenPieLabel(type)} />

                <Legend layout="vertical" align="left" formatter={(value, entry: any) => `${value} (${((entry.payload.percent || 0) * 100).toFixed(2)}%)`} />
            </PieRechart>
        </ResponsiveContainer>
    );
}

const ParentPieLabel = ({ formattedGraphicalItems }: any) => {
    const { t } = useTranslation();
    const [parentPie] = formattedGraphicalItems;

    const parentX = parentPie.props.cx;
    const parentY = 20;

    return (
        <text x={parentX} y={parentY} textAnchor="middle">
            {t('Lbl_RechargeType_2') + '/' + t('Lbl_TradeType_1')}
        </text>
    );
};

const ChildrenPieLabel =
    (type: 'deposit' | 'recharge') =>
    ({ formattedGraphicalItems }: any) => {
        const { t } = useTranslation();
        const [, childrenPie] = formattedGraphicalItems;

        const childX = childrenPie.props.cx;
        const childY = 20;

        return (
            <text x={childX} y={childY} textAnchor="middle">
                {type === 'deposit' ? t('Lbl_DepositRecord') : t('Lbl_RechargeRecord')}
            </text>
        );
    };

const UpperTangentLine = ({ formattedGraphicalItems }: any) => {
    const [parentPie, childrenPie] = formattedGraphicalItems;
    const color = parentPie.props.data[0].fill;

    const parentX = parentPie.props.cx;
    const parentY = parentPie.props.cy - parentPie.props.outerRadius;

    const childrenX = childrenPie.props.cx;
    const childrenY = childrenPie.props.cy - childrenPie.props.outerRadius;

    return <line stroke={color} x={parentX} y={parentY} y1={parentY} y2={childrenY} x1={parentX} x2={childrenX} />;
};

const LowerTangentLine = ({ formattedGraphicalItems }: any) => {
    const [parentPie, childrenPie] = formattedGraphicalItems;
    const color = parentPie.props.data[0].fill;

    const parentX = parentPie.props.cx;
    const parentY = parentPie.props.cy + parentPie.props.outerRadius;

    const childrenX = childrenPie.props.cx;
    const childrenY = childrenPie.props.cy + childrenPie.props.outerRadius;

    return <line stroke={color} x={parentX} y={parentY} y1={parentY} y2={childrenY} x1={parentX} x2={childrenX} />;
};

const renderCustomizedLabel =
    (hideMinors: boolean) =>
    ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const RADIAN = Math.PI / 180;
        const x = cx + 1.25 * radius * Math.cos(-midAngle * RADIAN);
        const y = cy + 1.25 * radius * Math.sin(-midAngle * RADIAN);

        if (hideMinors && percent < 0.05) {
            return null;
        }

        return (
            <text x={x} y={y} fill="black" textAnchor="middle" fontSize="0.75em">
                <tspan>{name}</tspan>
                <tspan x={x} dy="1em">{`${(percent * 100).toFixed(0)}%`}</tspan>
            </text>
        );
    };
