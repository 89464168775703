import { HistoryTableLayoutEnum } from '@/components/historyModel/layout';
import {
    ExtraTableDataType,
    ITableLayoutData,
    TableColumnStyle,
    TableConfig,
    TableExpandBody,
    TableExtraData,
} from '@/models/layout/tableLayout';
import { IReportCommission, IReportCommissionData, IReportCommissionStatus } from '@/models/page/report/IReportCommission';
import { api } from '@/service/api';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';
import { numberComma } from '@/service/utils';
import { from, groupBy, map, mergeMap, toArray } from 'rxjs';

export function commissionData(input: IReportCommission): ITableLayoutData {
    const outputConfig: TableConfig<IReportCommissionData> = {
        column: [
            {
                name: 'partnerId',
                text: 'Th_partnerId',
                itemKeys: ['PartnerId'],
                type: TableColumnStyle.Partner,
            },
            {
                name: 'date',
                text: 'Th_month',
                itemKeys: ['Date'],
                type: TableColumnStyle.DateMonth,
            },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'agentUserId', text: '', itemKeys: ['AgentUserId'], type: TableColumnStyle.Text, invisible: true },

            {
                name: 'commAmount',
                text: 'Th_commAmount',
                itemKeys: ['CommAmount'],
                type: TableColumnStyle.CommaNumeric,
            },
            {
                name: 'diff',
                text: 'Th_Diff',
                itemKeys: ['CommAmount', 'CommAmountBeta'],
                type: TableColumnStyle.Icon,
                calculate: (data, keys) => {
                    const isSome = keys.every((key) => {
                        const a = FloatHelper.floatFixed(parseFloat((data as any)['CommAmount'] + ''), RoundType.Floor, 0);
                        const b = FloatHelper.floatFixed(parseFloat((data as any)['CommAmountBeta'] + ''), RoundType.Floor, 0);
                        return a === b;
                    });

                    return isSome ? '' : 'notification';
                },
            },
            {
                name: 'commAmountBeta',
                text: '',
                itemKeys: ['CommAmountBeta'],
                type: TableColumnStyle.CommaNumeric,
                invisible: true,
            },
            {
                name: 'expandable',
                text: '',
                itemKeys: ['CommAmount, CommAmountBeta'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    const commAmountNum = FloatHelper.floatFixed(parseFloat(data.CommAmount + ''), RoundType.Floor, 0);
                    const commAmountBetaNum = FloatHelper.floatFixed(parseFloat(data.CommAmountBeta + ''), RoundType.Floor, 0);
                    return commAmountNum !== commAmountBetaNum ? 1 : 0;
                },
                invisible: true,
            },
            { name: 'proxyComm', text: 'Th_proxyComm', itemKeys: ['ProxyComm'], type: TableColumnStyle.CommaNumeric },
            { name: 'amount', text: 'Th_commTotalAmount', itemKeys: ['Amount'], type: TableColumnStyle.CommaNumeric },
            { name: 'returnAmount', text: 'Th_returnAmount', itemKeys: ['ReturnAmount'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'status',
                text: 'Th_commStatus',
                itemKeys: ['Status'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    // 牛8
                    if (data.PartnerId === 3) {
                        return 'Lbl_ReleaseStatus_Unknow';
                    }

                    switch (data.Status) {
                        case IReportCommissionStatus.Settlement:
                            return 'Lbl_ReleaseStatus_Settlement';
                        case IReportCommissionStatus.PendingFinalSettlement:
                            return 'Lbl_ReleaseStatus_PendingFinalSettlement';
                    }
                    return `Lbl_ReleaseStatus_${data.Status}`;
                },
            },
        ],
        footer: ['commAmount', 'proxyComm', 'amount', 'returnAmount'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    const tableExpandBody = tableBody.map((item) => {
        const date = { columnKey: 'date', value: '内部数据', type: 'text' } as TableExpandBody;
        const commAmountNum = FloatHelper.floatFixed(parseFloat(item.commAmount + ''), RoundType.Floor, 0);
        const commAmountBetaNum = FloatHelper.floatFixed(parseFloat(item.commAmountBeta + ''), RoundType.Floor, 0);
        const diff = commAmountBetaNum - commAmountNum;
        const commAmount = {
            columnKey: 'commAmount',
            value: `${numberComma(commAmountBetaNum)} /n (${diff > 0 ? '多' : diff === 0 ? '' : '少'}${numberComma(Math.abs(diff))})/color:${
                diff > 0 ? 'text-red-500' : diff === 0 ? '' : 'text-green-500'
            }`,
            type: 'multiLine-text',
        } as TableExpandBody;
        const status = {
            columnKey: 'status',
            value: '抓取纪录',
            type: 'link',
            requestData: { src: api.commissionHistory, requestKey: ['Date', 'AgentUserId'], layoutId: HistoryTableLayoutEnum.COMMISSION_HISTORY },
        } as TableExpandBody;
        return !!item.expandable ? [date, commAmount, status] : [];
    });

    const extraTransfer = (list: IReportCommissionData[], key: string) => {
        return list.reduce((x, y) => {
            return x + FloatHelper.floatFixed(parseFloat(y[key as keyof IReportCommissionData] + ''), RoundType.Floor, 0);
        }, 0);
    };
    const extraTableData: { headerList: { key: ExtraTableDataType; value: string }[]; data: TableExtraData[] } = { headerList: [], data: [] };

    from(input.Data)
        .pipe(
            groupBy((v) => v.PartnerId),
            mergeMap((v) => v.pipe(toArray())),
            map((item) => {
                const resultObj: TableExtraData = { dataList: [] };
                if (item.length > 0) {
                    const commAmount = extraTransfer(item, 'CommAmount');
                    const commAmountBeta = extraTransfer(item, 'CommAmountBeta');
                    const diff = commAmountBeta - commAmount;
                    const diffResult = `${diff > 0 ? '多' : diff === 0 ? '' : '少'}${numberComma(Math.abs(diff))}/color:${
                        diff > 0 ? 'text-red-500' : diff === 0 ? '' : 'text-green-500'
                    }`;

                    resultObj.dataList = [
                        { value: item[0].PartnerId, alignment: 'text-right', type: TableColumnStyle.Partner },
                        { value: commAmount, type: TableColumnStyle.CommaNumeric },
                        { value: commAmountBeta, type: TableColumnStyle.CommaNumeric },
                        { value: diffResult, type: TableColumnStyle.TextWithColor },
                    ];
                }

                return resultObj;
            }),
            toArray(),
        )
        .subscribe((data) => {
            extraTableData.headerList = [
                { key: ExtraTableDataType.HEADER, value: '会员佣金汇总' },
                { key: ExtraTableDataType.BODY, value: '品牌' },
                { key: ExtraTableDataType.BODY, value: '内部数据' },
                { key: ExtraTableDataType.BODY, value: '差异状况' },
            ];
            extraTableData.data = data;
        });

    return {
        tableHeader,
        tableBody,
        tableFooter,
        totalRecords: input.Data.length,
        hasFooter: outputConfig.footer && outputConfig.footer.length > 0,
        tableExpandBody: tableExpandBody,
        extraTableData,
    };
}
