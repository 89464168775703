import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportMemberCommission, IReportMemberCommissionData } from '@/models/page/report/IReportCommission';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function commissionMemberDetailData(input: IReportMemberCommission): ITableLayoutData {
    const outputConfig: TableConfig<IReportMemberCommissionData> = {
        column: [
            { name: 'userName',text: 'Th_userName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'betAmount',text: 'Th_betAmount', itemKeys: ['BetAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'betCount',text: 'Th_betCount', itemKeys: ['BetCount'], type: TableColumnStyle.Numeric },
        ],
        footer: ['betAmount', 'betCount'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.Users,hasFooter: outputConfig.footer && outputConfig.footer.length > 0  };
}
