import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { useEffect, useState } from 'react';

export type IMemberInfo = {
    PartnerId: number;
    MemberUserName: string;
    AgentUserCode: string;
};

export type IChatFriendModel = {
    NickName: string;
    PartnerUsers: IMemberInfo[];

    HeadImg: string;
    MsgCount: number;
    VChatId: string;
};

export function useFriendList() {
    const [pageData, setPageData] = useState<IChatFriendModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const triggerGetFriendListHandler = useFetchApi<unknown, IChatFriendModel[], IChatFriendModel[]>(
        api.getVChatFriendList,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            if (value.data.IsSuccess) {
                setPageData(value.data.Data);
            }
            setIsLoading(false);
        },
        [],
        () => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        triggerGetFriendListHandler({});
    }, []);

    return {
        friends: pageData,
        isLoading,
        reload: triggerGetFriendListHandler,
    };
}
