import { ContactTypeEnum, ContactTypeGroupEnum } from '@/models/page/report/IReportMemberDetails';
import { ActionTypeEnum, IMemberContactsData } from '@/pages/memberAdmin/admin/profile';
import cn from 'mxcn';
import { Input, Select, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';

const contactTypeMapping: Record<ContactTypeGroupEnum, ContactTypeEnum[]> = {
    [ContactTypeGroupEnum.PersonalContact]: [ContactTypeEnum.Mobile, ContactTypeEnum.QQ, ContactTypeEnum.VChat, ContactTypeEnum.WeChat],
    [ContactTypeGroupEnum.LiveAccount]: [ContactTypeEnum.Live360, ContactTypeEnum.NineOneNine, ContactTypeEnum.EightEightSix],
};

function getIconNameFromContactType(contactTypeId: ContactTypeEnum): string {
    switch (contactTypeId) {
        case ContactTypeEnum.Mobile:
            return 'icon-mobile';
        case ContactTypeEnum.QQ:
            return 'icon-qq';
        case ContactTypeEnum.WeChat:
            return 'icon-sina-weibo';
        case ContactTypeEnum.VChat:
            return 'icon-vchat';
        case ContactTypeEnum.Live360:
            return 'Live360';
        case ContactTypeEnum.NineOneNine:
            return 'NineOneNine';
        case ContactTypeEnum.EightEightSix:
            return 'EightEightSix';
        default:
            return '';
    }
}

function getContactNameFromType(contactTypeId: ContactTypeEnum): string {
    switch (contactTypeId) {
        case ContactTypeEnum.Mobile:
            return 'Mobile';
        case ContactTypeEnum.QQ:
            return 'QQ';
        case ContactTypeEnum.WeChat:
            return 'WeChat';
        case ContactTypeEnum.VChat:
            return '66';
        case ContactTypeEnum.Live360:
            return '360吧';
        case ContactTypeEnum.NineOneNine:
            return '919直播';
        case ContactTypeEnum.EightEightSix:
            return '886直播';
        default:
            return '';
    }
}

type Props = {
    className?: string;
    editable: boolean;
    contactType: ContactTypeGroupEnum;
    contactItems: IMemberContactsData[];
    onChanged: (type: ActionTypeEnum, payload: IMemberContactsData[]) => void;
};

export const ContactForm = ({ className, editable, contactType, contactItems, onChanged }: Props) => {
    const { t } = useTranslation();
    const selectRef = useRef<HTMLSelectElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const matchingItems = contactItems.filter((item) => item.ContactTypeGroupId === contactType);

    function onAddItem() {
        if (!inputRef.current || !selectRef.current) return;

        if (inputRef.current.value && inputRef.current.value !== '') {
            inputRef.current.removeAttribute('data-invalid');
            const isVChat = ~~selectRef.current.value === ContactTypeEnum.VChat;
            onChanged(ActionTypeEnum.ADD, [
                {
                    id: Date.now(),
                    ContactTypeGroupId: contactType,
                    ContactTypeId: ~~selectRef.current.value as ContactTypeEnum,
                    ContactContent: (inputRef.current.value || '').trim(),
                    IsUnique: isVChat,
                    AllowEdit: !isVChat,
                },
            ]);
            inputRef.current.value = '';
        } else {
            inputRef.current.setAttribute?.('data-invalid', 'true');
        }
    }

    const [inputPlaceholder, setPlaceholder] = useState('');

    return (
        <div data-contact className={cn('flex items-center', className)}>
            <Text className="py-4 mx-6">{t(`Lbl_ContactTypeGroup_${contactType}`)}</Text>

            <div className="flex flex-col mx-6">
                {editable && (
                    <div className="flex">
                        <Select
                            isRequired
                            defaultValue={ContactTypeEnum.Mobile}
                            ref={selectRef}
                            onChange={(e) => {
                                setPlaceholder(() => {
                                    return (~~e.target.value as ContactTypeEnum) === ContactTypeEnum.VChat ? '请输入66 ID' : '';
                                });
                            }}>
                            {Object.values(ContactTypeEnum)
                                .filter((v) => {
                                    return !isNaN(+v) && contactTypeMapping[contactType].includes(+v);
                                })
                                .filter((id) => {
                                    const isUnique = !!contactItems.find((v) => v.ContactTypeId === id)?.IsUnique;
                                    return !isUnique;
                                })
                                .map((v) => (
                                    <option key={`select-contact-type-${v}`} value={v} className={cn('icon', getIconNameFromContactType(v as ContactTypeEnum))}>
                                        {getContactNameFromType(v as ContactTypeEnum)}
                                    </option>
                                ))}
                        </Select>

                        <Input className="mx-2" ref={inputRef} placeholder={inputPlaceholder} />

                        <button type="button" onClick={onAddItem}>
                            <span className="icon icon-add-outline self-center cursor-pointer" />
                        </button>
                    </div>
                )}

                {matchingItems ? (
                    matchingItems.map((item) => (
                        <div key={item.id} className="flex my-2">
                            {contactType === ContactTypeGroupEnum.LiveAccount ? (
                                <span className="font-extrabold self-center whitespace-nowrap">{getContactNameFromType(item.ContactTypeId)}</span>
                            ) : (
                                <span className={cn('icon text-2xl self-center', getIconNameFromContactType(item.ContactTypeId))} />
                            )}

                            <Input
                                className="mx-2"
                                disabled={!editable || item.AllowEdit === false}
                                defaultValue={item.ContactContent}
                                onChange={(e) => {
                                    const value = e.currentTarget.value;
                                    onChanged(ActionTypeEnum.UPDATE, [{ ...item, ContactContent: value }]);
                                }}
                            />

                            {editable && (
                                <span
                                    className="icon icon-cancel-circle self-center cursor-pointer"
                                    onClick={() => {
                                        onChanged(ActionTypeEnum.DELETE, [item]);
                                    }}
                                />
                            )}
                        </div>
                    ))
                ) : (
                    <p>無</p>
                )}
            </div>
        </div>
    );
};
