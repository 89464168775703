import { getEnableConfig } from '@/assets/config/common/partner.config';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { IRechargeQueryModel, IRechargePageModel, RechagerTaskStatusEnum } from '@/models/page/recharge/IRechargeMonitor';
import { PermissionsId } from '@/models/permissions';
import { api } from '@/service/api';
import { RootState } from '@/store/store.config';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetchApi from './useFetchApi';

export default function useRechargeOrders(): [boolean, () => void] {
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const [hasRechargeItems, setHasRechargeItems] = useState<boolean>(false);
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) =>
        state.userStore.agentPartners.filter((item) => {
            const isPartnerEnable = getEnableConfig()
                .map((v) => v.id)
                .includes(item.PartnerId);
            return isPartnerEnable;
        }),
    );

    const [queryModel] = useState<IRechargeQueryModel>({
        PartnerId: 0,
        DownLineList: agentUserList.map((item) => item.AgentUserId),
        TaskStatus: [RechagerTaskStatusEnum.Failed].map((item) => item),
        PageNo: 1,
        PageSize: 10,
    });

    const detachGetRechargeItems = useFetchApi<IRechargeQueryModel, IRechargePageModel, IRechargeQueryModel>(
        api.getRechargeOrders,
        ({ value }) => {
            return value;
        },
        ({ value }) => {
            if (!value || !value.data) {
                return;
            }

            if (value.data.IsSuccess) {
                setHasRechargeItems(value.data.Data.RechargeOrders.length > 0);
            } else {
            }
        },
        [queryModel],
        (error) => {},
    );

    useEffect(() => {
        permissionList.includes(PermissionsId.MemberRecharge) && detachGetRechargeItems(queryModel);
    }, [queryModel, permissionList]);

    const detachAction = () => detachGetRechargeItems(queryModel);

    return [hasRechargeItems, detachAction];
}
