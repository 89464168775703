export interface IReportChargeAndWithdrawal {
    Data: IReportChargeAndWithdrawalData[];
    Summary: IReportChargeAndWithdrawalSummary;
}

export interface IReportChargeAndWithdrawalData {
    Date: string;
    RechargeUser: number;
    Recharge: number;
    UpAmount: number;
    UpAmountUser: number;
    Withdraw: number;
    WithdrawUser: number;
    TradeType?: DepositTradeType;
}

export interface IReportChargeAndWithdrawalSummary {
    TotalRecords: number;
    RechargeUser: number;
    Recharge: number;
    UpAmount: number;
    UpAmountUser: number;
    Withdraw: number;
    WithdrawUser: number;
}

export interface DespositDetail {
    Summary: DespositSummary;
    Data: DepositDetailData[];
}

export interface DespositSummary {
    TotalRecords: number;
    Amount: number;
    CommFee: number;
}

export interface DepositDetailData {
    PartnerId: number;
    UserName: string;
    TradeNo: string;
    SubType: string;
    Amount: number;
    Status: DepositTradeStatus;
    CommFee: number;
    Balance: number;
    TradeType: string;
    CreateTime: string;
}

export enum DepositTradeType {
    ALL,
    RECHARGWE,
    WITHDRAW,
}

export enum SearchDepositTradeStatus {
    ALL = 0,
    PROCESSING = 3,
    SUCCESS = 1,
    FAILURE = 2,
}

export enum DepositTradeStatus {
    SUCCESS = 1,
    FAILURE = 2,
    PROCESSING = 0,
    PROCESSING2 = -1,
}
