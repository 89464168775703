import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export interface IRespMemberMonthlyReportData {
    Data: IData[];
    Summary: { FirstRechargeAmount: number; TotalRecords: number };
}

interface IData {
    AgentUserCode: string;
    AgentNickName: string;
    BetRate: number;
    EffectiveStatus: number;
    FirstRechargeAmount: number;
    MemberCode: string;
    MemberNickName: string;
    PartnerId: number;
    RegisterDate: string;
    RegisterDomain: string;
    UnbetDays: number;
    VipLevel: number;
    HasVChatId: boolean;
}

export function memberMonthlyReportData(input: IRespMemberMonthlyReportData): ITableLayoutData {
    const outputConfig: TableConfig<IData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'agentUserCode', text: '', itemKeys: ['AgentUserCode'], type: TableColumnStyle.Text, invisible: true },
            { name: 'memberCode', text: 'Th_userName', itemKeys: ['MemberCode'], type: TableColumnStyle.Text },
            { name: 'memberNickName', text: 'Txt_NickName', itemKeys: ['MemberNickName'], type: TableColumnStyle.Text },
            { name: 'registerDate', text: 'Th_registerDate', itemKeys: ['RegisterDate'], type: TableColumnStyle.DateTime },
            { name: 'vipLevel', text: 'Th_vipLevel', itemKeys: ['VipLevel'], type: TableColumnStyle.Numeric },
            {
                name: 'effectiveStatus',
                text: 'Th_effectiveStatus',
                itemKeys: ['EffectiveStatus'],
                type: TableColumnStyle.TextI18NWithColor,
                calculate: (data, keys) => {
                    const colorMap = new Map<number, string>([
                        [1, 'text-red-500'],
                        [2, 'text-green-500'],
                    ]);

                    const i18nMap = new Map<number, string>([
                        [1, 'Txt_ValidMember'],
                        [2, 'Txt_InvalidMember'],
                    ]);

                    return `${i18nMap.get(data.EffectiveStatus)}_with_${colorMap.get(data.EffectiveStatus)}`;
                },
            },
            { name: 'registerDomain', text: 'Lbl_registerDomain', itemKeys: ['RegisterDomain'], type: TableColumnStyle.Text },
            { name: 'firstRechargeAmount', text: 'Lbl_FirstRechargeSum', itemKeys: ['FirstRechargeAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'unbetDays', text: 'Th_unBetDays', itemKeys: ['UnbetDays'], type: TableColumnStyle.Numeric },
            { name: 'betRate', text: 'Lbl_This_Month_Bet_Rate', itemKeys: ['BetRate'], type: TableColumnStyle.VIPRank },
            { name: 'hasVChatId', text: '', itemKeys: ['HasVChatId'], type: TableColumnStyle.Text, invisible: true },
        ],
        footer: ['firstRechargeAmount'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return {
        tableHeader,
        tableBody,
        tableFooter,
        totalRecords: input.Summary.TotalRecords,
        hasFooter: outputConfig.footer && outputConfig.footer.length > 0,
    };
}
