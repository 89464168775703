import { useTranslation } from 'react-i18next';
import { PropsWithChildren } from 'react';

type Props = {
    triggerHandler: () => void;
    isButtonNewLine?: boolean;
};
const SearchArea = (props: PropsWithChildren<Props>) => {
    const { triggerHandler = () => {}, children, isButtonNewLine = true } = props;
    const { t } = useTranslation();
    return (
        <>
            <div className={`flex ${isButtonNewLine ? 'flex-col w-full' : ''}`}>
                <div className="flex-1 mx-4">{children}</div>
                <div className="self-end">
                    <button
                        className={`p-2 mr-5 bg-affiliate text-white rounded hover:bg-gray-500 w-28 ${isButtonNewLine ? 'mt-2' : ''}`}
                        onClick={triggerHandler}>
                        <span className="px-1 icon icon-search" />
                        <label className="px-1 cursor-pointer whitespace-nowrap">{t('Lbl_search')}</label>
                    </button>
                </div>
            </div>
        </>
    );
};

export default SearchArea;
