import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportCommissionStatus } from '@/models/page/report/IReportCommission';
import { FloatHelper } from '@/service/floatHelper';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

interface IMemberCommissionHistory {
    AgentNickName: string;
    AgentUserCode: string;
    AgentUserId: number;
    Amount: number;
    Bonus: number;
    CommAmount: number;
    CommRate: number;
    Date: string;
    LastAmount: number;
    NetWin: number;
    PartnerId: number;
    PlatFee: number;
    Rebeat: number;
    RechargeFee: number;
    SysStartTime: string;
    TotalWin: number;
    UserCount: number;
    WithdrawFee: number;
    Status: number;
}

export function memberCommissionHistoryData(input: { Data: IMemberCommissionHistory[] }): ITableLayoutData {
    const outputConfig: TableConfig<IMemberCommissionHistory> = {
        column: [
            { name: 'userCount', text: 'Th_userCount', itemKeys: ['UserCount'], type: TableColumnStyle.Numeric },
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner, invisible: true },
            { name: 'date', text: 'Th_date', itemKeys: ['Date'], type: TableColumnStyle.Date, invisible: true },
            { name: 'agentUserId', text: '', itemKeys: ['AgentUserId'], type: TableColumnStyle.Text, invisible: true },
            { name: 'agentUserCode', text: 'Th_proxyName', itemKeys: ['AgentUserCode'], type: TableColumnStyle.Text, invisible: true },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text, invisible: true },
            { name: 'totalWin', text: 'Th_totalWin', itemKeys: ['TotalWin'], type: TableColumnStyle.CommaNumericWithSymbol },
            { name: 'platfee', text: 'Th_platfee', itemKeys: ['PlatFee'], type: TableColumnStyle.CommaNumeric },
            { name: 'bonus', text: 'Th_bonus', itemKeys: ['Bonus'], type: TableColumnStyle.CommaNumeric },
            { name: 'rebeat', text: 'Th_rebeat', itemKeys: ['Rebeat'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'rechargAndWithdrawfee',
                text: 'Th_rechargAndWithdrawfee',
                itemKeys: ['RechargeFee', 'WithdrawFee'],
                type: TableColumnStyle.CommaNumeric,
                calculate: (data, keys) => {
                    const num = keys.reduce((x, y) => {
                        return FloatHelper.plus(parseFloat((data as any)[y]), x);
                    }, 0);

                    return num;
                },
            },
            { name: 'lastAmount', text: 'Th_lastAmount', itemKeys: ['LastAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'netWin', text: 'Th_netWin', itemKeys: ['NetWin'], type: TableColumnStyle.CommaNumericWithSymbol },
            { name: 'commRate', text: 'Th_commRate', itemKeys: ['CommRate'], type: TableColumnStyle.Percentage },
            { name: 'amount', text: 'Th_actureAmount', itemKeys: ['Amount'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'status',
                text: 'Th_commStatus',
                itemKeys: ['Status'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    // 牛8
                    if (data.PartnerId === 3) {
                        return 'Lbl_ReleaseStatus_Unknow';
                    }

                    switch (data.Status) {
                        case IReportCommissionStatus.Settlement:
                            return 'Lbl_ReleaseStatus_Settlement';
                        case IReportCommissionStatus.PendingFinalSettlement:
                            return 'Lbl_ReleaseStatus_PendingFinalSettlement';
                    }
                    return `Lbl_ReleaseStatus_${data.Status}`;
                },
            },
            { name: 'sysDate', text: 'Lbl_grabbingDate', itemKeys: ['SysStartTime'], type: TableColumnStyle.DateTime },
        ],
        footer: [],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: 1, hasFooter: false };
}
