import { FC } from 'react';
import AccountManagement from './layouts/accountManagement';
import AuditLog from './layouts/auditLog';
export enum SystemSettingLayoutEnum {
    ACCOUNT_MANAGEMENT,
    SUB_ACCOUNT_MANAGEMENT,
    AUDIT_LOG,
}

export const SystemSettingLayoutMapping: Record<SystemSettingLayoutEnum, FC<ISystemSettingLayoutProps>> = {
    [SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT]: AccountManagement,
    [SystemSettingLayoutEnum.SUB_ACCOUNT_MANAGEMENT]: AccountManagement,
    [SystemSettingLayoutEnum.AUDIT_LOG]: AuditLog,
};

export interface ISystemSettingLayoutProps {
    type: SystemSettingLayoutEnum;
}

export interface ISystemSettingPopupLayoutProps {
    onClose(): void;
}
