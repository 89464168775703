import { useTranslation } from 'react-i18next';
import { numberComma } from '@/service/utils';
import React from 'react';
import cn from 'mxcn';
import { MemberStatisticType } from '@/pages/memberAdmin/admin/statistics/useStatisticsData';
import dayjs from 'dayjs';
import { FloatHelper } from '@/service/floatHelper';

export interface IMemberStatisticsDataType {
    key: string;
    label: string;
    amount: number;
    chartType?: number;
    latestDataTime?: string;
    latestDataAmount?: number;
    remark: string[];
}

export interface IMemberStatisticsTableProps {
    data: {
        lastUpdatedTime: string | null;
        stats: IMemberStatisticsDataType[];
        summary: Array<{
            key: string;
            amount: number;
        }>;
    };
    onSwitchChart: (statisticType: string, chartIndex: number) => void;
    onViewItinerary: (itineraryType: MemberStatisticType) => void;
}

export default function MemberStatisticsTable({ data, onSwitchChart, onViewItinerary }: IMemberStatisticsTableProps) {
    const { t } = useTranslation();

    return (
        <table className="table-auto w-full">
            <thead className="bg-gray-100 h-16 mb-12 border-b align-bottom">
                <tr>
                    <th colSpan={2} className="text-right">
                        {t('Lbl_date_range') + t('Th_balance')}
                    </th>
                    <th></th>
                    <th className="text-gray-400 text-left">{t('Lbl_LatestRecord')}</th>
                    <th colSpan={2} className="text-sm text-gray-400">
                        <span>{t('Lbl_RecordUpdatedTil')}</span>
                        <span>{dayjs(data.lastUpdatedTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td colSpan={6} className="p-2" />
                </tr>

                {data.stats.map((stat, index) => (
                    <tr key={`${stat.key}_${index}`} className="even:bg-gray-100 h-12">
                        <td>{t(stat.label)}</td>
                        <td>
                            <Amount data={stat} onClick={onViewItinerary} />
                        </td>
                        <td className="min-w-[45px]">
                            {stat.chartType !== undefined && (
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSwitchChart(stat.key, stat.chartType!);
                                    }}>
                                    <span className="icon icon-statistics" />
                                </a>
                            )}
                        </td>
                        <td className="text-gray-400">{dayjs(stat.latestDataTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td className="text-gray-400">
                            {stat.latestDataAmount && '$' + numberComma(FloatHelper.floatFixed(stat.latestDataAmount, 'Floor', 0))}
                        </td>
                        <td className="text-sm">
                            <Remark data={stat} />
                        </td>
                    </tr>
                ))}
            </tbody>

            <tfoot className="border-t">
                {data.summary.map((sum) => (
                    <tr key={sum.key} className="odd:bg-gray-100 h-12">
                        <td>{t(sum.key)}</td>
                        <td colSpan={5}>{'$' + numberComma(FloatHelper.floatFixed(sum.amount, 'Floor', 0))}</td>
                    </tr>
                ))}
            </tfoot>
        </table>
    );
}

function Amount({ data, onClick }: { data: IMemberStatisticsDataType; onClick: (itineraryType: MemberStatisticType) => void }) {
    switch (data.key) {
        case 'WinLose':
            return (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        onClick('WinLose');
                    }}
                    className={cn('underline', {
                        'text-red-600': data.amount > 0,
                        'text-green-600': data.amount < 0,
                    })}>
                    {'$' + numberComma(FloatHelper.floatFixed(data.amount, 'Floor', 0))}
                </a>
            );
        case 'FirstRecharge':
            return <span>{'$' + numberComma(FloatHelper.floatFixed(data.amount, 'Floor', 0))}</span>;
        default:
            return (
                <a href="#" onClick={() => onClick(data.key)} className="underline">
                    {'$' + numberComma(FloatHelper.floatFixed(data.amount, 'Floor', 0))}
                </a>
            );
    }
}

function Remark({ data }: { data: IMemberStatisticsDataType }) {
    const { t } = useTranslation();

    if (data.remark.length <= 0) return null;

    switch (data.key) {
        case 'WinLose':
            return data.remark[0] === 'True' ? (
                <span className="text-green-600">{t('Th_ValidUser')}</span>
            ) : (
                <span className="text-red-600">{t('Th_InvalidUser')}</span>
            );
        case 'BetAmount':
            return (
                <span className="text-gray-400">
                    {t('Th_unBetDays')}： {data.remark[0]}
                </span>
            );
        case 'Withdraw':
            return (
                <span className="text-gray-400">
                    {data.remark[0] === '-1' ? t('Lbl_RechargeType_1') : data.remark[0] === '1' ? t('Lbl_Third_Auto_Withdraw') : data.remark[0]}
                    <br />
                    {data.remark[1]}
                </span>
            );
        default:
            return (
                <span className="text-gray-400">
                    <pre>{data.remark.join('\n')}</pre>
                </span>
            );
    }
}
