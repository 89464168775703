export interface IPieChartData {
    name: string;
    value: number;
}

export const COLORS = ['skyblue', 'seagreen', 'goldenrod', 'slateblue'];

const RADIAN = Math.PI / 180;
export const renderCustomizedLabel = ({
                                          cx,
                                          cy,
                                          midAngle,
                                          innerRadius,
                                          outerRadius,
                                          percent,
                                      }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent < 0.05) {
        return null
    }

    return (
        <text x={x} y={y} fill='black' fontSize='0.75em' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
