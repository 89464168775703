import { useTranslation } from 'react-i18next';
import { IOtherInfoTextBoardProp } from '@/models/page/dashboard/IDashboard';

export const OtherInfoTextBoard = (props: IOtherInfoTextBoardProp) => {
    const { t } = useTranslation();
    return (
        <div className="flex divide-x-2 divide-gray-200 text-lg w-full m-2 ">
            <div className={'ml-2 pl-2 flex-col text-center grow '}>
                <div className=" text-xs">{t('Lbl_HistoryRechargeUsers')}</div> <div className=" font-bold">{props.HistoryRechargeUsers}</div>
            </div>
            <div className={'ml-2 pl-2 flex-col text-center grow '}>
                <div className=" text-xs">{t('Lbl_EffectiveUserCount')}</div> <div className="font-bold">{props.EffectiveUserCount}</div>
            </div>
        </div>
    );
};
