import { Cell, Legend, Pie, PieChart as PieRechart, ResponsiveContainer } from 'recharts';
import { COLORS, IPieChartData, renderCustomizedLabel } from '@/components/pieChart/helpers';

export interface IPieChartProps {
    title: string;
    data: IPieChartData[];
}

export function PieChart({
    title, data,
                         }: IPieChartProps) {
    return (
        <div className="flex flex-col justify-center">
            <h6 className="">{title}</h6>

            <ResponsiveContainer width="100%" height={300}>
                <PieRechart width={240} height={240}>
                    <Pie data={data} dataKey="value" cx="50%" cy="50%" outerRadius={120} fill="#8884d8" label={renderCustomizedLabel} labelLine={false}>
                        {data.map((entry, index) => (
                            <Cell key={entry.name} fill={COLORS[index]} />
                        ))}
                    </Pie>

                    <Legend
                        layout="vertical"
                        align="right"
                        formatter={(value, entry: any) => {
                            return `${value} (${(entry.payload.percent * 100).toFixed(2)}%)`;
                        }}
                    />
                </PieRechart>
            </ResponsiveContainer>
        </div>
    );
}
