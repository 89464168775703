import { DATETIME_FORMAT } from '@/constants/datetime';
import dayjs from 'dayjs';
import { IBaseRequestData } from '../apiRequest';
import { DEFAULT_PAGE_COUNT } from '../apiRequest/baseRequest';

export enum MemberAdminPageEnum {
    INDEX = -1,
    EDIT_PROFILE,
    STATISTICS,
    RELATION_ACCOUNT,
    RECHARGE,
    CHAT,
}

export type MemberDetailParams = {
    agentUserCode: string;
    agentNickName: string;
    betRate: MemberAdminPageEnum;
    effectiveStatus: string;
    firstRechargeAmount: number;
    memberCode: string;
    memberNickName: string;
    partnerId: number;
    registerDate: string;
    registerDomain: string;
    unbetDays: number;
    vipLevel: number;
    hasVChatId: boolean;
};

export interface IMemberAdminRequest extends IBaseRequestData {
    IsFuzzySearch: boolean;
    BetRate: number;
    EffectiveStatus: number;
    VipLevel: number;
    UnBetDays: number;
    DateFrom: string;
    DateTo: string;
    PartnerId: number;
    UserIdList: string[];
    PageNo: number;
    PageSize: number;
}

export const initMemberAdminRequestParams: IMemberAdminRequest = {
    IsFuzzySearch: false,
    BetRate: 0,
    EffectiveStatus: 0,
    VipLevel: -1,
    UnBetDays: 0,
    DateFrom: '2018-01-01 00:00:00',
    DateTo: dayjs().format(DATETIME_FORMAT.YMDHMSS),
    PartnerId: 0,
    UserName: '',
    UserIdList: [],
    PageNo: 1,
    PageSize: DEFAULT_PAGE_COUNT,
    UserId: 1,
};
