import { MemberDetailsLayoutProps } from '..';
import { statisticRequestTypeMapping, useMemberStatisticsData } from '@/pages/memberAdmin/admin/statistics/useStatisticsData';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { IMemberDetailStatisticWinLoseData } from '@/models/page/report/IReportMemberDetails';
import { numberComma } from '@/service/utils';
import cn from 'mxcn';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import LoadingMask from '@/components/loadingMask/loadingMask';

// 輸贏
const MemberDetailsWinLose = ({ memberDetail, requestData }: MemberDetailsLayoutProps) => {
    const [statistics, fetchHandler, isLoading] = useMemberStatisticsData<IMemberDetailStatisticWinLoseData>({
        memberPartnerId: memberDetail.PartnerId,
        memberUserName: memberDetail.UserName,
        queryDateRange: {
            startDate: requestData.DateFrom,
            endDate: requestData.DateTo,
        },
    });
    const { t } = useTranslation();

    useEffect(() => {
        fetchHandler({
            StatisticType: statisticRequestTypeMapping.WinLose,
            PartnerId: memberDetail.PartnerId,
            UserName: memberDetail.UserName,
            DateFrom: requestData.DateFrom,
            DateTo: requestData.DateTo,
            PageNo: 1,
            PageSize: 10,
        });
    }, []);

    return (
        <>
            <Table className="table">
                <Thead className="table" style={{ width: 'calc( 100% - 1em )' }}>
                    <Tr>
                        <Th>{t('Th_game')}</Th>
                        <Th className="w-[20%]">
                            <div className="text-right">{t('Th_betAmount')}</div>
                        </Th>
                        <Th>
                            <div className="text-right">{t('Th_totalWin')}</div>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody className="block overflow-y-auto max-h-[200px] ">
                    {statistics &&
                        statistics.MemberGameProductStatistics.map((entry, index) => (
                            <Tr key={index} className="table w-full table-fixed">
                                <Td>{entry.ProductName}</Td>
                                <Td>
                                    <div className="text-right">
                                        {'$' + numberComma(FloatHelper.floatFixed(parseFloat(entry.BetAmount.toString()), RoundType.Floor, 0))}
                                    </div>
                                </Td>
                                <Td>
                                    <div
                                        key={entry.ProductName}
                                        className={`text-right ${cn({
                                            'text-red-600': entry.TotalWin > 0,
                                            'text-green-600': entry.TotalWin < 0,
                                        })}`}>
                                        {'$' + numberComma(FloatHelper.floatFixed(parseFloat(entry.TotalWin.toString()), RoundType.Floor, 0))}
                                    </div>
                                </Td>
                            </Tr>
                        ))}
                </Tbody>
                <Tfoot className="table w-full table-fixed bg-gray-100">
                    {statistics && (
                        <Tr key="total">
                            <Td>{t('Lbl_total-summary')}</Td>
                            <Td>
                                <div className="text-right">
                                    {'$' + numberComma(FloatHelper.floatFixed(parseFloat(statistics.TotalBetAmount.toString()), RoundType.Floor, 0))}
                                </div>
                            </Td>
                            <Td>
                                <div
                                    className={`text-right ${cn({
                                        'text-red-600': statistics.TotalWinLose > 0,
                                        'text-green-600': statistics.TotalWinLose < 0,
                                    })}`}>
                                    {'$' + numberComma(FloatHelper.floatFixed(parseFloat(statistics.TotalWinLose.toString()), RoundType.Floor, 0))}
                                </div>
                            </Td>
                        </Tr>
                    )}
                </Tfoot>
            </Table>

            <LoadingMask visible={isLoading} />
        </>
    );
};

export default MemberDetailsWinLose;
