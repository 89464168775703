type Props = {
    children?: string | JSX.Element | JSX.Element[];
};

const TitleLabel = ({ children }: Props) => {
    return (
        <>
            <div className="my-4 mr-1">
                <span className="pl-2 py-2 text-lg font-black bg-affiliate text-gray-100 rounded-r-full pr-5">{children}</span>
            </div>
        </>
    );
};

export default TitleLabel;
