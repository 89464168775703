import { PopupModel } from '@/components/popupModal/popupModal';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, UseDisclosureProps } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { IRechargeItemModel, RechagerTaskStatusEnum } from '@/models/page/recharge/IRechargeMonitor';
import cn from 'mxcn';
import { DATETIME_FORMAT } from '@/constants/datetime';
import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';

export type IRechargeMonitorPupupProps = {
    details?: IRechargeItemModel;
} & UseDisclosureProps;

const logColumns = [
    { label: 'Lbl_serialId', name: 'Id' },
    { label: 'Lbl_ExecutedDate', name: 'DateCreated' },
    { label: 'Th_status', name: 'TaskStatus' },
    { label: 'Lbl_ModifyAccount', name: 'ModifyAccount' },
    { label: 'Lbl_Memo', name: 'ErrorMessage' },
]

export const RechargeMonitorLogPopup = ({ details, ...props }: IRechargeMonitorPupupProps) => {
    const { t } = useTranslation();

    return (
        <>
            <PopupModel {...{ ...props, title: t('Lbl_ExecuteLog'), size: '3xl' }}>
                <div className="flex-col flex h-[650px] gap-4 px-4 overflow-y-auto">
                    <div data-container>
                        {details && (
                            <ul className='grid grid-cols-2 grid-rows-2 gap-4'>
                                <li className='flex gap-2 items-center py-2'>
                                    <span className="font-bold text-sm">{t('Th_partnerId')}</span>
                                    <span>{t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[details.PartnerId].text}`)}</span>
                                </li>

                                <li className='flex gap-2 items-center py-2'>
                                    <span className="font-bold text-sm">{t('Th_agentNickName')}</span>
                                    <span>{details.AgentNickName}</span>
                                </li>

                                <li className='flex gap-2 items-center py-2'>
                                    <span className="font-bold text-sm">{t('Lbl_MemberAccount')}</span>
                                    <span>{details.UserName}</span>
                                </li>

                                <li className='flex gap-2 items-center py-2'>
                                    <span className="font-bold text-sm">{t('Lbl_TransactionNo')}</span>
                                    <span>{details.TransactionNo}</span>
                                </li>
                            </ul>
                        )}
                    </div>

                    <hr style={{borderTopWidth: '3px'}} />

                    <div data-container>
                        <TableContainer>
                            <Table variant="striped" colorScheme="blackAlpha">
                                <Thead>
                                    <Tr>
                                        {logColumns.map((item) => (
                                            <Th key={`userInfo-th-${item.label}`}>
                                                <span className="font-bold">{t(item.label)}</span>
                                            </Th>
                                        ))}
                                    </Tr>
                                </Thead>

                                <Tbody>
                                    {details?.RechargeAuditLogs ?
                                        details.RechargeAuditLogs.map((item, idx) => (
                                            <Tr key={`tr-log-detail-key-${item.DateCreated}`}>
                                                <Td>{idx + 1}</Td>
                                                <Td>{dayjs(item.DateCreated).format(DATETIME_FORMAT.YMDHMS)}</Td>
                                                <Td>
                                                    <span className={cn(item.TaskStatus === RechagerTaskStatusEnum.Failed && 'text-red-500 font-bold')}>
                                                        {t(`Txt_RechagerTaskStatus_${item.TaskStatus}`)}
                                                    </span>
                                                </Td>
                                                <Td>{item.UserUpdated}</Td>
                                                <Td className="whitespace-pre-wrap">{item.ErrorMessage}</Td>
                                            </Tr>
                                        )) : (
                                        <Tr>
                                            <Td fontSize='2xl' colSpan={logColumns.length} className="text-center" textAlign="center">
                                                {t('Lbl_NoRecord')}
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </PopupModel>
        </>
    );
};
