import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportCommissionStatus } from '@/models/page/report/IReportCommission';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

interface ICommissionHistory {
    AgentNickName: string;
    AgentUserCode: string;
    AgentUserId: number;
    Amount: number;
    CommAmount: number;
    CommAmountBeta: number;
    Date: string;
    PartnerId: number;
    ProxyComm: number;
    ReturnAmount: number;
    Status: number;
    SysStartTime: string;
}

export function commissionHistoryData(input: { Data: ICommissionHistory[] }): ITableLayoutData {
    const outputConfig: TableConfig<ICommissionHistory> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner, invisible: true },
            { name: 'agentNickName', text: '', itemKeys: ['AgentNickName'], type: TableColumnStyle.Partner, invisible: true },
            { name: 'date', text: 'Th_date', itemKeys: ['Date'], type: TableColumnStyle.Date, invisible: true },
            { name: 'commAmount', text: 'Th_commAmount', itemKeys: ['CommAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'proxyComm', text: 'Th_proxyComm', itemKeys: ['ProxyComm'], type: TableColumnStyle.CommaNumeric },
            { name: 'amount', text: 'Th_commTotalAmount', itemKeys: ['Amount'], type: TableColumnStyle.CommaNumeric },
            { name: 'returnAmount', text: 'Th_returnAmount', itemKeys: ['ReturnAmount'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'status',
                text: 'Th_commStatus',
                itemKeys: ['Status'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    // 牛8
                    if (data.PartnerId === 3) {
                        return 'Lbl_ReleaseStatus_Unknow';
                    }

                    switch (data.Status) {
                        case IReportCommissionStatus.Settlement:
                            return 'Lbl_ReleaseStatus_Settlement';
                        case IReportCommissionStatus.PendingFinalSettlement:
                            return 'Lbl_ReleaseStatus_PendingFinalSettlement';
                    }
                    return `Lbl_ReleaseStatus_${data.Status}`;
                },
            },
            { name: 'sysDate', text: 'Lbl_grabbingDate', itemKeys: ['SysStartTime'], type: TableColumnStyle.DateTime },
        ],
        footer: [],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: 1, hasFooter: false };
}
