import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { DepositTradeStatus, DespositDetail, DepositDetailData } from '@/models/page/report/IChargeAndWithdrawal';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function withdrawUserData(input: DespositDetail): ITableLayoutData {
    const outputConfig: TableConfig<DepositDetailData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'tradeNo', text: 'Th_tradeBillNo', itemKeys: ['TradeNo'], type: TableColumnStyle.Text },
            { name: 'createTime', text: 'Th_tradeDate', itemKeys: ['CreateTime'], type: TableColumnStyle.Date },
            { name: 'userName', text: 'Th_userName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            {
                name: 'subType',
                text: 'Th_withdrawTradeType',
                itemKeys: ['SubType'],
                type: TableColumnStyle.Text,
                calculate: (data, key) => {
                    let result = data.SubType;
                    const filterCase = [
                        { key: '1', value: '三方自动出款' },
                        { key: '-1', value: '其他' },
                    ];
                    result = filterCase.find((v) => v.key === result)?.value || result;
                    return result;
                },
            },
            { name: 'amountWithdraw', text: 'Th_balance', itemKeys: ['Amount'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'status',
                text: 'Th_status',
                itemKeys: ['Status'],
                type: TableColumnStyle.TextI18NWithColor,
                calculate: (data, keys) => {
                    const colorMap = new Map<number, string>([
                        [DepositTradeStatus.PROCESSING, 'text-gray-300'],
                        [DepositTradeStatus.PROCESSING2, 'text-gray-300'],
                        [DepositTradeStatus.FAILURE, 'text-green-500'],
                        [DepositTradeStatus.SUCCESS, 'text-red-500'],
                    ]);
                    return `Lbl_DepositTradeStatus_${data.Status}_with_${colorMap.get(data.Status)}`;
                },
            },
        ],
        footer: [],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.TotalRecords, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
