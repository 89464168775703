import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IMemberFirstCharge, IMemberFirstChargeData } from '@/models/page/report/IMemberFirstCharge';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function memberFirstChargeData(input: IMemberFirstCharge): ITableLayoutData {
    const outputConfig: TableConfig<IMemberFirstChargeData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'userName', text: 'Th_userName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'amount', text: 'Th_firstChargeAmount', itemKeys: ['Amount'], type: TableColumnStyle.CommaNumeric },
            { name: 'tradeType', text: 'Th_firstChargeType', itemKeys: ['TradeType'], type: TableColumnStyle.Text },
            { name: 'rechargeTime', text: 'Th_firstChargeDate', itemKeys: ['RechargeTime'], type: TableColumnStyle.DateTime },
            { name: 'tradeNo', text: 'Th_tradeNo', itemKeys: ['TradeNo'], type: TableColumnStyle.Text },
        ],
        footer: ['amount'],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return {
        tableHeader,
        tableBody,
        tableFooter,
        totalRecords: input.Summary.TotalRecords || 0,
        hasFooter: outputConfig.footer && outputConfig.footer.length > 0,
    };
}
