import { useTranslation } from 'react-i18next';
import { APIModel, IBaseExportData, IBaseRequestData, IExportSearchData } from '@/models/apiRequest';
import { ITableLayoutData, TableColumnStyle, TableHeaderType, TableLayoutAPI, TableSearch } from '@/models/layout/tableLayout';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IReportProps } from '.';
import useFetchApi from '@/hooks/useFetchApi';
import { tableMapping } from '@/models/mapping/table.mapping';
import TableComponent, { ITableComp, ModelType } from './tableComponent';
import Pagination from '@/components/pagination';
import DateRangeWithIput from '@/components/searchArea/dateRangeWithInput';
import { tableLayoutConfig } from '@/assets/config/layout/layout.config';
import { pluckFirst, useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import { withLatestFrom } from 'rxjs';
import TitleLabel from '@/components/titleLabel';
import { parseCommissionType, toFirstUpperCase } from '@/service/utils';
import LoadingMask from '@/components/loadingMask/loadingMask';
import MemberModel, { MemberModelRef } from '@/components/memberModel';
import { Box, Button, Card, CardBody, Heading, Stack, StackDivider, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { tableNoteMapping } from '@/models/mapping/reportNote.mapping';
import { baseRequest, DEFAULT_PAGE_COUNT } from '@/models/apiRequest/baseRequest';
import SearchColumn from '@/components/searchArea/searchColumn';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { IAgentPartner, RoleTypeEnum } from '@/models/page/logon/ILogon';
import { IDialogSetting, showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import { getEnableConfig } from '@/assets/config/common/partner.config';
import { ReportPageEnum } from '@/models/transfer/reportTransfer';
import HistoryModel, { HistoryModelRef } from '@/components/historyModel';
import { HistoryTableLayoutEnum } from '@/components/historyModel/layout';
import ExtraTable, { IExtraTable } from '@/components/extraTable';
import { EXPORT_CONFIG_MAP } from '@/assets/config/layout/export.config';

interface IProps extends IReportProps {}
const Container = (props: IProps) => {
    const { api, currentPageId: currentTab, search, direactableKeyList, payload, headerText, setReportProps } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userRoleType = useSelector<RootState, RoleTypeEnum>((s) => s.userStore.roleType);
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) =>
        state.userStore.agentPartners.filter((item) => {
            const isPartnerEnable = getEnableConfig()
                .map((v) => v.id)
                .includes(item.PartnerId);
            return isPartnerEnable;
        }),
    ); // 取全部AgentUserIdList列表
    const agentEnableUserList = useMemo(() => {
        return agentUserList.filter((item) => item.IsEnabled);
    }, [agentUserList]); // 取所有EnableAgentUserIdList列表
    const [searchData, setSearchData] = useState<IBaseRequestData>(api?.initParams ?? baseRequest); // 紀錄搜尋列表的參數用
    const [requestData, setRequestData] = useState<IBaseRequestData>(searchData); // 紀錄 fetch api Request的值
    const [realRequestData, setRealRequestData] = useState<{ [key: string]: IBaseRequestData }>();
    const [isLoading, setIsLoading] = useState(false);
    const [renderKey, setRenderKey] = useState(Date.now()); // rerender component用
    const [responseData, setResponseData] = useState<ITableLayoutData>();
    const [switchId, setSwitchId] = useState(-1);
    const hasPrevRequest = useRef<boolean>(false);

    useEffect(() => {
        // 只有透過submenu點擊進來才會觸發
        props.isTriggerBySubMenu && setRealRequestData(undefined);
        hasPrevRequest.current = false;
    }, [props.isTriggerBySubMenu]);

    useEffect(() => {
        // 每次切頁面時將SearchData刷回預設值
        const defaultSearchData = api?.initParams || { ...baseRequest };
        if (Object.hasOwn(defaultSearchData, 'UserIdList')) {
            defaultSearchData.UserIdList = agentEnableUserList.map((item) => item.AgentUserId.toString());
        }
        setSearchData((data) => {
            return hasPrevRequest.current ? data : defaultSearchData;
        });

        setRenderKey(Date.now());
        setResponseData(undefined);
        setRequestData((data) => {
            let req = { ...baseRequest, ...props.api?.initParams };
            const currentPartnerId = req.PartnerId;
            if (Object.hasOwn(req, 'UserIdList')) {
                req['UserIdList'] = agentEnableUserList.map((item) => item.AgentUserId.toString());
            }
            req = hasPrevRequest.current ? searchData : req;
            detectChangeAndFetchHandler(req);
            hasPrevRequest.current = false;
            return req;
        });
        setSwitchId(props.headerType === TableHeaderType.SWITCHER ? 0 : -1);
    }, [currentTab]);

    const [searchAreaHandler, searchChangeHandler$] = useObservableCallback<{ [key: string]: string }>((event$) => {
        return event$;
    });
    // fetch api
    const detectChangeAndFetchHandler = useFetchApi<
        IBaseRequestData,
        any,
        { payload: typeof payload; agentUserList: IAgentPartner[]; searchData: IBaseRequestData; currentPageId: ReportPageEnum }
    >(
        api,
        ({ apiItem, value, deps: [{ payload: _payload, agentUserList: _agentUserList, searchData: _searchData, currentPageId: _currentPageId }] }) => {
            let resultRequest: IBaseRequestData = value;
            const currentApi = apiItem as TableLayoutAPI;
            resultRequest.DateFrom = dayjs(resultRequest.DateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            resultRequest.DateTo = dayjs(resultRequest.DateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            setRealRequestData((data) => {
                const result = !!data ? { ...data, ...{ [_currentPageId]: resultRequest } } : { [_currentPageId]: resultRequest };
                return { ...result };
            });
            if (currentApi?.insertParamsBeforeFetch) {
                if (!!_payload?.forBeforeFetch?.find((n) => n.key === 'userIdList')) {
                    const index = _payload.forBeforeFetch.findIndex((n) => n.key === 'userIdList');
                    const currentPartnerId = ~~_payload.forBeforeFetch.find((n) => n.key === 'partnerId')!.value;
                    const currentPartnerUserIdList = _agentUserList
                        .filter((item) => item.PartnerId === currentPartnerId || currentPartnerId === 0)
                        .map((item) => item.AgentUserId);
                    const result = _searchData.UserIdList!.filter((v: string) => currentPartnerUserIdList.includes(~~v));
                    _payload.forBeforeFetch[index].value = result;
                    // 因為之後該頁必須要有UserList的欄位但該頁預設SearchData並沒有該欄位，所以幫它加
                    setSearchData((data) => {
                        data.UserIdList = result;
                        return data;
                    });
                }

                resultRequest = currentApi?.insertParamsBeforeFetch(resultRequest, _payload?.forBeforeFetch) || resultRequest;
                setRequestData(resultRequest);
            }
            setIsLoading(true);
            return resultRequest;
        },
        ({ apiItem, value, deps: [{ payload: _payload }], request }) => {
            const currentApi = apiItem as TableLayoutAPI;
            let _data: ITableLayoutData;
            if (currentApi?.beforeTransfer) {
                _data = tableMapping[currentTab](currentApi?.beforeTransfer(value.data.Data, _payload?.forBeforeTrensfer), request.PageNo, request.PageSize);
            } else {
                _data = tableMapping[currentTab](value.data.Data, request.PageNo, request.PageSize);
            }

            setResponseData(_data);
            setIsLoading(false);
        },
        [{ payload, agentUserList, searchData, currentPageId: currentTab }],
        (error) => {
            setIsLoading(false);
        },
    );

    const configSearch$ = useObservable(pluckFirst, [search]);
    const searchData$ = useObservable(pluckFirst, [searchData]);

    // 按下搜尋鈕後會觸發
    useSubscription(searchChangeHandler$.pipe(withLatestFrom(configSearch$, searchData$)), ([searchRef, configSearchData, _searchData]) => {
        if (responseData && responseData?.totalRecords) {
            responseData.totalRecords = 0;
        }

        const resultData = { ...props.api?.initParams, ..._searchData, PageNo: 1 }; // 每次搜尋後頁數要reset
        const setData = (searchItem: TableSearch, _data: IBaseRequestData) => {
            const _searchItem = searchItem;
            if (_searchItem.matchKey) {
                (_data as any)[toFirstUpperCase(_searchItem.matchKey)] = searchRef[_searchItem.matchKey];
                const fuzzySearchKey = !!_searchItem.fuzzyKey ? _searchItem.fuzzyKey : 'IsFuzzySearch';
                _searchItem.hasFuzzy && ((_data as any)[fuzzySearchKey] = !!searchRef[fuzzySearchKey]);
            } else if (_searchItem.dateMatchKey) {
                const startName = _searchItem.dateMatchKey!['startDate'] || 'startDate';
                const endName = _searchItem.dateMatchKey!['endDate'] || 'endDate';
                (_data as any)[startName] = searchRef[startName];
                (_data as any)[endName] = searchRef[endName];
            }
        };

        configSearchData?.forEach((searchItem) => {
            if (Array.isArray(searchItem)) {
                const _searchItemList = searchItem as TableSearch[];
                _searchItemList.forEach((_searchItem) => {
                    setData(_searchItem, resultData);
                });
                return;
            }
            setData(searchItem, resultData);
        });

        if (Object.hasOwn(resultData, 'UserIdList') && resultData['UserIdList']?.length === 0) {
            dispatch(
                showDialog({
                    title: '警告',
                    content: '必须选一个所属代理',
                    type: DialogButtonType.Warning,
                }),
            );
        } else {
            setSearchData(resultData);
            setRequestData(resultData);
            detectChangeAndFetchHandler(resultData);
        }
    });

    const [breadcrumbList, setBreadcrumbList] = useState<IReportProps[]>([]);

    useEffect(() => {
        setBreadcrumbList((arr) => {
            const targetPageData = tableLayoutConfig.find((v) => v.pageId === props.currentPageId);
            if (targetPageData && targetPageData.menuVisible) {
                return [props];
            }
            const isExist = arr.findIndex((v) => v.currentPageId === props.currentPageId);
            if (isExist > -1) {
                arr.splice(isExist + 1, arr.length);
                return [...arr];
            } else {
                arr.push(props);
                return [...arr];
            }
        });
    }, [props]);

    const redirect = useCallback(
        (pageId: ReportPageEnum, props: IReportProps) => {
            if (realRequestData) {
                hasPrevRequest.current = true;
                setSearchData(realRequestData[pageId]);
            }

            setReportProps?.({ ...props, isTriggerBySubMenu: false });
        },
        [breadcrumbList, currentTab, realRequestData],
    );

    const getTitleName = useCallback(
        (lb: IReportProps) => {
            if (lb.payload?.titleLabelKey) {
                return lb.payload?.titleLabelKey;
            } else {
                return lb.pageName && t(`Subnav_${lb.pageName}`);
            }
        },
        [t],
    );

    const ref = useRef<MemberModelRef>(null);
    const historyModelRef = useRef<HistoryModelRef>(null);
    const onModelTrigger = useCallback(
        (type: ModelType, api: APIModel, data: { userName?: string; partnerId?: number; [key: string]: any }, layoutId: HistoryTableLayoutEnum) => {
            if (type === ModelType.Member) {
                ref.current?.onMemberDataTrigger &&
                    ref.current.onMemberDataTrigger(api, {
                        DateFrom: requestData.DateFrom,
                        DateTo: requestData.DateTo,
                        UserName: data.userName!,
                        PartnerId: data.partnerId!,
                    });
            } else if (type === ModelType.Commission) {
                if (Object.hasOwn(data, 'UserIdList')) {
                    // 這邊還要來看自己勾選Enable的狀況，目前還沒判斷
                    const currentUserIdByPartnerId = agentUserList.filter((f) => f.PartnerId === data['PartnerId']).map((v) => v.AgentUserId);
                    const resultUserIdList = data.UserIdList.filter((id: string) => currentUserIdByPartnerId.includes(+id));
                    data.UserIdList = resultUserIdList;
                }
                historyModelRef.current?.onModelDataTrigger && historyModelRef.current.onModelDataTrigger(api, data, layoutId);
            }
        },
        [requestData, agentUserList],
    );

    const noteItem = useMemo(() => {
        return tableNoteMapping[currentTab];
    }, [currentTab]);

    const currentPageName = breadcrumbList.length > 0 ? getTitleName(breadcrumbList[breadcrumbList.length - 1])?.toString() : '';

    const tableRef = useRef<ITableComp>();
    const expandTableRef = useRef<IExtraTable>();

    const exportReportCSV = useFetchApi<IBaseExportData, string, IExportSearchData>(
        EXPORT_CONFIG_MAP[currentTab].exportapi,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setIsLoading(false);
            if (value.data.IsSuccess) {
                download(value.data.Data);
            } else {
                const dialogSetting: IDialogSetting = {
                    title: t('Lbl_Button_Warring'),
                    content: value.data.Message,
                    type: DialogButtonType.Warning,
                };
                dispatch(showDialog(dialogSetting));
            }
        },
    );

    const exportCSVFun = async () => {
        let queryData: IExportSearchData = { ...requestData };

        const body: IBaseExportData = {
            ReportType: EXPORT_CONFIG_MAP[currentTab].exportType,
            MainReportType: 0,
            Search: '',
        };

        switch (currentTab) {
            // 佣金报表 / 会员佣金
            case ReportPageEnum.COMMISSION_MEMBER:
                queryData = { ...queryData, Date: `${responseData?.tableBody[0].date}`, AgentUserId: responseData?.tableBody[0].agentUserId as number };
                break;
            // 佣金报表 / 下级代理佣金
            case ReportPageEnum.COMMISSION_AGENT_LEVEL:
                queryData = { ...queryData, Mode: switchId };
                body.MainReportType = 0;
                break;
            // 佣金报表 / 会员佣金 / 存提手续费
            case ReportPageEnum.COMMISSION_RECHARGE_WITHDRAW_FEE:
                queryData = { ...queryData };
                body.MainReportType = 1;
                break;
            // 红利返水报表 / 红利详情
            case ReportPageEnum.REBATE_BONUS_DETAIL:
                queryData = { ...queryData, Mode: 0 };
                break;
            // 红利返水报表 / 返水详情
            case ReportPageEnum.REBATE_USER_DETAIL:
                queryData = { ...queryData, Mode: 1 };
                break;
            // 存提明细 / 充值详情
            case ReportPageEnum.RECHARGE_DEPOSIT_DETAIL:
                body.MainReportType = 7;
                break;
            // 存提明细 / 提现详情
            case ReportPageEnum.WITHDRAW_DEPOSIT_DETAIL:
                body.MainReportType = 7;
                break;
        }

        body.Search = JSON.stringify(queryData);

        await exportReportCSV(body);
    };

    const genExportDateStr = (dateStr: string) => {
        if (!dateStr || dateStr.length === 0) return '';
        return ((dateStr || '').split(' ')[0] || '').replace(/-/g, '');
    };

    const download = async (content: string) => {
        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', content]);
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `${currentPageName || 'report'}_${genExportDateStr(requestData.DateFrom)}-${genExportDateStr(requestData.DateTo)}.csv`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
            <div className="my-4 ">
                <TitleLabel>
                    <>
                        <span>{`${t('Nav_report')} `}</span>
                        {breadcrumbList.map((lb, index) => (
                            <span key={`breadcrumb-${lb.pageName}`}>
                                <span>/ </span>
                                <span
                                    className=" cursor-pointer hover:text-orange-300"
                                    key={`breadcrumb-${lb.currentPageId}`}
                                    onClick={() => {
                                        redirect(lb.currentPageId, lb);
                                    }}>
                                    {getTitleName(lb)}
                                </span>
                            </span>
                        ))}
                    </>
                </TitleLabel>
            </div>

            <div data-search-area>
                {props.headerType === TableHeaderType.SEARCH_COLUMN && (
                    <SearchColumn
                        defaultValue={requestData}
                        search={search as TableSearch[][]}
                        handler={(data, isQuick) => {
                            searchAreaHandler(data);
                        }}
                    />
                )}

                {(props.headerType === TableHeaderType.SEARCH ||
                    props.headerType === TableHeaderType.SEARCH_WITH_TEXT ||
                    props.headerType === TableHeaderType.SEARCH_WITH_TABLE) && (
                    <DateRangeWithIput
                        // key={`search-area-${props.currentPageId}-${renderKey}`}
                        defaultValue={requestData}
                        search={search as TableSearch[]}
                        handler={(data, isQuick) => {
                            searchAreaHandler(data);
                        }}
                        isButtonNewLine={false}
                    />
                )}

                {props.headerType === TableHeaderType.SWITCHER && (
                    <Tabs
                        align="center"
                        onChange={(index) => {
                            setSwitchId(index);
                        }}>
                        <TabList>
                            <Tab>{t('Tab_commissionMode')}</Tab>
                            <Tab>{t('Tab_dividedMode')}</Tab>
                        </TabList>
                    </Tabs>
                )}

                {(props.headerType === TableHeaderType.TEXT || props.headerType === TableHeaderType.SEARCH_WITH_TEXT) && !!headerText && !isLoading && (
                    <div className="flex flex-row-reverse self-end mt-2">
                        <Card>
                            <CardBody className="bg-gray-100">
                                <Stack direction="row" divider={<StackDivider />} spacing="10">
                                    {headerText.map((keyItem, i) => {
                                        let item;
                                        if (keyItem.keyRef) {
                                            const v = (responseData && responseData[keyItem.keyRef][0]) || (responseData && responseData[keyItem.keyRef]) || {};
                                            item = keyItem.key === '' ? responseData && responseData[keyItem.keyRef] : v[keyItem.key];
                                        }
                                        if (keyItem.refObj && keyItem.key === 'dateStartToEnd') {
                                            item = `${dayjs(requestData.DateFrom).format('YYYY/MM/DD')} - ${dayjs(requestData.DateTo).format('YYYY/MM/DD')}`;
                                        } else if (keyItem.refObj && ['groupName', 'month'].includes(keyItem.key)) {
                                            const result = `${payload?.forBeforeTrensfer?.find((it) => it.key === keyItem.key)?.value}`;
                                            item = keyItem.key === 'month' ? dayjs(result).format('YYYY-MM') : result;
                                        }

                                        return (
                                            <Box key={`header-text-${i}`}>
                                                <Heading size="xs" textTransform="uppercase">
                                                    {t(keyItem.title)}
                                                </Heading>
                                                <Text pt="2" fontSize="lg" className="text-gray-500 font-bold">
                                                    {parseCommissionType(item, keyItem?.type || TableColumnStyle.Text) || 'NA'}
                                                </Text>
                                            </Box>
                                        );
                                    })}
                                </Stack>
                            </CardBody>
                        </Card>
                    </div>
                )}
            </div>

            <div data-export className="flex">
                <Button className="mt-2 ml-4" onClick={exportCSVFun} rightIcon={<span className="icon icon-export" />} backgroundColor="gray.300">
                    {t('Lbl_export_csv')}
                </Button>
            </div>

            <div data-extra-area className="m-4 ">
                {props.headerType === TableHeaderType.SEARCH_WITH_TABLE &&
                    responseData?.extraTableData?.data &&
                    responseData?.extraTableData?.data.length > 0 &&
                    userRoleType === RoleTypeEnum.SuperUser && <ExtraTable ref={expandTableRef} extraData={responseData?.extraTableData} />}
            </div>

            <div data-table-container className="mx-4">
                <TableComponent
                    ref={tableRef}
                    oriRequestData={requestData}
                    data={responseData}
                    direactableKeyList={direactableKeyList}
                    setProps={setReportProps}
                    onModelTrigger={onModelTrigger}
                    sortingTrigger={(v) => {
                        setSearchData((data) => {
                            if (data) {
                                const result = { ...data, ...v };
                                detectChangeAndFetchHandler(result);
                                return result;
                            }

                            return data;
                        });
                    }}
                    switchId={switchId}
                    isLoaded={isLoading}
                    needFold={props.needFold}
                />
            </div>

            {props.isShowPagging ? (
                <div data-pagination className="mt-2 flex justify-center">
                    <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord', [responseData?.totalRecords || 0])}</span>

                    <Pagination
                        key={`pagination-${props.currentPageId}-${responseData?.totalRecords || 1}`}
                        pageCount={Math.ceil((responseData?.totalRecords || 1) / DEFAULT_PAGE_COUNT)}
                        onPageChange={(e) => {
                            setSearchData((data) => {
                                if (data) {
                                    const result = { ...data, PageNo: e.selected + 1 };
                                    detectChangeAndFetchHandler(result);
                                    return result;
                                }

                                return data;
                            });
                        }}
                    />
                </div>
            ) : (
                <div data-pagination className="mt-2 flex justify-center h-5" />
            )}

            <div data-note className="ml-4 mb-4 text-xs">
                <ul className="ml-4 list-disc">
                    {noteItem.map((text, i) => {
                        const regex = /\${(.*?)}/;
                        const match = text.match(regex);
                        let result = <>{text}</>;
                        if (match) {
                            const iconClass = match[1];
                            const secText = text.replace('${' + iconClass + '}', '');
                            result = (
                                <>
                                    <span className={`icon ${iconClass} mr-2`} />
                                    <span>{secText}</span>
                                </>
                            );
                        }
                        return (
                            <li key={`note-${currentTab}-${i}`} className="text-red-400 whitespace-pre-line">
                                {result}
                            </li>
                        );
                    })}
                </ul>
            </div>

            {props.noteComp ? (
                <div data-note-extra className="ml-4 mb-4 text-xs">
                    <props.noteComp />
                </div>
            ) : (
                <></>
            )}

            <div data-copryright />

            <MemberModel ref={ref} />
            <HistoryModel ref={historyModelRef} />
            <LoadingMask visible={isLoading} />
        </>
    );
};
export default Container;
