import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportProxyMemberData, IReportProxyMemberDownLine } from '@/models/page/report/IReportCommission';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

// need fix type

export function agentLevelData(input: IReportProxyMemberData): ITableLayoutData {
    const outputConfig: TableConfig<IReportProxyMemberData> = {
        column: [
            { name: 'proxyLevelName', text: 'Th_proxyLevel', itemKeys: ['ProxyLevelName'], type: TableColumnStyle.Text, switchId: 0 },
            { name: 'proxyEffectCount', text: 'Th_agentCount', itemKeys: ['ProxyEffectCount'], type: TableColumnStyle.Numeric, switchId: 0 },
            { name: 'commRate', text: 'Th_commRate', itemKeys: ['CommRate'], type: TableColumnStyle.Percentage, switchId: 0 },
            { name: 'proxyAmount', text: 'Th_totalCommission', itemKeys: ['CommissionAmount'], type: TableColumnStyle.CommaNumeric, switchId: 0 },
            { name: 'commissionAmount', text: 'Th_actureAmount', itemKeys: ['ProxyAmount'], type: TableColumnStyle.CommaNumeric, switchId: 0 },
        ],
        footer: [],
    };

    const outputConfig2: TableConfig<IReportProxyMemberDownLine> = {
        column: [
            { name: 'level', text: 'Th_proxyLevel', itemKeys: ['Level'], type: TableColumnStyle.Numeric, switchId: 1 },
            { name: 'userName', text: 'Th_proxyDirect', itemKeys: ['UserName'], type: TableColumnStyle.Text, switchId: 1 },
            {
                name: 'mode',
                text: 'Th_commissionMode',
                itemKeys: ['Mode'],
                type: TableColumnStyle.TextI18N,
                switchId: 1,
                calculate: (data, key) => {
                    return `Lbl_ModeType_${data.Mode}`;
                },
            },
            {
                name: 'calcMode',
                text: 'Th_proxyDirectCount',
                itemKeys: ['EffectChildSize', 'ChildSize'],
                calculate: (a, b) => {
                    return b.map((k) => (a as any)[k]).join('/');
                },
                type: TableColumnStyle.Text,
                switchId: 1,
            },
            { name: 'commRate', text: 'Th_commissionRate', itemKeys: ['CommRate'], type: TableColumnStyle.CommaNumeric, switchId: 1 },
            {
                name: 'commissionAmount',
                text: 'Th_nextLevelMemberCommission',
                itemKeys: ['CommissionAmount'],
                type: TableColumnStyle.CommaNumeric,
                switchId: 1,
            },
            { name: 'proxyAmount', text: 'Th_proxtTotalAmount', itemKeys: ['ProxyAmount'], type: TableColumnStyle.CommaNumeric, switchId: 1 },
        ],
        footer: [],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(!!input ? [input] : [], outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, !!input ? [input] : [], tableBody);

    const tableHeader2 = getTableHeader(outputConfig2.column);
    const tableBody2 = getTableBody(!!input ? input.DownLines : [], outputConfig2.column);
    const tableFooter2 = getTableFooter(outputConfig, !!input ? input.DownLines : [], tableBody2);

    return {
        tableHeader: [...tableHeader, ...tableHeader2],
        tableBody: [...tableBody, ...tableBody2],
        tableFooter: { ...tableFooter, ...tableFooter2 },
        totalRecords: 1,
        hasFooter: false,
        extraData: [tableBody.length, tableBody2.length],
    };
}
