import TitleLabel from '@/components/titleLabel';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChatAdminTabs } from '../chatAdminTabs';
import { MyFriendListComp } from '../../../components/myFriendListComp/myFriendListComp';

export const ChatListingPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <ChatAdminTabs />
            <div className="my-4">
                <TitleLabel>{t('Nav_chat-list')}</TitleLabel>
            </div>
            <div className="m-5 w-1/2">
                <Tabs isLazy={true}>
                    <TabList>
                        <Tab>{t('Lbl_MyFriends')}</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <div>
                                <MyFriendListComp />
                            </div>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </>
    );
};
