import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useCallback } from 'react';

type QuickDateType = 'YESTERDAY' | 'A_WEEK_AGO' | 'THIS_MONTH' | 'LAST_MONTH';

type Props = {
    btnList: QuickDateType[];
    handler: (date: { startDate: string; endDate: string }) => void;
};

const QuickDatePicker = ({ btnList, handler }: Props) => {
    const { t } = useTranslation();
    const btnCallback = useCallback(
        (e: React.MouseEvent) => {
            const targetValue = (e.target as any).value as QuickDateType;

            let result = { startDate: dayjs().format('YYYY-MM-DD'), endDate: dayjs().format('YYYY-MM-DD') };
            switch (targetValue) {
                case 'YESTERDAY': {
                    result = {
                        startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
                        endDate: dayjs().format('YYYY-MM-DD'),
                    };
                    break;
                }
                case 'A_WEEK_AGO': {
                    result = {
                        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
                        endDate: dayjs().format('YYYY-MM-DD'),
                    };
                    break;
                }
                case 'THIS_MONTH': {
                    result = {
                        startDate: dayjs().date(1).format('YYYY-MM-DD'),
                        endDate: dayjs().format('YYYY-MM-DD'),
                    };
                    break;
                }
                case 'LAST_MONTH': {
                    result = {
                        startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                        endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
                    };
                    break;
                }
                default: {
                    result = { startDate: dayjs().format('YYYY-MM-DD'), endDate: dayjs().format('YYYY-MM-DD') };
                }
            }

            handler?.(result);
        },
        [handler],
    );

    const renderBtn = btnList.map((key, i) => {
        return (
            <button key={`${key}-${i}`} className="mx-1 btn data-active:bg-orange-300" onClick={(e) => btnCallback(e)} value={key}>
                {t(`Lbl_${key.toLowerCase()}`)}
            </button>
        );
    });

    return (
        <>
            <div data-quick-date className="flex items-center">
                <label className="ml-2 mr-4 whitespace-nowrap">{`${t('Lbl_quick_date')}`}:</label>
                {renderBtn}
            </div>
        </>
    );
};

export default QuickDatePicker;
