import dayjs from 'dayjs';

export const DEFAULT_PAGE_COUNT = 20;

export const baseRequest = {
    DateFrom: dayjs().add(-1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    DateTo: dayjs().add(-1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    PartnerId: 0,
    UserId: 1,
    PageNo: 1,
    PageSize: DEFAULT_PAGE_COUNT,
};