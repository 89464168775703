export enum BettingRateEnum {
    ALL = 0,
    NDP = 1,
    LV,
    MV,
    HV,
    VIP,
}

export enum SettlementStatusEnum {
    Watting = 1,
    Done = 2,
}

export enum ValidStatusEnum {
    Valid = 1,
    InValid,
}

export interface RelationMemberItem {
    BetAmount: number;
    GroupName: string;
    BetRate: BettingRateEnum;
    BonusAmount: number;
    EffectiveRelatedMember: 1;
    GroupId: 18;
    RebateAmount: 0;
    RechargeAmount: 0;
    RechargeCount: 0;
    RelatedMember: 2;
    ReportMonth: '2023-06-01T00:00:00';
    ReportStatus: SettlementStatusEnum;
    TotalWin: 0;
    UpAmount: 588;
    UpAmountCount: 0;
    WithdrawAmount: 0;
}

export interface RelationMemberData {
    Summary: RelationMemberSummary;
    Data: RelationMemberItem[];
}

export interface RelationMemberSummary {
    TotalRecords: number;
    Deposit: number;
    Recharge: number;
    Withdraw: number;
    BetAmount: number;
    WinLoss: number;
    Rebate: number;
    Return: number;
}

export interface RelationMemberDetailItem {
    PartnerId: number;
    AgentNickName: string;
    MemberName: string;
    MemberNickName: string;
    RegisterDate: string;
    VipLevel: number;
    EffectiveStatus: ValidStatusEnum;
    BetAmount: number;
    BetRate: BettingRateEnum;
    MemberCode: string;
    AgentUserCode: string;
    BonusAmount: number;
    RebateAmount: number;
    RechargeAmount: number;
    TotalWin: number;
    UnbetDays: number;
    UpAmount: number;
    WithdrawAmount: number;
}

export interface RelationMemberDetailData {
    Summary: RelationMemberSummary;
    Data: RelationMemberDetailItem[];
}
