import { APIModel } from '@/models/apiRequest';
import { ReportExportEnum, ReportPageEnum } from '@/models/transfer/reportTransfer';
import { api } from '@/service/api';

export type IExportConfig = {
    exportapi: APIModel;
    exportType: ReportExportEnum;
};

export const EXPORT_CONFIG_MAP: Record<ReportPageEnum, IExportConfig> = {
    // 财务总报表
    [ReportPageEnum.FUND]: {
        exportType: ReportExportEnum.FUND,
        exportapi: api.exportFundReport,
    },
    // 財務總報表 / 新注册会员
    [ReportPageEnum.FUND_NEW_ACCOUNT]: {
        exportType: ReportExportEnum.REGISTER_MEMBERS,
        exportapi: api.exportRegisterMembersReport,
    },
    // 佣金报表
    [ReportPageEnum.COMMISSION]: {
        exportType: ReportExportEnum.COMMISSION,
        exportapi: api.exportCommissionReport,
    },
    // 佣金报表 / 会员佣金
    [ReportPageEnum.COMMISSION_MEMBER]: {
        exportType: ReportExportEnum.COMMISSION_MEMBER,
        exportapi: api.exportCommissionReport,
    },
    // 佣金报表 / 下级代理佣金
    [ReportPageEnum.COMMISSION_AGENT_LEVEL]: {
        exportType: ReportExportEnum.PROXY_COMMISSION,
        exportapi: api.exportProxyCommissionReport,
    },
    // 佣金报表 / 会员佣金 / 有效会员
    [ReportPageEnum.COMMISSSION_MEMBER_DETAIL]: {
        exportType: ReportExportEnum.COMMISSION_MEMBER_VALID,
        exportapi: api.exportCommissionMemberReport,
    },
    // 佣金报表 / 会员佣金 / 存提手续费
    [ReportPageEnum.COMMISSION_RECHARGE_WITHDRAW_FEE]: {
        exportType: ReportExportEnum.DEPOSIT_DETAIL,
        exportapi: api.exportDepositDetailsReport,
    },
    // 团队数据报表
    [ReportPageEnum.TEAM]: {
        exportType: ReportExportEnum.TEAM,
        exportapi: api.exportTeamReport,
    },
    // 会员数据报表
    [ReportPageEnum.MEMBER]: {
        exportType: ReportExportEnum.MEMBER,
        exportapi: api.exportMemberReport,
    },
    // 游戏输赢报表
    [ReportPageEnum.GAME]: {
        exportType: ReportExportEnum.GAME,
        exportapi: api.exportGameReport,
    },
    // 游戏输赢报表/ 品牌
    [ReportPageEnum.GAME_BRAND]: {
        exportType: ReportExportEnum.GAME_DETAIL,
        exportapi: api.exportGameDetailsReport,
    },
    // 会员首存报表
    [ReportPageEnum.FIRST_CHARGE]: {
        exportType: ReportExportEnum.FIRST_CHARGE,
        exportapi: api.exportFirstRechargeReport,
    },
    // 红利返水报表
    [ReportPageEnum.REBATE]: {
        exportType: ReportExportEnum.REBATE,
        exportapi: api.exportRebateReport,
    },
    // 红利返水报表 / 红利详情
    [ReportPageEnum.REBATE_BONUS_DETAIL]: {
        exportType: ReportExportEnum.REBATE_DETAIL,
        exportapi: api.exportRebateDetailsReport,
    },
    // 红利返水报表 / 返水详情
    [ReportPageEnum.REBATE_USER_DETAIL]: {
        exportType: ReportExportEnum.REBATE_DETAIL,
        exportapi: api.exportRebateDetailsReport,
    },
    // 存提明细
    [ReportPageEnum.CHARGE_AND_REDRAW]: {
        exportType: ReportExportEnum.CHARGE_AND_REDRAW,
        exportapi: api.exportChargeAndWithdrawalReport,
    },
    // 存提明细 / 充值详情
    [ReportPageEnum.RECHARGE_DEPOSIT_DETAIL]: {
        exportType: ReportExportEnum.DEPOSIT_DETAIL,
        exportapi: api.exportDepositDetailsReport,
    },
    // 存提明细 / 提现详情
    [ReportPageEnum.WITHDRAW_DEPOSIT_DETAIL]: {
        exportType: ReportExportEnum.DEPOSIT_DETAIL,
        exportapi: api.exportDepositDetailsReport,
    },
    // 代充明细
    [ReportPageEnum.AGENT_RECHARGE]: {
        exportType: ReportExportEnum.AGENT_RECHARGE,
        exportapi: api.exportAgentRechargeReport,
    },
    // 关联会员报表
    [ReportPageEnum.RELATION_MEMBER]: {
        exportType: ReportExportEnum.MEMBER_GROUP,
        exportapi: api.exportMemberGroupReport,
    },
    // 关联会员报表 / 关联会员详情
    [ReportPageEnum.RELATION_MEMBER_DETAIL]: {
        exportType: ReportExportEnum.MEMBER_GROUP_DETAIL,
        exportapi: api.exportMemberGroupDetailReport,
    },
};
