import { TableSearch, TableSearchEnum } from '@/models/layout/tableLayout';
import { Checkbox, Input, Select } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SearchArea from '.';
import dayjs from 'dayjs';
import { getEnableConfig } from '@/assets/config/common/partner.config';
import MultiSelect from '../multiSelect';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import RangeSelect from '../rangeSelect';
import DateRangePicker from '../dateRangePicker';
import { IBaseRequestData } from '@/models/apiRequest';
import { toFirstUpperCase } from '@/service/utils';
import { useTranslation } from 'react-i18next';
import { usePartnerList } from '@/hooks/usePartnerList';
import { DATETIME_FORMAT } from '@/constants/datetime';
import { ITransactionType } from '@/models/transactionType';

type ReturnFormat = { [key: string]: string };

type Props = {
    search?: TableSearch[][];
    defaultValue: IBaseRequestData;
    handler?: (prop: ReturnFormat, isQuick?: boolean) => void;
    layout?: 'justify-between' | 'justify-start';
    isButtonNewLine?: boolean;
};

const styleObject = {
    bgColor: 'white',
    borderColor: 'whiteAlpha.50',
    focusBorderColor: 'black',
    color: 'gray.500',
    width: '180px',
};

const SearchColumn = ({ search, defaultValue, handler = () => {}, layout = 'justify-between', isButtonNewLine = true }: Props) => {
    const { t } = useTranslation();
    const [searchData, setSearchData] = useState(() => {
        const data = {};
        return data;
    });
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) => state.userStore.agentPartners);
    const transactionTypes = useSelector<RootState, ITransactionType[]>((state) => state.userStore.transactionTypeList);

    const defaultPartnerList = usePartnerList();

    const defaultSelectedUserIdStatePool = useMemo(() => {
        const defaultList = defaultPartnerList.map((item) => {
            return {
                partnerId: item.id,
                list: agentUserList
                    .filter((agent) => {
                        return (item.id === 0 || item.id === agent.PartnerId) && agent.IsEnabled;
                    })
                    .map((v) => v.AgentUserId),
            };
        });

        const index = defaultList.findIndex((v) => ~~v.partnerId === ~~defaultValue.PartnerId);

        defaultList[index].list = defaultValue.UserIdList?.map((v: string) => ~~v) || defaultList[index].list;
        return defaultList;
    }, [agentUserList, defaultValue]);

    const getCurrentDefaultSelectedUserIdList = useMemo(() => {
        const currentPartnerId = (searchData as any)['partnerId'] || 0;
        const index = defaultSelectedUserIdStatePool.findIndex((v) => ~~v.partnerId === ~~currentPartnerId);

        return defaultSelectedUserIdStatePool[index].list;
    }, [defaultSelectedUserIdStatePool, (searchData as any)['partnerId']]);

    const list = useMemo(() => {
        return search?.map((items) => {
            return items.map((item) => {
                if (item.matchKey === 'partnerId') {
                    (item.selectOptions as Array<{ id: number; text: string }>) = defaultPartnerList;
                }
                if (item.matchKey === 'userIdList') {
                    // 列出所有的userIdList
                    const currentPartnerId = (searchData as any)['partnerId'] || 0;
                    const selectOptions = agentUserList
                        .filter((item) => ~~currentPartnerId === 0 || ~~currentPartnerId === ~~item.PartnerId)
                        .map((config) => {
                            const getPartner = `Lbl_Partner_${getEnableConfig().find((list) => ~~list.id === ~~config.PartnerId)!.text}`;
                            return {
                                id: config.AgentUserId,
                                text: `${config.NickName}(${t(getPartner)})`,
                            };
                        });

                    (item.selectOptions as Array<{ id: number; text: string }>) = selectOptions;
                }

                if (item.matchKey === 'transactionType') {
                    const currentPartnerId = (searchData as any)['partnerId'] || 0;
                    item.selectOptions = [
                        { id: 0, text: '全部' },
                        ...transactionTypes
                            .filter((item) => item.PartnerId === ~~currentPartnerId)
                            .sort((x, y) => {
                                if (x.OrderID < y.OrderID) {
                                    return -1;
                                }
                                if (x.OrderID > y.OrderID) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((item) => ({ id: item.TransactionTypeID, text: item.TransactionTypeName })),
                    ];
                }
                return item;
            });
        });
    }, [search, transactionTypes, agentUserList, (searchData as any)['partnerId'], t]);

    useEffect(() => {
        const data: any = {};
        search?.forEach((items) => {
            items.forEach((item) => {
                if ([TableSearchEnum.DATE_RANGE, TableSearchEnum.DATE_RANGE_MONTH].includes(item.type)) {
                    if (item.matchKey === 'registerDate') {
                        const key = item.matchKey!;
                        data[key] = defaultValue[toFirstUpperCase(key) as keyof IBaseRequestData];
                    } else {
                        const startName = item.dateMatchKey!['startDate'] || 'startDate';
                        const endName = item.dateMatchKey!['endDate'] || 'endDate';
                        data[startName] = defaultValue.DateFrom;
                        data[endName] = defaultValue.DateTo;
                    }
                } else {
                    const key = item.matchKey!;
                    data[key] = defaultValue[toFirstUpperCase(key) as keyof IBaseRequestData];
                    const fuzzySearchKey = !!item.fuzzyKey ? item.fuzzyKey : 'IsFuzzySearch';
                    data[fuzzySearchKey] = defaultValue[fuzzySearchKey as keyof IBaseRequestData];
                }
            });
        });

        setSearchData(data);
    }, [search, defaultValue]);

    const onChangeHandler = useCallback(
        (value: any, matchKey?: string, forceUpdate?: boolean, isKill?: boolean) => {
            setSearchData((data) => {
                const key = matchKey || 'data';
                if (matchKey === 'partnerId') {
                    const userIdList = agentUserList
                        .filter((v) => v.PartnerId === ~~value || ~~value === 0)
                        .map((v) => v.AgentUserId.toString())
                        .filter((v) => defaultValue.UserIdList?.includes(v));
                    (data as any)['userIdList'] = userIdList;
                    if ('transactionType' in data) {
                        (data as any)['transactionType'] = 0; // 切換品牌就切換預設
                    }
                }

                (data as any)[key] = value;
                isKill && delete (data as any)[key];
                return forceUpdate ? { ...data } : data;
            });
        },
        [agentUserList],
    );

    const [inputObj, setInputObj] = useState<Record<string, string>>({});

    useEffect(() => {
        const obj: any = {};
        list?.forEach((row) => {
            return row.forEach((item) => {
                if (item.type === TableSearchEnum.INPUT) {
                    obj[item.matchKey!] = (searchData as any)[item.matchKey!];
                }
            });
        });

        setInputObj(obj);
    }, [list, searchData]);

    const layoutRender = useMemo(
        () =>
            list?.map((row, r) => {
                return (
                    <div className={`flex mt-4 ${layout}`} key={`column-${r}`}>
                        {row.map((item) => {
                            let _defaultValue = (searchData as any)[item.matchKey!];
                            if ([TableSearchEnum.DATE_RANGE, TableSearchEnum.DATE_RANGE_MONTH].includes(item.type)) {
                                if (item.matchKey === 'registerDate') {
                                    _defaultValue = (searchData as any)[item.matchKey!];
                                } else {
                                    const startName = item.dateMatchKey!['startDate'] || 'startDate';
                                    const endName = item.dateMatchKey!['endDate'] || 'endDate';
                                    _defaultValue = { start: (searchData as any)[startName], end: (searchData as any)[endName] };
                                }
                            }
                            return (
                                <div className="flex" key={`label-${item.label}`}>
                                    {![TableSearchEnum.DATE_RANGE, TableSearchEnum.DATE_RANGE_MONTH].includes(item.type) && (
                                        <div className={`self-center text-center px-4 ${layout === 'justify-start' ? 'w-[150px]' : ''}`}>
                                            <label className=" text-base whitespace-nowrap">{` ${item.label} : `}</label>
                                        </div>
                                    )}

                                    <div>
                                        {item.type === TableSearchEnum.INPUT ? (
                                            <Input
                                                {...styleObject}
                                                value={inputObj[item.matchKey || ''] || ''}
                                                placeholder={item.placeholder}
                                                onChange={(e) => {
                                                    setInputObj((v) => {
                                                        item.matchKey && (v[item.matchKey] = e.target.value);
                                                        return { ...v };
                                                    });
                                                    onChangeHandler(e.target.value, item.matchKey);
                                                }}
                                            />
                                        ) : item.type === TableSearchEnum.SELECT ? (
                                            <Select
                                                disabled={item.selectOptions?.length === 0}
                                                {...styleObject}
                                                value={_defaultValue}
                                                onChange={(e) => {
                                                    onChangeHandler(e.target.value, item.matchKey, true);
                                                }}>
                                                {item.selectOptions?.map((listItem, i) => (
                                                    <option key={`select-opt-${listItem.id}`} value={listItem.id}>
                                                        {listItem.withoutI18n ? listItem.text : t(listItem.text)}
                                                    </option>
                                                ))}
                                            </Select>
                                        ) : item.type === TableSearchEnum.MULTI_SELECT ? (
                                            <MultiSelect
                                                className="w-[200px]"
                                                label="全部"
                                                options={item.selectOptions || []}
                                                defaultSelectOptions={getCurrentDefaultSelectedUserIdList}
                                                onChange={(newValue) => {
                                                    onChangeHandler(newValue, item.matchKey);
                                                }}
                                            />
                                        ) : item.type === TableSearchEnum.MULTI_SELECT_CUSTOME ? (
                                            <MultiSelect
                                                className="w-[200px]"
                                                label="全部"
                                                options={item.selectOptions || []}
                                                defaultSelectOptions={item.defaultSelectOptions || []}
                                                onChange={(newValue) => {
                                                    onChangeHandler(newValue, item.matchKey);
                                                }}
                                            />
                                        ) : item.type === TableSearchEnum.RANGE_SELECT ? (
                                            <RangeSelect
                                                label={item.placeholder}
                                                options={item.rangeSelectOptions}
                                                onChange={(e) => {
                                                    const { min, max } = e;
                                                    onChangeHandler({ min, max }, item.matchKey, false, e.isKill);
                                                }}
                                            />
                                        ) : item.type === TableSearchEnum.DATE_RANGE || item.type === TableSearchEnum.DATE_RANGE_MONTH ? (
                                            <DateRangePicker
                                                className="w-[240px] min-w-[240px]"
                                                labelClassName={layout === 'justify-start' ? 'px-4 w-[150px]' : ''}
                                                defaultDate={_defaultValue}
                                                format={item.type === TableSearchEnum.DATE_RANGE_MONTH ? 'YM' : 'YMD'}
                                                shortcuts={
                                                    item.dateMatchKey?.shortcuts ?? (item.type === TableSearchEnum.DATE_RANGE_MONTH ? 'monthOnly' : true)
                                                }
                                                label={item.label}
                                                minDate={item.minDate}
                                                withoutI18n
                                                valueHandler={({ startDate, endDate }) => {
                                                    setSearchData((data) => {
                                                        const startName = item.dateMatchKey!['startDate'] || 'startDate';
                                                        const endName = item.dateMatchKey!['endDate'] || 'endDate';
                                                        if (item.matchKey) {
                                                            (data as any)[item.matchKey] = {
                                                                [startName]: dayjs(startDate).startOf('day').format(DATETIME_FORMAT.YMDHMSS),
                                                                [endName]: dayjs(endDate).endOf('day').format(DATETIME_FORMAT.YMDHMSS),
                                                            };
                                                        } else {
                                                            (data as any)[startName] = dayjs(startDate).startOf('day').format(DATETIME_FORMAT.YMDHMSS);
                                                            (data as any)[endName] = dayjs(endDate).endOf('day').format(DATETIME_FORMAT.YMDHMSS);
                                                        }
                                                        // handler(data);
                                                        return data;
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    {item.hasFuzzy && (
                                        <div className=" whitespace-nowrap p-2">
                                            <Checkbox
                                                onChange={(e) => {
                                                    setSearchData((data) => {
                                                        const fuzzySearchKey = !!item.fuzzyKey ? item.fuzzyKey : 'IsFuzzySearch';
                                                        return { ...data, [fuzzySearchKey]: e.target.checked };
                                                    });
                                                }}>
                                                {t('Lbl_fuzzy')}
                                            </Checkbox>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            }),
        [list, inputObj],
    );

    const searchHandler = useCallback(() => {
        handler(searchData);
    }, [searchData]);

    return (
        <div className="mt-2 flex">
            <SearchArea triggerHandler={searchHandler} isButtonNewLine={isButtonNewLine}>
                <div>{layoutRender}</div>
            </SearchArea>
        </div>
    );
};

export default SearchColumn;
