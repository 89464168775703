import { IReportFundNewAccountData } from './../../../models/page/report/IReportFund';
import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportFundNewAccount } from '@/models/page/report/IReportFund';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function funNewAccountData(input: IReportFundNewAccount): ITableLayoutData {
    const outputConfig: TableConfig<IReportFundNewAccountData> = {
        column: [
            {
                name: 'partnerId',
                text: 'Th_partnerId',
                itemKeys: ['PartnerId'],
                type: TableColumnStyle.Partner,
            },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'registerDate', text: 'Th_registerDate', itemKeys: ['RegisterDate'], type: TableColumnStyle.DateTime },
            { name: 'userName', text: 'Th_newUser', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'firstChargeAmount', text: 'Th_firstChargeAmount', itemKeys: ['Amount'], type: TableColumnStyle.CommaNumeric },
        ],
        footer: [],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.TotalRecords, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
