import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { ISystemAccount } from '@/models/page/systemSetting';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function subAccountManagement(input: { Data: ISystemAccount[]; TotalRecord: number }, pageNo?: number, pageSize?: number): ITableLayoutData {
    const outputConfig: TableConfig<ISystemAccount> = {
        column: [
            { name: 'serialId', text: 'Lbl_serial_number', itemKeys: ['SerialId'], type: TableColumnStyle.Text },
            { name: 'userId', text: 'Th_Name', itemKeys: ['UserId'], type: TableColumnStyle.Text, invisible: true },
            { name: 'parentName', text: 'Th_parentName', itemKeys: ['ParentAccount'], type: TableColumnStyle.Text },
            { name: 'account', text: 'Txt_username', itemKeys: ['Account'], type: TableColumnStyle.Text },
            { name: 'name', text: 'Txt_name', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            {
                name: 'roleType',
                text: 'Th_Role',
                itemKeys: ['RoleType'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    return `Lbl_Role_${data.RoleType}`;
                },
            },
            { name: 'registerDate', text: 'Lbl_CreatedDate', itemKeys: ['DateCreated'], type: TableColumnStyle.Date },
            {
                name: 'status',
                text: 'Th_status',
                itemKeys: ['IsActive'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    return data.IsActive ? 'Lbl_enable' : 'Lbl_disable';
                },
            },
            {
                name: 'agent_M6',
                text: 'Th_Agent_M6',
                itemKeys: ['PartnerAgentCounts'],
                calculate: (data, keys) => {
                    // PARTNERS_CONFIGS
                    const target = data.PartnerAgentCounts.find((item) => item.PartnerId === 1);
                    return target?.Count || 0;
                },
                type: TableColumnStyle.Numeric,
            },
            {
                name: 'agent_BB',
                text: 'Th_Agent_BB',
                itemKeys: ['PartnerAgentCounts'],
                calculate: (data, keys) => {
                    // PARTNERS_CONFIGS
                    const target = data.PartnerAgentCounts.find((item) => item.PartnerId === 2);
                    return target?.Count || 0;
                },
                type: TableColumnStyle.Numeric,
            },
            {
                name: 'agent_N8',
                text: 'Th_Agent_N8',
                itemKeys: ['PartnerAgentCounts'],
                calculate: (data, keys) => {
                    // PARTNERS_CONFIGS
                    const target = data.PartnerAgentCounts.find((item) => item.PartnerId === 3);
                    return target?.Count || 0;
                },
                type: TableColumnStyle.Numeric,
            },
        ],
        footer: [],
    };

    const addSerialIdToData = input.Data.map((item: any, index: number) => ({
        ...item,
        SerialId: index + 1 + (pageNo ? (pageNo - 1) * (pageSize || 1) : 0),
    }));

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(addSerialIdToData, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.TotalRecord, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
