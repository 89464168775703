import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';

interface IAuditLogContent {
    Account?: string;
    IsActive?: boolean;
    ParentAccount?: string;
    Password?: string;
    Remark?: string;
    RoleType?: number;
    AgentUserCodes?: { PartnerId: number; AgentUserCode: string }[];
    UserName?: number;
}

interface IAuditLogItem {
    key: string;
    value: unknown;
    translate?: boolean;
}

export function auditLogContentParser(str: string): IAuditLogItem[] {
    const data: IAuditLogContent = JSON.parse(str);

    return Object.keys(data).map((key) => {
        const item: IAuditLogItem = { key, value: (data as any)[key], translate: false };

        switch (key) {
            case 'Account':
                item.key = 'Txt_username';
                break;
            case 'IsActive':
                item.key = 'Th_status';
                item.value = item.value ? 'Lbl_enable' : 'Lbl_disable';
                item.translate = true;
                break;
            case 'ParentAccount':
                item.key = 'Th_parentName';
                break;
            case 'Password':
                item.key = 'Txt_password';
                break;
            case 'Remark':
                item.key = 'Th_Remark';
                break;
            case 'UserName':
                item.key = 'Txt_name';
                break;
            case 'AgentUserCodes':
                item.key = 'Th_proxyName';
                item.value = (item.value as { PartnerId: number; AgentUserCode: string }[])
                    .map((item) => `${item.AgentUserCode} (${PARTNERS_CONFIGS_MAP[item.PartnerId].text})`)
                    .join(' ');
                break;
            case 'RoleType':
                item.key = 'Th_Role';
                item.value = `Lbl_Role_${item.value}`;
                item.translate = true;
                break;
            case 'VChatId':
                item.key = 'Th_VChatId';
                break;
        }

        return item;
    });
}
