import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { DespositDetail, DepositDetailData } from '@/models/page/report/IChargeAndWithdrawal';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

// need fix type
export function rechargeWthdrawFeeData(input: DespositDetail): ITableLayoutData {
    const outputConfig: TableConfig<DepositDetailData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'tradeNo', text: 'Th_tradeNo', itemKeys: ['TradeNo'], type: TableColumnStyle.Text },
            { name: 'createTime', text: 'Th_tradeDate', itemKeys: ['CreateTime'], type: TableColumnStyle.DateTime },
            { name: 'tradeType', text: 'Th_tradeType', itemKeys: ['TradeType'], type: TableColumnStyle.Text },
            { name: 'userName', text: 'Th_userName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'commFee', text: 'Th_fee', itemKeys: ['CommFee'], type: TableColumnStyle.CommaNumeric },
            { name: 'amount', text: 'Th_tradeAmount', itemKeys: ['Amount'], type: TableColumnStyle.CommaNumeric },
        ],
        footer: ['commFee','amount'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.TotalRecords, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
