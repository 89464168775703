import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { getEnableConfig } from '@/assets/config/common/partner.config';
import { useTranslation } from 'react-i18next';

export function usePartnerAgentList(partnerId = 0) {
    const { t } = useTranslation();
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) => state.userStore.agentPartners);

    return agentUserList
        .filter((item) => partnerId === 0 || partnerId === item.PartnerId)
        .map((agent) => {
            const partner = getEnableConfig().find((partner) => partner.id === agent.PartnerId)!;

            return {
                id: agent.AgentUserId,
                text: `${agent.NickName}(${t(`Lbl_Partner_${partner.text}`)})`,
            };
        });
}
