import {
    Bar,
    BarChart,
    Cell,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';

interface IBarChartData {
    name: string;
    value: number;
}

interface IBarChartProps {
    title: string
    data: IBarChartData[]
}

export const BrushBarChart = ({ title, data }: IBarChartProps) => {
    return (
        <div className="flex flex-col justify-center">
            <h6 className="">
                {title}
            </h6>

            <ResponsiveContainer width={600} height={400}>
                <BarChart data={data} width={400} height={400}>
                    <XAxis dataKey="name" padding={{ left: 10, right: 10 }} stroke="gray" width={1} />
                    <YAxis stroke="gray" padding={{ top: 10, bottom: 10 }} />

                    <ReferenceLine y={0} stroke="#ccc" />
                    <Bar dataKey="value" fill="black" label={{ position: 'top' }}>
                        {data.map(d => (
                            <Cell key={`cell-${d.name}`} fill={d.value >= 0 ? 'rgb(239 68 68)' : 'rgb(22 163 74)'} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
