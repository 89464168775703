import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportAgentRecharge, IReportAgentRechargeData } from '@/models/page/report/IReportAgentRecharge';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function agentRechargeData(input: IReportAgentRecharge): ITableLayoutData {
    const outputConfig: TableConfig<IReportAgentRechargeData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'rechargeTime', text: 'Th_rechargeTime', itemKeys: ['RechargeTime'], type: TableColumnStyle.DateTime },
            { name: 'orderId', text: 'Th_tradeNo', itemKeys: ['OrderId'], type: TableColumnStyle.Text },
            { name: 'modifyUserName', text: 'Th_userName', itemKeys: ['ModifyUserName'], type: TableColumnStyle.Text },
            { name: 'changeAmount', text: 'Th_effectAmountSum', itemKeys: ['ChangeAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'Remark', text: 'Th_note', itemKeys: ['Remark'], type: TableColumnStyle.Remark },
        ],
        footer: ['changeAmount'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.TotalRecords, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
