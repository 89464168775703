import { DialogButtonType } from '@/components/dialog/dialog';
import { NoteInfos } from '@/components/noteInfos/noteInfos';
import { PopupModel } from '@/components/popupModal/popupModal';
import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { IDialogSetting, showDialog } from '@/store/slices/dialog.slice';
import { Text, Input, Table, Tbody, Td, Th, Thead, Tr, UseDisclosureProps, Skeleton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface IChatGroupRequist {
    GroupCodes: number[];
}

interface ISaveChatGroupRequist {
    SaveType: 2;
    VChatGroups: { VChatGroupId: number; VChatGroupCode: number }[];
}

interface IChatGroupItem {
    GroupId: number;
    GroupCode: number;
    GroupName: string;
    GroupOwner?: {
        Name: string;
        VChatId: string;
    };
    GroupAdmins?: {
        Name: string;
        VChatId: string;
    }[];
    LinkedRegisterCodes: string[];
    markNeedsToBeRemoved?: boolean;
    isNewer?: boolean;
    IsDeleted: boolean;
}

const EditChatGroupListPopup = (props: UseDisclosureProps) => {
    const { t } = useTranslation();
    const [currentChatGroupId, setChatGroupId] = useState<number | undefined>();
    const [chatGroupList, setChatGroupList] = useState<IChatGroupItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const getRegisterCodeGroupConfig = useFetchApi<any, IChatGroupItem[]>(
        api.getRegisterCodeGroupConfig,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            const data: IChatGroupItem[] = value.data.Data;
            setChatGroupList(data ? data.map((obj) => ({ ...obj, markNeedsToBeRemoved: false, isNewer: false })) : []);
            setIsLoading(false);
        },
        [],
        () => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        if (props.isOpen) {
            getRegisterCodeGroupConfig({});
        }
    }, [props.isOpen]);

    const getNewChatGroup = useFetchApi<IChatGroupRequist, IChatGroupItem[], IChatGroupItem[]>(
        api.getChatGroup,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [list] }) => {
            if (value.data.IsSuccess) {
                const dataList: IChatGroupItem[] = value.data.Data;
                const resultList = dataList
                    .filter((item) => !list.some((listItem) => listItem.GroupId === item.GroupId))
                    .map((item) => ({ ...item, markNeedsToBeRemoved: false, isNewer: true }));
                setChatGroupList((_list) => {
                    return [...resultList, ..._list];
                });
            } else {
                const dialogSetting: IDialogSetting = {
                    title: t('Lbl_Button_Info'),
                    content: `${t('Lbl_ChatGroupIdNotExist')}`,
                    type: DialogButtonType.Info,
                };
                dispatch(showDialog(dialogSetting));
            }
            setIsLoading(false);
        },
        [chatGroupList],
        () => {
            setIsLoading(false);
        },
    );

    const saveRegisterCodeGroup = useFetchApi<ISaveChatGroupRequist, any>(
        api.saveRegisterCode,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            if (value.data.IsSuccess) {
                getRegisterCodeGroupConfig({});
            } else {
                setIsLoading(false);
            }
        },
        [],
        () => {
            setIsLoading(false);
        },
    );

    const hintContents = [
        '请注意，如果您删除某群聊并储存修改，您将无法在"注册码编辑"页面选取到此群组。',
        '系统只允许 新增大管和其下子帐号绑定的66ID 是群主/管理的群聊。',
    ];

    const isValidInteger = (str: string) => {
        const regex = /^(?!0)\d{1,10}$/;
        return regex.test(str);
    };

    const handleAddNewGroup = () => {
        if (currentChatGroupId === undefined) return;

        if (chatGroupList.find((item) => item.GroupCode === currentChatGroupId)) {
            const dialogSetting: IDialogSetting = {
                title: t('Lbl_Button_Info'),
                content: `${t('Lbl_ChatGroupIdExist')}`,
                type: DialogButtonType.Info,
            };
            dispatch(showDialog(dialogSetting));
            return;
        }

        getNewChatGroup({
            GroupCodes: [currentChatGroupId],
        });
    };

    const getOperationButton = (groupId: number, isRemoved: boolean) => {
        return (
            <>
                <span
                    className={`icon cursor-pointer text-center ${isRemoved ? 'icon-refresh' : 'icon-cancel-circle'}`}
                    onClick={() => {
                        setChatGroupList((_list) => {
                            return _list.map((obj) => {
                                if (obj.GroupId === groupId) {
                                    return { ...obj, markNeedsToBeRemoved: !isRemoved };
                                }
                                return obj;
                            });
                        });
                    }}>
                    {t(isRemoved ? 'Btn_undo' : 'Btn_delete')}
                </span>
            </>
        );
    };

    return (
        <PopupModel {...props} title={t('Lbl_EditChatGroupList')} size="6xl">
            <div data-container className="mx-4">
                <div className="flex items-center w-full p-2">
                    <Text className="mr-4 whitespace-nowrap  after:content-[':']">{t('Lbl_ChatGroupId')}</Text>
                    <Input
                        disabled={isLoading}
                        type="number"
                        className="flex-1"
                        placeholder={t('Lbl_EnterChatGroupId')}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === '.') {
                                e.preventDefault();
                                return;
                            }
                        }}
                        onChange={(e) => {
                            if (e.target.value.indexOf('-') > -1) {
                                return;
                            }

                            if (e.target.value.length === 0) {
                                setChatGroupId(undefined);
                                return;
                            }

                            if (!isValidInteger(e.target.value)) {
                                return;
                            }

                            const result = +e.target.value;
                            setChatGroupId((v) => {
                                return isNaN(result) ? v : result;
                            });
                        }}
                        value={currentChatGroupId || ''}
                    />
                    <button
                        disabled={isLoading || currentChatGroupId === undefined}
                        className="self-end p-2 m-2 bg-affiliate text-white rounded hover:bg-gray-500 disabled:bg-gray-200 disabled:cursor-progress"
                        onClick={() => handleAddNewGroup()}>
                        <label className="px-1 cursor-pointer">{t('Lbl_Add')}</label>
                    </button>
                </div>
                {isLoading ? (
                    <Skeleton className="w-full h-[100px]" />
                ) : (
                    <>
                        <Table variant="striped" colorScheme="blackAlpha">
                            <Thead>
                                <Tr>
                                    <Th>
                                        <span className="font-bold text-sm">{t('Lbl_serialId')}</span>
                                    </Th>
                                    <Th>
                                        <span className="font-bold text-sm">{t('Lbl_ChatGroupId')}</span>
                                    </Th>
                                    <Th>
                                        <span className="font-bold text-sm">{t('Lbl_GroupChatName')}</span>
                                    </Th>
                                    <Th>
                                        <span className="font-bold text-sm">{t('Lbl_GroupOwner')}</span>
                                    </Th>
                                    <Th>
                                        <span className="font-bold text-sm">{t('Lbl_GroupAdmin')}</span>
                                    </Th>
                                    <Th>
                                        <span className="font-bold text-sm">{t('Lbl_ExistingRegistrationCodes')}</span>
                                    </Th>
                                    <Th>
                                        <span className="font-bold text-sm">{t('Lbl_optional')}</span>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {chatGroupList.map((item, index) => {
                                    const isInvalid = !item.GroupId || !item.GroupName || item.IsDeleted;
                                    return (
                                        <Tr key={`chat_group_${item.GroupId}`} className="text-sm">
                                            <Td>{index + 1}</Td>
                                            {isInvalid ? (
                                                <>
                                                    <Td />
                                                    <Td />
                                                    <Td>{t('Lbl_ChatGroupAlreadyDeleted')}</Td>
                                                    <Td />
                                                </>
                                            ) : (
                                                <>
                                                    <Td>{item.GroupCode}</Td>
                                                    <Td>
                                                        {item.GroupName}{' '}
                                                        {item.isNewer && <span className=" bg-red-500 text-white p-2 text-center leading-3 text-sm">new</span>}
                                                    </Td>
                                                    <Td>{item.GroupOwner?.Name}</Td>
                                                    <Td>
                                                        <div className="flex flex-col">
                                                            {item.GroupAdmins?.map((admin) => (
                                                                <div key={`name_${item.GroupId}_${admin.VChatId}`}>{admin.Name}</div>
                                                            ))}
                                                        </div>
                                                    </Td>
                                                </>
                                            )}
                                            <Td>
                                                <div className="flex flex-col">
                                                    {!!item.LinkedRegisterCodes ? (
                                                        item.LinkedRegisterCodes.map((code) => <div key={`code${code}`}>{code}</div>)
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Td>

                                            <Td>
                                                {!!item.LinkedRegisterCodes && item.LinkedRegisterCodes.length > 0 ? (
                                                    <></>
                                                ) : (
                                                    getOperationButton(item.GroupId, !!item.markNeedsToBeRemoved)
                                                )}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </>
                )}

                <NoteInfos notes={hintContents} />
                <div className="flex justify-center">
                    <button
                        className="p-2 m-2 border border-affiliate rounded hover:bg-gray-200"
                        onClick={() => {
                            props.onClose?.();
                        }}>
                        <label className="px-1 cursor-pointer">{t('Lbl_Button_Cancle')}</label>
                    </button>
                    <button
                        disabled={isLoading}
                        className="p-2 m-2 bg-affiliate text-white rounded hover:bg-gray-500 disabled:bg-gray-200 disabled:cursor-not-allowed"
                        onClick={() => {
                            const resultList = chatGroupList
                                .filter((item) => !item.markNeedsToBeRemoved)
                                .map((v) => ({ VChatGroupId: v.GroupId, VChatGroupCode: v.GroupCode }));

                            saveRegisterCodeGroup({
                                SaveType: 2,
                                VChatGroups: resultList,
                            });
                        }}>
                        <label className="px-1 cursor-pointer">{t('Btn_save_setting')}</label>
                    </button>
                </div>
            </div>
        </PopupModel>
    );
};

export default EditChatGroupListPopup;
