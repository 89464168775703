import TitleLabel from '@/components/titleLabel';
import { useTranslation } from 'react-i18next';

const NotificationPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="my-4">
                <TitleLabel>通知</TitleLabel>
            </div>
        </>
    );
};
export default NotificationPage;
