export enum ReportPageEnum {
    FUND,
    FUND_NEW_ACCOUNT,
    COMMISSION,
    COMMISSION_MEMBER,
    COMMISSSION_MEMBER_DETAIL,
    COMMISSION_RECHARGE_WITHDRAW_FEE,
    COMMISSION_AGENT_LEVEL,
    TEAM,
    MEMBER,
    GAME,
    GAME_BRAND,
    FIRST_CHARGE,
    REBATE,
    REBATE_BONUS_DETAIL,
    REBATE_USER_DETAIL,
    CHARGE_AND_REDRAW,
    RECHARGE_DEPOSIT_DETAIL,
    WITHDRAW_DEPOSIT_DETAIL,
    AGENT_RECHARGE,
    RELATION_MEMBER,
    RELATION_MEMBER_DETAIL,
}

export enum ReportExportEnum {
    FUND = 0,
    COMMISSION = 1,
    TEAM = 2,
    MEMBER = 3,
    GAME = 4,
    FIRST_CHARGE = 5,
    REBATE = 6,
    CHARGE_AND_REDRAW = 7,
    AGENT_RECHARGE = 8,
    MEMBER_GROUP = 9,
    REGISTER_MEMBERS = 10,
    COMMISSION_MEMBER = 11,
    PROXY_COMMISSION = 12,
    COMMISSION_MEMBER_VALID = 13,
    DEPOSIT_DETAIL = 14,
    GAME_DETAIL = 15,
    REBATE_DETAIL = 16,
    MEMBER_GROUP_DETAIL = 17,
}
