import { PartnerAgentList } from '@/models/page/systemSetting';
import { Checkbox, CheckboxGroup, Text } from '@chakra-ui/react';

type PartnerAgentListProps = {
    defaultPartnerAgentList: Record<string, PartnerAgentList[]> | undefined;
    selectedAgentList: PartnerAgentList[];
    onChange: (currentPartnerId: number, list: (string | number)[]) => void;
};

const PartnerAgentListComp = ({ defaultPartnerAgentList, selectedAgentList, onChange }: PartnerAgentListProps) => {
    const partnerList = Object.keys(defaultPartnerAgentList || {});
    return (
        <>
            {partnerList.map((key, index) => {
                const agentList = defaultPartnerAgentList![key];
                const targetValueList = selectedAgentList
                    .filter((item) => item.PartnerId === agentList[0].PartnerId)
                    .filter((item) => item.IsAppliedAgent)
                    .map((item) => item.AgentUserCode);

                return (
                    <div className="flex " key={`partner-agent-${key}-${index}`}>
                        <Text backgroundColor="gray.300" className="w-1/5 p-2 whitespace-nowrap text-center mb-[-1px]">
                            {key}
                        </Text>
                        <div className="w-4/5 p-2 border border-gray-300 mb-[-1px]">
                            <CheckboxGroup
                                colorScheme="blue"
                                value={targetValueList}
                                onChange={(list) => {
                                    const currentPartnerId = agentList[0].PartnerId;
                                    onChange?.(currentPartnerId, list);
                                }}>
                                <div className="flex flex-wrap">
                                    {agentList.map(({ AgentUserCode }, index) => (
                                        <Checkbox key={`agent-list-${AgentUserCode}-${index}`} value={AgentUserCode}>
                                            {AgentUserCode}
                                        </Checkbox>
                                    ))}
                                </div>
                            </CheckboxGroup>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default PartnerAgentListComp;
