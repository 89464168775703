import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { store } from './store/store.config';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './layout/app';
import './i18n';
import getAppStorageUtil, { STORAGE_KEYS } from './service/appStorageUtil';
import { CommProvider } from '@/hooks/useComm';
const removeItem = getAppStorageUtil()[2];
removeItem(STORAGE_KEYS.LOGIN_TOKEN);
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Provider store={store}>
        <BrowserRouter>
            {/* <MemoryRouter> */}
            <ChakraProvider>
                <CommProvider>
                    <App />
                </CommProvider>
            </ChakraProvider>
            {/* </MemoryRouter> */}
        </BrowserRouter>
    </Provider>,
);
