import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import { IDepositRequestData } from '@/models/apiRequest';
import { ICardTablePagingBoardProps, IPagingDataType, IRealtimeHandler, IRealtimeTableConstValue } from '@/models/page/dashboard/IDashboard';
import { DespositDetail, DepositDetailData } from '@/models/page/report/IChargeAndWithdrawal';
import { api } from '@/service/api';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { DATETIME_FORMAT } from '@/constants/datetime';

export function useDepositDetail({
                                     agentIdList,
                                     buttonSettings,
                                     onPageChange,
                                     onUpdateRealtimeData,
                                     partnerId,
                                     onUpdateRealtimeType,
                                     userId
                                 }: IRealtimeHandler) {
    const { t } = useTranslation();

    const transformData = useCallback(
        (data: DespositDetail): ICardTablePagingBoardProps<DepositDetailData> => {
            const records = data.Data;
            return {
                totalRecords: data.Summary.TotalRecords, // data.TotalRecords,
                title: t('Lbl_RealtimeReport'),
                buttons: buttonSettings,
                titleNames: [t('Th_partnerId'), t('Lbl_MemberAccount'), t('Lbl_RechargeSumForRelation'), t('Lbl_DepositTime')],
                keys: ['PartnerId', 'UserName', 'Amount', 'PayTime'],
                types: [IPagingDataType.Partner, IPagingDataType.Text, IPagingDataType.Number, IPagingDataType.DateMonth],
                items: records,
                onClick: (key) => {
                    onUpdateRealtimeType(key as IRealtimeTableConstValue);
                },
                onPagingChange: (page) => {
                    onPageChange(page);
                    handleDepositDetailData({ PageNo: page, PartnerId: partnerId, UserIdList: agentIdList });
                },
            };
        },
        [partnerId, agentIdList, t],
    );

    const [depositDetailData, setDepositDetailData] = useState<DespositDetail>();

    const handleDepositDetailData = useFetchApi<Partial<IDepositRequestData>, DespositDetail>(
        api.depositDetails,
        ({ value }) => {
            const base = {
                DateFrom: dayjs().subtract(5, 'd').startOf('d').format(DATETIME_FORMAT.YMDHMSS),
                DateTo: dayjs().endOf('d').format(DATETIME_FORMAT.YMDHMSS),
                PartnerId: partnerId,
                UserId: userId,
                PageNo: 1,
                PageSize: 10,
                SearchTradeType: 1,
                SearchStatus: 1,
            } as IDepositRequestData;
            return { ...base, ...value };
        },
        ({ value: { data } }) => {
            setDepositDetailData(data.Data);
            onUpdateRealtimeData(transformData(data.Data));
        },
    );
    return [depositDetailData, handleDepositDetailData] as const;
}
