import Hint from '@/components/hint';
import { ICardInfoTextBoardProps, IInfoTextItem } from '@/models/page/dashboard/IDashboard';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { numberComma } from '@/service/utils';
import { InfoItem } from './cardboard';

export const CardInfoTextBoard = (props: ICardInfoTextBoardProps) => {
    return (
        <>
            <h1 className="font-black text-lg m-1 pl-2">
                <span className=" mr-1">{props.title}</span>
                <Hint title={props.helper?.title} content={props.helper?.content}></Hint>
            </h1>
            <div className={'flex'}>
                {props.infos ? <InfoItem items={props.infos}></InfoItem> : ''}
                <InfoTabItem {...props.tab}></InfoTabItem>
            </div>
        </>
    );
};

export const InfoTabItem = (props: IInfoTextItem) => {
    const style = {
        width: 'fit-content',
        left: '50%',
        transform: 'translateX(-50%)',
    };

    const borderCss = 'border-r-2 border-solid border-gray-200';

    return (
        <>
            <div key={props.title} className={'border-solid border-2 border-gray-200 m-2  relative rounded-lg flex h-24 w-full'}>
                <span className="absolute w-fit font-bold text-lg " style={style}>
                    {props.title}
                </span>
                <div className="flex mt-8  w-full p-1">
                    {props.items.map((item, idx) => (
                        <div
                            key={`iti_${item.key}`}
                            className={`flex justify-between flex-col basis-1/2 text-center ${idx !== props.items.length - 1 ? borderCss : ''}`}>
                            <span className={' text-sm'}>{`${item.text}`}</span>
                            <span className={' font-bold '}>{numberComma(FloatHelper.floatFixed(item.value, RoundType.Floor, 0))}</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
