import TableColumnComp from '@/components/tableColumnComp';
import { ITableLayoutData, TableColumnStyle } from '@/models/layout/tableLayout';
import { alignCenterStyles, numericStyles } from '@/service/utils';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    data?: ITableLayoutData;
    offsetPageSN?: number;
};

const FundDetailTable = ({ data, offsetPageSN }: Props) => {
    const { t } = useTranslation();
    const parseHeader = () => {
        const result = data?.tableHeader || [];
        return result;
    };
    const parsebody = () => {
        const result = data?.tableBody || [];
        return result;
    };
    const col = parseHeader();
    const body = parsebody();
    const footer = data?.tableFooter;
    const parseFooterStyle = useCallback((text: string | number, style: TableColumnStyle) => {
        return <TableColumnComp text={text} style={style} />;
    }, []);
    // const permissionList = useSelector((state: RootState) => state.userStore.permissions);

    return (
        <>
            <div className="table w-full min-w-[300px] ">
                <div className="table-header-group bg-gray-100 text-xs">
                    <div className="table-row ">
                        <div className="table-cell p-6 whitespace-nowrap">{t('Lbl_serial_number')}</div>
                        {col
                            .filter((item) => !item.invisible)
                            .map((item, i) => {
                                return (
                                    <div
                                        key={`table-header-${i}`}
                                        className={`table-cell p-6 whitespace-nowrap ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                            alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                        }`}>
                                        {t(item.text)}
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="table-row-group">
                    {body.map((hash, pi) => {
                        const params = {};
                        col.forEach((item) => {
                            (params as any)[item.name] = hash[item.name];
                        });

                        return (
                            <Fragment key={`ff-${pi}`}>
                                <div className="table-row odd:bg-gray-200 even:bg-slate-50" key={`table-td-tr-${pi}`}>
                                    <div className="table-cell py-4 px-6">{pi + 1 + (offsetPageSN || 0)}</div>
                                    {col
                                        .filter((item) => !item.invisible)
                                        .map((item, i) => {
                                            return (
                                                <div
                                                    className={`table-cell py-4 px-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                        alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                    } ${item.name === 'memberCode' ? 'text-blue-500 cursor-pointer' : ''}`}
                                                    key={`tbody-${item.name}`}>
                                                    <TableColumnComp text={hash[item.name]} style={item.type} />
                                                </div>
                                            );
                                        })}
                                </div>
                            </Fragment>
                        );
                    })}
                </div>

                {body.length > 0 && !!footer && (
                    <div className="table-footer-group bg-gray-300 text-xs font-bold">
                        <div className="table-row ">
                            <div className="table-cell py-4 px-6" />
                            {col
                                .filter((item) => !item.invisible)
                                .map((item, index) => {
                                    return (
                                        <div
                                            key={`table-footer-${index}`}
                                            className={`table-cell py-4 px-6 whitespace-nowrap ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                            }`}>
                                            {index === 0
                                                ? t('Lbl_total-summary')
                                                : footer[item.name] !== undefined && footer[item.name] !== ''
                                                ? parseFooterStyle(footer[item.name], item.type)
                                                : ''}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
            {body.length <= 0 && (
                <div className="my-4 flex justify-center">
                    <span className="text-5xl text-gray-400 icon icon-notification">{t('Lbl_NoRecord')}</span>
                </div>
            )}
        </>
    );
};

export default FundDetailTable;
