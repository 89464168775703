const PageNotFound = () => {
    return (
        <div className="flex flex-col w-screen h-screen justify-center">
            <h1 className="self-center text-5xl font-extrabold text-gray-500">页面不存在 !</h1>
            <p className="m-6 self-center text-4xl font-bold text-gray-400">Page Not Found !</p>
        </div>
    );
};

export default PageNotFound;
