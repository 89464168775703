import { FloatHelper, RoundType } from '@/service/floatHelper';
import { numberComma } from '@/service/utils';

export type INumberHighlightProp = {
    value: number;
    cssClass?: string;
};

export const NumberHighlight = (props: INumberHighlightProp) => {
    const sign = props.value > 0 ? '+' : '';
    const signCss = props.value > 0 ? 'text-red-500' : props.value < 0 ? 'text-green-500' : '';

    return (
        <span className={`${signCss} ${props.cssClass} text-right`}>
            {sign}
            {numberComma(FloatHelper.floatFixed(props.value, RoundType.Floor, 0))}
        </span>
    );
};
