export type INoteInfoProps = {
    notes: string[];
};

export const NoteInfos = (props: INoteInfoProps) => {
    return (
        <div data-note className="ml-4 mb-4 text-xs">
            <ul className="ml-4 list-disc">
                {props.notes.map((item, i) => (
                    <li key={`noteinfo-key-${i}`} className="text-red-400 whitespace-pre-line">{`${item}`}</li>
                ))}
            </ul>
        </div>
    );
};
