import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';
import { auditLogContentParser } from '../helper';
import { TFunction } from 'i18next';

export interface AuditLogsData {
    Data: AuditLogsItemData[];
    TotalRecord: number;
}

interface AuditLogsItemData {
    FunctionName: string;
    Action: number;
    Target: string;
    LogContent: string;
    DateCreated: string;
    CreatorAccount: string;
    IpAddress: string;
}

export function auditLogs(input: AuditLogsData, t: TFunction, offset = 0): ITableLayoutData {
    const outputConfig: TableConfig<AuditLogsItemData> = {
        column: [
            { name: 'serialId', text: 'Lbl_serial_number', itemKeys: ['SerialId'], type: TableColumnStyle.Text },
            {
                name: 'functionName',
                text: 'Th_FunctionName',
                itemKeys: ['FunctionName'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    return `Lbl_${data.FunctionName}`;
                },
            },
            {
                name: 'action',
                text: 'Th_Action',
                itemKeys: ['Action'],
                type: TableColumnStyle.TextI18N,
                calculate: (data, keys) => {
                    return `Txt_save_type_${data.Action}`;
                },
            },
            { name: 'target', text: 'Th_Target', itemKeys: ['Target'], type: TableColumnStyle.Text },
            {
                name: 'logContent',
                text: 'Th_LogContent',
                itemKeys: ['LogContent'],
                type: TableColumnStyle.Text,
                calculate: (data) =>
                    auditLogContentParser(data.LogContent)
                        .map(({ key, value, translate }) => `${t(key)}: ${translate ? t(value as string) : value}`)
                        .join(','),
            },
            { name: 'dateCreated', text: 'Th_DateCreated', itemKeys: ['DateCreated'], type: TableColumnStyle.Date },
            { name: 'creatorAccount', text: 'Th_CreatorAccount', itemKeys: ['CreatorAccount'], type: TableColumnStyle.Text },
            { name: 'ipAddress', text: 'Th_IP', itemKeys: ['IpAddress'], type: TableColumnStyle.Text },
        ],
        footer: [],
    };

    const addSerialIdToData = input.Data.map((item: AuditLogsItemData, index: number) => ({
        ...item,
        SerialId: index + 1 + offset,
    }));

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(addSerialIdToData, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.TotalRecord, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
