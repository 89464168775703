import { TableSearchOption } from '@/models/layout/tableLayout';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { comma2Number, LIMIT_VALUE, numberComma } from '@/service/utils';
import { Button, Divider, Input, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, useNumberInput } from '@chakra-ui/react';
import { useMemo, useRef, useState } from 'react';

enum FocusOn {
    START,
    END,
}

type Props = {
    label?: string;
    options?: { start: TableSearchOption[]; end: TableSearchOption[] };
    onChange?: (e: { min: number; max: number; isKill: boolean }) => void;
};
const RangeSelect = (props: Props) => {
    const { getInputProps } = useNumberInput({});
    const inputProp = { ...getInputProps(), bgColor: 'white', borderColor: 'whiteAlpha.50', focusBorderColor: 'black', color: 'gray.500' };
    const { label } = props;
    const start = useMemo(() => {
        return (
            props.options?.start.map((item) => (
                <li
                    className="px-2 hover:bg-gray-100 w-full text-left text-gray-500"
                    key={`start-${item.text}`}
                    onClick={() => {
                        if (firstFieldRef.current && secondFieldRef.current) {
                            setData((data) => ({ ...data, min: item.text }));
                            validateFun(FocusOn.START);
                            secondFieldRef.current.focus();
                        }
                    }}>
                    {~~item.text === -LIMIT_VALUE ? '不限' : numberComma(FloatHelper.floatFixed(~~item.text, RoundType.Floor, 0))}
                </li>
            )) || []
        );
    }, [props.options?.start]);
    const end = useMemo(() => {
        return (
            props.options?.end.map((item) => (
                <li
                    className="px-2 hover:bg-gray-100 w-full text-right text-gray-500"
                    key={`end-${item.text}`}
                    onClick={() => {
                        if (secondFieldRef.current) {
                            setData((data) => ({ ...data, max: item.text }));
                            validateFun(FocusOn.END);
                        }
                    }}>
                    {~~item.text === LIMIT_VALUE ? '不限' : numberComma(FloatHelper.floatFixed(~~item.text, RoundType.Floor, 0))}
                </li>
            )) || []
        );
    }, [props.options?.end]);

    const [data, setData] = useState({ min: '', max: '' });
    const [text, setText] = useState(label);
    const [focusOn, setFocusOn] = useState(FocusOn.START);
    const firstFieldRef = useRef<HTMLInputElement>(null);
    const secondFieldRef = useRef<HTMLInputElement>(null);

    const validateFun = (key: FocusOn = FocusOn.START) => {
        setData((item) => {
            const limitStr = '不限';
            const parseMin = item.min === limitStr ? -LIMIT_VALUE : comma2Number(item.min);
            const parseMax = item.max === limitStr ? LIMIT_VALUE : comma2Number(item.max);
            let resultMin = isNaN(parseMin) ? NaN : parseMin <= -LIMIT_VALUE ? -LIMIT_VALUE : parseMin;
            let resultMax = isNaN(parseMax) ? NaN : parseMax >= LIMIT_VALUE ? LIMIT_VALUE : parseMax;

            if (key === FocusOn.START && (resultMin > resultMax || isNaN(resultMax))) {
                resultMax = resultMin;
            } else if (key === FocusOn.END && resultMin > resultMax) {
                // swap
                [resultMin, resultMax] = [resultMax, resultMin];
            }

            setText(() => {
                const min = isNaN(resultMin) || resultMin === -LIMIT_VALUE ? limitStr : resultMin + '';
                const max = isNaN(resultMax) || resultMax === LIMIT_VALUE ? limitStr : resultMax + '';

                if (min === limitStr && max === limitStr) return limitStr

                return [
                    min === limitStr ? limitStr : numberComma(FloatHelper.floatFixed(~~min, RoundType.Floor, 0)),
                    max === limitStr ? limitStr : numberComma(FloatHelper.floatFixed(~~max, RoundType.Floor, 0)),
                ].join(' ~ ')
            });

            props.onChange?.({
                min: isNaN(resultMin) ? -LIMIT_VALUE : resultMin,
                max: isNaN(resultMax) ? LIMIT_VALUE : resultMax,
                isKill: isNaN(resultMin) && isNaN(resultMax),
            });

            return {
                min: isNaN(resultMin)
                    ? ''
                    : resultMin === -LIMIT_VALUE
                        ? limitStr
                        : '' + numberComma(FloatHelper.floatFixed(~~resultMin, RoundType.Floor, 0)),
                max: isNaN(resultMax)
                    ? ''
                    : resultMax === LIMIT_VALUE
                        ? limitStr
                        : '' + numberComma(FloatHelper.floatFixed(~~resultMax, RoundType.Floor, 0)),
            };
        });
    };

    return (
        <>
            <Popover initialFocusRef={firstFieldRef}>
                <PopoverTrigger>
                    <Button
                        backgroundColor="white"
                        color="gray.500"
                        borderColor="gray.100"
                        borderWidth={1}
                        p={2}
                        px={4}
                        borderRadius="5px"
                        _focus={{
                            outline: 'none',
                        }}
                        width="180px"
                        minWidth="180px">
                        {text}
                    </Button>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent>
                        <PopoverBody>
                            <div className="flex justify-between items-center">
                                <Input
                                    {...inputProp}
                                    ref={firstFieldRef}
                                    w="120px"
                                    data-start
                                    placeholder="最低"
                                    onFocus={() => {
                                        setFocusOn(FocusOn.START);
                                    }}
                                    value={data.min}
                                    onBlur={() => {
                                        validateFun(FocusOn.START);
                                    }}
                                    onChange={(e) => {
                                        setData((item) => ({ ...item, min: e.target.value }));
                                    }} />
                                <span>~</span>
                                <Input
                                    {...inputProp}
                                    ref={secondFieldRef}
                                    w="120px"
                                    data-end
                                    placeholder="最高"
                                    value={data.max}
                                    onFocus={() => {
                                        setFocusOn(FocusOn.END);
                                    }}
                                    // options={end}
                                    onBlur={() => {
                                        validateFun(FocusOn.END);
                                    }}
                                    onChange={(e) => {
                                        setData((item) => ({ ...item, max: e.target.value }));
                                    }} />
                            </div>
                            <Divider className="py-1" />

                            <ul className="h-[150px] overflow-y-scroll flex flex-col">{focusOn === FocusOn.START ? start : end}</ul>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </>
    );
};

export default RangeSelect;
