type Props = {
    HeadImg: string;
    NickName: string;
    Phone: string;
    TelCode: string;
    VChatID: string;
};

const Edit66Chat = ({ HeadImg, NickName, Phone, TelCode, VChatID }: Props) => {
    return (
        <>
            <div className="flex flex-col">
                <div>
                    <h1 className="text-2xl font-bold text-gray-700">您确定要新增该会员的66联系方式吗?</h1>
                    <p className="text-gray-700">新增成功后可以透过一站式与该会员进行聊天</p>
                </div>
                <figcaption className="flex justify-around mt-2">
                    <img src={HeadImg} alt="66 頭像" className="flex-none w-14 h-14 rounded-full object-cover self-center" loading="lazy" decoding="async" />

                    <div className="flex flex-col w-2/3">
                        <div className="flex">
                            <div className="w-1/2 border border-gray-400 ml-[-1px] mt-[-1px] p-1 font-bold">66 昵称</div>
                            <div className="w-1/2 border border-gray-400 ml-[-1px] mt-[-1px] p-1">{NickName}</div>
                        </div>
                        <div className="flex">
                            <div className="w-1/2 border border-gray-400 ml-[-1px] mt-[-1px] p-1 font-bold">66 ID</div>
                            <div className="w-1/2 border border-gray-400 ml-[-1px] mt-[-1px] p-1">{VChatID}</div>
                        </div>
                        <div className="flex">
                            <div className="w-1/2 border border-gray-400 ml-[-1px] mt-[-1px] p-1 font-bold">电话号码</div>
                            <div className="w-1/2 border border-gray-400 ml-[-1px] mt-[-1px] p-1">
                                <span>{TelCode}</span> <span>{Phone}</span>
                            </div>
                        </div>
                    </div>
                </figcaption>
                <div className="mt-2">
                    <p className="text-xs text-gray-500">如果您尚未与该帐号成为66好友，确认后系统会传送好友邀请给对方，等对方同意后，你们就可以进行聊天</p>
                </div>
            </div>
        </>
    );
};

export default Edit66Chat;
