import { SystemSettingLayoutEnum, SystemSettingLayoutMapping } from './layout.mapping';
import { useTranslation } from 'react-i18next';
import { createElement, useMemo, useState } from 'react';

const SystemSettingPage = () => {
    const { t } = useTranslation();
    const mapping = [
        // { text: 'Nav_AccountManagement', key: SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT },
        { text: 'Nav_SubAccountManagement', key: SystemSettingLayoutEnum.SUB_ACCOUNT_MANAGEMENT },
        { text: 'Nav_AuditLog', key: SystemSettingLayoutEnum.AUDIT_LOG },
    ];

    const [currentLayout, setCurrentLayout] = useState(SystemSettingLayoutEnum.SUB_ACCOUNT_MANAGEMENT);

    const getCurrentLayout = useMemo(() => SystemSettingLayoutMapping[currentLayout], [currentLayout]);
    const getCurrentLayoutProps = useMemo(() => {
        return {
            type: currentLayout,
        };
    }, [currentLayout]);
    return (
        <>
            <div className="flex mt-1">
                {mapping.map((item, i) => (
                    <div
                        key={`system-setting-${i}`}
                        data-active={currentLayout === item.key}
                        className="w-[100px] cursor-pointer leading-10 h-10 text-center  data-active:text-gray-800 data-active:font-bold data-active:bg-orange-300"
                        onClick={() => {
                            setCurrentLayout(item.key);
                        }}>
                        {t(item.text)}
                    </div>
                ))}
            </div>

            {createElement(getCurrentLayout, getCurrentLayoutProps)}
        </>
    );
};

export default SystemSettingPage;
