import { useState } from 'react';
import { PasswordInputComp } from '@/components/passwordInput/passwordInputComp';
import { PopupModel } from '@/components/popupModal/popupModal';
import { useTranslation } from 'react-i18next';
import { Select, UseDisclosureProps } from '@chakra-ui/react';
import { usePartnerList } from '@/hooks/usePartnerList';
import { usePartnerAgentList } from '@/hooks/usePartnerAgentList';
import { WarningMessageEnum } from '@/pages/profile/securityCenter/popup';
import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { useDispatch } from 'react-redux';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType, DialogContentType } from '@/components/dialog/dialog';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { validatePasswordFormat } from '@/service/utils';

interface IUpdatePasswordValues {
    partnerId?: number;
    agentId?: number;
    oldPassword?: string;
    newPassword?: string;
    confirmNewPassword?: string;
}

interface IUpdatePasswordRequest {
    PartnerId: number;
    AgentUserId: number;
    OldPassword: string;
    NewPassword: string;
}

interface IUpdatePasswordModalProps extends UseDisclosureProps {
    onSave: (values: Omit<IUpdatePasswordValues, 'confirmNewPassword'>) => void;
}

const styleObject = {
    bgColor: 'white',
    borderColor: 'gray.200',
    focusBorderColor: 'black',
    color: 'gray.500',
    width: '180px',
};

const errorMessageMapping: Record<string, string> = {
    [WarningMessageEnum.WrongFormat]: 'Msg_Error_ResetPassword_WrongFormat',
    [WarningMessageEnum.NewConfirmNotTheSame]: 'Msg_Error_ResetPassword_NewConfirmNotTheSame',
    [WarningMessageEnum.NewOldTheSame]: 'Msg_Error_ResetPassword_NewOldTheSame',
    [WarningMessageEnum.WrongPassword]: 'Msg_Error_ResetPassword_WrongPassword',
    [WarningMessageEnum.NewNeedValue]: 'Msg_Error_UpdateBrandPassword_NeedValue',
};

export const UpdatePasswordModal = (props: IUpdatePasswordModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [values, setValues] = useState<IUpdatePasswordValues>({});
    const [errors, setErrors] = useState<{ [key in keyof IUpdatePasswordValues]: string[] }>({});

    const partnerList = usePartnerList({ selectAllLabel: 'Msg_PleaseSelectPartner' });
    const partnerAgentList = usePartnerAgentList(values.partnerId);

    const mutate = useFetchApi<IUpdatePasswordRequest, never, IUpdatePasswordRequest>(
        api.updatePartnerPassword,
        ({ value }) => {
            setLoading(true);
            return {
                PartnerId: value.PartnerId,
                AgentUserId: value.AgentUserId,
                OldPassword: value.OldPassword,
                NewPassword: value.NewPassword,
            };
        },
        ({ value }) => {
            if (!value.data.Data) {
                dispatch(
                    showDialog({
                        title: t('Lbl_ConfirmBelowInfo'),
                        content: t('Msg_Error_UpdateBrandPassword_ReqFailed'),
                        contentType: DialogContentType.Text,
                        type: DialogButtonType.Error,
                    }),
                );
            } else {
                props.onClose?.();
                dispatch(
                    showDialog({
                        title: t('Lbl_ConfirmBelowInfo'),
                        content: t('Txt_updatePasswordSuccess'),
                        contentType: DialogContentType.Text,
                        type: DialogButtonType.Info,
                    }),
                );
            }

            setLoading(false);
        },
        [],
        (err) => {
            setLoading(false);
        },
    );

    function changeHandler(fieldName: keyof IUpdatePasswordValues, isNumber = false) {
        return function (value: string) {
            const newValues = {
                ...values,
                [fieldName]: isNumber ? parseInt(value) : value,
            };
            const validation = validateForm(fieldName, newValues);
            if (['oldPassword', 'newPassword', 'confirmNewPassword'].includes(fieldName)) {
                const oldPasswordValidation = validateForm('oldPassword', newValues);
                const newPasswordValidation = validateForm('newPassword', newValues);
                setErrors((errors) => ({
                    ...errors,
                    oldPassword: oldPasswordValidation,
                    newPassword: newPasswordValidation,
                    [fieldName]: validation,
                }));
            } else {
                setErrors((errors) => ({
                    ...errors,
                    [fieldName]: validation,
                }));
            }
            setValues(newValues);
        };
    }

    function submitHandler() {
        if (Object.values(errors).some((e) => e.length > 0)) return;

        mutate({
            PartnerId: values.partnerId!,
            AgentUserId: values.agentId!,
            OldPassword: values.oldPassword!,
            NewPassword: values.newPassword!,
        });
    }

    return (
        <>
            <PopupModel
                {...props}
                onClose={() => {
                    setErrors({});
                    setValues({});
                    props.onClose?.();
                }}
                title={t('Lbl_UpdateBrandPassword')}>
                <div className="flex-col flex min-h-[55vh]">
                    <div className="font-bold pb-8">{t('Msg_UpdateBrandPassword_Info')}</div>

                    <div className="flex-col flex-1 justify-start gap-2 flex">
                        <div className="flex items-center">
                            <div className="basis-1/3 text-right text-sm mt-1 text-gray-500">
                                <span className=" text-red-600">*</span>
                                <span>{t('Th_partnerId')}:</span>
                            </div>
                            <div className="basis-2/3 ml-1">
                                <Select {...styleObject} value={values.partnerId} onChange={(e) => changeHandler('partnerId', true)(e.currentTarget.value)}>
                                    {partnerList.map((partner) => (
                                        <option key={`select-opt-${partner.id}`} value={partner.id}>
                                            {t(partner.text)}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="basis-1/3 text-right text-sm mt-1 text-gray-500">
                                <span className=" text-red-600">*</span>
                                <span>{t('Th_agentNickName')}:</span>
                            </div>
                            <div className="basis-2/3 ml-1">
                                <Select {...styleObject} value={values.agentId} onChange={(e) => changeHandler('agentId', true)(e.currentTarget.value)}>
                                    <option key="select-opt-default" value="">
                                        {t('Msg_PleaseSelectAgent')}
                                    </option>
                                    {partnerAgentList.map((partner) => (
                                        <option key={`select-opt-${partner.id}`} value={partner.id}>
                                            {partner.text}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="basis-1/3 text-right text-sm mt-1 text-gray-500">
                                <span className=" text-red-600">*</span>
                                <span>{t('Lbl_OldPassword')}:</span>
                            </div>
                            <div className="basis-2/3 ml-1">
                                <PasswordInputComp placeHolder={t('Msg_PleaseEnterOldPassword')} onChange={changeHandler('oldPassword')} />
                                <div className="text-red-500 text-xs">{errors.oldPassword && <span>{t(errors.oldPassword[0])}</span>}</div>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="basis-1/3 text-right text-sm mt-1 text-gray-500">
                                <span className=" text-red-600">*</span>
                                <span>{t('Lbl_Newassword')}:</span>
                            </div>
                            <div className="basis-2/3 ml-1 flex-col flex">
                                <PasswordInputComp placeHolder={t('Msg_PleaseEnterNewPassword')} onChange={changeHandler('newPassword')} />
                                <div className="text-red-500 text-xs">{errors.newPassword && <span>{t(errors.newPassword[0])}</span>}</div>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="basis-1/3 text-right text-sm mt-1 text-gray-500">
                                <span className=" text-red-600">*</span>
                                <span>{t('Lbl_ConfirmPassword')}:</span>
                            </div>
                            <div className="basis-2/3 ml-1">
                                <PasswordInputComp placeHolder={t('Msg_PleaseEnterConfirmPassword')} onChange={changeHandler('confirmNewPassword')} />
                                <div className="text-red-500 text-xs">{errors.confirmNewPassword && <span>{t(errors.confirmNewPassword[0])}</span>}</div>
                            </div>
                        </div>
                    </div>
                    {/* Message */}
                    {values.partnerId && values.partnerId !== 0 ? (
                        <div data-note className="basis-3/12 flex mt-3">
                            <div data-note className="ml-4 mb-4 text-xs">
                                <ul className="ml-4 list-disc">
                                    {getHintContentByPartnerId(values.partnerId).map((text, i) => (
                                        <li key={`note-${i}`} className="text-red-400 whitespace-pre-line">
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : null}

                    <div className="basis-2/12 mt-2 text-center">
                        <button className="p-2 bg-affiliate text-white rounded hover:bg-gray-500" onClick={submitHandler}>
                            <label className="px-1 cursor-pointer">{t('Lbl_UpdatePassword')}</label>
                        </button>
                    </div>
                </div>
            </PopupModel>

            <LoadingMask visible={isLoading} />
        </>
    );
};

function getPasswordLengthLimitByPartnerId(partnerId?: number) {
    let lengthLimit: [number, number];

    switch (partnerId) {
        case 3:
            lengthLimit = [6, 16];
            break;
        default:
            lengthLimit = [8, 20];
            break;
    }

    return lengthLimit;
}

function getHintContentByPartnerId(partnerId: number) {
    const lengthLimit = getPasswordLengthLimitByPartnerId(partnerId);

    return ['至少包含一个字母或数字。', '可以包含英文大小写字母、数字和特殊符号!@#$%^& .。', `长度在${lengthLimit[0]}到${lengthLimit[1]}之间。`];
}

function validateForm(fieldName: keyof IUpdatePasswordValues, values: IUpdatePasswordValues): string[] {
    const errors: string[] = [];

    if (!values[fieldName]) {
        errors.push(errorMessageMapping[WarningMessageEnum.NewNeedValue]);
        return errors;
    }

    switch (fieldName) {
        case 'oldPassword':
        case 'newPassword': {
            const lengthLimit = getPasswordLengthLimitByPartnerId(values.partnerId);
            const isValidFormat = validatePasswordFormat(values[fieldName]!, lengthLimit);

            !isValidFormat && errors.push(errorMessageMapping[WarningMessageEnum.WrongFormat]);

            if (fieldName === 'newPassword') {
                values.oldPassword === values.newPassword && errors.push(errorMessageMapping[WarningMessageEnum.NewOldTheSame]);
            }

            break;
        }
        case 'confirmNewPassword': {
            values.newPassword !== values.confirmNewPassword && errors.push(errorMessageMapping[WarningMessageEnum.NewConfirmNotTheSame]);
            break;
        }
        default:
            break;
    }

    return errors;
}
