import { useMemo, useState } from 'react';
import {
    IButtonSetting,
    ICardTablePagingBoardProps,
    IRealtimeHandler,
    IRealtimeTableConst,
    IRealtimeTableConstValue,
} from '@/models/page/dashboard/IDashboard';
import { useRegisterMember } from '@/pages/dashboard/hooks/useRegisterMember';
import { useDepositDetail } from '@/pages/dashboard/hooks/useDepositDetail';
import { useFirstRecharge } from '@/pages/dashboard/hooks/useFirstRecharge';
import { useTranslation } from 'react-i18next';
import { useInterval } from '@chakra-ui/react';

export interface UseRealtimeDashboardParams {
    partnerId: number;
    agentIdList: string[];
}

export function useRealtimeDashboard({ partnerId, agentIdList }: UseRealtimeDashboardParams) {
    const [isLoading, setLoading] = useState(false);
    const [realtimeType, setRealtimeType] = useState<IRealtimeTableConstValue>(IRealtimeTableConst.Register);
    const [realtimeData, setRealtimeData] = useState<ICardTablePagingBoardProps<any>>();
    const { t } = useTranslation();

    const realtimeButtons = useMemo(
        () =>
            [IRealtimeTableConst.Register, IRealtimeTableConst.Recharge, IRealtimeTableConst.FirstRecharge].map(
                (key) =>
                    ({
                        key: key,
                        text: t(`Lbl_${key}`),
                    }) as IButtonSetting,
            ),
        [t],
    );

    const updateRealtimeType = (key: IRealtimeTableConstValue) => {
        setLoading(true);
        setRealtimeType(key);
        switch (key) {
            case IRealtimeTableConst.Register:
                handleRegisterMembers({ PartnerId: partnerId, UserIdList: agentIdList });
                break;
            case IRealtimeTableConst.Recharge:
                handleDepositDetailData({ PartnerId: partnerId, UserIdList: agentIdList });
                break;
            case IRealtimeTableConst.FirstRecharge:
                handleFirstCharge({ PartnerId: partnerId, UserIdList: agentIdList });
                break;
        }
    };

    const realTimeProps: IRealtimeHandler = {
        buttonSettings: realtimeButtons,
        onUpdateRealtimeType: updateRealtimeType,
        partnerId,
        userId: 1,
        onUpdateRealtimeData: (item) => {
            setRealtimeData(item);
            setLoading(false);
        },
        onPageChange: () => {
            setLoading(true);
        },
        agentIdList,
    };

    const [, handleRegisterMembers] = useRegisterMember(realTimeProps);
    const [, handleDepositDetailData] = useDepositDetail(realTimeProps);
    const [, handleFirstCharge] = useFirstRecharge(realTimeProps);

    useInterval(
        () => {
            updateRealtimeType(realtimeType);
        },
        6 * 60 * 1000,
    );

    return {
        isLoading,
        realtimeType,
        realtimeData,
        updateRealtimeType,
    } as const;
}
