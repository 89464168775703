import useTransferTableValue from '@/hooks/useTransferTableValue';
import { ExtraTableDataType, TableColumnStyle, TableExtraData } from '@/models/layout/tableLayout';
import { alignCenterStyles, downloadCSV, numericStyles } from '@/service/utils';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import TableColumnComp from '../tableColumnComp';
import cn from 'mxcn';

type Props = {
    extraData: {
        headerList: { key: ExtraTableDataType; value: string }[];
        data: TableExtraData[];
    };
};

export interface IExtraTable {
    exportCSV(fileName: string): void;
}

const ExtraTable = forwardRef<IExtraTable | undefined, Props>((props, ref) => {
    const list = props.extraData.headerList.map((item, i) => {
        return {
            key: item.key,
            list: [
                {
                    value: item.value,
                    type: TableColumnStyle.Text
                },
                ...props.extraData.data.map((v) => v.dataList[i])
            ]
        };
    });

    const header = list.filter((v) => v.key === ExtraTableDataType.HEADER);
    const body = list.filter((v) => v.key === ExtraTableDataType.BODY);
    const footer = list.filter((v) => v.key === ExtraTableDataType.FOOTER);

    const transferTableValue = useTransferTableValue();

    const transferCSV = useCallback(
        (fileName: string) => {
            const csvHeader = header.map((l, hi) => {
                return l.list.map((item, hli) => {
                    return transferTableValue(item.value, item.type);
                });
            });

            const csvBody = body.map((l, hi) => {
                return l.list.map((item, hli) => {
                    return transferTableValue(item.value, item.type);
                });
            });

            const result = [...csvHeader, ...csvBody];
            downloadCSV(result, fileName);
        },
        [header, body, footer, transferTableValue]
    );

    useImperativeHandle(ref, () => ({
        exportCSV(fileName: string) {
            transferCSV(fileName);
        }
    }));

    return (
        <div data-extra-table className="table w-1/2 border-2 border-solid ">
            <div data-extra-table-header className="table-header-group bg-gray-300 text-sm">
                {header.map((l, hi) => (
                    <div className="table-row" key={`table-extra-th-${hi}`}>
                        {l.list.map((item, hli) => (
                            <div
                                className={cn(
                                    'table-cell p-4',
                                    item.alignment,
                                    numericStyles.includes(item.type) && 'text-right',
                                    alignCenterStyles.includes(item.type) && 'text-center'
                                )}
                                key={`table-extra-th-tc-${hli}`}>
                                <TableColumnComp text={item.value} style={item.type} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div data-extra-table-body className="table-row-group text-xs">
                {body.map((l, hi) => {
                    return (
                        <div className="table-row odd:bg-gray-200 even:bg-slate-50" key={`table-extra-body-${hi}`}>
                            {l.list.map((item, hli) => {
                                return (
                                    <div
                                        className={cn(
                                            'table-cell p-4',
                                            numericStyles.includes(item.type) && 'text-right',
                                            alignCenterStyles.includes(item.type) && 'text-center'
                                        )}
                                        key={`table-extra-body-tc-${hli}`}>
                                        <TableColumnComp text={item.value} style={item.type} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div data-extra-table-footer className="table-footer-group">
                {footer.map((l, hi) => {
                    return (
                        <div className="table-row odd:bg-gray-300 even:bg-slate-100" key={`table-extra-footer-${hi}`}>
                            {l.list.map((item, hli) => {
                                return (
                                    <div
                                        className={`table-cell p-4 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                            alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                        }`}
                                        key={`table-extra-footer-tc-${hli}`}>
                                        <TableColumnComp text={item.value} style={item.type} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default ExtraTable;
