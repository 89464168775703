import { createContext, PropsWithChildren, useContext, useEffect, useRef } from 'react';
import ChatToast from '@/components/chatToast';
import { CommAnnouncer, useComm } from '@/components/chatView/useComm';
import { updateUnreadMessageCount } from '@/store/slices/chat.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';

const CommContext = createContext<CommAnnouncer | null>(null);

export const useCommContext = () => useContext(CommContext);

export function CommProvider({ children }: PropsWithChildren) {
    const frameRef = useRef<HTMLIFrameElement>(null);
    const userVChatId = useSelector((state: RootState) => state.userStore.vChatId);
    const dispatch = useDispatch();
    const { lastMessage, announce } = useComm(frameRef);

    useEffect(() => {
        if (!lastMessage) {
            return;
        }
        const { action, params } = lastMessage;
        switch (action) {
            case 'messageCount':
                dispatch(updateUnreadMessageCount(params.count));
                break;
        }
    }, [lastMessage]);

    return (
        <CommContext.Provider value={announce}>
            {children}

            {!!userVChatId ? <ChatToast ref={frameRef} /> : <></>}
        </CommContext.Provider>
    );
}
