import { IMemberRequestData } from '@/models/apiRequest';
import { TableColumnStyle } from '@/models/layout/tableLayout';
import { IReportMemberInfoData } from '@/models/page/report/IReportMemberDetails';
import { PermissionsId } from '@/models/permissions';
import { parseCommissionType } from '@/service/utils';
import { openRechargePopupAction } from '@/store/actions/recharge.action';
import { RootState } from '@/store/store.config';
import { Box, Button, Card, CardBody, Heading, Skeleton, Stack, StackDivider, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
    memberDetail: IReportMemberInfoData | undefined;
    currentRequest: IMemberRequestData | undefined;
    isLoaded: boolean;
    onClose(): void;
};

const MemberDetailsHeader = ({ memberDetail, isLoaded, currentRequest, onClose }: Props) => {
    const { t } = useTranslation();
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const dispatch = useDispatch();
    return (
        <>
            <Card>
                <CardBody className="bg-gray-100">
                    {/* <Text>View a summary of all your customers over the last month.</Text> */}
                    <Stack divider={<StackDivider />} spacing="4">
                        <Skeleton height="45.8px" isLoaded={isLoaded}>
                            <Box display="flex" justifyContent={'space-between'}>
                                <Box>
                                    <div className="flex gap-4">
                                        <div>
                                            <Heading size="xs" textTransform="uppercase">
                                                {t('Th_userName')}
                                            </Heading>
                                            <Text pt="2" fontSize="sm">
                                                {memberDetail?.UserName}
                                            </Text>
                                        </div>
                                        {permissionList.includes(PermissionsId.MemberRecharge) && (
                                            <Box>
                                                <Button
                                                    backgroundColor={'orange'}
                                                    color={'white'}
                                                    size="md"
                                                    _hover={{ bg: 'orange.500' }}
                                                    onClick={() => {
                                                        onClose();
                                                        const param = {
                                                            isOpen: true,
                                                            partnerId: memberDetail?.PartnerId,
                                                            agentUserCode: memberDetail?.AgentUserCode,
                                                            memberLists: memberDetail?.UserName ? [memberDetail?.UserName] : [],
                                                            readonly: true,
                                                        };
                                                        dispatch(openRechargePopupAction(param));
                                                    }}>
                                                    {t('Lbl_RechargeType_2')}
                                                </Button>
                                            </Box>
                                        )}
                                    </div>
                                </Box>

                                <Box>
                                    <Heading size="xs" textTransform="uppercase">
                                        {t('Th_agentNickName')}
                                    </Heading>
                                    <Text pt="2" fontSize="sm">
                                        {memberDetail?.AgentNickName}
                                    </Text>
                                </Box>
                            </Box>
                        </Skeleton>
                        <Skeleton height="45.8px" isLoaded={isLoaded}>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {t('Th_registerDate')}
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    {memberDetail ? dayjs(memberDetail.RegisterDate).format('YYYY/MM/DD HH:mm:ss') : ''}
                                </Text>
                            </Box>
                        </Skeleton>
                        <Skeleton height="45.8px" isLoaded={isLoaded}>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {t('Th_partnerId')}
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    {memberDetail?.PartnerId && t(parseCommissionType(memberDetail?.PartnerId, TableColumnStyle.Partner) + '')}
                                </Text>
                            </Box>
                        </Skeleton>
                        <Skeleton height="45.8px" isLoaded={isLoaded}>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {t('Lbl_RegistDomain')}
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    {memberDetail?.DomainName !== '--' ? memberDetail?.DomainName : memberDetail?.RegisterDomain}
                                </Text>
                            </Box>
                        </Skeleton>
                    </Stack>
                </CardBody>
            </Card>
            <Box>
                <Text pt="2" fontSize="sm">
                    {`${t('Lbl_date_range')} : ${currentRequest?.DateFrom && parseCommissionType(currentRequest?.DateFrom, TableColumnStyle.Date)} ~ ${
                        currentRequest?.DateTo && parseCommissionType(currentRequest?.DateTo, TableColumnStyle.Date)
                    }`}
                </Text>
            </Box>
        </>
    );
};

export default MemberDetailsHeader;
