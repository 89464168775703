import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import cn from 'mxcn';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';
import { useMemo } from 'react';

type IProfileTab = {
    router: string;
    text: string;
    permission: PermissionsId;
};

export const ProfileTabs = () => {
    const { t } = useTranslation();
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);

    const mapping: IProfileTab[] = useMemo(
        () =>
            [
                {
                    router: '/profile',
                    text: 'Txt_profile',
                    permission: PermissionsId.PersonalInformation,
                },
                {
                    router: '/securitycenter',
                    text: 'Txt_securityCenter',
                    permission: PermissionsId.SecurityCenter,
                },
                {
                    router: '/rechargemonitor',
                    text: 'Txt_RechargeMonitor',
                    permission: PermissionsId.RechargeMonitoring,
                },
                {
                    router: '/passwordmanager',
                    text: 'Txt_PasswordManager',
                    permission: PermissionsId.PartnerPasswordManagement,
                },
                {
                    router: '/funddetails',
                    text: 'Title_FundDetails',
                    permission: PermissionsId.FundDetails,
                },
            ].filter((item) => permissionList.includes(item.permission)),
        [permissionList],
    );

    return (
        <>
            <div className="flex mt-1">
                {mapping.map((item) => (
                    <NavLink to={item.router} className="flex" key={`navlink-${item.router}`}>
                        {({ isActive }) => (
                            <div
                                className={cn(
                                    'min-w-[100px] leading-10 h-10 text-center whitespace-nowrap',
                                    isActive && 'text-gray-800 font-bold bg-orange-300',
                                )}>
                                {t(item.text)}
                            </div>
                        )}
                    </NavLink>
                ))}
            </div>
        </>
    );
};
