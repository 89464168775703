export enum PermissionsId {
    Dashboard = 1,
    MemberManagement = 2,
    MemberList = 3,
    BasicInformation = 4,
    BasicInformationView = 5,
    BasicInformationEdit = 6,
    MemberStatistics = 7,
    RelatedMembers = 8,
    RelatedMembersView = 9,
    RelatedMembersEdit = 10,
    MemberRecharge = 11,
    PromotionLink = 12,
    ReportCenter = 13,
    FundReport = 14,
    CommissionReport = 15,
    CommissionReportView = 16,
    CommissionReportDiff = 17,
    TeamDataReport = 18,
    MemberDataReport = 19,
    GameWinLossReport = 20,
    MemberFirstDepositReport = 21,
    BonusRebateReport = 22,
    DepositWithdrawalDetails = 23,
    RechargeDetails = 24,
    RelatedMembersReport = 25,
    PersonalCenter = 26,
    PersonalInformation = 27,
    SecurityCenter = 28,
    PartnerPasswordManagement = 29,
    RechargeMonitoring = 30,
    SystemSetting = 31,
    SubAccountManagement = 32,
    LogManagement = 33,
    MemberChat = 34,
    CrossSystemQuery = 35,
    Chat = 36,
    FriendChat = 37,
    ViewChatHistory = 38,
    ChatManagement = 39,
    NotificationCenter = 40,
    CreateRegisterCode = 41,
    EditRegisterCode = 42,
    EditChatGroupList = 43,
    FundDetails = 44,
}
