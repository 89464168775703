import { PermissionsId } from '@/models/permissions';
import { RRouteObject } from '@/models/router/RRouteObject';

const initialRoutePriorityMap = [
    ['dashboard',PermissionsId.Dashboard],
    ['member',PermissionsId.MemberManagement],
    ['report',PermissionsId.ReportCenter],
    ['chat-list',PermissionsId.Chat],
    ['profile',PermissionsId.PersonalCenter]
] as const

export function permissionGuards(permissionList: number[]) {
    return (routes: RRouteObject[]) => {
        const targetRoutesIndex = routes.findIndex((route) => route.path === '/');
        const targetRoutes = routes[targetRoutesIndex]?.children || [];
        const resultRoutes = targetRoutes.filter((item: RRouteObject) => {
            const isUndefined = item.meta?.id === undefined;
            const hasPermission = permissionList.includes(item.meta?.id as number);
            return isUndefined || hasPermission;
        });
        if (targetRoutesIndex >= 0) {
            // 因為預設首頁是進入Dashboard, 所以判斷如果Dashboard沒有全線則導到MemberAdminPage頁
            const defaultIndex = resultRoutes.findIndex((route) => route.path === '');
            for (const item of initialRoutePriorityMap) {
                const [ path, permission ] = item
                const route = resultRoutes.find((route) => route.path === path);

                if (permissionList.includes(permission) && route) {
                    resultRoutes[defaultIndex].element = route.element;
                    break;
                }
            }

            routes[targetRoutesIndex].children = resultRoutes;
        }
        return routes;
    };
}
