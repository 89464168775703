import { BrushBarChart } from '@/components/barChart/brushBarChart';
import { PieChart } from '@/components/pieChart/pieChart';
import {
    IMemberDetailStatisticDepositData,
    IMemberDetailStatisticRechargeData,
    IMemberDetailStatisticsData,
    IMemberDetailStatisticWinLoseData,
} from '@/models/page/report/IReportMemberDetails';
import { MemberStatisticType } from '@/pages/memberAdmin/admin/statistics/useStatisticsData';
import { PieOfPieChart } from '@/components/pieChart/pieOfPieChart';
import { useTranslation } from 'react-i18next';
import { FloatHelper } from '@/service/floatHelper';

interface IMemberStatisticsChartProps {
    statisticType: MemberStatisticType;
    data: IMemberDetailStatisticsData | null;
    extraData: {
        totalAvailableAmount: number;
    };
}

export const MemberStatisticsChart = ({ statisticType, data, extraData: { totalAvailableAmount } }: IMemberStatisticsChartProps) => {
    const { t } = useTranslation();

    if (data === null) return null;

    switch (statisticType) {
        case 'Recharge': {
            const recharge = data as IMemberDetailStatisticRechargeData;
            if (recharge.Summary.length <= 0) return null;
            return (
                <PieOfPieChart
                    type="recharge"
                    parentData={[
                        {
                            name: t('Lbl_RechargeType_2'),
                            value: recharge.TotalAmount,
                        },
                        {
                            name: t('Lbl_TradeType_1'),
                            value: Math.max(0, totalAvailableAmount - recharge.TotalAmount),
                        },
                    ]}
                    childrenData={recharge.Summary.map((entry) => ({
                        name: entry.Remark,
                        value: entry.Amount,
                    }))}
                />
            );
        }
        case 'Deposit': {
            const deposit = data as IMemberDetailStatisticDepositData;
            if (deposit.Data.length <= 0) return null;
            return (
                <PieOfPieChart
                    type="deposit"
                    parentData={[
                        {
                            name: t('Lbl_TradeType_1'),
                            value: deposit.TotalAmount,
                        },
                        {
                            name: t('Lbl_RechargeType_2'),
                            value: Math.max(0, totalAvailableAmount - deposit.TotalAmount),
                        },
                    ]}
                    childrenData={deposit.Summary.map((entry) => ({
                        name: entry.SubType,
                        value: entry.DepositAmount,
                    }))}
                />
            );
        }
        case 'BetAmount': {
            const betAmount = data as IMemberDetailStatisticWinLoseData;
            if (betAmount.TotalBetAmount === 0) return null;
            return (
                <>
                    <PieChart
                        title={t('Lbl_Product_Bet_Amount')}
                        data={betAmount.MemberGameProductStatistics.map((entry) => ({
                            name: entry.ProductName,
                            value: entry.BetAmount,
                        }))}
                    />

                    <PieChart
                        title={t('Lbl_Game_Group_Bet_Amount')}
                        data={betAmount.MemberGameGroupStatistics.map((entry) => ({
                            name: entry.GameGroupName,
                            value: entry.BetAmount,
                        }))}
                    />
                </>
            );
        }
        case 'WinLose': {
            const winLose = data as IMemberDetailStatisticWinLoseData;
            if (winLose.TotalBetAmount === 0) return null;
            return (
                <>
                    <BrushBarChart
                        title={t('Lbl_Product_WinLose')}
                        data={winLose.MemberGameProductStatistics.map((entry) => ({
                            name: entry.ProductName,
                            value: FloatHelper.floatFixed(entry.TotalWin, 'Floor', 0),
                        }))}
                    />

                    <BrushBarChart
                        title={t('Lbl_Game_Group_WinLose')}
                        data={winLose.MemberGameGroupStatistics.map((entry) => ({
                            name: entry.GameGroupName,
                            value: FloatHelper.floatFixed(entry.TotalWin, 'Floor', 0),
                        }))}
                    />
                </>
            );
        }
        default:
            return null;
    }
};
