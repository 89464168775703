import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { NumberHighlight } from '@/components/numberHighlight/numberHighlight';
import QuickDatePicker from '@/components/quickDatePicker';
import SearchColumn from '@/components/searchArea/searchColumn';
import useFetchApi from '@/hooks/useFetchApi';
import { APIModel, IBaseRequestData } from '@/models/apiRequest';
import { baseRequest } from '@/models/apiRequest/baseRequest';
import { TableSearch, TableSearchEnum, TableSearchOption } from '@/models/layout/tableLayout';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { numberComma } from '@/service/utils';
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/service/api';
import { UpdateGroupNamePopup } from './updateGroupNamePopup';
import { UpdateGroupMembersPopup } from './updateGroupMembersPopup';
import {
    IMemberPartner,
    IRelationAccountItemModel,
    IRelationAccountQueryProps,
    IRelationAccountResponseModel,
    IRelationAccountSummaryModel,
    IRelationUpdateMemberGroupModel,
    StatusEnum,
} from '@/models/page/memberAdmin/IRelationAccountItemModel';
import { useTranslation } from 'react-i18next';
import MemberModel, { MemberModelRef } from '@/components/memberModel';
import { HistoryTableLayoutEnum } from '@/components/historyModel/layout';
import { NoteInfos } from '@/components/noteInfos/noteInfos';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import { IMemberAdminPageProps } from '@/pages/memberAdmin/admin/pageConfig';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';
import { DATETIME_FORMAT } from '@/constants/datetime';

export interface IRelationAccountRequestData extends IBaseRequestData {
    EffectiveStatus: StatusEnum;
}

type IGroupPopupParam = {
    groupName: string;
    isOpen: boolean;
};

type IMembersPopupParam = {
    isOpen: boolean;
};

export const RelationAccount = (props: IMemberAdminPageProps) => {
    const { t } = useTranslation();
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const noteInfos: string[] = [
        '每月 5 号最后结算上月的关联会员报表, 请在执行前确认所有关联资料',
        '为了保障数据安全及即时性，只保留3个自然月内数据，其他区段内关联数据可至关联会员报表查看；',
        '群组名称: 关联会员报表将以此名称进行显示并提供查询',
        '未下注天数: 统计会员当前时间距离最后一次下注时间的自然天数;',
    ];
    const ref = useRef<MemberModelRef>(null);

    const onModelTrigger = useCallback(
        (api: APIModel, data: { userName?: string; partnerId?: number; dateFrom?: string; dateTo?: string }, layoutId: HistoryTableLayoutEnum) => {
            ref.current?.onMemberDataTrigger &&
                ref.current.onMemberDataTrigger(api, {
                    DateFrom: data.dateFrom ?? requestData.DateFrom,
                    DateTo: data.dateTo ?? requestData.DateTo,
                    UserName: data.userName!,
                    PartnerId: data.partnerId!,
                });
        },
        [],
    );

    // const isAllowEditRelation = userRoleType !== RoleTypeEnum.SuperUser;
    const isAllowEditRelation = permissionList.includes(PermissionsId.RelatedMembersEdit);

    const [queryModel, setQueryModel] = useState<IRelationAccountQueryProps>({
        PartnerId: 0,
        DateFrom: dayjs().date(1).format(DATETIME_FORMAT.YMDHMSS),
        DateTo: dayjs().endOf('day').format(DATETIME_FORMAT.YMDHMSS),
        EffectiveStatus: StatusEnum.All,
        AgentUserCode: props.AgentUserCode,
        MemberPartnerId: props.MemberPartnerId,
        MemberUserName: props.MemberUserName,
    });

    const [requestData, setRequestData] = useState<IRelationAccountRequestData>(() => ({
        ...baseRequest,
        DateFrom: dayjs().date(1).format(DATETIME_FORMAT.YMDHMSS),
        DateTo: dayjs().endOf('day').format(DATETIME_FORMAT.YMDHMSS),
        EffectiveStatus: queryModel.EffectiveStatus,
    }));

    const [pageData, setPageData] = useState<IRelationAccountResponseModel>({
        GroupName: '',
        GroupId: 0,
        Details: [],
    });

    const [groupPopupParam, setGroupPopupParam] = useState<IGroupPopupParam>({
        groupName: '',
        isOpen: false,
    });

    const [membersPopupParam, setMembersPopupParam] = useState<IMembersPopupParam>({
        isOpen: false,
    });

    const sum = (nums: number[]) => nums.reduce((total, num) => FloatHelper.plus(total, num), 0);

    const summaryData = useMemo<IRelationAccountSummaryModel>(() => {
        return {
            FirstChargeAmount: sum(pageData.Details.map((item) => item.FirstRechargeAmount)),
            RechargeAmount: sum(pageData.Details.map((item) => item.RechargeAmount)),
            UpAmount: sum(pageData.Details.map((item) => item.UpAmount)),
            WithdrawAmount: sum(pageData.Details.map((item) => item.WithdrawAmount)),
            BetAmount: sum(pageData.Details.map((item) => item.BetAmount)),
            TotalWin: sum(pageData.Details.map((item) => item.TotalWin)),
            BonusAmount: sum(pageData.Details.map((item) => item.BonusAmount)),
            RebateAmount: sum(pageData.Details.map((item) => item.RebateAmount)),
        };
    }, [pageData]);

    const effextiveStatusList: TableSearchOption[] = [
        {
            id: StatusEnum.All,
            text: 'Txt_NoLimit',
        },
        {
            id: StatusEnum.Effective,
            text: 'Txt_ValidMember',
        },
        {
            id: StatusEnum.InEffective,
            text: 'Txt_InvalidMember',
        },
    ];

    const search = useMemo<TableSearch[][]>(() => {
        return [
            [
                {
                    type: TableSearchEnum.DATE_RANGE,
                    dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo', shortcuts: false },
                    label: t('Lbl_date_range'),
                },
            ],
            [
                {
                    type: TableSearchEnum.SELECT,
                    matchKey: 'partnerId',
                    label: t('Th_partnerId'),
                    placeholder: t('Lbl_All'),
                    selectOptions: [],
                },
                {
                    type: TableSearchEnum.SELECT,
                    matchKey: 'EffectiveStatus',
                    label: t('Th_effectiveStatus'),
                    placeholder: t('Lbl_All'),
                    selectOptions: effextiveStatusList,
                    defaultSelectOptions: [StatusEnum.All],
                },
            ],
        ];
    }, [t]);

    const col = useMemo(
        () => [
            { label: 'Lbl_serialId', name: 'Id' },
            { label: 'Th_partnerId', name: 'PartnerId' },
            { label: 'Th_agentNickName', name: 'AgentNickName' },
            { label: 'Lbl_MemberAccount', name: 'MemberAccount' },
            { label: 'Txt_NickName', name: 'MemberNickname' },
            { label: 'Th_registerDate', name: 'RegisterDate' },
            { label: 'Th_vipLevel', name: 'VipLevel' },
            { label: 'Th_effectiveStatus', name: 'EffectiveStatus' },
            { label: 'Th_unBetDays', name: 'UnBetDays' },

            { label: 'Th_firstChargeAmount', name: 'FirstChargeAmount' },
            { label: 'Th_rechargeSum', name: 'RechargeAmount' },
            { label: 'Th_effectAmountSum', name: 'UpAmount' },
            { label: 'Th_withdrawAmount', name: 'WithdrawAmount' },
            { label: 'Th_betAmount', name: 'BetAmount' },

            { label: 'Th_totalWin', name: 'TotalWin' },
            { label: 'Th_bonus', name: 'BonusAmount' },
            { label: 'Th_rebeat', name: 'RebateAmount' },
        ],
        [],
    );

    const detachGetGroupDetailItems = useFetchApi<IRelationAccountQueryProps, IRelationAccountResponseModel, IRelationAccountQueryProps>(
        api.relationMemberGroupDetail,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setIsLoading(false);

            if (!value || !value.data) {
                return;
            }

            if (value.data.IsSuccess) {
                const rep = value.data.Data;
                const func = (item: IRelationAccountItemModel) =>
                    item.PartnerId === props.MemberPartnerId && item.AgentUserCode === props.AgentUserCode && item.UserName === props.MemberUserName;
                rep.Details = rep.Details.sort((a, b) => (func(a) ? -1 : func(b) ? 1 : a.PartnerId - b.PartnerId));
                setPageData(value.data.Data);
            } else {
            }
        },
        [queryModel],
        (error) => {
            setIsLoading(false);
        },
    );

    const detachUpdateMemberGroup = useFetchApi<IRelationUpdateMemberGroupModel, string[], IRelationUpdateMemberGroupModel>(
        api.relationUpdateMemberGroup,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setIsLoading(false);

            if (!value || !value.data) {
                return;
            }
            if (value.data.IsSuccess) {
                props.Reload && props.Reload();
                if (value.data.Data.length > 0) {
                    dispatch(
                        showDialog({
                            title: t('Lbl_Button_Info'),
                            content: `${t('Msg_GroupsAlreadyDeleted')}\n` + value.data.Data.join(', '),
                            type: DialogButtonType.Info,
                        }),
                    );
                }
                detachGetGroupDetailItems(queryModel);
            } else {
            }
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        detachGetGroupDetailItems(queryModel);
    }, [queryModel]);

    useEffect(() => {
        detachGetGroupDetailItems(queryModel);
    }, []);

    const handleOnSearch = (data: any) => {
        setRequestData((pre) => ({
            ...pre,
            PartnerId: Number(data['partnerId']),
            EffectiveStatus: Number(data['EffectiveStatus']),
            DateFrom: data['DateFrom'],
            DateTo: data['DateTo'],
        }));
        setQueryModel((pre) => ({
            ...pre,
            DateFrom: dayjs(data['DateFrom']).startOf('day').format(DATETIME_FORMAT.YMDHMSS),
            DateTo: dayjs(data['DateTo']).startOf('day').format(DATETIME_FORMAT.YMDHMSS),
            PartnerId: Number(data['partnerId']),
            EffectiveStatus: Number(data['EffectiveStatus']),
        }));
    };

    const handleMemberClick = (memberAccount: IRelationAccountItemModel) => {
        onModelTrigger(
            api.memberDetails,
            {
                userName: memberAccount.UserName,
                partnerId: memberAccount.PartnerId,
                dateFrom: requestData.DateFrom,
                dateTo: requestData.DateTo,
            },
            HistoryTableLayoutEnum.NONE,
        );
    };

    const handleQuickDateClick = (date: { startDate: string; endDate: string }) => {
        setRequestData((pre) => ({
            ...pre,
            DateFrom: date.startDate,
            DateTo: date.endDate,
        }));

        setQueryModel((pre) => ({
            ...pre,
            DateFrom: dayjs(date.startDate).startOf('day').format(DATETIME_FORMAT.YMDHMSS),
            DateTo: dayjs(date.endDate).startOf('day').format(DATETIME_FORMAT.YMDHMSS),
        }));
    };

    const handleUpdateGroupNameClick = () => {
        setGroupPopupParam((pre) => ({ ...pre, isOpen: true }));
    };

    const handleUpdateGroupName = (groupName: string) => {
        detachUpdateMemberGroup({
            GroupId: pageData.GroupId,
            GroupName: groupName,
            MemberPartners: [],
            IsUpdateDetail: false,
        });
        setGroupPopupParam((pre) => ({ ...pre, isOpen: false }));
    };

    const handleUpdateGroupMembersClick = () => {
        setMembersPopupParam((pre) => ({ ...pre, isOpen: true }));
    };

    const handleUpdateGroupMembersOnSave = (members: IMemberPartner[]) => {
        if (pageData.GroupId === 0) {
            members = [
                ...members,
                {
                    AgentUserCode: props.AgentUserCode,
                    UserName: props.MemberUserName,
                    PartnerId: props.MemberPartnerId,
                },
            ];
        }

        detachUpdateMemberGroup({
            GroupId: pageData.GroupId,
            GroupName: pageData.GroupId === 0 ? `${props.MemberUserName}-群` : pageData.GroupName,
            MemberPartners: members,
            IsUpdateDetail: true,
        });
        setMembersPopupParam((pre) => ({ ...pre, isOpen: false }));
    };

    return (
        <>
            <div className="text-lg my-4 px-4 font-bold border-l-8 border-blue-400">
                <span>{t('Lbl_RelationAccount')}</span>
            </div>

            <LoadingMask visible={isLoading} />

            <div className="flex mb-3">
                <div className=" grow">
                    <SearchColumn defaultValue={requestData} search={search} handler={handleOnSearch} isButtonNewLine={false} />
                </div>
                <div data-quick-date-picker className="flex-col self-end text-righ">
                    {isAllowEditRelation && (
                        <div className=" text-right mb-2">
                            <button className="self-end p-2 bg-affiliate text-white rounded hover:bg-gray-500" onClick={handleUpdateGroupMembersClick}>
                                <span className="px-1 icon icon-edit-profile" />
                                <label className="px-1 cursor-pointer">{t('Lbl_UpdateGroupRelation')}</label>
                            </button>
                        </div>
                    )}
                    <div>
                        <QuickDatePicker btnList={['YESTERDAY', 'THIS_MONTH', 'LAST_MONTH']} handler={(date) => handleQuickDateClick(date)} />
                    </div>
                </div>
            </div>

            <hr className=" mb-3" />

            <div className="m-2 ml-5 flex">
                <label className=" self-center">{t('Th_GroupName')}: </label>
                <label className=" self-center ml-2">{pageData.GroupName || '-'}</label>
                {permissionList.includes(PermissionsId.RelatedMembersEdit) && (
                    <div className=" ml-5">
                        <button
                            className="self-end p-2 bg-affiliate text-white rounded hover:bg-gray-500 disabled:bg-gray-500"
                            onClick={handleUpdateGroupNameClick}
                            disabled={pageData.GroupId === 0}>
                            <span className="px-1 icon icon-edit-profile" />
                            <label className="px-1 cursor-pointer">{t('Lbl_Update')}</label>
                        </button>
                    </div>
                )}
            </div>

            <div data-container className="mx-4 mb-5">
                <TableContainer>
                    <Table variant="striped" colorScheme="blackAlpha">
                        <Thead>
                            <Tr>
                                {col.map((item) => {
                                    return (
                                        <Th key={`relation-account-th-${item.label}`}>
                                            <span className="font-bold">{t(item.label)}</span>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {!!pageData &&
                                pageData.Details.length > 0 &&
                                pageData.Details.map((item, idx) => (
                                    <Tr key={`tr-key-${item.UserName}-${item.AgentNickName}-${item.PartnerId}`}>
                                        <Td>{idx + 1}</Td>
                                        <Td>{t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[item.PartnerId].text}`)}</Td>
                                        <Td>{item.AgentNickName}</Td>
                                        <Td textAlign="center">
                                            <Button
                                                colorScheme="blue"
                                                variant="link"
                                                onClick={() => {
                                                    handleMemberClick(item);
                                                }}>
                                                {item.UserName}
                                            </Button>
                                        </Td>
                                        <Td>{item.MemberNickname}</Td>
                                        <Td>{dayjs(item.RegisterDate).format('YYYY/MM/DD HH:mm:ss')}</Td>
                                        <Td textAlign="center">{item.VipLevel}</Td>
                                        <Td
                                            className={`${item.EffectiveStatus === StatusEnum.Effective ? 'text-red-500' : 'text-green-500'}`}
                                            textAlign="center">
                                            {t(`Txt_ValidStatusEnum_${item.EffectiveStatus}`)}
                                        </Td>
                                        <Td>{item.UnBetDays}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(item.FirstRechargeAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(item.RechargeAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(item.UpAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(item.WithdrawAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(item.BetAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>
                                            <NumberHighlight value={item.TotalWin} />
                                        </Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(item.BonusAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(item.RebateAmount, RoundType.Floor, 0))}</Td>
                                    </Tr>
                                ))}

                            {!!summaryData && !!pageData && pageData.Details.length > 0 && (
                                <>
                                    <Tr>
                                        <Td colSpan={6}>{t('Lbl_total-summary')}</Td>
                                        <Td />
                                        <Td />
                                        <Td />
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(summaryData.FirstChargeAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(summaryData.RechargeAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(summaryData.UpAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(summaryData.WithdrawAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(summaryData.BetAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>
                                            <NumberHighlight value={summaryData.TotalWin} />
                                        </Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(summaryData.BonusAmount, RoundType.Floor, 0))}</Td>
                                        <Td isNumeric>{numberComma(FloatHelper.floatFixed(summaryData.RebateAmount, RoundType.Floor, 0))}</Td>
                                    </Tr>
                                </>
                            )}

                            {!!pageData && pageData.Details.length === 0 ? (
                                <Tr>
                                    <Td fontSize="2xl" colSpan={col.length} className="text-center" textAlign="center">
                                        {t('Lbl_NoRecord')}
                                    </Td>
                                </Tr>
                            ) : (
                                <></>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>

            <NoteInfos notes={noteInfos} />

            <UpdateGroupNamePopup
                key={`popup-gnp-${groupPopupParam.isOpen}`}
                isOpen={groupPopupParam.isOpen}
                groupName={pageData.GroupName}
                onSave={(newName) => handleUpdateGroupName(newName)}
                onClose={() => setGroupPopupParam((pre) => ({ ...pre, isOpen: false }))}
            />

            {membersPopupParam.isOpen && (
                <UpdateGroupMembersPopup
                    key={`popup-gmp-${membersPopupParam.isOpen}`}
                    isOpen={membersPopupParam.isOpen}
                    groupAgentUserCode={props.AgentUserCode}
                    groupPartnerId={props.MemberPartnerId}
                    groupMemberName={props.MemberUserName}
                    onSave={(members) => handleUpdateGroupMembersOnSave(members)}
                    onClose={() => setMembersPopupParam((pre) => ({ ...pre, isOpen: false }))}
                />
            )}

            <MemberModel ref={ref} />
        </>
    );
};
