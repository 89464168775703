import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import TitleLabel from '@/components/titleLabel';
import { Checkbox, Divider, Heading, Input, Select, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text, Link } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberAdminTabs } from '../memberAdminTabs';
import { api } from '@/service/api';
import useFetchApi from '@/hooks/useFetchApi';
import LoadingMask from '@/components/loadingMask/loadingMask';
import DateRangePicker from '@/components/dateRangePicker';
import dayjs from 'dayjs';
import useMemberInfo from '@/hooks/useMemberInfo';
import MemberModel from '@/components/memberModel';
import { useDispatch, useSelector } from 'react-redux';
import { summonChat } from '@/store/slices/chat.slice';
import { RootState } from '@/store/store.config';
import { useAddVChatFriend } from '@/components/chatView/useAddVChatFriend';
import { DATETIME_FORMAT } from '@/constants/datetime';
import { useCommContext } from '@/hooks/useComm';

type IAffChatMember = {
    PartnerId: number;
    AgentUserCode: string;
    AgentUserId: string;
    AgentNickName: string;
    MemberName: string;
    MemberNickName: string;
    HeadImg: string;
    RegisterTime: string;
    PhoneNumber: string;
    VChatId: string;
    IsFriend: boolean;
};

type IVchatUserInfo = {
    Id: number;
    TelCode: string;
    Phone: string;
    FullPhoneNumber: string;
    Email: string;
    Gender: number;
    Country: string;
    Province: string;
    City: string;
    IsFriend: boolean;
    VChatId: string;
    RegisterCode: string;
    Address: string;
    HeadImg: string;
};

type ICrossSystemResponseModel = {
    AffMembers: IAffChatMember[];
    VChatMembers: IVchatUserInfo[];
};

type ICrossSystemRequestModel = {
    SearchType: CrossSystemSearchType;
    SearchKeys: string[];
    RegisterCode: string;
    DateFrom: string;
    DateTo: string;
};

type VChatMappingType = { Name: string; PartnerId: number };

enum CrossSystemSearchType {
    Phone,
    VChatId,
    RegisterCode,
}

const CrossSystemPage = () => {
    const { t } = useTranslation();
    const [queryModel, setQueryModel] = useState<Partial<ICrossSystemRequestModel>>({
        SearchType: CrossSystemSearchType.Phone,
        SearchKeys: [],
    });
    const [pageData, setPageData] = useState<ICrossSystemResponseModel>({ AffMembers: [], VChatMembers: [] });
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [displayMode, setDisplayMode] = useState({
        AFF: true,
        VChat: true,
    });
    const [vchatMapping, setVChatMapping] = useState<{ [key: string]: VChatMappingType[] }>({});
    const [searchKeyString, setSearchKeyString] = useState('');
    const [ref, onModelTrigger] = useMemberInfo();
    const ownVChatId = useSelector((state: RootState) => state.userStore.vChatId);
    const commAnnounce = useCommContext()

    const genderMap = useMemo<{ [key: string]: string }>(() => ({ '1': t('Txt_man'), '2': t('Txt_woman') }), []);

    function valueMap<T>(item: T, key: string): string {
        switch (key) {
            case 'Gender':
                return genderMap[item[key as keyof T] as string];
            default:
                return item[key as keyof T] as string;
        }
    }

    const triggerQueryData = useFetchApi<Partial<ICrossSystemRequestModel>, ICrossSystemResponseModel, ICrossSystemResponseModel>(
        api.crossSystemMember,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setIsLoading(false);

            if (!value.data || !value.data.IsSuccess) {
                setPageData({ AffMembers: [], VChatMembers: [] });
                return;
            }

            if (value.data.Data.AffMembers) {
                const mapping = value.data.Data.AffMembers.reduce(
                    (mapping, cur) => {
                        if (!!cur.VChatId) {
                            mapping[cur.VChatId] = mapping[cur.VChatId] || [];
                            mapping[cur.VChatId] = [
                                ...mapping[cur.VChatId],
                                {
                                    Name: cur.MemberName,
                                    PartnerId: cur.PartnerId,
                                },
                            ];
                        }

                        return mapping;
                    },
                    {} as Record<string, VChatMappingType[]>,
                );
                setVChatMapping(mapping);
            }

            if (value.data.Data.VChatMembers) {
                value.data.Data.VChatMembers = value.data.Data.VChatMembers.filter((item) => item.VChatId !== ownVChatId).map((item) => ({
                    ...item,
                    Address: `${item.Country}${item.Province}${item.City}`,
                    FullPhoneNumber: `${item.TelCode}${item.Phone}`,
                }));
            }

            setPageData(value.data.Data);
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    const onClickMemberInfo = (member: IAffChatMember) => {
        onModelTrigger(api.memberDetails, {
            userName: member.MemberName,
            partnerId: member.PartnerId,
        });
    };

    const [triggerAddVChatFriendHandler, isrAddVChatFriendLoading] = useAddVChatFriend({onResponded: (data) => {
            if (data.Data) {
                triggerQueryData(queryModel);
            }
        },});

    const onClickAddFriend = (vChatId: string) => {
        triggerAddVChatFriendHandler({ vChatId: vChatId });
    };

    const queryMetadata = useMemo(() => {
        return {
            [CrossSystemSearchType.Phone]: {
                placeHolder: '多号码查询请"," 分隔',
                info: '输入请包含国码, 范例: 86123456,886123111222',
            },
            [CrossSystemSearchType.VChatId]: {
                placeHolder: '多号码查询请"," 分隔',
                info: '输入范例: eskdskf,eowijew',
            },
            [CrossSystemSearchType.RegisterCode]: {
                placeHolder: '單一号码查询',
                info: '',
            },
        };
    }, []);

    const onQueryTypeChange = (value: CrossSystemSearchType) => {
        switch (value) {
            case CrossSystemSearchType.Phone:
                setQueryModel(() => ({ SearchType: value, SearchKeys: [] }));
                setSearchKeyString('');
                break;
            case CrossSystemSearchType.VChatId:
                setQueryModel(() => ({ SearchType: value, SearchKeys: [] }));
                setSearchKeyString('');
                break;
            case CrossSystemSearchType.RegisterCode:
                setQueryModel(() => ({
                    SearchType: value, RegisterCode: '',
                    DateFrom: dayjs().subtract(1, 'month').startOf('day').toString(),
                    DateTo: dayjs().endOf('day').toString(),
                }));
                setSearchKeyString('');
                break;
            default:
        }
    };

    const onQuerySearchKey = (searchType: CrossSystemSearchType, value: string) => {
        switch (searchType) {
            case CrossSystemSearchType.RegisterCode: {
                setQueryModel((pre) => ({ ...pre, RegisterCode: value }));
                break;
            }
            default: {
                const list = value.split(',').filter((str) => !!str && str.length !== 0);
                setQueryModel((pre) => ({ ...pre, SearchKeys: list }));
                break;
            }
        }
    };

    const onSearchKeyChange = (value: string) => {
        setSearchKeyString(value);
        onQuerySearchKey(queryModel.SearchType!, value);
    };

    const onSearch = () => {
        triggerQueryData(queryModel);
    };

    const onClickChat = (chat: IVchatUserInfo | IAffChatMember) => {
        dispatch(summonChat());
        commAnnounce?.({
            action: 'session',
            params: {
                type: 'session',
                id: chat.VChatId,
            },
        })
    };

    return (
        <>
            <LoadingMask visible={isLoading || isrAddVChatFriendLoading} />
            <MemberAdminTabs />
            <div className="my-4">
                <TitleLabel>{t('Nav_crossSystem')}</TitleLabel>
            </div>
            <div data-index-page>
                <div className="flex mb-3">
                    <div className="flex" data-search-area>
                        <div className="w-[130px] ml-5">
                            <Select onChange={(e) => onQueryTypeChange(Number(e.target.value))}>
                                {[CrossSystemSearchType.Phone, CrossSystemSearchType.VChatId, CrossSystemSearchType.RegisterCode].map((opt) => (
                                    <option key={`CSST-OPT-${opt}`} value={opt}>
                                        {t(`Lbl_CrossSystemSearchType_${opt}`)}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className="w-[230px] ml-2">
                            <Input
                                value={searchKeyString}
                                placeholder={queryMetadata[queryModel.SearchType!].placeHolder}
                                onChange={(e) => onSearchKeyChange(e.target.value)}
                            />
                        </div>
                        <div className="w-max-[530px] flex ml-2">
                            <Text fontSize="lg" className="self-center">
                                {queryMetadata[queryModel.SearchType!].info}
                            </Text>
                        </div>
                        {queryModel.SearchType === CrossSystemSearchType.RegisterCode && (
                            <div className="w-[530px]">
                                <DateRangePicker
                                    className="w-[240px] min-w-[240px]"
                                    labelClassName='justify-start'
                                    defaultDate={{ start: queryModel.DateFrom, end: queryModel.DateTo }}
                                    format='YMD'
                                    shortcuts={false}
                                    label={`& ${t('Th_registerDate')}`}
                                    minDate={dayjs(queryModel.DateTo).subtract(90, 'd').endOf('day').format(DATETIME_FORMAT.YMDHMSS)}
                                    withoutI18n={false}
                                    valueHandler={({ startDate, endDate }) => {
                                        setQueryModel((pre) => ({
                                            ...pre,
                                            DateFrom: dayjs(startDate).startOf('day').format(DATETIME_FORMAT.YMDHMSS),
                                            DateTo: dayjs(endDate).endOf('day').format(DATETIME_FORMAT.YMDHMSS),
                                        }));
                                    }} />
                            </div>
                        )}
                    </div>
                    <div className="ml-10">
                        <button className="p-2 bg-affiliate text-white rounded hover:bg-gray-500" onClick={() => onSearch()}>
                            <span className="px-1 icon icon-search" />
                            <label className="px-1 cursor-pointer">{t('Lbl_search')}</label>
                        </button>
                    </div>
                </div>

                <Divider size="3xl" color="red.900" />

                <div className=" max-h-[700px] overflow-y-auto">
                    <div className="m-2 ml-5">
                        <Stack spacing={5} direction="row">
                            <Checkbox colorScheme="blue" defaultChecked onChange={(e) => setDisplayMode((pre) => ({ ...pre, ...{ AFF: e.target.checked } }))}>
                                {t('Txt_AFF')}
                            </Checkbox>
                            <Checkbox colorScheme="blue" defaultChecked onChange={(e) => setDisplayMode((pre) => ({ ...pre, ...{ VChat: e.target.checked } }))}>
                                {t('Txt_VChat')}
                            </Checkbox>
                        </Stack>
                    </div>
                    <div>
                        {displayMode.AFF && (
                            <div data-container className="mx-4 mb-5">
                                <Heading as="h5" size="sm">
                                    {t('Txt_AFF')}
                                </Heading>
                                <TableContainer>
                                    <Table variant="striped" colorScheme="blackAlpha">
                                        <Thead>
                                            <Tr>
                                                {affCol.map((item) => {
                                                    return (
                                                        <Th key={`aff-th-${item.label}`}>
                                                            <span className="font-bold">{t(item.label)}</span>
                                                        </Th>
                                                    );
                                                })}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {!!pageData &&
                                                pageData.AffMembers.length > 0 &&
                                                pageData.AffMembers.map((item, idx) => (
                                                    <Tr key={`tr-key-${item.VChatId}-${item.AgentNickName}-${item.PartnerId}`}>
                                                        <>
                                                            <Td>{idx + 1}</Td>
                                                            <Td>{t('Txt_AFF')}</Td>
                                                            <Td>{t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[item.PartnerId].text}`)}</Td>
                                                            {affCol
                                                                .filter((col) => !['Id', 'optional', 'System', 'PartnerId'].includes(col.name))
                                                                .map((col) => {
                                                                    switch (col.name) {
                                                                        case 'MemberName':
                                                                            return (
                                                                                <Td key={`td-aff-key-${idx}-${col.name}`} data-tkey="MemberName">
                                                                                    <Link color="blue.500" onClick={() => onClickMemberInfo(item)}>
                                                                                        {valueMap(item, col.name)}
                                                                                    </Link>
                                                                                </Td>
                                                                            );
                                                                        default:
                                                                            return (
                                                                                <Td key={`td-aff-key-${idx}-${col.name}`} data-tkey={col.name}>
                                                                                    {valueMap(item, col.name)}
                                                                                </Td>
                                                                            );
                                                                    }
                                                                })}
                                                            <Td className="flex gap-2">
                                                                {item.IsFriend ? (
                                                                    <span
                                                                        className="icon icon-chat-list bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 "
                                                                        onClick={() => {
                                                                            onClickChat(item);
                                                                        }} />
                                                                ) : !!item.VChatId ? (
                                                                    <span
                                                                        className="icon icon-user-plus bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 "
                                                                        onClick={() => {
                                                                            onClickAddFriend(item.VChatId);
                                                                        }} />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Td>
                                                        </>
                                                    </Tr>
                                                ))}
                                            {!pageData ||
                                                (pageData.AffMembers.length === 0 && (
                                                    <Tr>
                                                        <Td colSpan={affCol.length} className="text-center" textAlign="center">
                                                            {t('Lbl_NoRecord')}
                                                        </Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </div>
                    <div>
                        {displayMode.VChat && (
                            <div data-container className="mx-4 mb-5">
                                <Heading as="h5" size="sm">
                                    {t('Txt_VChat')}
                                </Heading>
                                <TableContainer>
                                    <Table variant="striped" colorScheme="blackAlpha">
                                        <Thead>
                                            <Tr>
                                                {chatCol.map((item) => {
                                                    return (
                                                        <Th key={`vchat-th-${item.label}`}>
                                                            <span className="font-bold">{t(item.label)}</span>
                                                        </Th>
                                                    );
                                                })}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {!!pageData &&
                                                pageData.VChatMembers.length > 0 &&
                                                pageData.VChatMembers.map((item, idx) => (
                                                    <Tr key={`tr-key-${item.VChatId}-${item.Address}-${item.FullPhoneNumber}`}>
                                                        <>
                                                            <Td>{idx + 1}</Td>
                                                            <Td>{t('Txt_VChat')}</Td>
                                                            {chatCol
                                                                .filter((col) => !['Id', 'optional', 'System', 'PartnerId'].includes(col.name))
                                                                .map((col) => (
                                                                    <Td key={`td-v6-key-${col.name}-${item.VChatId}`}>{valueMap(item, col.name)}</Td>
                                                                ))}
                                                            <Td>
                                                                {vchatMapping[item.VChatId] ? (
                                                                    <>
                                                                        {t('Th_PartnerAndMember')}:
                                                                        {vchatMapping[item.VChatId].map((vit) => (
                                                                            <span key={`sp-vit-${item.VChatId}-${vit.PartnerId}`}>
                                                                                <br />
                                                                                {vit.Name}({t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[vit.PartnerId].text}`)})
                                                                            </span>
                                                                        ))}
                                                                    </>
                                                                ) : item.IsFriend ? (
                                                                    <>
                                                                        <span
                                                                            className="icon icon-chat-list bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 "
                                                                            onClick={() => {
                                                                                onClickChat(item);
                                                                            }} />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span
                                                                            className="icon icon-user-plus bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 "
                                                                            onClick={() => {
                                                                                onClickAddFriend(item.VChatId);
                                                                            }} />
                                                                    </>
                                                                )}
                                                            </Td>
                                                        </>
                                                    </Tr>
                                                ))}

                                            {!pageData ||
                                                (pageData.VChatMembers.length === 0 && (
                                                    <Tr>
                                                        <Td colSpan={chatCol.length} className="text-center" textAlign="center">
                                                            {t('Lbl_NoRecord')}
                                                        </Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <MemberModel ref={ref} />
        </>
    );
};

export default CrossSystemPage;

const affCol = [
    { label: 'Lbl_serial_number', name: 'Id' },
    { label: 'Th_System', name: 'System' },
    { label: 'Th_partnerId', name: 'PartnerId' },
    { label: 'Th_agentNickName', name: 'AgentNickName' },
    { label: 'Th_userName', name: 'MemberName' },
    { label: 'Th_UserNickname', name: 'MemberNickName' },
    { label: 'Lbl_RegistTime', name: 'RegisterDateTime' },
    { label: 'Th_PhoneNumber', name: 'PhoneNumber' },
    { label: 'Th_VChatId', name: 'VChatId' },
    { label: 'Lbl_optional', name: 'optional' },
];

const chatCol = [
    { label: 'Lbl_serial_number', name: 'Id' },
    { label: 'Th_System', name: 'System' },
    { label: 'Th_VChatId', name: 'VChatId' },
    { label: 'Th_PhoneNumber', name: 'FullPhoneNumber' },
    { label: 'Th_UserNickname', name: 'NickName' },
    { label: 'Lbl_RegistTime', name: 'RegisterDateTime' },
    { label: 'Th_RegistrationCode', name: 'RegisterCode' },
    { label: 'Lbl_optional', name: 'optional' },
];
