import { MemberAdminPageEnum, MemberDetailParams } from '@/models/memberAdmin';
import { Card, CardBody, CardHeader, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { IMemberAdminPageProps, memberAdminPageConfig } from './pageConfig';
import { useTranslation } from 'react-i18next';

import MemberDetailsHeader from './memberDetailsHeader';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';

type Props = {
    pageType: MemberAdminPageEnum;
    data?: MemberDetailParams;
};
const MemberDetails = ({ data, pageType }: Props) => {
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const [hasVChatId, setVChatVisible] = useState(data?.hasVChatId);
    const { t } = useTranslation();
    const [memberDetailsHeaderProps, setMemberDetailsHeaderProps] = useState<IMemberAdminPageProps>({
        AgentUserCode: data?.agentUserCode || '',
        MemberPartnerId: data?.partnerId || 0,
        MemberUserName: data?.memberCode || '',
    });

    const [tabIndex, setTabIndex] = useState(pageType);
    const enableTabList = useMemo(() => {
        return Object.values(memberAdminPageConfig).filter((item) => {
            const chatVisible = item.id === MemberAdminPageEnum.CHAT ? !!hasVChatId : true;
            return permissionList.includes(item.permission) && chatVisible;
        });
    }, [permissionList, hasVChatId]);

    const defaultPageIndex = useMemo(() => {
        return enableTabList.findIndex((v) => v.id === pageType);
    }, [enableTabList, pageType]);

    const dynamicTag = useMemo(() => {
        return enableTabList.map((item, _index) => {
            const Component = item.component;
            const props: IMemberAdminPageProps = {
                AgentUserCode: data?.agentUserCode || '',
                MemberPartnerId: data?.partnerId || 0,
                MemberUserName: data?.memberCode || '',
                Reload: () => {
                    setMemberDetailsHeaderProps((v) => ({ ...v }));
                },
                setVChatVisible,
            };
            return <TabPanel key={`panel-${item.name}`}>{tabIndex === item.id && <Component {...props}></Component>}</TabPanel>;
        });
    }, [data, tabIndex, enableTabList, setVChatVisible]);

    return (
        <>
            <Card margin={2}>
                <CardHeader>
                    <MemberDetailsHeader {...memberDetailsHeaderProps}></MemberDetailsHeader>
                </CardHeader>
                <CardBody>
                    <Tabs
                        isLazy={false}
                        variant="enclosed"
                        defaultIndex={defaultPageIndex}
                        onChange={(index) => {
                            setTabIndex(enableTabList[index].id);
                        }}>
                        <TabList>
                            {enableTabList.map((item) => {
                                return (
                                    <Tab
                                        key={`memberAdminPage-${item.name}`}
                                        borderColor={'gray.300'}
                                        backgroundColor={'gray.100'}
                                        _selected={{ color: 'gray.50', bg: 'gray.500' }}>
                                        {t(item.name)}
                                    </Tab>
                                );
                            })}
                        </TabList>

                        <TabPanels>{dynamicTag}</TabPanels>
                    </Tabs>
                </CardBody>
            </Card>
        </>
    );
};

export default MemberDetails;
