import { useLocation, useRoutes } from 'react-router-dom';
import useInterceptors from '@/hooks/useInterceptors';
import getRoutes from '@/assets/config/router/routes.config';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { getApiConfig } from '@/store/slices/config.slice';
import { AppState, RootState } from '@/store/store.config';
import VConsole from 'vconsole';
import { IUserInfo } from '@/models/entities/IUserInfo';
import { permissionGuards } from '@/service/guardsHelper';
import useUserAccountsVchatInfo from '@/hooks/useUserAccountsVchatInfo';
import { updateHasRechargeOrder, updateUserVChatInfos } from '@/store/slices/user.slice';
import useRechargeOrders from '@/hooks/useRechargeOrders';
import { PermissionsId } from '@/models/permissions';

function App() {
    useInterceptors();
    const location = useLocation();

    const userInfo = useSelector<RootState, IUserInfo>((store) => store.userStore);
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const userName = useSelector<RootState, string>((store) => store.userStore.userName);
    const routes = getRoutes(permissionGuards(permissionList));
    const router = useRoutes(routes);
    const dispatch = useDispatch();

    const currentEnv = useSelector<AppState>((store) => store.configStore.apiHost?.env || '');
    const title = useRef(window.document.title);
    const [userAccountsVChatInfos, detachGetItems] = useUserAccountsVchatInfo();
    const [hasRechargeItems, detachAction] = useRechargeOrders();

    useEffect(() => {
        const v = currentEnv !== '' ? `-${currentEnv}` : '';
        window.document.title = `${title.current}${v}`;
    }, [currentEnv]);

    useEffect(() => {
        const usercode = new URLSearchParams(location.search).get('usercode');
        if (!!usercode && userName.toLowerCase() === usercode.toLowerCase()) {
            const vConsole = new VConsole();
        }
    }, []);

    useEffect(() => {
        dispatch(getApiConfig());
    }, []);

    useEffect(() => {
        const id = setInterval(
            () => {
                detachGetItems({});
            },
            5 * 60 * 1000,
        );

        return () => {
            clearInterval(id);
        };
    }, []);

    useEffect(() => {
        dispatch(updateUserVChatInfos(userAccountsVChatInfos));
    }, [userAccountsVChatInfos]);

    useEffect(() => {
        if (!permissionList.includes(PermissionsId.MemberRecharge)) {
            return;
        }
        const id = setInterval(() => {
            detachAction();
        }, 30 * 1000);

        return () => {
            clearInterval(id);
        };
    }, []);

    useEffect(() => {
        dispatch(updateHasRechargeOrder(hasRechargeItems));
    }, [hasRechargeItems]);

    // useEpic()

    return <>{router}</>;
}

export default App;
