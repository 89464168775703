/* eslint-disable camelcase */
import LoadingMask from '@/components/loadingMask/loadingMask';
import Pagination from '@/components/pagination';
import SearchColumn from '@/components/searchArea/searchColumn';
import TableColumnComp from '@/components/tableColumnComp';
import TitleLabel from '@/components/titleLabel';
import { DATETIME_FORMAT } from '@/constants/datetime';
import useFetchApi from '@/hooks/useFetchApi';
import { IBaseRequestData } from '@/models/apiRequest';
import { DEFAULT_PAGE_COUNT } from '@/models/apiRequest/baseRequest';
import { ITableLayoutData, TableSearch, TableSearchEnum } from '@/models/layout/tableLayout';
import { api } from '@/service/api';
import { auditLogs, AuditLogsData } from '@/service/modelTransfer/systemSetting/auditLogs';
import { alignCenterStyles, numericStyles, toFirstUpperCase } from '@/service/utils';
import dayjs from 'dayjs';
import { pluckFirst, useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { withLatestFrom } from 'rxjs';
import { ISystemSettingLayoutProps } from '../../layout.mapping';
interface IRequest extends IBaseRequestData {
    Target: string;
    IsTargetFuzzySearch: boolean;
    FunctionId: number | string;
    OperatorAccount: string;
    IsOperatorAccountFuzzySearch: boolean;
    PageNo: number;
    PageSize: number;
}
const initParams: IRequest = {
    DateFrom: dayjs().format(DATETIME_FORMAT.YMD),
    DateTo: dayjs().format(DATETIME_FORMAT.YMD),
    PartnerId: 0,
    OperatorAccount: '',
    IsOperatorAccountFuzzySearch: false,
    FunctionId: '0',
    Target: '',
    IsTargetFuzzySearch: false,
    PageNo: 1,
    PageSize: DEFAULT_PAGE_COUNT,
    UserId: 1,
};
const search: TableSearch[][] = [
    [
        {
            type: TableSearchEnum.DATE_RANGE,
            dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo', shortcuts: false },
            label: '操作时间段',
        },
        {
            type: TableSearchEnum.INPUT,
            matchKey: 'target',
            label: '修改帐号',
            placeholder: '请输入帳號',
            hasFuzzy: true,
            fuzzyKey: 'IsTargetFuzzySearch',
        },
    ],
    [
        {
            type: TableSearchEnum.SELECT,
            label: '項目',
            matchKey: 'functionId',
            selectOptions: [
                { id: 0, text: '不限', withoutI18n: true },
                { id: 32, text: '子帐号管理', withoutI18n: true },
            ],
        },
        {
            type: TableSearchEnum.INPUT,
            matchKey: 'operatorAccount',
            label: '操作者',
            placeholder: '请输入操作者',
            hasFuzzy: true,
            fuzzyKey: 'IsOperatorAccountFuzzySearch',
        },
    ],
];
const AuditLog = ({ type }: ISystemSettingLayoutProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState<IRequest>(initParams); // 紀錄搜尋列表的參數用
    const [requestData, setRequestData] = useState<IRequest>(searchData); // 紀錄 fetch api Request的值
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState<ITableLayoutData>();
    const [searchAreaHandler, searchChangeHandler$] = useObservableCallback<{ [key: string]: any }>((event$) => {
        return event$;
    });

    const fetchHandler = useFetchApi<IRequest, AuditLogsData, any>(
        api.getAuditLogList,
        ({ value }) => {
            const resultRequest: IRequest = value;
            resultRequest.DateFrom = dayjs(resultRequest.DateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            resultRequest.DateTo = dayjs(resultRequest.DateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');

            setIsLoading(true);
            return resultRequest;
        },
        ({ value, request }) => {
            let _data: ITableLayoutData;
            if (value) {
                const offset = (request.PageNo ? (request.PageNo - 1) * (request.PageSize || 1) : 0)
                _data = auditLogs(value.data.Data, t, offset);

                setResponseData(_data);
            }
            setIsLoading(false);
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        // init fetch
        fetchHandler(requestData);
    }, [type]);

    const col = useMemo(() => {
        return responseData?.tableHeader || [];
    }, [responseData]);

    const body = useMemo(() => {
        return responseData?.tableBody || [];
    }, [responseData]);

    const configSearch$ = useObservable(pluckFirst, [search]);
    const searchData$ = useObservable(pluckFirst, [searchData]);

    // 按下搜尋鈕後會觸發
    useSubscription(searchChangeHandler$.pipe(withLatestFrom(configSearch$, searchData$)), ([searchRef, configSearchData, _searchData]) => {
        if (responseData && responseData?.totalRecords) {
            responseData.totalRecords = 0;
        }

        const resultData = { ...initParams, ..._searchData, PageNo: 1 }; // 每次搜尋後頁數要reset
        const setData = (searchItem: TableSearch, _data: IRequest) => {
            const _searchItem = searchItem;
            if (_searchItem.matchKey) {
                (_data as any)[toFirstUpperCase(_searchItem.matchKey)] = searchRef[_searchItem.matchKey];
                const fuzzySearchKey = !!_searchItem.fuzzyKey ? _searchItem.fuzzyKey : 'IsFuzzySearch';
                _searchItem.hasFuzzy && ((_data as any)[fuzzySearchKey] = !!searchRef[fuzzySearchKey]);
            } else if (_searchItem.dateMatchKey) {
                const startName = _searchItem.dateMatchKey!['startDate'] || 'startDate';
                const endName = _searchItem.dateMatchKey!['endDate'] || 'endDate';
                (_data as any)[startName] = searchRef[startName];
                (_data as any)[endName] = searchRef[endName];
            }
        };

        configSearchData?.forEach((searchItem) => {
            if (Array.isArray(searchItem)) {
                const _searchItemList = searchItem as TableSearch[];
                _searchItemList.forEach((_searchItem) => {
                    setData(_searchItem, resultData);
                });
                return;
            }
            setData(searchItem, resultData);
        });

        setSearchData({ ...resultData });
        setRequestData(resultData);
        fetchHandler(resultData);
    });

    return (
        <>
            <div className="flex justify-between my-4">
                <TitleLabel>{t('Nav_AuditLog')}</TitleLabel>
            </div>
            <div data-index-page>
                <div data-search-area>
                    <SearchColumn
                        defaultValue={requestData}
                        search={search}
                        handler={(data) => {
                            searchAreaHandler(data);
                        }}
                        layout="justify-start" />
                </div>
            </div>
            {responseData && responseData?.totalRecords > 0 && (
                <div data-table-container className="m-2 ">
                    <div className="table w-full min-w-[300px]">
                        <div className="table-header-group bg-gray-100 text-xs">
                            <div className="table-row ">
                                {col
                                    .filter((item) => !item.invisible)
                                    .map((item, i) => {
                                        return (
                                            <div
                                                key={`table-header-${i}`}
                                                className={`table-cell p-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                    alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                }`}>
                                                {t(item.text)}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>

                        <div className="table-row-group">
                            {body.map((hash, pi) => {
                                return (
                                    <Fragment key={`ff-${pi}`}>
                                        <div className="table-row odd:bg-gray-200 even:bg-slate-50" key={`table-td-tr-${pi}`}>
                                            {col
                                                .filter((item) => !item.invisible)
                                                .map((item, i) => {
                                                    return (
                                                        <div
                                                            className={`table-cell py-4 px-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                                alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                            }`}
                                                            key={`tbody-${item.name}`}>
                                                            <TableColumnComp text={hash[item.name]} style={item.type} />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

            {!isLoading && responseData && responseData?.totalRecords <= 0 && (
                <div className="my-6 flex justify-center">
                    <span className="text-4xl text-gray-400 icon icon-notification">{t('Lbl_NoRecord')}</span>
                </div>
            )}

            <div data-pagination className="mt-2 flex justify-center">
                <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord').replace('{0}', `${responseData?.totalRecords || 0}`)}</span>
                <Pagination
                        key={`member-admain-pagination-${responseData?.totalRecords || 1}`}
                        pageCount={Math.ceil((responseData?.totalRecords || 1) / DEFAULT_PAGE_COUNT)}
                        onPageChange={(e) => {
                            setSearchData((data) => {
                                if (data) {
                                    const result = { ...data, PageNo: e.selected + 1 };
                                    fetchHandler(result);
                                    return result;
                                }

                                return data;
                            });
                        }} />
            </div>

            <LoadingMask visible={isLoading} />
        </>
    );
};

export default AuditLog;
