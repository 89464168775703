import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';

export interface IGetVChatTokenResponse {
    Token: string;
}

export enum GetVChatTokenStatus {
    SUCCESS,
    NOT_FRIEND,
}

export function useChatViewUrl() {
    const vChatEndpoint = useSelector((state: RootState) => state.configStore.apiHost?.vchat ?? '');
    const userVChatId = useSelector((state: RootState) => state.userStore.vChatId);
    const [chatViewUrl, setChatViewUrl] = useState<string | undefined>(undefined);
    const [error, setError] = useState<GetVChatTokenStatus | string | null>(null);

    const fetchApi = useFetchApi<Record<string, never>, IGetVChatTokenResponse>(
        api.getVChatToken,
        ({ value }) => {
            setChatViewUrl(undefined);
            return { ...value };
        },
        ({ value }) => {
            if (value.data.IsSuccess) {
                const token = value.data.Data.Token;
                setChatViewUrl(`${vChatEndpoint}/Home/LaunchChat?sourceVChatId=${userVChatId}&token=${token}`)
            } else if (value.data.Message === '200003') {
                setError(GetVChatTokenStatus.NOT_FRIEND);
            } else {
                setError(value.data.Message);
            }
        },
        [],
        () => {},
    );

    return [
        chatViewUrl,
        fetchApi,
        error,
    ] as const;
}
