import { IMemberAdminPageProps } from '@/pages/memberAdmin/admin/pageConfig';
import { useTranslation } from 'react-i18next';


import { useEffect, useState } from 'react';
import { api } from '@/service/api';
import { ChatView } from '@/components/chatView';
import useFetchApi from '@/hooks/useFetchApi';
import { IMemberRequestData } from '@/models/apiRequest';
import { ContactTypeEnum, ContactTypeGroupEnum, IMemberDetailsData } from '@/models/page/report/IReportMemberDetails';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from '@/constants/datetime';
import LoadingMask from '@/components/loadingMask/loadingMask';

export default function MemberAdminChatPage({
    AgentUserCode,
    MemberPartnerId,
    MemberUserName
                                            }: IMemberAdminPageProps) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [chatId, setChatId] = useState<string | null>(null);

    const detectChangeAndFetchHandler = useFetchApi<IMemberRequestData, IMemberDetailsData>(
        api.memberDetails,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value: resp }) => {
            setIsLoading(false);
            const { Contacts } = resp.data.Data;

            const vchatContact = Contacts.find(c => c.ContactTypeGroupId === ContactTypeGroupEnum.PersonalContact && c.ContactTypeId === ContactTypeEnum.VChat);
            if (vchatContact) {
                setChatId(vchatContact.ContactContent);
            }
        },
        [],
        (error) => {
            setIsLoading(false);
        }
    );

    useEffect(() => {
        detectChangeAndFetchHandler({
            DateFrom: dayjs().subtract(1, 'day').format(DATETIME_FORMAT.YMD),
            DateTo: dayjs().format(DATETIME_FORMAT.YMD),
            UserName: MemberUserName,
            PartnerId: MemberPartnerId
        });
    }, []);

    return (
        <>
            <div className="text-lg my-4 px-4 font-bold border-l-8 border-blue-400">
                <span>{t('Th_MemberAdmin_Chat')}</span>
            </div>

            <ChatView
                chatId={chatId}
                userName={MemberUserName}
                partnerId={MemberPartnerId}
                agentUserCode={AgentUserCode} />

            <LoadingMask visible={isLoading} />
        </>
    );
}
