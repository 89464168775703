import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';

import { IReportGameDetail, IReportGameDetailData } from '@/models/page/report/IReportGame';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function gameDetailsData(input: IReportGameDetail): ITableLayoutData {
    const outputConfig: TableConfig<IReportGameDetailData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'userName', text: 'Th_userName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'effectAmount', text: 'Th_betAmount', itemKeys: ['EffectAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'validAmount', text: 'Th_validAmount', itemKeys: ['ValidAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'prize', text: 'Th_prize', itemKeys: ['Prize'], type: TableColumnStyle.CommaNumeric },
            { name: 'totalWin', text: 'Th_totalWin', itemKeys: ['TotalWin'], type: TableColumnStyle.CommaNumericWithSymbol },
        ],
        footer: ['effectAmount', 'prize', 'validAmount', 'totalWin'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.TotalRecord, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
