import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ProfileTabs } from '@/pages/profile/profileTabs/profileTabs';
import TitleLabel from '@/components/titleLabel';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { UpdatePasswordModal } from '@/pages/profile/passwordManager/updatePasswordModal';

const PasswordManagerPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ProfileTabs />

            <div className="my-4">
                <TitleLabel>
                    {t('Txt_PasswordManager')}
                </TitleLabel>
            </div>
            <LoadingMask visible={isLoading} />

            <div className="w-[620px] bg-slate-200 p-2 mt-3 rounded-lg ml-4">
                <div className="flex items-center">
                    <div className="basis-1/6">
                        <div className="">
                            <span className="bg-gradient-to-r  text-7xl  text-gray-500 icon icon-keepass" />
                        </div>
                    </div>
                    <div className="flex-col items-center basis-2/3">
                        <div className="font-bold ">
                            {t('Lbl_UpdateBrandPassword')}
                        </div>
                        <div className=" mt-1">
                            {t('Msg_UpdateBrandPassword_Info')}
                        </div>
                    </div>
                    <div className="basis-1/6">
                        <button
                            className="p-2 bg-affiliate text-white rounded hover:bg-gray-500 w-30"
                            onClick={() => setIsOpen(true)}>
                            <label className="px-1 cursor-pointer">
                                {t('Lbl_UpdatePassword')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>

            <UpdatePasswordModal
                onSave={console.debug}
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }} />
        </>
    );
}

export default PasswordManagerPage;
