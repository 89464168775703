import { agentLevelData } from './agentLevel';
import { agentRechargeData } from './agentRecharge';
import { chargeAndWithdrawData } from './chargeAndWithdraw';
import { commissionData } from './commission';
import { commissionMemberData } from './commissionMember';
import { commissionMemberDetailData } from './commissionMemberDetail';
import { depositDetailData } from './depositDetail';
import { fundData } from './fund';
import { funNewAccountData } from './fundNewAccount';
import { gameData } from './game';
import { gameDetailsData } from './gameDetails';
import { memberData } from './member';
import { memberFirstChargeData } from './memberFirstCharge';
import { rebateData } from './rebate';
import { rebateBonusUserDetailData } from './rebateBonusUserDetail';
import { rebateUserDetailData } from './rebateUserDetail';
import { rechargeWthdrawFeeData } from './rechargeWthdrawFee';
import { relationMember } from './relationMember';
import { relationMemberDetail } from './relationMemberDetail';
import { teamData } from './team';
import { withdrawUserData } from './withdrawUser';

const reportTransferFun = {
    agentLevelData,
    agentRechargeData,
    chargeAndWithdrawData,
    commissionData,
    commissionMemberData,
    commissionMemberDetailData,
    depositDetailData,
    fundData,
    funNewAccountData,
    gameData,
    gameDetailsData,
    memberData,
    memberFirstChargeData,
    rebateData,
    rebateBonusUserDetailData,
    rebateUserDetailData,
    rechargeWthdrawFeeData,
    teamData,
    withdrawUserData,
    relationMember,
    relationMemberDetail,
};

export default reportTransferFun;
