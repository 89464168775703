import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import { APIModel } from '@/models/apiRequest';
import { ITableLayoutData, TableColumnStyle } from '@/models/layout/tableLayout';
import { numericStyles, parseCommissionType } from '@/service/utils';
import {
    Text,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Card,
    CardBody,
    Box,
    Td,
    Button,
    Stack,
    StackDivider,
    Heading,
    Skeleton,
} from '@chakra-ui/react';
import { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { HistoryTableLayoutConfig, historyTableLayoutConfig, HistoryTableLayoutEnum } from './layout';
import TableColumnComp from '../tableColumnComp';

export type HistoryModelRef = {
    onModelDataTrigger<T>(api: APIModel, request: T, layoutId: HistoryTableLayoutEnum): void;
};

const HistoryModel = forwardRef<HistoryModelRef>((props, ref) => {
    const configs = useRef(historyTableLayoutConfig);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isLoaded, setIsLoaded] = useState(false);
    const { t } = useTranslation();
    const [currentResp, setResp] = useState<any>({});
    const [currentConfig, setConfig] = useState<HistoryTableLayoutConfig>();
    const [currentTableData, setTableData] = useState<ITableLayoutData>();

    const detectChangeAndFetchHandler = useFetchApi(
        { path: '', method: 'GET' },
        ({ value }) => {
            setIsLoaded(false);
            return value;
        },
        ({ value: resp }) => {
            setIsLoaded(true);
            setResp(resp.data);
        },
        [],
        (error) => {
            setResp({ Data: [] });
            setIsLoaded(false);
        },
    );

    const parseDataFun = useMemo(() => {
        return currentConfig?.parseFetchDataFun;
    }, [currentConfig]);

    useEffect(() => {
        setTableData(parseDataFun?.(currentResp));
    }, [currentResp]);

    useEffect(() => {}, [currentTableData]);

    const col = useMemo(() => {
        return currentTableData?.tableHeader || [];
    }, [currentTableData]);

    const body = useMemo(() => {
        return currentTableData?.tableBody || [];
    }, [currentTableData]);

    useImperativeHandle(ref, () => ({
        onModelDataTrigger: (api, request, layoutId) => {
            setConfig(configs.current.find((config) => config.layoutId === layoutId));
            detectChangeAndFetchHandler({ ...request, api });

            onOpen();
        },
    }));

    return (
        <>
            <Modal onClose={onClose} size="6xl" isOpen={isOpen} isCentered scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className=" rounded-t">
                        <Card>
                            <CardBody className="bg-gray-100">
                                <Box>{t(`Th_${currentConfig?.pageName}`)}</Box>
                                <div className="flex justify-end">
                                    <Stack direction="row" divider={<StackDivider />} spacing="10">
                                        {currentConfig?.headerText?.map((keyItem, i) => {
                                            const item = body[0] ? body[0][keyItem.key] || '' : '';
                                            const content = parseCommissionType(item, keyItem?.type || TableColumnStyle.Text) || 'NA';

                                            return (
                                                <Box key={`history-model-header-text-${i}`}>
                                                    <Heading size="xs" textTransform="uppercase">
                                                        {keyItem.title}
                                                    </Heading>
                                                    <Skeleton isLoaded={isLoaded}>
                                                        <Text pt="2" fontSize="lg" className="text-gray-500 font-bold">
                                                            {keyItem?.type === TableColumnStyle.Partner ? t(content + '') : content}
                                                        </Text>
                                                    </Skeleton>
                                                </Box>
                                            );
                                        })}
                                    </Stack>
                                </div>
                            </CardBody>
                        </Card>
                    </ModalHeader>
                    {/* <ModalCloseButton className="text-gray-100" /> */}
                    <ModalBody>
                        <Skeleton height="588px" isLoaded={isLoaded}>
                            <Table variant="striped" colorScheme="blackAlpha">
                                <Thead>
                                    <Tr>
                                        <Th />
                                        {col
                                            .filter((item) => !item.invisible)
                                            .map((item, i) => {
                                                return (
                                                    <Th
                                                        key={`history-table-header-${i}`}
                                                        className="min-w-[150px]"
                                                        isNumeric={numericStyles.includes(item.type)}>
                                                        {t(item.text)}
                                                    </Th>
                                                );
                                            })}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {body.map((hash, pi) => {
                                        return (
                                            <Fragment key={`history-table-fragment-${pi}`}>
                                                <Tr key={`history-table-td-tr-${pi}`}>
                                                    <Td>{pi === 0 ? <span className="icon icon-target-checked text-gray-500" /> : null}</Td>
                                                    {col
                                                        .filter((item) => !item.invisible)
                                                        .map((item, i) => {
                                                            return (
                                                                <Td key={`history-tbody-${item.name}`} isNumeric={numericStyles.includes(item.type)}>
                                                                    <TableColumnComp text={hash[item.name]} style={item.type} />
                                                                </Td>
                                                            );
                                                        })}
                                                </Tr>
                                            </Fragment>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </Skeleton>
                    </ModalBody>
                    <ModalFooter>
                        <div className="w-full flex flex-col">
                            <div>
                                <span className="icon icon-target-checked text-gray-500"></span>
                                <span>目前使用此笔纪录做差异比对</span>
                            </div>
                            <div className="flex justify-center">
                                <Button colorScheme={'orange'} onClick={onClose}>
                                    确认
                                </Button>
                            </div>
                        </div>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
});

export default HistoryModel;
