import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { RelationMemberDetailData, RelationMemberDetailItem, ValidStatusEnum } from '@/models/page/report/IRelationMember';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

// need fix type
export function relationMemberDetail(input: RelationMemberDetailData, pageNo?: number, pageSize?: number): ITableLayoutData {
    const outputConfig: TableConfig<RelationMemberDetailItem> = {
        column: [
            { name: 'serialId', text: 'Lbl_serial_number', itemKeys: ['SerialId'], type: TableColumnStyle.Text },
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'userName', text: 'Th_userName', itemKeys: ['MemberCode'], type: TableColumnStyle.Text },
            { name: 'memberNickName', text: 'Txt_NickName', itemKeys: ['MemberNickName'], type: TableColumnStyle.Text },
            { name: 'registerDate', text: 'Th_registerDate', itemKeys: ['RegisterDate'], type: TableColumnStyle.Date },

            { name: 'vipLevel', text: 'Th_vipLevel', itemKeys: ['VipLevel'], type: TableColumnStyle.Numeric },
            {
                name: 'currentValidMember',
                text: 'Th_CurrentValidMember',
                itemKeys: ['EffectiveStatus'],
                type: TableColumnStyle.TextI18NWithColor,
                calculate: (data, keys) => {
                    const colorMap = new Map<number, string>([
                        [ValidStatusEnum.InValid, 'text-green-500'],
                        [ValidStatusEnum.Valid, 'text-red-500'],
                    ]);
                    return `Txt_ValidStatusEnum_${data.EffectiveStatus}_with_${colorMap.get(data.EffectiveStatus)}`;
                },
            },

            { name: 'depositAmount', text: 'Lbl_RechargeSumForRelation', itemKeys: ['RechargeAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'rechargeAmount', text: 'Lbl_UpAmount', itemKeys: ['UpAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'withdrawAmount', text: 'Th_withdrawAmount', itemKeys: ['WithdrawAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'betAmountAmount', text: 'Th_betAmount', itemKeys: ['BetAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'winLossAmount', text: 'Th_totalWin', itemKeys: ['TotalWin'], type: TableColumnStyle.CommaNumericWithSymbol },
            { name: 'rebateAmount', text: 'Lbl_Bonus', itemKeys: ['BonusAmount'], type: TableColumnStyle.CommaNumeric },
            { name: 'returnAmount', text: 'Lbl_Rebate', itemKeys: ['RebateAmount'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'bettingRate',
                text: 'Th_BettingRate',
                itemKeys: ['BetRate'],
                type: TableColumnStyle.VIPRank,
            },
        ],
        footer: ['depositAmount', 'rechargeAmount', 'withdrawAmount', 'betAmountAmount', 'winLossAmount', 'rebateAmount', 'returnAmount'],
    };

    const addSerialIdToData = input.Data.map((item: any, index: number) => ({
        ...item,
        SerialId: index + 1 + (pageNo ? (pageNo - 1) * (pageSize || 1) : 0),
    }));

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(addSerialIdToData, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.Summary.TotalRecords, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
    // return { tableHeader, tableBody, tableFooter, totalRecords: 0, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
