import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportRebate, IReportRebateData } from '@/models/page/report/IReportRebate';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function rebateData(input: IReportRebate): ITableLayoutData {
    const outputConfig: TableConfig<IReportRebateData> = {
        column: [
            { name: 'date', text: 'Th_date', itemKeys: ['Date'], type: TableColumnStyle.Date },
            { name: 'bonusUser', text: 'Th_bonusUserCount', itemKeys: ['BonusUser'], type: TableColumnStyle.Numeric },
            {
                name: 'bonus',
                text: 'Th_bonus',
                itemKeys: ['Bonus'],
                type: TableColumnStyle.CommaNumericWithNegativeSymbol,
            },
            { name: 'rebateUser', text: 'Th_RebateUserCount', itemKeys: ['RebateUser'], type: TableColumnStyle.Numeric },
            { name: 'rebate', text: 'Th_rebeat', itemKeys: ['Rebate'], type: TableColumnStyle.CommaNumeric },
        ],
        footer: ['bonusUser', 'bonus', 'rebateUser', 'rebate'],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);

    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return {
        tableHeader,
        tableBody,
        tableFooter,
        totalRecords: input.Summary.TotalRecord || 0,
        hasFooter: outputConfig.footer && outputConfig.footer.length > 0,
    };
}
