import { IValueType } from '@/components/dateRangePicker';
import { useEffect, useMemo, useState } from 'react';
import { IMemberDetailsData, IMemberDetailStatisticsData, IReportMemberStatisticsData } from '@/models/page/report/IReportMemberDetails';
import useFetchApi from '@/hooks/useFetchApi';
import { IMemberRequestData } from '@/models/apiRequest';
import { api } from '@/service/api';
import dayjs from 'dayjs';
import { IMemberStatisticsTableProps } from '@/pages/memberAdmin/admin/statistics/memberStatisticsTable';

const statisticsTransformationMapping: Record<string, string> = {
    FirstRecharge: 'Th_firstChargeAmount',
    Recharge: 'Th_effectAmountSum',
    Deposit: 'Th_rechargeSum',
    WinLose: 'Th_totalWin',
    BetAmount: 'Th_betAmount',
    Withdraw: 'Th_withdrawAmount',
};

export type MemberStatisticType = keyof typeof statisticsTransformationMapping;

export const statisticsChartableMapping: Record<MemberStatisticType, number | undefined> = {
    Deposit: 0,
    Recharge: 0,
    WinLose: 1,
    BetAmount: 2,
};

interface IUseMemberDataInput {
    queryDateRange: IValueType;
    memberPartnerId: number;
    memberUserName: string;
}

export function useMemberDetailsData({ memberPartnerId, memberUserName, queryDateRange }: IUseMemberDataInput) {
    const [lastUpdatedTime, setLastUpdatedTime] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [memberDetails, setMemberDetails] = useState<IReportMemberStatisticsData>();

    // fetch api
    const detectChangeAndFetchHandler = useFetchApi<IMemberRequestData, IMemberDetailsData, never>(
        api.memberDetails,
        ({ value }) => {
            setIsLoading(true);
            return {
                ...value,
                DateFrom: dayjs(value.DateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
                DateTo: dayjs(value.DateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
            };
        },
        ({ value }) => {
            setIsLoading(false);
            setLastUpdatedTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
            setMemberDetails(value.data.Data.MemberStatistic);
        },
        [],
        () => {
            setIsLoading(false);
            setLastUpdatedTime(null);
        },
    );

    const statisticsData = useMemo<IMemberStatisticsTableProps['data']>(
        () => ({
            lastUpdatedTime: lastUpdatedTime,
            stats: transformMemberDetailStatisticsData(memberDetails),
            summary: [
                {
                    key: 'Th_rebeat',
                    amount: memberDetails?.Rebate.AmountSummary ?? 0,
                },
                {
                    key: 'Th_bonus',
                    amount: memberDetails?.Bonus.AmountSummary ?? 0,
                },
            ],
        }),
        [lastUpdatedTime, memberDetails],
    );

    useEffect(() => {
        detectChangeAndFetchHandler({
            UserName: memberUserName,
            PartnerId: memberPartnerId,
            DateFrom: queryDateRange.startDate as string,
            DateTo: queryDateRange.endDate as string,
        });
    }, [memberPartnerId, memberUserName, queryDateRange]);

    return [statisticsData, isLoading] as const;
}

function transformMemberDetailStatisticsData(statistics: IReportMemberStatisticsData | undefined): IMemberStatisticsTableProps['data']['stats'] {
    const result: IMemberStatisticsTableProps['data']['stats'] = [];

    if (statistics) {
        for (const [key, data] of Object.entries(statistics)) {
            if (!Object.keys(statisticsTransformationMapping).includes(key)) continue;

            const entry: IMemberStatisticsTableProps['data']['stats'][0] = {
                key,
                label: statisticsTransformationMapping[key],
                amount: data.AmountSummary,
                chartType: statisticsChartableMapping[key],
                latestDataTime: data.LatestUpdated,
                latestDataAmount: data.LatestAmount,
                remark: [data.Remark, data.TransactionNo && `(${data.TransactionNo})`].filter((d) => !!d),
            };

            if (data.AmountSummary < 0 || entry.latestDataTime === '0001-01-01T00:00:00') {
                entry.latestDataTime = undefined;
                entry.latestDataAmount = undefined;
            }

            result.push(entry);
        }

        if (result.length > 0) {
            return statisticOrder.map((key) => result.find((r) => r.key === key)!);
        }
    }

    return result;
}

const statisticOrder = ['FirstRecharge', 'Recharge', 'Deposit', 'WinLose', 'BetAmount', 'Withdraw'];

export const statisticRequestTypeMapping: Record<MemberStatisticType, number> = {
    Recharge: 1,
    Deposit: 2,
    WinLose: 3,
    BetAmount: 4,
    Withdraw: 5,
};

interface IMemberStatisticsRequestData extends IMemberRequestData {
    StatisticType?: number;
    PageNo: number;
    PageSize: number;
}

export function useMemberStatisticsData<T extends IMemberDetailStatisticsData>({ memberPartnerId, memberUserName }: IUseMemberDataInput) {
    const [isLoading, setLoading] = useState(false);
    const [memberStatistics, setMemberStatistics] = useState<IMemberDetailStatisticsData | null>(null);

    const detectChangeAndFetchHandler = useFetchApi<IMemberStatisticsRequestData, IMemberDetailStatisticsData, never>(
        api.memberStatistics,
        ({ value }) => {
            setMemberStatistics(null);
            setLoading(true);
            return {
                ...value,
                UserName: memberUserName,
                PartnerId: memberPartnerId,
                DateFrom: dayjs(value.DateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
                DateTo: dayjs(value.DateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
            };
        },
        ({ value }) => {
            setLoading(false);
            setMemberStatistics(value.data.Data);
        },
        [],
        () => {
            setLoading(false);
        },
    );

    return [memberStatistics as T | null, detectChangeAndFetchHandler, isLoading] as const;
}

export function transformStatisticType(type: string): number | undefined {
    return statisticRequestTypeMapping[type];
}
