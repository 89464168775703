import { getEnableConfig } from '@/assets/config/common/partner.config';
import BettingRateNote from '@/components/bettingRateNote/bettingRateNote';
import { DialogButtonType } from '@/components/dialog/dialog';
import LoadingMask from '@/components/loadingMask/loadingMask';
import Pagination from '@/components/pagination';
import QuickDatePicker from '@/components/quickDatePicker';
import SearchColumn from '@/components/searchArea/searchColumn';
import TitleLabel from '@/components/titleLabel';
import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_COUNT } from '@/models/apiRequest/baseRequest';
import { ITableLayoutData, TableSearch, TableSearchEnum } from '@/models/layout/tableLayout';
import { IMemberAdminRequest, initMemberAdminRequestParams, MemberAdminPageEnum, MemberDetailParams } from '@/models/memberAdmin';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { api } from '@/service/api';
import { IRespMemberMonthlyReportData, memberMonthlyReportData } from '@/service/modelTransfer/memberAdmin/memberMonthlyReport';
import { toFirstLowerCase, toFirstUpperCase } from '@/service/utils';
import { showDialog } from '@/store/slices/dialog.slice';
import { RootState } from '@/store/store.config';
import dayjs from 'dayjs';
import { pluckFirst, useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withLatestFrom } from 'rxjs';
import MemberAdminTable from './memberAdminTable';
import MemberDetails from './memberDetails';
import cn from 'mxcn';
import { MemberAdminTabs } from '../memberAdminTabs';

const search: TableSearch[][] = [
    [
        {
            type: TableSearchEnum.DATE_RANGE,
            dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo', shortcuts: false },
            label: '會員註冊時間',
        },
        {
            type: TableSearchEnum.SELECT,
            matchKey: 'partnerId',
            label: '品牌',
            placeholder: '全部',
            selectOptions: [],
        },
        {
            type: TableSearchEnum.MULTI_SELECT,
            matchKey: 'userIdList',
            label: '所属代理',
            selectOptions: [],
        },
        {
            type: TableSearchEnum.INPUT,
            matchKey: 'userName',
            label: '会员帐号',
            placeholder: '请输入会员帐号',
            hasFuzzy: true,
        },
    ],
    [
        {
            type: TableSearchEnum.SELECT,
            label: '当月有效会员',
            matchKey: 'enable',
            selectOptions: [
                { id: 0, text: 'Txt_NoLimit' },
                { id: 1, text: 'Txt_ValidMember' },
                { id: 2, text: 'Txt_InvalidMember' },
            ],
        },
        {
            type: TableSearchEnum.SELECT,
            label: 'VIP等級',
            matchKey: 'vipLevel',
            selectOptions: [
                { id: -1, text: 'Lbl_selectAll' },
                { id: 0, text: '0', withoutI18n: true },
                { id: 1, text: '1', withoutI18n: true },
                { id: 2, text: '2', withoutI18n: true },
                { id: 3, text: '3', withoutI18n: true },
                { id: 4, text: '4', withoutI18n: true },
                { id: 5, text: '5', withoutI18n: true },
                { id: 6, text: '6', withoutI18n: true },
                { id: 7, text: '7', withoutI18n: true },
                { id: 8, text: '8', withoutI18n: true },
                { id: 9, text: '9', withoutI18n: true },
            ],
        },
        {
            type: TableSearchEnum.SELECT,
            matchKey: 'unBetDays',
            label: '未下注天数',
            selectOptions: [
                { id: 0, text: 'Lbl_Partner_All' },
                { id: 1, text: 'Txt_7-15' },
                { id: 2, text: 'Txt_15-30' },
                { id: 3, text: 'Txt_60' },
            ],
        },
        {
            type: TableSearchEnum.SELECT,
            matchKey: 'betRate',
            label: '投注率',
            selectOptions: [
                { id: 0, text: 'Lbl_selectAll' },
                { id: 1, text: 'Lbl_BettingRateEnum_str_1' },
                { id: 2, text: 'Lbl_BettingRateEnum_str_2' },
                { id: 3, text: 'Lbl_BettingRateEnum_str_3' },
                { id: 4, text: 'Lbl_BettingRateEnum_str_4' },
                { id: 5, text: 'Lbl_BettingRateEnum_str_5' },
            ],
        },
    ],
];

const MemberAdminPage = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) =>
        state.userStore.agentPartners.filter((item) => {
            const isPartnerEnable = getEnableConfig()
                .map((v) => v.id)
                .includes(item.PartnerId);
            return isPartnerEnable;
        }),
    ); // 取全部AgentUserIdList列表
    const agentEnableUserList = useMemo(() => {
        return agentUserList.filter((item) => item.IsEnabled);
    }, [agentUserList]); // 取所有EnableAgentUserIdList列表
    const [searchData, setSearchData] = useState<IMemberAdminRequest>(() => {
        const result = { ...initMemberAdminRequestParams };
        result.UserIdList = agentEnableUserList.map((item) => item.AgentUserId.toString());
        return result;
    }); // 紀錄搜尋列表的參數用
    const [requestData, setRequestData] = useState<IMemberAdminRequest>(searchData); // 紀錄 fetch api Request的值
    // const [realRequestData, setRealRequestData] = useState<{ [key: string]: IRequest }>();
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState<ITableLayoutData>();

    const [searchAreaHandler, searchChangeHandler$] = useObservableCallback<{ [key: string]: any }>((event$) => {
        return event$;
    });

    // fetch api
    const detectChangeAndFetchHandler = useFetchApi<IMemberAdminRequest, any, IRespMemberMonthlyReportData>(
        api.memberMonthlyReport,
        ({ apiItem, value }) => {
            const resultRequest: IMemberAdminRequest = value;
            resultRequest.DateFrom = dayjs(resultRequest.DateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            resultRequest.DateTo = dayjs(resultRequest.DateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');

            setIsLoading(true);
            return resultRequest;
        },
        ({ apiItem, value }) => {
            let _data: ITableLayoutData;
            if (value) {
                _data = memberMonthlyReportData(value.data.Data);
                setResponseData(_data);
            }
            setIsLoading(false);
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        // init
        detectChangeAndFetchHandler(requestData);
    }, []);

    const reFetch = useCallback(() => {
        detectChangeAndFetchHandler(requestData);
    }, [requestData]);

    const configSearch$ = useObservable(pluckFirst, [search]);
    const searchData$ = useObservable(pluckFirst, [searchData]);

    // 按下搜尋鈕後會觸發
    useSubscription(searchChangeHandler$.pipe(withLatestFrom(configSearch$, searchData$)), ([searchRef, configSearchData, _searchData]) => {
        if (responseData && responseData?.totalRecords) {
            responseData.totalRecords = 0;
        }

        const resultData = { ...initMemberAdminRequestParams, ..._searchData, PageNo: 1 }; // 每次搜尋後頁數要reset
        const setData = (searchItem: TableSearch, _data: IMemberAdminRequest) => {
            const _searchItem = searchItem;
            if (_searchItem.matchKey) {
                (_data as any)[toFirstUpperCase(_searchItem.matchKey)] = searchRef[_searchItem.matchKey];
                _searchItem.hasFuzzy && ((_data as any)['IsFuzzySearch'] = !!searchRef['IsFuzzySearch']);
            } else if (_searchItem.dateMatchKey) {
                const startName = _searchItem.dateMatchKey!['startDate'] || 'startDate';
                const endName = _searchItem.dateMatchKey!['endDate'] || 'endDate';
                (_data as any)[startName] = searchRef[startName];
                (_data as any)[endName] = searchRef[endName];
            }
        };

        configSearchData?.forEach((searchItem) => {
            if (Array.isArray(searchItem)) {
                const _searchItemList = searchItem as TableSearch[];
                _searchItemList.forEach((_searchItem) => {
                    setData(_searchItem, resultData);
                });
                return;
            }
            setData(searchItem, resultData);
        });

        if (Object.hasOwn(resultData, 'UserIdList') && resultData['UserIdList']?.length === 0) {
            dispatch(
                showDialog({
                    title: '警告',
                    content: '必须选一个所属代理',
                    type: DialogButtonType.Warning,
                }),
            );
        } else {
            setSearchData({ ...resultData });
            setRequestData(resultData);
            detectChangeAndFetchHandler(resultData);
        }
    });
    const [page, setPage] = useState(MemberAdminPageEnum.INDEX);
    const [currentParams, setParams] = useState<MemberDetailParams>();

    const switchPage = useCallback((type: MemberAdminPageEnum, params: MemberDetailParams) => {
        setPage(type);
        setParams(params);
    }, []);

    const onQuickDatePickerChangeHandler = useCallback(
        (date: { startDate: string; endDate: string }) => {
            const ori: { [key: string]: any } = searchData;
            const result: { [key: string]: any } = {};
            Object.keys(ori).forEach((key) => {
                result[toFirstLowerCase(key)] = ori[key];
            });
            searchAreaHandler({ ...result, DateFrom: date.startDate, DateTo: date.endDate });
        },
        [searchData],
    );

    return (
        <>
            <MemberAdminTabs />
            <TitleLabel>
                <span
                    className={cn({ 'cursor-pointer hover:text-orange-300': page !== MemberAdminPageEnum.INDEX })}
                    onClick={() => {
                        setPage(MemberAdminPageEnum.INDEX);
                        reFetch();
                    }}>
                    {t('Nav_member')}
                </span>
                {page !== MemberAdminPageEnum.INDEX ? <span> / {t('Th_memberDetails')}</span> : <></>}
            </TitleLabel>
            {page === MemberAdminPageEnum.INDEX && (
                <div data-index-page>
                    <div data-search-area>
                        <SearchColumn
                            defaultValue={requestData}
                            search={search}
                            handler={(data) => {
                                searchAreaHandler(data);
                            }}
                            layout="justify-start"
                        />
                    </div>
                    <div data-quick-date-picker className="my-4 flex justify-end">
                        <QuickDatePicker btnList={['YESTERDAY', 'A_WEEK_AGO', 'THIS_MONTH', 'LAST_MONTH']} handler={onQuickDatePickerChangeHandler} />
                    </div>
                    <hr />
                    <div data-table-container className="m-2 ">
                        <MemberAdminTable data={responseData} redirectPage={switchPage} offsetPageSN={(searchData.PageNo - 1) * searchData.PageSize} />
                    </div>
                    <div data-pagination className="mt-2 flex justify-center">
                        <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord').replace('{0}', `${responseData?.totalRecords || 0}`)}</span>

                        <Pagination
                            key={`member-admain-pagination-${responseData?.totalRecords || 1}`}
                            pageCount={Math.ceil((responseData?.totalRecords || 1) / DEFAULT_PAGE_COUNT)}
                            onPageChange={(e) => {
                                setSearchData((data) => {
                                    if (data) {
                                        const result = { ...data, PageNo: e.selected + 1 };
                                        detectChangeAndFetchHandler(result);
                                        return result;
                                    }

                                    return data;
                                });
                            }}
                        />
                    </div>
                    <div data-note className="ml-4 mb-4 text-xs">
                        <ul className="ml-4 list-disc">
                            <li className="text-red-400 whitespace-pre-line">未下注天数: 统计会员当前时间距离最后一次下注时间的自然天数;</li>
                            <li className="text-red-400 whitespace-pre-line">
                                投注率: 月投注金额/月储值金额(充值+代充)， 客户活跃度或愿意参与投注率的指标。计算后将分成以下五个等级
                            </li>
                            <li className="text-red-400 whitespace-pre-line">红色正数代表公司盈利，绿色负数代表公司成本</li>
                        </ul>
                        <div className="my-2">
                            <BettingRateNote />
                        </div>
                    </div>
                </div>
            )}

            {page !== MemberAdminPageEnum.INDEX && (
                <div data-detail-page>
                    <MemberDetails data={currentParams} pageType={page} />
                </div>
            )}

            <LoadingMask visible={isLoading} />
        </>
    );
};

export default MemberAdminPage;
