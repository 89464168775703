import { CardInfoBoard, CardTabBoard, CardTableBoard } from './cardboard/cardboard';
import {
    IRealtimeTableConst,
} from '@/models/page/dashboard/IDashboard';
import { useEffect, useState } from 'react';
import { ITabBoardProp, ITabSetting, TabSelectOptionBoard } from './cardboard/tabboard';
import { TextBoard } from './cardboard/textboard';
import { OtherInfoTextBoard } from './cardboard/otherInfoTextBoard';
import { getEnableConfig } from '@/assets/config/common/partner.config';
import { CardTablePagingBoard } from './cardboard/cardTablePagingBoard';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { useDashboard } from './hooks/useDashboard';
import { CardInfoTextBoard } from './cardboard/cardInfoTextBoard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import { useTranslation } from 'react-i18next';
import cn from 'mxcn';
import { useRealtimeDashboard } from '@/pages/dashboard/hooks/useRealtimeDashboard';

const cssClass = 'text-gray-800 text-base bg-gray-100 rounded-lg m-2';

const DashboardPage = (): JSX.Element => {
    const { t} = useTranslation();
    const dispatch = useDispatch();
    const [partnerId, setPartnerId] = useState(0);
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) => state.userStore.agentPartners);
    const [agentIdList, setAgentIdList] = useState<string[]>(() => {
        return agentUserList
            .filter((item) => {
                const isPartnerEnable = getEnableConfig()
                    .map((v) => v.id)
                    .includes(item.PartnerId);
                return isPartnerEnable && item.IsEnabled;
            })
            .map((config) => {
                return config.AgentUserId + '';
            });
    });
    const [trigger, setTrigger] = useState(Date.now());

    const {
        handleDashboard,
        memberCommissionData,
        proxyCommission,
        operationStatisticData,
        gameStaticData,
        rechargeRebateData,
        textBoardProp,
        otherInfoTextBoardProp,
    } = useDashboard();

    const {
        isLoading,
        realtimeData,
        updateRealtimeType,
    } = useRealtimeDashboard({
        partnerId,
        agentIdList,
    });

    useEffect(() => {
        handleDashboard({ PartnerId: partnerId, UserId: 1, UserIdList: agentIdList });
        updateRealtimeType(IRealtimeTableConst.Register);
    }, [trigger]);

    const onChangeHandler = (item: { partnerId: number; agentIdList: string[] }) => {
        setPartnerId(item.partnerId);
        setAgentIdList(item.agentIdList);
        if (item.agentIdList.length > 0) {
            setTrigger(Date.now());
        } else {
            dispatch(
                showDialog({
                    title: t('Lbl_Button_Warring'),
                    content: t('Msg_agentUserRequire'),
                    type: DialogButtonType.Warning,
                }),
            );
        }
    };

    const tabBoardProp: ITabBoardProp = {
        onChange: onChangeHandler,
        tabs: [
            {
                id: 0,
                text: t('Lbl_Partner_All'),
            },
        ].concat(getEnableConfig().map(
            (config): ITabSetting =>
                ({
                    id: config.id,
                    text: t(`Lbl_Partner_${config.text}`),
                }),
        )),
        defaultTabId: partnerId,
    };

    return (
        <>
            <div className="m-2 bg-gray-200 flex flex-col" key={partnerId}>
                <div className="flex basis-1/12 ">
                    <div className={cn('basis-1/3 flex items-center', cssClass)}>
                        {textBoardProp && <TextBoard {...textBoardProp} />}
                    </div>
                    <div className={cn('basis-1/3 flex items-center', cssClass)}>
                        <TabSelectOptionBoard {...tabBoardProp} />
                    </div>
                    <div className={cn('basis-1/3 flex items-center ', cssClass)}>
                        {otherInfoTextBoardProp && <OtherInfoTextBoard {...otherInfoTextBoardProp} />}
                    </div>
                </div>
                <div className="flex basis-7/12">
                    <div className="flex flex-col basis-2/3 mr-2">
                        <div className={cn('basis-1/3', cssClass)}>
                            {/* 本月会员佣金 */}
                            {memberCommissionData && <CardInfoBoard {...memberCommissionData} />}
                        </div>
                        <div className={cn('basis-1/12', cssClass)}>
                            {/* 本月代理佣金 */}
                            {proxyCommission && <CardInfoBoard {...proxyCommission} />}
                        </div>
                        <div className={cn('basis-5/12 grow', cssClass)}>
                            {/* 运营数据 */}
                            {operationStatisticData && (
                                <CardTabBoard key={trigger} {...operationStatisticData} titleStyle={undefined} />
                            )}
                        </div>
                    </div>
                    <div className="basis-1/3 flex flex-col">
                        <div className={cn('basis-1/6', cssClass)}>
                            {/* 本月代充返利 */}
                            {rechargeRebateData && <CardInfoTextBoard {...rechargeRebateData} />}
                        </div>
                        <div className={cn('basis-5/6', cssClass)}>
                            {/* 实时播报 */}
                            {realtimeData && <CardTablePagingBoard key={trigger} {...realtimeData} />}
                        </div>
                    </div>
                </div>

                <div className={cn('basis-1/6', cssClass)}>
                    {/* 游戏数据汇总 */}
                    {gameStaticData && <CardTableBoard key={trigger} {...gameStaticData} />}
                </div>
            </div>

            <LoadingMask visible={isLoading} />
        </>
    );
};

export default DashboardPage;
