import { useTranslation } from 'react-i18next';
import { ReportPageEnum } from '@/models/transfer/reportTransfer';
import { useEffect, useMemo, useState } from 'react';
import { tableLayoutConfig } from '@/assets/config/layout/layout.config';
import { TableHeaderTextList, TableHeaderType, TableLayoutAPI, TableSearch } from '@/models/layout/tableLayout';
import Container from './container';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';

export interface IReportProps {
    currentPageId: ReportPageEnum;
    api?: TableLayoutAPI;
    setReportProps?: (reportProps: IReportProps | null) => void;
    payload?: {
        titleLabelKey?: string | number;
        forSearch?: { key: string; value: string }[];
        forBeforeTrensfer?: { key: string; value: string }[];
        forBeforeFetch?: { key: string; value: any }[];
    };
    pageName?: string;
    headerType: TableHeaderType;
    search?: TableSearch[] | TableSearch[][];
    headerText?: TableHeaderTextList[];
    direactableKeyList?: { key: string; directLayout?: ReportPageEnum; isPopupModel?: boolean }[];
    isShowPagging: boolean;
    isTriggerBySubMenu?: boolean;
    needFold?: boolean;
    noteComp?: () => JSX.Element;
}

const ReportPage = () => {
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const [reportProps, setReportProps] = useState<IReportProps | null>(null);

    const { t } = useTranslation();
    const subMenu = useMemo(() => {
        return tableLayoutConfig.filter((page) => page.menuVisible && permissionList.includes(page.permissionId));
    }, [ permissionList ]);

    const [activeSubPage, setActiveSubMenu] = useState<ReportPageEnum>(ReportPageEnum.FUND);

    useEffect(() => {
        if (reportProps) {
            const targetPage = tableLayoutConfig.find((item) => item.pageId === reportProps.currentPageId);
            targetPage?.menuVisible && setActiveSubMenu(reportProps.currentPageId);
        } else {
            const initPage = tableLayoutConfig.find((item) => permissionList.includes(item.permissionId));

            if (initPage) {
                setReportProps({
                    currentPageId: initPage.pageId,
                    api: initPage.api,
                    pageName: initPage.pageName,
                    headerType: initPage.headerType,
                    search: initPage.search,
                    headerText: initPage.headerText,
                    direactableKeyList: initPage.direactableKeyList,
                    isShowPagging: initPage.isShowPagging,
                    isTriggerBySubMenu: true,
                    needFold: initPage.needFold,
                    noteComp: initPage.noteComp,
                })
            }
        }
    }, [reportProps?.currentPageId, permissionList]);

    const switchPage = (page: ReportPageEnum) => {
        const targetPage = tableLayoutConfig.find((item) => item.pageId === page && permissionList.includes(item.permissionId));

        setReportProps((props) => {
            if (targetPage) {
                return {
                    currentPageId: targetPage.pageId,
                    api: targetPage.api,
                    pageName: targetPage.pageName,
                    headerType: targetPage.headerType,
                    search: targetPage.search,
                    headerText: targetPage.headerText,
                    direactableKeyList: targetPage.direactableKeyList,
                    isShowPagging: targetPage.isShowPagging,
                    isTriggerBySubMenu: true,
                    needFold: targetPage.needFold,
                    noteComp: targetPage?.noteComp,
                };
            }

            return props;
        });
    };

    return (
        <>
            <nav>
                <ul className="flex m-2">
                    {subMenu.map((page) => {
                        return (
                            <li
                                className="text-base cursor-pointer"
                                key={`subMenu-${page.pageName}`}
                                id={`subMenu-${page.pageName}`}
                                onClick={() => switchPage(page.pageId)}>
                                <div
                                    data-active={page.pageId === activeSubPage}
                                    className="p-2 bg-gray-200 hover:bg-orange-300 data-active:bg-orange-300 data-active:font-bold">
                                    {t(`Subnav_${page.pageName}`)}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </nav>

            {reportProps && (
                <Container
                    {...reportProps}
                    setReportProps={setReportProps} />
            )}
        </>
    );
};

export default ReportPage;
