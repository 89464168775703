import { BettingRateEnum } from '@/models/page/report/IRelationMember';
import { useTranslation } from 'react-i18next';

export const VIPTagComp = ({ bettingRate, className = '' }: { bettingRate: BettingRateEnum; className?: string }) => {
    const { t } = useTranslation();
    const colorMapping = {
        [BettingRateEnum.VIP]: 'bg-red-500',
        [BettingRateEnum.HV]: 'bg-orange-500',
        [BettingRateEnum.MV]: 'bg-blue-600',
        [BettingRateEnum.LV]: 'bg-green-600',
        [BettingRateEnum.NDP]: 'bg-gray-500',
        [BettingRateEnum.ALL]: '',
    };

    return (
        <span className={` ${colorMapping[bettingRate]} text-gray-100 rounded-md p-1 pl-2 pr-2 font-bold ${className}`}>
            {t(`Lbl_BettingRateEnum_${bettingRate}`)}
        </span>
    );
};
