import { getEnableConfig } from '@/assets/config/common/partner.config';

interface Params {
    selectAllLabel?: string
}

export function usePartnerList({ selectAllLabel = 'Lbl_Partner_All' }: Params = {}) {
    const selectOptions = getEnableConfig().map((config) => ({
        id: config.id,
        text: `Lbl_Partner_${config.text}`,
    }));

    return [
        {
            id: 0,
            text: selectAllLabel,
        },
        ...selectOptions,
    ];
}
