import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import { IChargeAndWithdrawalRequestData, IMemberFirstChargeRequestData } from '@/models/apiRequest';
import { ICardTablePagingBoardProps, IPagingDataType, IRealtimeHandler, IRealtimeTableConstValue } from '@/models/page/dashboard/IDashboard';
import { IMemberFirstCharge, IMemberFirstChargeData } from '@/models/page/report/IMemberFirstCharge';
import { api } from '@/service/api';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { DATETIME_FORMAT } from '@/constants/datetime';

export function useFirstRecharge({
                                     agentIdList,
                                     buttonSettings,
                                     onPageChange,
                                     onUpdateRealtimeData,
                                     partnerId,
                                     onUpdateRealtimeType,
                                     userId
                                 }: IRealtimeHandler) {
    const { t } = useTranslation();

    const transformData = useCallback(
        (data: IMemberFirstCharge): ICardTablePagingBoardProps<IMemberFirstChargeData> => {
            const records = data.Data;
            return {
                totalRecords: data.Summary.TotalRecords,
                title: t('Lbl_RealtimeReport'),
                buttons: buttonSettings,
                titleNames: [t('Th_partnerId'), t('Lbl_MemberAccount'), t('Lbl_FirstRechargeSum'), t('Lbl_FirstRechargeTime')],
                keys: ['PartnerId', 'UserName', 'Amount', 'RechargeTime'],
                types: [IPagingDataType.Partner, IPagingDataType.Text, IPagingDataType.Number, IPagingDataType.DateMonth],
                items: records,
                onClick: (key) => {
                    onUpdateRealtimeType(key as IRealtimeTableConstValue);
                },
                onPagingChange: (page) => {
                    onPageChange(page);
                    handleFirstCharge({ PageNo: page, PartnerId: partnerId, UserIdList: agentIdList });
                },
            };
        },
        [partnerId, agentIdList, t],
    );

    const [firstRechargeData, setFirstRechargeData] = useState<IMemberFirstCharge>();

    const handleFirstCharge = useFetchApi<Partial<IMemberFirstChargeRequestData>, IMemberFirstCharge>(
        api.firstRechargeReport,
        ({ value }) => {
            const base = {
                DateFrom: dayjs().subtract(5, 'd').startOf('d').format(DATETIME_FORMAT.YMDHMSS),
                DateTo: dayjs().endOf('d').format(DATETIME_FORMAT.YMDHMSS),
                PartnerId: partnerId,
                UserId: userId,
                PageNo: 1,
                PageSize: 10,
            } as IChargeAndWithdrawalRequestData;

            return { ...base, ...value };
        },
        ({ value: { data } }) => {
            setFirstRechargeData(data.Data);
            onUpdateRealtimeData(transformData(data.Data));
        },
    );

    return [firstRechargeData, handleFirstCharge] as const;
}
