import { IUserAccountsVChatInfo } from '@/models/chatMessage/IChatMessage';
import { api } from '@/service/api';
import { useEffect, useState } from 'react';
import useFetchApi from './useFetchApi';

export default function useUserAccountsVchatInfo(): [IUserAccountsVChatInfo[], (args: any) => void] {
    const [userAccountsVChatInfos, setUserAccountsVChatInfos] = useState<IUserAccountsVChatInfo[]>([]);

    const detachGetItems = useFetchApi<any, IUserAccountsVChatInfo[], any>(
        api.getUserAccountsVchatInfo,
        ({ value }) => {
            return value;
        },
        ({ value }) => {
            if (!value || !value.data) {
                return;
            }

            if (value.data.IsSuccess) {
                setUserAccountsVChatInfos(value.data.Data);
            } else {
            }
        },
        [{}],
        (error) => {},
    );

    useEffect(() => {
        detachGetItems({});
    }, []);

    return [userAccountsVChatInfos, detachGetItems];
}
