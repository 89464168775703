import { useState } from 'react';
import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { IChatMessage } from '@/models/chatMessage/IChatMessage';

export interface IMemberChatHistoryRequest {
    MemberUserName: string;
    PartnerId: number;
    Keyword?: string;
    UserId?: number;
}

export interface IMemberChatHistoryResponse {
    OwnerNickName: string;
    OwnerVChatId: string;
    FriendVChatId: string;
    FriendNickName: string;
    ChatSession?: {
        UserAID: number;
        UserANickName: string;
        UserAHeadImg: string;
        UserBID: number;
        UserBNickName: string;
        UserBHeadImg: string;
    };
    ChatMessage: Array<{
        UserId: number;
        CreateTime: string;
        Message: string;
        MsgType: string;
    }>;
}

export interface IMemberChatHistoryData {
    fromUser: {
        UserId?: number
        UserVChatId: string;
        UserNickName?: string;
        UserHeadImg?: string;
    };
    toUser: {
        UserId?: number;
        UserVChatId: string;
        UserNickName?: string;
        UserHeadImg?: string;
    };
    messages: IChatMessage[];
}

export function useMemberChatHistoryData() {
    const [isLoading, setLoading] = useState(false);
    const [memberChatHistory, setMemberChatHistory] = useState<IMemberChatHistoryData | null>(null);

    const detectChangeAndFetchHandler = useFetchApi<IMemberChatHistoryRequest, IMemberChatHistoryResponse, never>(
        api.memberChatHistory,
        ({ value }) => {
            setLoading(true);
            return value;
        },
        ({ value }) => {
            setMemberChatHistory(transformChatHistory(value.data.Data));
            setLoading(false);
        },
        [],
        () => {
            setLoading(false);
        },
    );

    return [memberChatHistory, setMemberChatHistory, detectChangeAndFetchHandler, isLoading] as const;
}

function transformChatHistory({ ChatMessage, ChatSession, OwnerVChatId, OwnerNickName, FriendVChatId, FriendNickName }: IMemberChatHistoryResponse): IMemberChatHistoryData {
    const userAIsOwner = OwnerNickName === ChatSession?.UserANickName;

    const fromUser = {
        UserId: (userAIsOwner ? ChatSession?.UserAID : ChatSession?.UserBID) ?? 0,
        UserVChatId: OwnerVChatId,
        UserNickName: OwnerNickName ?? '',
        UserHeadImg: (userAIsOwner ? ChatSession?.UserAHeadImg : ChatSession?.UserBHeadImg) ?? '',
    };
    const toUser = {
        UserId: (userAIsOwner ? ChatSession?.UserBID : ChatSession?.UserAID) ?? 0,
        UserVChatId: FriendVChatId,
        UserNickName: FriendNickName ?? '',
        UserHeadImg: (userAIsOwner ? ChatSession?.UserBHeadImg : ChatSession?.UserAHeadImg) ?? '',
    };

    return {
        toUser,
        fromUser,
        messages: !!ChatMessage
            ? ChatMessage.map((message) => ({
                  ...(message.UserId === toUser.UserId ? toUser : fromUser),
                  CreateTime: message.CreateTime,
                  Message: message.Message,
                  MsgType: message.MsgType,
              }))
            : [],
    };
}
