import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import Pagination from '@/components/pagination';
import { ICardTablePagingBoardProps, IPagingDataType, IPagingDataTypeValue } from '@/models/page/dashboard/IDashboard';
import { FloatHelper, RoundType } from '@/service/floatHelper';
import { numberComma } from '@/service/utils';
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { ButtonsGroup } from './buttonsGroup';
import { useTranslation } from 'react-i18next';

export const CardTablePagingBoard = (props: ICardTablePagingBoardProps<any>) => {
    const {t} = useTranslation();
    const displayText = useCallback(
        (value: string | number, type: IPagingDataTypeValue): string | number => {
            switch (type) {
                case IPagingDataType.Text:
                    return value;
                case IPagingDataType.Partner:
                    return t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[value as number].text}`);
                case IPagingDataType.Date:
                    return dayjs(value).format('YYYY/MM/DD HH:mm:ss');
                case IPagingDataType.DateMonth:
                    return dayjs(value).format('MM/DD HH:mm:ss');
                case IPagingDataType.Number:
                    return numberComma(FloatHelper.floatFixed(value as number, RoundType.Floor, 0));
            }
        },
        [t],
    );

    const firstKey = props.keys[0] || '';
    const titleRightAligns: IPagingDataTypeValue[] = [IPagingDataType.Number, IPagingDataType.Date];

    return (
        <div className="flex flex-col h-full">
            <div className=" flex justify-between m-1  basis-1/12 pl-2">
                <h1 className="font-black text-lg">{props.title}</h1>
                <div>
                    <ButtonsGroup buttons={props.buttons} onClick={props.onClick}></ButtonsGroup>
                </div>
            </div>
            <div className="basis-10/12">
                <TableContainer className="">
                    <Table className="" colorScheme={'blackAlpha'} variant="striped">
                        <Thead>
                            <Tr>
                                {props.titleNames.map((tname, idx) => (
                                    <Th key={tname} fontSize="md" textAlign={`${titleRightAligns.includes(props.types[idx]) ? 'right' : 'left'}`}>
                                        {tname}
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody >
                            {props.items.length > 0 ? (
                                props.items.map((item, rowIdx) => (
                                    <Tr key={`tb_${rowIdx}_${item[firstKey]}`}>
                                        {props.keys.map((key, idx) => (
                                            <Td
                                                textAlign={`${titleRightAligns.includes(props.types[idx]) ? 'right' : 'left'}`}
                                                data-test={`tb_td_${rowIdx}_${idx}_${item[firstKey]}`}
                                                key={`tb_td_${rowIdx}_${idx}_${item[firstKey]}`}>
                                                {displayText(item[key], props.types[idx]) || '-'}
                                            </Td>
                                        ))}
                                    </Tr>
                                ))
                            ) : (
                                <Tr>
                                    <Td fontSize={'2xl'} colSpan={props.keys.length} className="text-center" textAlign="center">
                                        {t('Lbl_NoRecord')}
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>

            <div className={`my-2 basis-1/12 flex justify-center ${props.totalRecords === 0 ? 'hidden' : ''}`}>
                <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord', [props.totalRecords || 0])}</span>
                <div>
                    <Pagination
                        key={`Page_${props.titleNames.join('-')}`}
                        pageCount={Math.ceil((props.totalRecords || 0) / 10)}
                        onPageChange={(e) => {
                            if (props.onPagingChange) {
                                props.onPagingChange(e.selected + 1);
                            }
                        }}></Pagination>
                    {/* {pagingComp} */}
                </div>
            </div>
        </div>
    );
};
