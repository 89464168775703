import { getEnableConfig } from '@/assets/config/common/partner.config';
import { DialogButtonType } from '@/components/dialog/dialog';
import LoadingMask from '@/components/loadingMask/loadingMask';
import Pagination from '@/components/pagination';
import SearchColumn from '@/components/searchArea/searchColumn';
import TitleLabel from '@/components/titleLabel';
import { DATETIME_FORMAT } from '@/constants/datetime';
import useFetchApi from '@/hooks/useFetchApi';
import { IBaseRequestData } from '@/models/apiRequest';
import { DEFAULT_PAGE_COUNT } from '@/models/apiRequest/baseRequest';
import { ITableLayoutData, TableSearch, TableSearchEnum } from '@/models/layout/tableLayout';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { ITransactionType } from '@/models/transactionType';
import { api } from '@/service/api';
import { fundDetailReportData, IRespFundDetailReportData } from '@/service/modelTransfer/profile/fundDetailReport';
import { toFirstUpperCase } from '@/service/utils';
import { showDialog } from '@/store/slices/dialog.slice';
import { RootState } from '@/store/store.config';
import dayjs from 'dayjs';
import { pluckFirst, useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withLatestFrom } from 'rxjs';
import { ProfileTabs } from '../profileTabs/profileTabs';
import FundDetailTable from './FundDetailTable';

type FundDetailRequest = {
    PartnerId: number;
    UserId: number;
    UserIdList: string[];
    PageSize: number;
    DateFrom: string;
    DateTo: string;
    Status: number;
    TransactionType: number;
} & IBaseRequestData;

export const initFundDetailRequestParams: FundDetailRequest = {
    PartnerId: 0,
    UserId: 18,
    UserIdList: [],
    PageSize: 20,
    PageNo: 1,
    DateFrom: dayjs().startOf('M').format(DATETIME_FORMAT.YMD),
    DateTo: dayjs().format(DATETIME_FORMAT.YMD),
    Status: -1,
    TransactionType: 0,
};

const FundDetailsPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) =>
        state.userStore.agentPartners.filter((item) => {
            const isPartnerEnable = getEnableConfig()
                .map((v) => v.id)
                .includes(item.PartnerId);
            return isPartnerEnable;
        }),
    ); // 取全部AgentUserIdList列表
    const transactionTypes = useSelector<RootState, ITransactionType[]>((state) => state.userStore.transactionTypeList);

    const search: TableSearch[][] = useMemo<TableSearch[][]>(
        () => [
            [
                {
                    type: TableSearchEnum.DATE_RANGE,
                    dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo', shortcuts: true },
                    label: t('Lbl_date_range'),
                },
            ],
            [
                {
                    type: TableSearchEnum.SELECT,
                    matchKey: 'partnerId',
                    label: t('Th_partnerId'),
                    placeholder: t('Lbl_All'),
                    selectOptions: [],
                },
                {
                    type: TableSearchEnum.MULTI_SELECT,
                    matchKey: 'userIdList',
                    label: t('Th_agentUserList'),
                    selectOptions: [],
                },
                {
                    type: TableSearchEnum.SELECT,
                    label: t('Th_tradeType'),
                    matchKey: 'transactionType',
                    selectOptions: [],
                },
                {
                    type: TableSearchEnum.SELECT,
                    label: t('Lbl_OrderStatus'),
                    matchKey: 'status',
                    selectOptions: [
                        { id: -1, text: 'Lbl_All' },
                        { id: 0, text: t('Txt_processing'), withoutI18n: true },
                        { id: 1, text: t('Txt_success'), withoutI18n: true },
                        { id: 2, text: t('Txt_fail'), withoutI18n: true },
                    ],
                },
            ],
        ],
        [],
    );

    const agentEnableUserList = useMemo(() => {
        return agentUserList.filter((item) => item.IsEnabled);
    }, [agentUserList]); // 取所有EnableAgentUserIdList列表
    const [searchData, setSearchData] = useState<FundDetailRequest>(() => {
        const result = { ...initFundDetailRequestParams };
        result.UserIdList = agentEnableUserList.map((item) => item.AgentUserId.toString());
        return result;
    }); // 紀錄搜尋列表的參數用
    const [requestData, setRequestData] = useState<FundDetailRequest>(searchData); // 紀錄 fetch api Request的值
    const [searchAreaHandler, searchChangeHandler$] = useObservableCallback<{ [key: string]: any }>((event$) => {
        return event$;
    });
    const [responseData, setResponseData] = useState<ITableLayoutData>();

    const configSearch$ = useObservable(pluckFirst, [search]);
    const searchData$ = useObservable(pluckFirst, [searchData]);
    const getFundDetails = useFetchApi<FundDetailRequest, IRespFundDetailReportData, ITransactionType[]>(
        api.fundsDetail,
        ({ value }) => {
            const resultRequest: FundDetailRequest = value;
            resultRequest.DateFrom = dayjs(resultRequest.DateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            resultRequest.DateTo = dayjs(resultRequest.DateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS');
            resultRequest.TransactionType = ~~resultRequest.PartnerId === 0 ? 0 : resultRequest.TransactionType;
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [transactionTypeList] }) => {
            let _data: ITableLayoutData;
            if (value) {
                _data = fundDetailReportData(value.data.Data, transactionTypeList);
                setResponseData(_data);
                setIsLoading(false);
            }
        },
        [transactionTypes],
        (e) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        // init
        getFundDetails(requestData);
    }, []);
    // 按下搜尋鈕後會觸發
    useSubscription(searchChangeHandler$.pipe(withLatestFrom(configSearch$, searchData$)), ([searchRef, configSearchData, _searchData]) => {
        if (responseData && responseData?.totalRecords) {
            responseData.totalRecords = 0;
        }

        const resultData = { ...initFundDetailRequestParams, ..._searchData, PageNo: 1 }; // 每次搜尋後頁數要reset
        const setData = (searchItem: TableSearch, _data: FundDetailRequest) => {
            const _searchItem = searchItem;
            if (_searchItem.matchKey) {
                (_data as any)[toFirstUpperCase(_searchItem.matchKey)] = searchRef[_searchItem.matchKey];
                _searchItem.hasFuzzy && ((_data as any)['IsFuzzySearch'] = !!searchRef['IsFuzzySearch']);
            } else if (_searchItem.dateMatchKey) {
                const startName = _searchItem.dateMatchKey!['startDate'] || 'startDate';
                const endName = _searchItem.dateMatchKey!['endDate'] || 'endDate';
                (_data as any)[startName] = searchRef[startName];
                (_data as any)[endName] = searchRef[endName];
            }
        };

        configSearchData?.forEach((searchItem) => {
            if (Array.isArray(searchItem)) {
                const _searchItemList = searchItem as TableSearch[];
                _searchItemList.forEach((_searchItem) => {
                    setData(_searchItem, resultData);
                });
                return;
            }
            setData(searchItem, resultData);
        });

        if (Object.hasOwn(resultData, 'UserIdList') && resultData['UserIdList']?.length === 0) {
            dispatch(
                showDialog({
                    title: t('Lbl_Button_Warring'),
                    content: t('Msg_agentUserRequire'),
                    type: DialogButtonType.Warning,
                }),
            );
        } else {
            setSearchData({ ...resultData });
            setRequestData(resultData);
            getFundDetails(resultData);
        }
    });
    return (
        <div>
            <ProfileTabs />
            <div className="my-4">
                <TitleLabel>{t('Title_FundDetails')}</TitleLabel>
            </div>
            <LoadingMask visible={isLoading} />
            <div data-index-page>
                <div data-search-area>
                    <SearchColumn
                        defaultValue={requestData}
                        search={search}
                        handler={(data) => {
                            searchAreaHandler(data);
                        }}
                        layout="justify-start"
                    />
                </div>
            </div>
            <div data-table-container className="m-2 ">
                <FundDetailTable data={responseData} offsetPageSN={(searchData.PageNo - 1) * searchData.PageSize} />
            </div>
            <div data-pagination className="mt-2 flex justify-center">
                <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord').replace('{0}', `${responseData?.totalRecords || 0}`)}</span>

                <Pagination
                    key={`member-admain-pagination-${responseData?.totalRecords || 1}`}
                    pageCount={Math.ceil((responseData?.totalRecords || 1) / DEFAULT_PAGE_COUNT)}
                    onPageChange={(e) => {
                        setSearchData((data) => {
                            if (data) {
                                const result = { ...data, PageNo: e.selected + 1 };
                                getFundDetails(result);
                                return result;
                            }

                            return data;
                        });
                    }}
                />
            </div>

            <LoadingMask visible={isLoading} />
        </div>
    );
};

export default FundDetailsPage;
