import { useObservable, useSubscription } from 'observable-hooks';
import { epic$ } from '@/store/epics';
import { filter, map, tap } from 'rxjs';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { noneAction } from '@/store/actions/none.action';

export default function useEpic<T>(action: ActionCreatorWithPayload<T>, handler: (data: T) => void) {
    const Obs$ = useObservable((inputs$) => inputs$.pipe(map(([_action, _handler]) => ({ targetAction: _action, callback: _handler }))), [action, handler]);

    useSubscription(Obs$, ({ targetAction, callback }) => {
        epic$.next((action$, store) =>
            action$.pipe(
                filter(targetAction.match),
                tap((v) => callback(v.payload)),
                map(() => noneAction),
            ),
        );
    });
}
