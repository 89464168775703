import { MemberDetailsLayoutProps } from '..';
import MemberDetailsDeposit from './deposit';
import MemberDetailsMain from './main';
import MemberDetailsRecharge from './recharge';
import MemberDetailsWinLose from './winLose';
import MemberDetailsWithdraw from './withdraw';
import React from 'react';

export enum MemberDetailsLayout {
    MAIN,
    DEPOSIT,
    RECHARGE,
    WINLOSE,
    WITHDRAW,
}

export const layoutNameMapping: Record<MemberDetailsLayout, string> = {
    [MemberDetailsLayout.MAIN]: 'Th_memberDetails',
    [MemberDetailsLayout.DEPOSIT]: 'Lbl_DepositRecord',
    [MemberDetailsLayout.RECHARGE]: 'Lbl_RechargeRecord',
    [MemberDetailsLayout.WINLOSE]: 'Lbl_GameWinLose',
    [MemberDetailsLayout.WITHDRAW]: 'Lbl_WithdrawRecord',
};


export const layoutMapping: Record<MemberDetailsLayout, React.FC<MemberDetailsLayoutProps>> = {
    [MemberDetailsLayout.MAIN]: MemberDetailsMain,
    [MemberDetailsLayout.DEPOSIT]: MemberDetailsDeposit,
    [MemberDetailsLayout.RECHARGE]: MemberDetailsRecharge,
    [MemberDetailsLayout.WINLOSE]: MemberDetailsWinLose,
    [MemberDetailsLayout.WITHDRAW]: MemberDetailsWithdraw,
};
