import MainLayout from '@/layout/Main';
import DashboardPage from '@/pages/dashboard';
import MemberAdminPage from '@/pages/memberAdmin/admin';
import { RRouteObject } from '@/models/router/RRouteObject';

import ReportPage from '@/pages/report';
import ProfilePage from '@/pages/profile';
import { useEffect } from 'react';
import PromotionLinkPage from '@/pages/PromotionLink';
import SecurityCenterPage from '@/pages/profile/securityCenter';
import RechargeMonitorPage from '@/pages/profile/rechargeMonitor/rechargeMonitor';
import PasswordManagerPage from '@/pages/profile/passwordManager';
import SystemSettingPage from '@/pages/systemSetting';
import { PermissionsId } from '@/models/permissions';
import KickOut from '@/pages/kickOut/kickOut';
import PageNotFound from '@/pages/404';
import CrossSystemPage from '@/pages/memberAdmin/cross';
import { ChatListingPage } from '@/pages/chatAdmin/chat';
import { ChatManagePage } from '@/pages/chatAdmin/manage';
import NotificationPage from '@/pages/notification';
import FundDetailsPage from '@/pages/profile/funddetails';

const LogonRedirect = () => {
    useEffect(() => {
        window.location.href = '/logon.html';
    }, []);

    return null;
};

function getRoutes(guardsFn?: (routes: RRouteObject[]) => RRouteObject[]) {
    const routes: RRouteObject[] = [
        {
            path: 'logon',
            element: <LogonRedirect />,
            meta: { isMenuVisible: false },
        },
        {
            path: 'kick-out',
            element: <KickOut />,
            meta: { isMenuVisible: false },
        },
        {
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardPage />,
                    meta: { isMenuVisible: true, id: PermissionsId.Dashboard },
                },
                {
                    path: 'member',
                    element: <MemberAdminPage />,
                    meta: { isMenuVisible: true, id: PermissionsId.MemberManagement },
                },
                {
                    path: 'cross-system',
                    element: <CrossSystemPage />,
                    meta: { isMenuVisible: false, id: PermissionsId.CrossSystemQuery },
                },
                {
                    path: 'profile',
                    element: <ProfilePage />,
                    meta: { isMenuVisible: false, id: PermissionsId.PersonalInformation },
                },
                {
                    path: 'securitycenter',
                    element: <SecurityCenterPage />,
                    meta: { isMenuVisible: false, id: PermissionsId.SecurityCenter },
                },
                {
                    path: 'passwordmanager',
                    element: <PasswordManagerPage />,
                    meta: { isMenuVisible: false, id: PermissionsId.PartnerPasswordManagement },
                },
                {
                    path: 'funddetails',
                    element: <FundDetailsPage />,
                    meta: { isMenuVisible: false, id: PermissionsId.FundDetails },
                },
                {
                    path: 'rechargemonitor',
                    element: <RechargeMonitorPage />,
                    meta: { isMenuVisible: false, id: PermissionsId.RechargeMonitoring },
                },
                {
                    path: 'promotion',
                    element: <PromotionLinkPage />,
                    meta: { isMenuVisible: true, id: PermissionsId.PromotionLink },
                },
                {
                    path: 'report',
                    meta: { isMenuVisible: true, id: PermissionsId.ReportCenter },
                    element: <ReportPage />,
                },
                {
                    path: 'chat-list',
                    meta: { isMenuVisible: true, id: PermissionsId.Chat },
                    element: <ChatListingPage />,
                },
                {
                    path: 'chat-manage',
                    meta: { isMenuVisible: false, id: PermissionsId.ChatManagement },
                    element: <ChatManagePage />,
                },
                {
                    path: 'system-setting',
                    meta: { isMenuVisible: true, id: PermissionsId.SystemSetting },
                    element: <SystemSettingPage />,
                },
                {
                    path: 'notification',
                    meta: { isMenuVisible: false, id: PermissionsId.SystemSetting },
                    element: <NotificationPage />,
                },
                {
                    path: '',
                    element: <DashboardPage />,
                    meta: { isMenuVisible: false },
                },
            ],
        },
        { path: '*', element: <PageNotFound />, meta: { isMenuVisible: false } },
    ];

    return guardsFn?.(routes) ?? routes;
}

export default getRoutes;
