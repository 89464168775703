import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { DepositTradeStatus } from '@/models/page/report/IChargeAndWithdrawal';
import { ITransactionType } from '@/models/transactionType';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export interface IRespFundDetailReportData {
    Data: IData[];
    Summary: { SumActualAmount: number; TotalRecords: number };
}

interface IData {
    PartnerId: number;
    AgentNickName: string;
    TransactionTypeName: string;
    ActualAmount: number;
    AfterBalance: number;
    BeforeBalance: number;
    USDTAmount: number;
    Fee: number;
    Remark: string;
    Status: number;
    SubTypeId: number;
    ActionDescription: string;
    TransactionAmount: number;
    TransactionDate: string;
    TransactionType: number;
    MemberCode: string;
}

export function fundDetailReportData(input: IRespFundDetailReportData, list: ITransactionType[]): ITableLayoutData {
    const outputConfig: TableConfig<IData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'transactionDate', text: 'Th_TransactionDate', itemKeys: ['TransactionDate'], type: TableColumnStyle.DateTime },
            {
                name: 'transactionType',
                text: 'Th_tradeType',
                itemKeys: ['TransactionType'],
                type: TableColumnStyle.Text,
                calculate: (data) => {
                    return list.filter((item) => item.PartnerId === data.PartnerId && item.TransactionTypeID === data.TransactionType)[0].TransactionTypeName;
                },
            },
            { name: 'actionDescription', text: 'Th_actionDescription', itemKeys: ['ActionDescription'], type: TableColumnStyle.Text },
            {
                name: 'transactionAmount',
                text: 'Th_balance',
                itemKeys: ['TransactionAmount'],
                type: TableColumnStyle.CommaNumeric,
            },
            { name: 'fee', text: 'Th_fee', itemKeys: ['Fee'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'actualAmount',
                text: 'Th_actualAmount',
                itemKeys: ['ActualAmount'],
                type: TableColumnStyle.CommaNumericWithText,
                calculate: (data, keys) => {
                    const isUSDT = data.USDTAmount > 0;
                    const result = isUSDT ? data.USDTAmount : data.TransactionAmount;
                    return `${data.Status === 2 ? 0 : result}_with_${isUSDT ? '(USDT)' : ''}`;
                },
            },
            { name: 'beforeBalance', text: 'Th_beforeBalance', itemKeys: ['BeforeBalance'], type: TableColumnStyle.CommaNumeric },
            { name: 'afterBalance', text: 'Th_afterBalance', itemKeys: ['AfterBalance'], type: TableColumnStyle.CommaNumeric },
            {
                name: 'status',
                text: 'Th_fundDetailStatus',
                itemKeys: ['Status'],
                type: TableColumnStyle.TextI18NWithColor,
                calculate: (data) => {
                    const colorMap = new Map<number, string>([
                        [DepositTradeStatus.PROCESSING, 'text-gray-300'],
                        [DepositTradeStatus.FAILURE, 'text-green-500'],
                        [DepositTradeStatus.SUCCESS, 'text-red-500'],
                    ]);
                    return `Lbl_DepositTradeStatus_${data.Status}_with_${colorMap.get(data.Status)}`;
                },
            },
            { name: 'orderId', text: 'Th_tradeBillNo', itemKeys: ['OrderId'], type: TableColumnStyle.TextWithCopy },
            { name: 'remark', text: 'Th_note', itemKeys: ['Remark'], type: TableColumnStyle.Text },
        ],
        footer: ['transactionAmount', 'fee', 'actualAmount', 'beforeBalance', 'afterBalance'],
    };
    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return {
        tableHeader,
        tableBody,
        tableFooter,
        totalRecords: input.Summary.TotalRecords,
        hasFooter: outputConfig.footer && outputConfig.footer.length > 0,
    };
}
