import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import MenuComp from '@/components/menu/menuComp';
import User from '@/components/user';
import { useCallback, useEffect, useState } from 'react';
import getRoutes from '@/assets/config/router/routes.config';
import { RRouteObject } from '@/models/router/RRouteObject';
import { Dialog } from '@/components/dialog/dialog';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { Button } from '@chakra-ui/react';
import { IRechargePupupProps, RechagePupup } from '@/pages/recharge/rechargePopup';
import { useTranslation } from 'react-i18next';
import { permissionGuards } from '@/service/guardsHelper';
import { PermissionsId } from '@/models/permissions';
import useEpic from '@/hooks/useEpic';
import { openRechargePopupAction } from '@/store/actions/recharge.action';

const MainLayout = (): JSX.Element => {
    const { t } = useTranslation();
    const [hasSubMenu, setHasSubMenu] = useState(false);
    const [subMenuRoutes, setSubMenuRoutes] = useState<RRouteObject[]>([]);
    const currentLocation = useLocation();
    const permissionList = useSelector((state: RootState) => state.userStore.permissions);
    const targetRoutes = getRoutes(permissionGuards(permissionList)).find((route) => route.path === '/') || {};
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.userStore);
    const menuRoutes = ((targetRoutes.children || []) as RRouteObject[]).filter((route) => {
        return route.meta?.isMenuVisible;
    });

    useEffect(() => {
        window.onkeydown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                navigate('/kick-out');
            }
        };

        return () => {
            window.onkeydown = null;
        };
    }, []);

    useEffect(() => {
        const routeKey = currentLocation.pathname.split('/')[1]; // 取得網址route的第一層
        const targetRoute = menuRoutes.filter((v) => `${v.path}` === routeKey)[0];

        setHasSubMenu(() => {
            return !!targetRoute?.children && targetRoute?.children?.length > 0;
        });

        setSubMenuRoutes((v) => {
            return targetRoute?.children?.filter((v: RRouteObject) => v.meta?.isMenuVisible) || v;
        });
    }, [currentLocation]);

    const [rechargePupupProps, setRechargePupupProps] = useState<IRechargePupupProps>({
        isOpen: false,
        onClose: () => {
            setRechargePupupProps((pre) => {
                return { ...pre, isOpen: false };
            });
        },
    });

    const [openRechargePopupkey, setOpenRechargePopupkey] = useState(true);

    const openRechargePopupCallback = useCallback((param: IRechargePupupProps) => {
        setOpenRechargePopupkey((v) => !v);
        setRechargePupupProps({
            ...param,
            onClose: () => {
                setRechargePupupProps(() => {
                    return {
                        isOpen: false,
                        onClose: () => {
                            setRechargePupupProps((pre) => {
                                return { ...pre, isOpen: false };
                            });
                        },
                    };
                });
            },
        });
    }, []);

    useEpic(openRechargePopupAction, openRechargePopupCallback);

    const openRechargePupup = () => {
        setRechargePupupProps((pre) => {
            return { ...pre, isOpen: true };
        });
    };

    return !!user.token ? (
        <>
            <div className="flex flex-col min-h-screen pb-12 bg-gray-100">
                <Dialog />
                <div data-banner className=" h-[7.5rem] flex flex-col ">
                    <nav className="flex flex-1 items-center bg-affiliate">
                        <div className="w-[8rem] flex-none flex items-center justify-center">
                            <img src="/vite.svg" alt="logo" className="box-content w-[6rem] h-[4.5rem] border-2" />
                        </div>
                        <div className="flex-1 items-center">
                            <MenuComp routes={menuRoutes} />
                        </div>
                        <div className="min-w-[16rem] flex-none flex items-center justify-center mr-2">
                            <User />
                            {permissionList.includes(PermissionsId.MemberRecharge) && (
                                <Button background="orange" color="gray.100" _hover={{ bg: 'orange.500' }} onClick={() => openRechargePupup()}>
                                    {t('Lbl_RechargeMembership')}
                                </Button>
                            )}
                        </div>
                    </nav>
                </div>
                <div>
                    <Outlet />
                </div>
            </div>
            <RechagePupup {...rechargePupupProps} key={`RCP-${rechargePupupProps.isOpen}-${openRechargePopupkey}`} />
        </>
    ) : (
        <Navigate to="/logon" />
    );
};

export default MainLayout;
