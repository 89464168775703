import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportRebateDateil, IReportRebateDateilData } from '@/models/page/report/IReportRebate';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function rebateUserDetailData(input: IReportRebateDateil): ITableLayoutData {
    const outputConfig: TableConfig<IReportRebateDateilData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'userName', text: 'Th_userName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'rebateAmount', text: 'Th_rebeat', itemKeys: ['RebateAmount'], type: TableColumnStyle.CommaNumeric },
        ],
        footer: [],
    };

    const tableHeader = getTableHeader(outputConfig.column);
    const tableBody = getTableBody(input.Data, outputConfig.column);

    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.TotalRecord || 0, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}
