import { PasswordInputComp } from '@/components/passwordInput/passwordInputComp';
import { PopupModel } from '@/components/popupModal/popupModal';
import { useTranslation } from 'react-i18next';
import { UseDisclosureProps } from '@chakra-ui/react';
import { useState } from 'react';
import { validatePasswordFormat } from '@/service/utils';

export type IResetPasswordModelProps =
    | {
          title?: string;
          titleIconClass?: string;
          onSave: (oldPW: string, newPW: string) => void;
      } & UseDisclosureProps;

export const WarningMessageEnum = {
    WrongFormat: 'WrongFormat',
    NewConfirmNotTheSame: 'NewConfirmNotTheSame',
    NewOldTheSame: 'NewOldTheSame',
    WrongPassword: 'WrongPassword',
    NewNeedValue: 'NewNeedValue',
} as const;

export type IWarringMessageType = keyof typeof WarningMessageEnum;

const hintContents = ['至少包含一个字母或数字。', '可以包含英文大小写字母、数字和特殊符号!@#$%^& .。', '长度在6到15之间。'];

export const ResetPasswordModel = (props: IResetPasswordModelProps) => {
    const { t } = useTranslation();
    const [oldPasswordMsg, setOldPasswordMsg] = useState('');
    const [newPasswordMsg, setNewPasswordMsg] = useState('');
    const [confirmPasswordMsg, setConfirmPasswordMsg] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const warringMessage: { [key: string]: string } = {
        [WarningMessageEnum.WrongFormat]: t('Msg_Error_ResetPassword_WrongFormat'),
        [WarningMessageEnum.NewConfirmNotTheSame]: t('Msg_Error_ResetPassword_NewConfirmNotTheSame'),
        [WarningMessageEnum.NewOldTheSame]: t('Msg_Error_ResetPassword_NewOldTheSame'),
        [WarningMessageEnum.WrongPassword]: t('Msg_Error_ResetPassword_WrongPassword'),
        [WarningMessageEnum.NewNeedValue]: t('Msg_Error_ResetPassword_NewNeedValue'),
    };

    const handleOldPassword = (password: string) => {
        setOldPassword(password);

        if (password === newPassword) {
            setOldPasswordMsg(warringMessage[WarningMessageEnum.NewOldTheSame]);
        } else {
            setOldPasswordMsg('');
        }
    };
    const handleNewPassword = (password: string) => {
        setNewPassword(password);
        if (password.length === 0) {
            setNewPasswordMsg(warringMessage[WarningMessageEnum.NewNeedValue]);
            return;
        }

        if (validatePasswordFormat(password)) {
            setNewPasswordMsg('');
        } else {
            setNewPasswordMsg(warringMessage[WarningMessageEnum.WrongFormat]);
        }

        if (password === oldPassword) {
            setOldPasswordMsg(warringMessage[WarningMessageEnum.NewOldTheSame]);
        } else {
            setOldPasswordMsg('');
        }

        if (password === confirmPassword) {
            setConfirmPasswordMsg('');
        } else {
            setConfirmPasswordMsg(warringMessage[WarningMessageEnum.NewConfirmNotTheSame]);
        }
    };

    const handleConfirmPassword = (password: string) => {
        setConfirmPassword(password);
        if (password === newPassword) {
            setConfirmPasswordMsg('');
        } else {
            setConfirmPasswordMsg(warringMessage[WarningMessageEnum.NewConfirmNotTheSame]);
        }
    };

    const handleOnSave = () => {
        const errMsgs = [oldPasswordMsg, newPasswordMsg, confirmPasswordMsg];
        if (errMsgs.every((item) => item === '')) {
            props.onSave(oldPassword, newPassword);
        }
    };

    return (
        <>
            <PopupModel {...props}>
                <div className="flex-col flex h-[380px]">
                    <div className="font-bold grow basis-1/12">{t('Msg_ResetPassword_Info')}</div>
                    <div className="flex-col basis-6/12 mt-2 grow flex">
                        <div className="flex grow ">
                            <div className="basis-1/3 text-right text-sm mt-1 text-gray-500">
                                <span className=" text-red-600">*</span>
                                <span>{t('Lbl_OldPassword')}:</span>
                            </div>
                            <div className="basis-2/3 ml-1">
                                <PasswordInputComp placeHolder={t('Msg_PleaseEnterOldPassword')} onChange={handleOldPassword}></PasswordInputComp>
                                <div className="h-1 text-red-500 text-xs">{oldPasswordMsg}</div>
                            </div>
                        </div>

                        <div className="flex grow">
                            <div className="basis-1/3 text-right text-sm mt-1 text-gray-500">
                                <span className=" text-red-600">*</span>
                                <span>{t('Lbl_Newassword')}:</span>
                            </div>
                            <div className="basis-2/3 ml-1 flex-col flex">
                                <PasswordInputComp placeHolder={t('Msg_PleaseEnterNewPassword')} onChange={handleNewPassword}></PasswordInputComp>
                                <div className="h-1 text-red-500 text-xs">{newPasswordMsg}</div>
                            </div>
                        </div>

                        <div className="flex grow">
                            <div className="basis-1/3 text-right text-sm mt-1 text-gray-500">
                                <span className=" text-red-600">*</span>
                                <span>{t('Lbl_ConfirmPassword')}:</span>
                            </div>
                            <div className="basis-2/3 ml-1">
                                <PasswordInputComp placeHolder={t('Msg_PleaseEnterConfirmPassword')} onChange={handleConfirmPassword}></PasswordInputComp>
                                <div className="h-1 text-red-500 text-xs">{confirmPasswordMsg}</div>
                            </div>
                        </div>
                    </div>
                    {/* Message */}
                    <div data-note className="basis-3/12 flex grow mt-3">
                        <div data-note className="ml-4 mb-4 text-xs">
                            <ul className="ml-4 list-disc">
                                {hintContents.map((text, i) => {
                                    return (
                                        <li key={`note-${i}`} className="text-red-400 whitespace-pre-line">
                                            {text}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="basis-2/12 mt-2 text-center grow">
                        <button className="p-2 bg-affiliate text-white rounded hover:bg-gray-500">
                            <label className="px-1 cursor-pointer" onClick={handleOnSave}>
                                {t('Lbl_UpdatePassword')}
                            </label>
                        </button>
                    </div>
                </div>
            </PopupModel>
        </>
    );
};
