import { PopupModel } from '@/components/popupModal/popupModal';
import { useTranslation } from 'react-i18next';
import { UseDisclosureProps } from '@chakra-ui/react';
import { QRCodeCanvas } from 'qrcode.react';

export type IQRCodePupupProps = {
    title?: string;
    onSave: () => void;
    domain: string;
} & UseDisclosureProps;

export const QRCodePupup = (params: IQRCodePupupProps) => {
    const { t } = useTranslation();
    return (
        <>
            <PopupModel {...params}>
                <div className="flex flex-col text-center">
                    <div className="text-center flex justify-center p-6">
                        <QRCodeCanvas id="gen-QR" value={params.domain} fgColor="black" size={160} />
                    </div>
                    <div>
                        <button className=" m-2 w-24 h-8 bg-affiliate text-white rounded hover:bg-gray-500">
                            <label className="px-1 cursor-pointer" onClick={params.onSave}>
                                {t('Lbl_Download')}
                            </label>
                        </button>
                    </div>
                </div>
            </PopupModel>
        </>
    );
};
