export type IButtonsGroupProps = {
    buttons: IButtonSetting[];
    defaultSelectedKey?: IButtonSetting['key']
    onClick: (key: string) => void;
};

export type ICardPagingTableItem = {
    [key: string]: number | string;
};

export const IPagingDataType = {
    Text: 'Text',
    Partner: 'Partner',
    Date: 'Date',
    DateMonth: 'DateMonth',
    Number: 'Number',
} as const;

export type IPagingDataTypeValue = keyof typeof IPagingDataType;

export interface ICardTablePagingBoardProps<T = object> {
    title: string;
    titleNames: string[];
    keys: ValueOf<T>[];
    items: T[];
    types: IPagingDataTypeValue[];
    buttons: IButtonSetting[];
    onClick: (key: string) => void;
    onPagingChange?: (page: number) => void;
    totalRecords: number;
}

export type ICardTableBoardProps = {
    title: string;
    rowNames: string[];
    buttons: IButtonSetting[];
    defaultSelectedKey?: IButtonSetting['key']
    onClick: (key: string) => void;
    items: ICardTableItem[];
};

export type ICardTableItem = {
    title: string;
    value: number;
    total: number;
    partner: number;
};

export type ICardBoardProps = {
    title: string;
    infos?: IInfoItem<string>[];
    items: ICardItem[];
    cols: number;
    helper: { title: string; content: string[] };
};

export type ICardBoardTabProps = ICardBoardProps & {
    buttons: IButtonSetting[];
    defaultSelectedKey?: IButtonSetting['key']
    onClick: (key: string) => void;
    titleStyle?: { [key: string]: string };
};

export type IButtonSetting = {
    key: IDashboardFilterOptionsValue | string;
    text: string;
};

export type IInfoItem<T> = {
    key: T;
    text: string;
    value: number;
    displayValue?: string;
};

export type ICardItem = {
    title: string;
    cureent: number;
    last: number;
    isHighlight: boolean;
    cureentTitle: string;
    lastTitle: string;
    titleStyle?: { [key: string]: string };
};

// Dashboard Response
export type IDashboard = {
    MemberCommission: { [name: string]: IMemberCommission };
    OperationStatistic: { [name: string]: IOperationStatistic };
    GameStatistic: { [name: string]: IGameStatistic[] };
    ProxyCommission: { [name: string]: IProxyCommission };
    CommissionRate: number;
    OtherInfo: IDashBoardOtherInfo;
};

export type IDashBoardOtherInfo = {
    EffectiveUserCount: number;
    HistroyRechargeUsers: number;
    LastUpdatedDate: string;
};

export type IProxyCommission = {
    ProxyComm: number;
    L2ProxyComm: number;
    L3ProxyComm: number;
    UpAmountReturn: number;
};

export type IDashboardParams = {
    PartnerId: number;
    UserId: number;
    UserIdList?: string[];
};

export type IMemberCommission = {
    Date: string;
    NetWin: number;
    Bonus: number;
    Commission: number;
    PlatformFee: number;
    DepositWithdrawFee: number;
    Rebate: number;
    LastAmount?: number;
};

export type IOperationStatistic = {
    Month: number;
    Date: string;
    NewUsers: number;
    BetUsers: number;
    FirstRechargeNum: number;
    RechargeNum: number;
    UpAmountUser: number;
    TotalWin: number;
    BetAmount: number;
    FirstRechargeSum: number;
    RechargeSum: number;
    UpAmount: number;
};

export type IGameStatistic = {
    Game: string;
    ValidAmount: number;
    TotalWin: number;
    PartnerId: number;
};

export const IDashboardFilterOptions = {
    Today: 'Today',
    Yesterday: 'Yesterday',
    ThisMonth: 'ThisMonth',
    LastMonth: 'LastMonth',
    ThisYear: 'ThisYear',
    TwoDayAgo: 'TwoDayAgo',
} as const;

export type ValueOf<T> = T[keyof T];

export type IDashboardFilterOptionsValue = keyof typeof IDashboardFilterOptions;

export type IRegisterMember = {
    PartnerId: number;
    Amount: number;
    UserName: string;
    RegisterDate: string;
    DomainName: string;
    AgentNickName: string;
    AgentUserCode: string;
};

export type IRegisterMemberSummary = {
    TotalRecords: number;
    Data: IRegisterMember[];
};

export const IRealtimeTableConst = {
    Register: 'Register',
    Recharge: 'Recharge',
    FirstRecharge: 'FirstRecharge',
} as const;

export type IRealtimeTableConstValue = keyof typeof IRealtimeTableConst;

export type IRealtimeHandler = {
    buttonSettings: IButtonSetting[];
    onUpdateRealtimeType: (key: IRealtimeTableConstValue) => void;
    partnerId: number;
    userId: number;
    onUpdateRealtimeData: <T>(data: ICardTablePagingBoardProps<T>) => void;
    onPageChange: (page: number) => void;
    agentIdList: string[];
};

export type ICardInfoTextBoardProps = {
    title: string;
    infos?: IInfoItem<string>[];
    tab: IInfoTextItem;
    helper: { title: string; content: string[] };
};

export type IInfoTextItem = {
    title: string;
    items: IInfoItem<string>[];
};

export type ITextBoardProp = {
    LastUpdatedDate: string;
};

export type IOtherInfoTextBoardProp = {
    EffectiveUserCount: number;
    HistoryRechargeUsers: number;
};
