import { TableSearch, TableSearchEnum } from '@/models/layout/tableLayout';
import { Checkbox, Input, Select } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SearchArea from '.';
import DateRangePicker from '../dateRangePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getEnableConfig } from '@/assets/config/common/partner.config';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import MultiSelect from '../multiSelect';
import { IBaseRequestData } from '@/models/apiRequest';
import { toFirstUpperCase } from '@/service/utils';
import { usePartnerList } from '@/hooks/usePartnerList';

type ReturnFormat = { [key: string]: string };

type Props = {
    search?: TableSearch[];
    defaultValue: IBaseRequestData;
    handler?: (prop: ReturnFormat, isQuick?: boolean) => void;
    isButtonNewLine?: boolean;
};

const DateRangeWithIput = ({ search, defaultValue, handler = () => {}, isButtonNewLine }: Props) => {
    const { t } = useTranslation();
    const hasDateRange = search?.find((item) => item.type === TableSearchEnum.DATE_RANGE || item.type === TableSearchEnum.DATE_RANGE_MONTH);
    const agentUserList = useSelector<RootState, IAgentPartner[]>((state) => state.userStore.agentPartners);

    const defaultPartnerList = usePartnerList();

    const defaultSelectedUserIdStatePool = useMemo(() => {
        const defaultList = defaultPartnerList.map((item) => {
            return {
                partnerId: item.id,
                list: agentUserList
                    .filter((agent) => {
                        return (item.id === 0 || item.id === agent.PartnerId) && agent.IsEnabled;
                    })
                    .map((v) => v.AgentUserId),
            };
        });

        const index = defaultList.findIndex((v) => ~~v.partnerId === ~~defaultValue.PartnerId);

        defaultList[index].list = defaultValue.UserIdList?.map((v: string) => ~~v) || defaultList[index].list;
        return defaultList;
    }, [agentUserList, defaultValue]);

    const [searchData, setSearchData] = useState({});
    const getCurrentDefaultSelectedUserIdList = useMemo(() => {
        const currentPartnerId = (searchData as any)['partnerId'] || 0;
        const index = defaultSelectedUserIdStatePool.findIndex((v) => ~~v.partnerId === ~~currentPartnerId);

        return defaultSelectedUserIdStatePool[index].list;
    }, [defaultSelectedUserIdStatePool, (searchData as any)['partnerId']]);

    const defaultDate = useMemo(() => {
        if (hasDateRange) {
            let _defaultValue = (searchData as any)[hasDateRange.matchKey!];
            if (hasDateRange.type === TableSearchEnum.DATE_RANGE || hasDateRange.type === TableSearchEnum.DATE_RANGE_MONTH) {
                if (hasDateRange.matchKey === 'registerDate') {
                    _defaultValue = (searchData as any)[hasDateRange.matchKey!];
                } else {
                    const startName = hasDateRange.dateMatchKey!['startDate'] || 'startDate';
                    const endName = hasDateRange.dateMatchKey!['endDate'] || 'endDate';
                    _defaultValue = { start: (searchData as any)[startName], end: (searchData as any)[endName] };
                }
            }
            return _defaultValue;
        } else {
            return undefined;
        }
    }, [hasDateRange, searchData]);

    useEffect(() => {
        const data: any = {};
        if (hasDateRange) {
            const startName = hasDateRange?.dateMatchKey!['startDate'] || 'startDate';
            const endName = hasDateRange?.dateMatchKey!['endDate'] || 'endDate';
            data[startName] = defaultValue.DateFrom;
            data[endName] = defaultValue.DateTo;
        }

        search?.forEach((item) => {
            if (item.type !== TableSearchEnum.DATE_RANGE && item.type !== TableSearchEnum.DATE_RANGE_MONTH) {
                const key = item.matchKey!;
                data[key] = defaultValue[toFirstUpperCase(key) as keyof IBaseRequestData];
                const fuzzySearchKey = !!item.fuzzyKey ? item.fuzzyKey : 'IsFuzzySearch';
                data[fuzzySearchKey] = defaultValue[fuzzySearchKey as keyof IBaseRequestData];
            }
        });

        setSearchData(data);
    }, [search, defaultValue]);

    const listWithoutDate = useMemo(() => {
        return search
            ?.filter((item) => item.type !== TableSearchEnum.DATE_RANGE && item.type !== TableSearchEnum.DATE_RANGE_MONTH)
            .map((item) => {
                if (item.matchKey === 'partnerId') {
                    (item.selectOptions as Array<{ id: number; text: string }>) = defaultPartnerList;
                }

                if (item.matchKey === 'userIdList') {
                    // 列出所有的userIdList
                    const currentPartnerId = (searchData as any)['partnerId'] || 0;
                    const selectOptions = agentUserList
                        .filter((item) => ~~currentPartnerId === 0 || ~~currentPartnerId === ~~item.PartnerId)
                        .map((config) => {
                            const getPartner = `Lbl_Partner_${getEnableConfig().find((list) => ~~list.id === ~~config.PartnerId)!.text}`;
                            return {
                                id: config.AgentUserId,
                                text: `${config.NickName}(${t(getPartner)})`,
                            };
                        });

                    (item.selectOptions as Array<{ id: number; text: string }>) = selectOptions;
                }
                return item;
            });
    }, [search, agentUserList, (searchData as any)['partnerId'], t]);

    const styleObject = {
        bgColor: 'white',
        borderColor: 'whiteAlpha.50',
        focusBorderColor: 'black',
        color: 'gray.500',
    };

    const searchHandler = useCallback(() => {
        handler(searchData);
    }, [searchData]);

    const layoutRender = listWithoutDate?.map((item, index) => {
        const _defaultValue = (searchData as any)[item.matchKey!];
        return (
            <div className="flex" key={`label-${item.label}`}>
                <div className="self-center text-center min-w-[99px]">
                    <label className=" text-base whitespace-nowrap">{item.label}:</label>
                </div>
                <div className="w-[370px] min-w-[245px]">
                    {item.type === TableSearchEnum.INPUT ? (
                        <Input
                            {...styleObject}
                            placeholder={item.placeholder}
                            onChange={(e) => {
                                setSearchData((data) => {
                                    const key = item.matchKey || 'data';
                                    (data as any)[key] = e.target.value;
                                    return data;
                                });
                            }}
                        />
                    ) : item.type === TableSearchEnum.SELECT ? (
                        <Select
                            value={_defaultValue}
                            {...styleObject}
                            onChange={(e) => {
                                setSearchData((data) => {
                                    const key = item.matchKey || 'data';
                                    const value = e.target.value;
                                    if (item.matchKey === 'partnerId') {
                                        const userIdList = agentUserList
                                            .filter((v) => v.PartnerId === ~~value || ~~value === 0)
                                            .map((v) => v.AgentUserId.toString())
                                            .filter((v) => defaultValue.UserIdList?.includes(v));
                                        (data as any)['userIdList'] = userIdList;
                                    }
                                    (data as any)[key] = value;
                                    return { ...data };
                                });
                            }}>
                            {item.selectOptions?.map((listItem, i) => (
                                <option key={`select-opt-${listItem.id}`} value={listItem.id}>
                                    {listItem.withoutI18n ? listItem.text : t(listItem.text)}
                                </option>
                            ))}
                        </Select>
                    ) : item.type === TableSearchEnum.MULTI_SELECT ? (
                        <MultiSelect
                            className="w-[350px]"
                            label="全部"
                            options={item.selectOptions || []}
                            defaultSelectOptions={getCurrentDefaultSelectedUserIdList}
                            onChange={(newValue) => {
                                setSearchData((data) => {
                                    const key = item.matchKey || 'data';
                                    (data as any)[key] = newValue;
                                    return data;
                                });
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                {item.hasFuzzy && (
                    <div className="p-2">
                        <Checkbox
                            onChange={(e) => {
                                setSearchData((data) => {
                                    const fuzzySearchKey = !!item.fuzzyKey ? item.fuzzyKey : 'IsFuzzySearch';
                                    return { ...data, [fuzzySearchKey]: e.target.checked };
                                });
                            }}>
                            {t('Lbl_fuzzy')}
                        </Checkbox>
                    </div>
                )}
            </div>
        );
    });
    return (
        <>
            {hasDateRange && (
                <div className="ml-4">
                    <DateRangePicker
                        defaultDate={defaultDate}
                        minDate={hasDateRange.minDate}
                        format={hasDateRange.type === TableSearchEnum.DATE_RANGE_MONTH ? 'YM' : 'YMD'}
                        shortcuts={hasDateRange.type === TableSearchEnum.DATE_RANGE_MONTH ? 'monthOnly' : true}
                        valueHandler={({ startDate, endDate, isQuick }) => {
                            setSearchData((data) => {
                                const startName = hasDateRange.dateMatchKey!['startDate'] || 'startDate';
                                const endName = hasDateRange.dateMatchKey!['endDate'] || 'endDate';

                                (data as any)[startName] = dayjs(startDate).format();
                                (data as any)[endName] = dayjs(endDate).format();

                                if (!listWithoutDate || listWithoutDate.length === 0 || isQuick) {
                                    handler(data, isQuick);
                                }
                                return data;
                            });
                        }}
                    />
                </div>
            )}

            {listWithoutDate && listWithoutDate.length > 0 && (
                <div className="mt-2 flex">
                    <SearchArea triggerHandler={searchHandler} isButtonNewLine={isButtonNewLine}>
                        <div className="flex">{layoutRender}</div>
                    </SearchArea>
                </div>
            )}
        </>
    );
};

export default DateRangeWithIput;
