import { getEnableConfig, PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import { PopupModel } from '@/components/popupModal/popupModal';
import { IBaseRequestData } from '@/models/apiRequest';
import { Button, ButtonGroup, Input, Select, Table, TableContainer, Tbody, Td, Th, Thead, Tr, UseDisclosureProps } from '@chakra-ui/react';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '@/service/api';
import useFetchApi from '@/hooks/useFetchApi';
import dayjs from 'dayjs';
import { IMemberPartner, IRelationAccountQueryProps, IRelationAccountResponseModel, StatusEnum } from '@/models/page/memberAdmin/IRelationAccountItemModel';
import { useTranslation } from 'react-i18next';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { NoteInfos } from '@/components/noteInfos/noteInfos';
import { DATETIME_FORMAT } from '@/constants/datetime';

export type IRelationMemberItem = {
    PartnerId: number;
    AgentNickName: string;
    AgentUserCode: string;
    UserName: string; // Member Account
    MemberNickname: string;
    IsReadOnly: boolean;

    IsExist: boolean;
    IsNew?: boolean;
};

export type IUpdateGroupMembersPopupProps = {
    groupAgentUserCode: string;
    groupPartnerId: number;
    groupMemberName: string;
    onSave: (members: IMemberPartner[]) => void;
} & UseDisclosureProps;

export interface ICheckMemberRequestData extends IBaseRequestData {
    MemberName: string;
}

export enum CheckMemberResponseCodeEnum {
    Success = 0,
    NoPermission = 100,
    MemberNotExists = 200,
    MemberNotSameTree = 201,
}

export type ICheckMemberRequestModel = {
    SourceAgentUserCode: string;
    SourcePartnerId: number;
    SourceUserName: string;
    TargetPartnerId: number;
    TargetUserName: string;
    MemberPartners: IRelationMemberItem[];
};

export type ICheckMemberResponseModel = {
    AgentNickName: string;
    AgentUserCode: string;
    MemberNickname: string;
    GroupName: string;
    RemainMember: number;
    ReturnCode: CheckMemberResponseCodeEnum;
};

const col = [
    { label: 'Lbl_serialId', name: 'Id' },
    { label: 'Th_partnerId', name: 'PartnerId' },
    { label: 'Th_agentNickName', name: 'AgentNickName' },
    { label: 'Lbl_MemberAccount', name: 'MemberAccount' },
    { label: 'Txt_NickName', name: 'MemberNickname' },
    { label: 'Lbl_optional', name: 'optional' },
];

export const UpdateGroupMembersPopup = (params: IUpdateGroupMembersPopupProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [relationMembers, setRelationMembers] = useState<IRelationMemberItem[]>([]);
    const noteInfos: string[] = [t('Msg_EditMemberRelationInfo_1'), t('Msg_EditMemberRelationInfo_2')];

    const selectPartnerOptions = getEnableConfig().map((config) => {
        return {
            id: config.id,
            text: t(`Lbl_Partner_${config.text}`),
        };
    });

    const membersQueryModel = useMemo<IRelationAccountQueryProps>(() => {
        return {
            PartnerId: 0,
            DateFrom: dayjs().date(1).format(DATETIME_FORMAT.YMDHMSS),
            DateTo: dayjs().endOf('day').format(DATETIME_FORMAT.YMDHMSS),
            EffectiveStatus: StatusEnum.All,
            AgentUserCode: params.groupAgentUserCode,
            MemberPartnerId: params.groupPartnerId,
            MemberUserName: params.groupMemberName,
        };
    }, []);

    const [checkQueryModel, setCheckQueryModel] = useState<ICheckMemberRequestModel>({
        SourceAgentUserCode: params.groupAgentUserCode,
        SourcePartnerId: params.groupPartnerId,
        SourceUserName: params.groupMemberName,
        TargetPartnerId: selectPartnerOptions[0].id,
        TargetUserName: '',
        MemberPartners: [],
    });

    const handelOnSave = () => {
        params.onSave(
            relationMembers
                .filter((item) => item.IsExist)
                .map((item) => ({
                    AgentUserCode: item.AgentUserCode,
                    PartnerId: item.PartnerId,
                    UserName: item.UserName,
                    IsReadOnly: true,
                })),
        );
    };

    const checkIsCurrent = (item: IRelationMemberItem) =>
        item.PartnerId === params.groupPartnerId && item.AgentUserCode === params.groupAgentUserCode && item.UserName === params.groupMemberName;

    const detachCheckMember = useFetchApi<ICheckMemberRequestModel, ICheckMemberResponseModel, ICheckMemberRequestModel>(
        api.relationCheckRelatedMember,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setIsLoading(false);

            if (!value || !value.data) {
                return;
            }

            if (value.data.IsSuccess) {
                const item = value.data.Data;

                switch (item.ReturnCode) {
                    case CheckMemberResponseCodeEnum.Success: {
                        setRelationMembers((pre) => [
                            ...pre,
                            {
                                PartnerId: checkQueryModel.TargetPartnerId,
                                AgentNickName: item.AgentNickName,
                                AgentUserCode: item.AgentUserCode,
                                UserName: checkQueryModel.TargetUserName, // Member Account
                                MemberNickname: item.MemberNickname,
                                IsReadOnly: false,
                                IsExist: true,
                                IsNew: true,
                            },
                        ]);

                        if (!!item.GroupName && item.GroupName.length > 0) {
                            dispatch(
                                showDialog({
                                    title: t('Lbl_Button_Info'),
                                    content:
                                        `${t('Msg_MemberAlreadyExistInGroup')
                                            .replace('{0}', checkQueryModel.TargetUserName)
                                            .replace('{1}', `"${item.GroupName}"`)}` + `\n (${t('Msg_RemainingNumber')}: ${item.RemainMember})`,
                                    type: DialogButtonType.Info,
                                }),
                            );
                        }

                        break;
                    }
                    default: {
                        dispatch(
                            showDialog({
                                title: t('Lbl_Button_Info'),
                                content: t(`Lbl_CheckMemberResponseCodeEnum_${item.ReturnCode}`),
                                type: DialogButtonType.Info,
                            }),
                        );
                    }
                }
            } else {
            }
        },
        [checkQueryModel],
        (error) => {
            setIsLoading(false);
        },
    );

    const detachGetGroupDetailItems = useFetchApi<IRelationAccountQueryProps, IRelationAccountResponseModel, IRelationAccountQueryProps>(
        api.relationMemberGroupDetail,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setIsLoading(false);

            if (!value || !value.data) {
                return;
            }

            if (value.data.IsSuccess) {
                const rep = value.data.Data;
                const items = rep.Details.map<IRelationMemberItem>((item) => ({
                    PartnerId: item.PartnerId,
                    AgentNickName: item.AgentNickName,
                    AgentUserCode: item.AgentUserCode,
                    UserName: item.UserName,
                    MemberNickname: item.MemberNickname,
                    IsExist: true,
                    IsReadOnly: item.IsReadOnly,
                })).sort((a, b) => (checkIsCurrent(a) ? -1 : checkIsCurrent(b) ? 1 : a.PartnerId - b.PartnerId));

                setRelationMembers(items);
            } else {
            }
        },
        [membersQueryModel],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        detachGetGroupDetailItems(membersQueryModel);
    }, []);

    const handleCheckMembers = () => {
        const alreadyExist = relationMembers.some(
            (item) => checkQueryModel.TargetPartnerId === item.PartnerId && item.UserName === checkQueryModel.TargetUserName.trim(),
        );

        if (alreadyExist) {
            dispatch(
                showDialog({
                    title: t('Lbl_Button_Info'),
                    content: t('Lbl_MemberAlreadyExistList'),
                    type: DialogButtonType.Info,
                }),
            );

            return;
        }

        detachCheckMember(checkQueryModel);
    };

    const forceRender = useReducer((a) => !a, false)[1];

    return (
        <>
            <LoadingMask visible={isLoading} />
            <PopupModel {...params} title={t('Lbl_UpdateGroupRelation')} size="3xl">
                <div className="flex-col ">
                    <div className="flex m-3">
                        <div className=" flex basis-1/3 mr-2">
                            <label className=" whitespace-nowrap self-center mr-2">{t('Th_partnerId')}:</label>
                            <Select
                                defaultValue={selectPartnerOptions[0].id}
                                onChange={(e) => {
                                    setCheckQueryModel((pre) => ({ ...pre, TargetPartnerId: Number(e.target.value) }));
                                }}>
                                {selectPartnerOptions.map((item) => (
                                    <option key={`partnerid-re-mem-${item.id}`} value={item.id}>
                                        {item.text}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className=" flex basis-1/2">
                            <label className="whitespace-nowrap self-center mr-2">{t('Th_userName')}:</label>
                            <Input
                                maxLength={20}
                                placeholder={t('Msg_PleaseEnterUserName')}
                                onChange={(e) => {
                                    setCheckQueryModel((pre) => ({ ...pre, TargetUserName: e.target.value.trim() }));
                                }}
                            />
                        </div>
                        <div className=" flex basis-1/6 justify-end">
                            <button className="p-2 bg-affiliate text-white rounded hover:bg-gray-500 ml-2" onClick={() => handleCheckMembers()}>
                                <label className="px-1 cursor-pointer">{t('Lbl_Add')}</label>
                            </button>
                        </div>
                    </div>

                    <div data-container className=" shrink-0 mx-4 mb-5 h-[500px] overflow-y-auto">
                        <TableContainer>
                            <Table variant="striped" colorScheme="blackAlpha">
                                <Thead>
                                    <Tr>
                                        {col.map((item) => {
                                            return (
                                                <Th key={`relation-account-th-${item.label}`}>
                                                    <span className="font-bold">{t(item.label)}</span>
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                </Thead>

                                <Tbody>
                                    {!!relationMembers &&
                                        relationMembers.length > 0 &&
                                        relationMembers.map((item, idx) => (
                                            <Tr key={`tr-key-${item.UserName}-${item.AgentNickName}-${item.PartnerId}-${item.IsExist}`}>
                                                <Td>{idx + 1}</Td>
                                                <Td>{t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[item.PartnerId].text}`)}</Td>
                                                <Td>{item.AgentNickName}</Td>
                                                <Td>
                                                    {item.UserName}

                                                    {item.IsNew && (
                                                        <span className=" bg-red-500 text-gray-100 rounded-md p-1 pl-2 pr-2 ml-1">{t('Lbl_New')}</span>
                                                    )}
                                                </Td>
                                                <Td>{item.MemberNickname}</Td>
                                                <Td>
                                                    {!(checkIsCurrent(item) || item.IsReadOnly) && (
                                                        <button
                                                            className="self-end p-2 bg-affiliate text-white rounded hover:bg-gray-500"
                                                            onClick={() => {
                                                                item.IsExist = !item.IsExist;
                                                                forceRender();
                                                            }}>
                                                            <span className={`px-1 icon ${item.IsExist ? 'icon-refresh' : 'icon-cancel-circle'}`} />
                                                            <label className="px-1 cursor-pointer">
                                                                {t(`${item.IsExist ? 'Lbl_DeleteRelation' : 'Lbl_RecoverRelation'}`)}
                                                            </label>
                                                        </button>
                                                    )}
                                                </Td>
                                            </Tr>
                                        ))}

                                    {!!params && relationMembers.length === 0 ? (
                                        <Tr>
                                            <Td fontSize="2xl" colSpan={col.length} className="text-center" textAlign="center">
                                                {t('Lbl_NoRecord')}
                                            </Td>
                                        </Tr>
                                    ) : (
                                        <></>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </div>

                    <NoteInfos notes={noteInfos} />

                    <div className="flex-col mt-2 grow flex">
                        {/* 確認 */}
                        <div className="flex grow justify-center">
                            <div className="ml-1 flex-col">
                                <ButtonGroup gap="2">
                                    <Button colorScheme="gray" onClick={() => params.onClose && params.onClose()}>
                                        {t('Lbl_Button_Cancle')}
                                    </Button>
                                    <Button colorScheme="orange" onClick={() => handelOnSave()}>
                                        {t('Lbl_Button_Confirm')}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </PopupModel>
        </>
    );
};
