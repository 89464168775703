import { MinusIcon, RepeatIcon, UpDownIcon } from '@chakra-ui/icons';
import React, { forwardRef, useEffect } from 'react';
import { useChatViewUrl } from '@/components/chatView/useChatViewUrl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { dismissChat, summonChat } from '@/store/slices/chat.slice';
import cn from 'mxcn';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ChatToast = forwardRef<HTMLIFrameElement>((_, ref) => {
    const { t } = useTranslation()
    const isPresented = useSelector<RootState, boolean>(s => s.chatStore.isPresented);
    const unreadMessageCount = useSelector<RootState, number>(s => s.chatStore.unreadMessageCount);
    const dispatch = useDispatch();

    const [chatViewUrl, fetchChatViewUrl] = useChatViewUrl();

    function handleHeaderClick() {
        if (isPresented) {
            dispatch(dismissChat());
        } else {
            dispatch(summonChat());
        }
    }

    useEffect(() => {
        fetchChatViewUrl({});
    }, []);

    return (
        <div id="chat-toast" className="fixed bottom-0 right-8">
            <div className={cn('flex flex-col rounded-t-md bg-vchat text-vchat-50 drop-shadow-lg transition-all', isPresented ? 'w-[35vw] max-w-[400px]' : 'w-[20vw] max-w-[210px]')}>
                <div data-toast-chat-header className="h-12 border-b border-gray-300 p-2 flex items-center justify-between" onClick={handleHeaderClick}>
                    <div className="flex-1 flex items-center gap-2">
                        <span className='icon icon-vchat' />
                        <span>
                            {t('Txt_VChat')}
                        </span>
                        {unreadMessageCount > 0 && <span className='bg-red-600 rounded-2xl px-2 text-white text-xs'>{unreadMessageCount}</span>}
                    </div>

                    <ButtonGroup size="sm" isAttached colorScheme='whiteAlpha' variant="ghost" data-header-operator>
                        {isPresented ? (
                            <Button className="group" colorScheme='whiteAlpha' variant="ghost" onClick={() => dispatch(dismissChat())}>
                                <MinusIcon w={4} h={4} />
                            </Button>
                        ) : (
                            <Button className="group" colorScheme='whiteAlpha' variant="ghost" onClick={() => dispatch(summonChat())}>
                                <UpDownIcon w={4} h={4} />
                            </Button>
                        )}

                        {isPresented && (
                            <Button
                                colorScheme="whiteAlpha"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    fetchChatViewUrl({});
                                }}>
                                <RepeatIcon w={4} h={4} />
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
                <div data-toast-chat-container className={cn('transition-all', isPresented ? 'visible h-[70vh]' : 'invisible h-0')}>
                    <iframe ref={ref} src={chatViewUrl} className="w-full h-full" />
                </div>
            </div>
        </div>
    );
})

export default ChatToast;
