import { useEffect } from 'react';
import { api } from '@/service/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { removeToken } from '@/store/slices/user.slice';

export default function KickOut() {
    const apiHost = useSelector((state: RootState) => state.configStore.apiHost);
    const user = useSelector((state: RootState) => state.userStore);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(removeToken());
        if (user.token) {
            apiHost &&
                fetch(`${apiHost.host}${api.kickOut.path}`, {
                    method: 'POST',
                    headers: { token: user.token ?? '' },
                    body: user.userName,
                })
                    .then(() => {})
                    .catch(console.error);
        }
        window.location.href = '/';
    }, [apiHost, user]);

    return null;
}
