import TitleLabel from '@/components/titleLabel';
import { useTranslation } from 'react-i18next';
import { IUserInfo } from '@/models/entities/IUserInfo';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { RootState } from '@/store/store.config';
import { Box, Card, CardBody, Heading, Stack, StackDivider, Text, Input, TableContainer, Table, Tbody, Thead, Tr, Th, Td, Checkbox } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { removeToken, updateAgentPartners, updateUserVChatId } from '@/store/slices/user.slice';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import { ProfileTabs } from './profileTabs/profileTabs';
import { IVChatUser } from '@/models/chatMessage/IChatMessage';
import { BindingVChatPopup, IBindingVChatModelProps } from './profile/bindingVChatPopup';
import { useNavigate } from 'react-router-dom';

enum ColType {
    TEXT,
    INPUT,
    CHECKBOX,
    PARTNER,
}

export type IVChatBinding = {
    BindingId: string;
    BindingType: number;
    Password: string;
};

const ProfilePage = () => {
    const { t } = useTranslation();
    const userInfo = useSelector<RootState, IUserInfo>((store) => store.userStore);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const props: IBindingVChatModelProps = {
        title: t('Lbl_Binding_VChatId'),
        isOpen: isOpen,
        onClose: () => {
            setIsOpen(false);
        },
        onSave: (password, vChatId) => {
            saveVChatUser({
                BindingId: vChatId,
                Password: password,
                BindingType: 1,
            });

            setIsOpen(false);
        },
    };

    const logout = () => {
        dispatch(removeToken());
        navigate('/logon');
    };

    const col = [
        { label: 'Th_partnerId', name: 'PartnerId', type: ColType.PARTNER },
        { label: 'Th_proxyName', name: 'AgentUserCode', type: ColType.TEXT },
        { label: 'Lbl_nickName', name: 'NickName', require: true, type: ColType.INPUT, maxLength: 20 },
        { label: 'Lbl_note', name: 'Remark', type: ColType.INPUT, maxLength: 50 },
        { label: 'Lbl_defaultSelected', name: 'IsEnabled', type: ColType.CHECKBOX },
    ];

    const [request, setRequest] = useState<IAgentPartner[]>(userInfo.agentPartners);
    const [highlightList, setHighlightList] = useState<number[]>([]);
    const [vChatUserInfo, setVChatUserInfo] = useState<IVChatUser>();

    const onValueChange = useCallback((value: string | number | boolean, key: string, id: number) => {
        setRequest((list) => {
            const newList = [...list];
            const targetObj = newList[id];
            newList[id] = { ...targetObj, [key as keyof IAgentPartner]: value };
            return newList;
        });
    }, []);

    const dispatchDialog = useCallback(() => {
        dispatch(
            showDialog({
                title: t('Lbl_Button_Info'),
                content: t('Txt_saveSuccess'),
                type: DialogButtonType.Info,
            }),
        );
    }, [t]);

    const dispatchNoPickDialog = useCallback(() => {
        dispatch(
            showDialog({
                title: t('Lbl_Button_Info'),
                content: t('Msg_Profile_AtLeastPickOne'),
                type: DialogButtonType.Info,
            }),
        );
    }, [t]);

    const detectChangeAndFetchHandler = useFetchApi<IAgentPartner[], any, IAgentPartner[]>(
        api.updateSetting,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_payload] }) => {
            if (value.data.IsSuccess) {
                dispatchDialog();
                dispatch(updateAgentPartners(_payload));
            }
            setIsLoading(false);
        },
        [request],
        (error) => {
            setIsLoading(false);
        },
    );

    const getColElement = useCallback(
        (list: IAgentPartner, name: string, type: ColType, colId: number, maxLength?: number) => {
            const value = list[name as keyof IAgentPartner];

            const styleObject =
                name === 'NickName' && highlightList.includes(colId)
                    ? {
                          borderColor: 'red',
                      }
                    : {};
            switch (type) {
                case ColType.TEXT: {
                    return <span>{value}</span>;
                }
                case ColType.INPUT: {
                    return (
                        <Input
                            {...styleObject}
                            defaultValue={value + ''}
                            placeholder={value + ''}
                            maxLength={maxLength}
                            onChange={(e) => {
                                onValueChange(e.target.value, name, colId);
                            }}
                        />
                    );
                }
                case ColType.CHECKBOX: {
                    return (
                        <Checkbox
                            bg={'white'}
                            border={'1px'}
                            defaultChecked={!!value}
                            onChange={(e) => {
                                onValueChange(e.target.checked, name, colId);
                            }}
                        />
                    );
                }
                case ColType.PARTNER: {
                    const partnerId = Number(value);
                    const config = PARTNERS_CONFIGS_MAP[partnerId];
                    const result = partnerId === 0 ? 'Lbl_Partner_All' : `Lbl_Partner_${config.text}`;
                    return <span>{t(result)}</span>;
                }
            }
        },
        [highlightList, t],
    );

    const saveHandler = useCallback(() => {
        const pickNoAccount = request.filter((item) => item.IsEnabled).length === 0;
        if (pickNoAccount) {
            dispatchNoPickDialog();
            return;
        }

        const nickNameInverifiedList = request
            .map((item, id) => ({ item, id }))
            .filter((item) => !item.item.NickName)
            .map((item) => item.id);
        setHighlightList(nickNameInverifiedList);
        if (nickNameInverifiedList.length === 0) {
            detectChangeAndFetchHandler(request);
        }
    }, [request]);

    const getVChatUser = useFetchApi<{ vChatId: string }, IVChatUser>(
        api.getVChatUser,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value: resp, request, deps: [_dispatch, i18n] }) => {
            setIsLoading(false);
            if (!resp?.data?.IsSuccess) {
                return;
            }

            setVChatUserInfo(resp.data.Data);
            dispatch(updateUserVChatId(resp.data?.Data?.VChatID || ''));
        },
        [dispatch, t],
        (error) => {
            // setResp({ Data: [] });
            setIsLoading(false);
        },
    );

    const warringMessage: { [key: string]: string } = {
        ['999999']: t('Txt_updateFailed'),
        ['200004']: t('Msg_VChat_Binding_Error_200004'),
        ['200005']: t('Msg_VChat_Binding_Error_200005'),
    };

    const saveVChatUser = useFetchApi<IVChatBinding>(
        api.saveVChatUserBinding,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value: resp, request }) => {
            setIsLoading(false);

            dispatch(
                showDialog({
                    title: t('Lbl_Binding_VChatId'),
                    content: t(resp?.data?.IsSuccess ? 'Txt_updateSuccess' : warringMessage[resp.data.Message] || 'Txt_updateFailed'),
                    onClose: () => {
                        if (resp?.data?.IsSuccess) {
                            // getVChatUser({ vChatId: request.BindingId });
                            logout();
                        }
                    },
                    type: resp?.data?.IsSuccess ? DialogButtonType.Info : DialogButtonType.Error,
                }),
            );

            if (resp.data.IsSuccess) {
                setTimeout(() => logout(), 10 * 1000);
            }
        },
        [dispatch, t],
        () => {
            // setResp({ Data: [] });
            setIsLoading(false);
        },
    );

    useEffect(() => {
        if (!userInfo || !userInfo.vChatId || userInfo.vChatId.length === 0) {
            return;
        }
        getVChatUser({ vChatId: userInfo.vChatId });
    }, [userInfo]);

    const onClickBindingVChatId = () => {
        setIsOpen(true);
    };

    const onClickRemoveVChatId = () => {
        dispatch(
            showDialog({
                title: t('Lbl_Remove_VChatId'),
                content: (
                    <div className=" flex flex-col text-left ml-2">
                        <span>{`${t('Lbl_Remove_VChatId')}: ${vChatUserInfo?.NickName} (${vChatUserInfo?.VChatID})`}</span>
                        <span className="text-red-400 whitespace-pre-line text-xs">移除成功，将会自动登出，请重新登入。</span>
                    </div>
                ),
                type: DialogButtonType.Info,
                onConfirm: () => {
                    saveVChatUser({
                        BindingId: '',
                        Password: '',
                        BindingType: 1,
                    });
                },
            }),
        );
    };

    return (
        <>
            <ProfileTabs />
            <div className="my-4">
                <TitleLabel>{t('Txt_profile')}</TitleLabel>
            </div>
            <div data-header className="flex ml-4">
                <Card>
                    <CardBody className="bg-gray-100">
                        {/* <Text>View a summary of all your customers over the last month.</Text> */}
                        <Stack direction="row" divider={<StackDivider />} spacing="4">
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {t('Txt_username')}
                                </Heading>
                                <Text pt="2" fontSize="lg" className="text-gray-500 font-bold">
                                    {userInfo.userName}
                                </Text>
                            </Box>

                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    {t('Th_registerDate')}
                                </Heading>
                                <Text pt="2" fontSize="lg" className="text-gray-500 font-bold">
                                    {dayjs(userInfo.registerDate).format('YYYY/MM/DD hh:mm:ss')}
                                </Text>
                            </Box>

                            {!!vChatUserInfo ? (
                                <Box>
                                    <Heading size="xs" textTransform="uppercase">
                                        {t('Lbl_ChatAdminNameTitle')}
                                    </Heading>
                                    <div className="flex">
                                        <Text pt="2" fontSize="lg" className="text-gray-500 font-bold">
                                            {vChatUserInfo.NickName}({vChatUserInfo.VChatID})
                                        </Text>
                                        <button className="ml-2 self-end p-2 bg-affiliate text-white rounded hover:bg-gray-500" onClick={onClickRemoveVChatId}>
                                            <label className="px-1 cursor-pointer">{t('Lbl_Remove_VChatId')}</label>
                                        </button>
                                    </div>
                                </Box>
                            ) : (
                                <Box>
                                    <Heading size="xs" textTransform="uppercase">
                                        {t('Th_VChatId')}
                                    </Heading>
                                    <button className="self-end p-2 bg-affiliate text-white rounded hover:bg-gray-500" onClick={onClickBindingVChatId}>
                                        <label className="px-1 cursor-pointer">{t('Lbl_Binding_VChatId')}</label>
                                    </button>
                                </Box>
                            )}
                        </Stack>
                    </CardBody>
                </Card>
            </div>
            <div data-container className="mx-4">
                <TableContainer>
                    <Table variant="striped" colorScheme="blackAlpha">
                        <Thead>
                            <Tr>
                                {col.map((item) => {
                                    return (
                                        <Th key={`userInfo-th-${item.name}`}>
                                            <span className="font-bold">
                                                {!!item.require && <span className="text-red-500">*</span>}
                                                {t(item.label)}
                                            </span>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {!!userInfo.agentPartners &&
                                userInfo.agentPartners.map((list, key) => {
                                    return (
                                        <Tr key={`userInfo-body-tr-${key}`}>
                                            {col.map((item) => (
                                                <Td key={`userInfo-body-td-${key}-${item.name}`}>
                                                    {getColElement(list, item.name, item.type, key, item.maxLength)}
                                                </Td>
                                            ))}
                                        </Tr>
                                    );
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>

            <div className="flex justify-center my-2">
                <button className="self-end p-2 bg-affiliate text-white rounded hover:bg-gray-500" onClick={saveHandler}>
                    {/* <span className="px-1 icon icon-key"></span> */}
                    <label className="px-1 cursor-pointer">保存</label>
                </button>
            </div>
            <div data-note className="ml-4">
                <ul className="text-red-500 text-sm">
                    <li>*"预设选取":设定报表内搜寻条件"所属代理"下拉选单的预设值</li>
                    <li>*系统预设代理显示名称与帐号相同</li>
                    <li>*代理别名最多可输入20个字元，不允许空值</li>
                    <li>*备注最多可输入50个字元</li>
                </ul>
            </div>
            <div data-copryright></div>
            <LoadingMask visible={isLoading} />
            <BindingVChatPopup {...props} key={`${isOpen}`} />
        </>
    );
};

export default ProfilePage;
