import useFetchApi from '@/hooks/useFetchApi';
import { ISaveSubAccountRequest, SaveType } from '@/models/page/systemSetting';
import { api } from '@/service/api';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISystemSettingPopupLayoutProps } from '../layout.mapping';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
    status?: boolean;
    account?: string;
    updateStatusFn?: (isSuccess: boolean) => void;
    loadingVisibleFn?: (visible: boolean) => void;
} & ISystemSettingPopupLayoutProps;
const UpdateAccountStatus = ({ status, account, onClose, updateStatusFn, loadingVisibleFn }: Props) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(status ? '1' : '2');
    const updateStatus = useFetchApi<ISaveSubAccountRequest>(
        api.saveAndUpdateSubAccount,
        ({ value, deps: [setIsLoading] }) => {
            setIsLoading?.(true);
            return value;
        },
        ({ value, deps: [setIsLoading] }) => {
            onClose();
            setIsLoading?.(false);
            if (value.data.IsSuccess) {
                updateStatusFn?.(true);
            } else {
                updateStatusFn?.(false);
            }
        },
        [loadingVisibleFn],
        (error, [setIsLoading]) => {
            onClose();
            setIsLoading?.(false);
        },
    );
    return (
        <div className="flex flex-col text-center">
            <div className="text-center flex justify-center p-6">
                <RadioGroup onChange={setValue} value={value}>
                    <Stack direction="row">
                        <Radio value="1">啟用</Radio>
                        <Radio value="2">停用</Radio>
                    </Stack>
                </RadioGroup>
            </div>
            <div>
                <button
                    className=" m-2 w-24 h-8 bg-gray-400 text-white rounded hover:bg-gray-500"
                    onClick={() => {
                        onClose();
                    }}>
                    <label className="px-1 cursor-pointer">{t('Lbl_Button_Cancle')}</label>
                </button>

                <button
                    className=" m-2 w-24 h-8 bg-affiliate text-white rounded hover:bg-gray-500"
                    onClick={() => {
                        !!account && updateStatus({ SaveType: SaveType.UPDATE_STATUS, Account: account, IsActive: value === '1' });
                    }}>
                    <label className="px-1 cursor-pointer">{t('Btn_confirm')}</label>
                </button>
            </div>
        </div>
    );
};

export default UpdateAccountStatus;
