import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import { APIModel, IMemberRequestData } from '@/models/apiRequest';
import { IMemberDetailsData, IReportMemberInfoData, IReportMemberStatisticsData } from '@/models/page/report/IReportMemberDetails';
import {
    Center,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    UseDisclosureProps,
} from '@chakra-ui/react';
import React, { createElement, Dispatch, ForwardedRef, forwardRef, SetStateAction, useImperativeHandle, useMemo, useState } from 'react';
import MemberDetailsHeader from './header';
import { layoutMapping, layoutNameMapping, MemberDetailsLayout } from './layout/layout.mapping';

export type MemberModelRef = {
    onMemberDataTrigger(api: APIModel, request: IMemberRequestData): void;
};

export type MemberDetailsLayoutProps = {
    memberDetail: IReportMemberInfoData;
    statistics: IReportMemberStatisticsData;
    requestData: IMemberRequestData;
    isLoaded: boolean;
    setLayout: Dispatch<SetStateAction<MemberDetailsLayout>>;
};

const MemberModel = forwardRef<MemberModelRef, UseDisclosureProps>(MemberModal);

function MemberModal(props: UseDisclosureProps, ref: ForwardedRef<MemberModelRef>) {
    const { isOpen, onOpen, onClose } = useDisclosure(props);
    const [memberDetail, setMemberDetail] = useState<IReportMemberInfoData>();
    const [statistics, setStatistics] = useState<IReportMemberStatisticsData>();
    const [currentRequest, setRequest] = useState<IMemberRequestData>();
    const [isLoaded, setIsLoaded] = useState(false);
    const { t } = useTranslation();

    const detectChangeAndFetchHandler = useFetchApi<IMemberRequestData, IMemberDetailsData>(
        { path: '', method: 'GET' },
        ({ value }) => {
            setIsLoaded(false);
            return value;
        },
        ({ value: resp }) => {
            setIsLoaded(true);
            setMemberDetail(resp.data.Data.MemberInfo);
            setStatistics(resp.data.Data.MemberStatistic);
        },
        [],
        (error) => {
            setIsLoaded(false);
        },
    );
    useImperativeHandle(ref, () => ({
        onMemberDataTrigger: (api, request) => {
            setMemberDetail(undefined);
            setStatistics(undefined);
            detectChangeAndFetchHandler({ ...request, api });
            setRequest(request);
            onOpen();
        },
    }));

    const [currentLayout, setLayout] = useState<MemberDetailsLayout>(MemberDetailsLayout.MAIN);

    const layoutProps = useMemo<MemberDetailsLayoutProps | undefined>(() => {
        if (memberDetail && statistics && currentRequest) {
            return {
                memberDetail,
                statistics,
                requestData: currentRequest,
                isLoaded,
                setLayout,
            };
        }
    }, [memberDetail, statistics, currentRequest, isLoaded, setLayout]);

    const getCurrentLayout = useMemo(() => layoutMapping[currentLayout], [currentLayout]);

    return (
        <>
            <Modal
                onClose={() => {
                    onClose();
                    setLayout(MemberDetailsLayout.MAIN);
                }}
                size="xl"
                isOpen={isOpen}
                isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="bg-affiliate text-gray-100 rounded-t">
                        <Center>
                            <Text fontSize="xl">{t(layoutNameMapping[currentLayout])}</Text>
                        </Center>
                    </ModalHeader>
                    <ModalCloseButton className="text-gray-100" />
                    <ModalBody>
                        {currentLayout !== MemberDetailsLayout.MAIN && (
                            <div className="cursor-pointer my-2" onClick={() => setLayout(MemberDetailsLayout.MAIN)}>
                                <span className="icon icon-keyboard_arrow_left"></span>
                                返回
                            </div>
                        )}
                        <MemberDetailsHeader
                            memberDetail={memberDetail}
                            currentRequest={currentRequest}
                            isLoaded={isLoaded}
                            onClose={onClose}></MemberDetailsHeader>
                        {layoutProps && createElement(getCurrentLayout, layoutProps)}
                    </ModalBody>
                    <ModalFooter>{/* <Button onClick={onClose}>Close</Button> */}</ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default MemberModel;
