import { IMemberAdminPageProps } from '../pageConfig';
import { useTranslation } from 'react-i18next';
import DateRangePicker, { IValueType } from '@/components/dateRangePicker';
import MemberStatisticsTable from '@/pages/memberAdmin/admin/statistics/memberStatisticsTable';
import { useMemo, useRef, useState } from 'react';
import { MemberStatisticsChart } from '@/pages/memberAdmin/admin/statistics/memberStatisticsChart';
import dayjs from 'dayjs';
import {
    MemberStatisticType,
    transformStatisticType,
    useMemberDetailsData,
    useMemberStatisticsData,
} from '@/pages/memberAdmin/admin/statistics/useStatisticsData';
import { MemberItineraryModal, IMemberItineraryModalRef } from '@/components/memberItineraryModal/memberItineraryModal';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { DATETIME_FORMAT } from '@/constants/datetime';

const MemberAdminStatisticsPage = ({ MemberPartnerId, MemberUserName }: IMemberAdminPageProps) => {
    const { t } = useTranslation();
    const modalRef = useRef<IMemberItineraryModalRef>(null);
    const [queryDateRange, setQueryDateRange] = useState<IValueType>(() => {
        return {
            isQuick: false,
            startDate: dayjs().date(1).format(DATETIME_FORMAT.YMD),
            endDate: dayjs().endOf('day').format(DATETIME_FORMAT.YMD),
        };
    });
    const [currentChart, setChart] = useState<string | null>(null);
    const [memberDetailsData, isDetailsLoading] = useMemberDetailsData({
        memberPartnerId: MemberPartnerId,
        memberUserName: MemberUserName,
        queryDateRange,
    });
    const [statisticsData, fetchStatisticsData, isStatisticsLoading] = useMemberStatisticsData({
        memberPartnerId: MemberPartnerId,
        memberUserName: MemberUserName,
        queryDateRange,
    });

    const totalAvailableAmount = useMemo(() => {
        if (!memberDetailsData || memberDetailsData.stats.length <= 0) return 0;

        return memberDetailsData.stats.filter((stat) => ['Recharge', 'Deposit'].includes(stat.key)).reduce((amount, stat) => amount + stat.amount, 0);
    }, [memberDetailsData]);

    function onSwitchChart(statisticType: string) {
        fetchStatisticsData({
            StatisticType: transformStatisticType(statisticType),
            PageNo: 1,
            PageSize: 10,
            DateFrom: queryDateRange.startDate as string,
            DateTo: queryDateRange.endDate as string,
            UserName: MemberUserName,
            PartnerId: MemberPartnerId,
        });
        setChart(statisticType);
    }

    function onViewItinerary(type: MemberStatisticType) {
        modalRef.current?.onModelDataTrigger({ path: '', method: 'GET' }, {}, type);
    }

    function updateDateRange(v: IValueType) {
        setChart(null);
        setQueryDateRange(v);
    }

    return (
        <>
            <div className="text-lg my-4 px-4 font-bold border-l-8 border-blue-400">
                <span>{t('Th_MemberAdmin_Statistics')}</span>
            </div>

            <div className="grid gap-6 grid-cols-[1fr_600px] grid-rows-[auto_1fr]">
                <div className="col-span-2 w-2/3">
                    <DateRangePicker
                        defaultDate={{ start: queryDateRange.startDate?.toString(), end: queryDateRange.endDate?.toString() }}
                        shortcuts="noToday"
                        valueHandler={updateDateRange}
                    />
                </div>

                <div className="row-start-2">
                    <MemberStatisticsTable data={memberDetailsData} onViewItinerary={onViewItinerary} onSwitchChart={onSwitchChart} />
                </div>

                <div className="col-start-2 row-start-2">
                    {currentChart !== null && statisticsData && (
                        <MemberStatisticsChart
                            statisticType={currentChart}
                            extraData={{
                                totalAvailableAmount,
                            }}
                            data={statisticsData}
                        />
                    )}
                </div>
            </div>

            <LoadingMask visible={isStatisticsLoading || isDetailsLoading} />

            <MemberItineraryModal ref={modalRef} partnerId={MemberPartnerId} userName={MemberUserName} queryDateRange={queryDateRange} />
        </>
    );
};

export default MemberAdminStatisticsPage;
